import Icon from '@ant-design/icons'

const Wallet2Svg = () => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6894 14.0459H10.75C7.98858 14.0459 5.75 16.2845 5.75 19.0459V36.9247C5.75 39.6861 7.98857 41.9247 10.75 41.9247H35.5985C38.3599 41.9247 40.5985 39.6861 40.5985 36.9247V19.0459C40.5985 16.2845 38.3599 14.0459 35.5985 14.0459H24.0455"
      stroke="#F26522"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2937 3.00801C20.3323 1.99153 17.9182 2.75752 16.9017 4.71891L11.165 15.7884H20.0841L25.773 4.81117L22.2937 3.00801Z"
      fill="#F26522"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.9768 9.45459L27.4121 5.53418L22.0977 15.7888H36.1931C36.1952 15.7888 36.1973 15.7888 36.1994 15.7888L36.6877 14.8465C37.7042 12.8851 36.9382 10.4711 34.9768 9.45459Z"
      fill="#F26522"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.75 24.2734C5.75 21.512 7.98858 19.2734 10.75 19.2734H35.5985C38.3599 19.2734 40.5985 21.512 40.5985 24.2734V36.9249C40.5985 39.6864 38.3599 41.925 35.5985 41.925H10.75C7.98857 41.925 5.75 39.6864 5.75 36.925V24.2734ZM18.9394 30.4954C18.9394 30.0812 19.2752 29.7454 19.6894 29.7454H23.2955V26.2431C23.2955 25.8289 23.6312 25.4931 24.0455 25.4931C24.4597 25.4931 24.7955 25.8289 24.7955 26.2431V29.7454H28.4015C28.8157 29.7454 29.1515 30.0812 29.1515 30.4954C29.1515 30.9096 28.8157 31.2454 28.4015 31.2454H24.7955V34.9552C24.7955 35.3695 24.4597 35.7052 24.0455 35.7052C23.6312 35.7052 23.2955 35.3695 23.2955 34.9552V31.2454H19.6894C19.2752 31.2454 18.9394 30.9096 18.9394 30.4954Z"
      fill="#F26522"
    />
  </svg>
)

const Wallet2Icon = (props: any) => <Icon component={Wallet2Svg} {...props} />
export default Wallet2Icon
