import { ArrowRightOutlined } from '@ant-design/icons'
import React from 'react'
import { HeaderBox, WrapBox, WrapBoxProduct } from '../styled'
import R from 'assets'
import { Row } from 'antd'
import { useHistory } from 'react-router-dom'
import { ROUTER_PATH } from 'common/config'
import { BigNews, SmallNews } from 'common/components/CardNews'
import { BigNewsBlock, SmallNewsBlock } from 'common/components/CardNews/styled'

const NewsList: React.FC<TProps> = ({ data, loading }) => {
  const history = useHistory()
  return (
    <WrapBox>
      <HeaderBox>
        {R.strings().title_bold_news}
        <span
          onClick={() => {
            history.push(ROUTER_PATH.NEWS)
          }}
        >
          {R.strings().title_see_more}
          <ArrowRightOutlined style={{ marginLeft: '10px' }} />
        </span>
      </HeaderBox>

      <Row justify="start">
        {data?.map((item: any, index: number) =>
          index < 2 ? (
            <BigNewsBlock key={index} xs={24} sm={12}>
              <BigNews data={item} />
            </BigNewsBlock>
          ) : index < 5 ? (
            <SmallNewsBlock xs={24} sm={8} key={index}>
              <SmallNews data={item} />
            </SmallNewsBlock>
          ) : (
            <></>
          )
        )}
      </Row>
    </WrapBox>
  )
}
export default NewsList
