import { ApiClient } from 'services/ApiService'

export const getListOrder = (payload: any) =>
  ApiClient.get(`/client/order/list-order`, payload)
export const getAllListOrder = (payload: any) =>
  ApiClient.get(`/client/order/list-order-all`, payload)
export const getDetailOrder = (id: number) =>
  ApiClient.get(`/client/order/${id}`)
export const getListBank = () => ApiClient.get(`/client/order/list-bank`)
export const cancelOrder = (order_id: any, payload: any) =>
  ApiClient.patch(`/client/order/${order_id}`, payload)
export const reasonCancel = () => ApiClient.get(`/client/order/config-reason`)
export const addReviewOrder = (
  petrol_store_id: number,
  order_id: number,
  payload: any
) =>
  ApiClient.post(
    `/customer/review/${petrol_store_id}/${order_id}/review`,
    payload
  )

export const paymentVT = (payload: any) =>
  ApiClient.post(`/payment/viettin-pay/create-payment`, payload)
