import { Progress, Tabs, Tag } from 'antd'
import styled from 'styled-components'

const StoreListBlock = styled.div``

const MapBlock = styled.div`
  height: 100%;
`

const InputBlock = styled.div`
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 10px 15px;
`

const ListStore = styled.div`
  overflow-y: scroll;
  border-top: 1px solid #ced4da;
  height: 420px;
  padding: 0 10px;
`
const WrapStoreItem = styled.div`
  display: flex;
  flex-flow: nowrap;
  padding: 10px 5px 0px 0;
  border-bottom: 1px solid #ced4da;
  cursor: pointer;
  :hover {
    background-color: #fff6ea;
  }
`
const TabStyle = styled(Tabs)`
  .ant-tabs-nav {
    padding: 0px;
    border-radius: 0 0 12px 12px;
  }
  .ant-tabs-tab {
    width: 34%;
    padding: 0px;
  }
  .ant-tabs-nav-list {
    width: 100%;
    border-radius: 0 0 12px 12px;
  }
  .ant-tabs-nav-wrap {
    border-radius: 0 0 12px 12px;
  }
  .ant-tabs-tab-btn {
    width: 100%;
    padding: 5px 0 10px 0;
    text-align: center;
    white-space: normal !important;
    :focus {
      color: #f26522 !important;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #f26522;
    border-bottom: 1px solid #f26522;
  }
  .ant-tabs-tab {
    padding: 0px !important;
  }
  margin: 0 0 50px 0;
`
const ImgStyle = styled.img`
  width: 90px;
  margin: 0 15px 0 0;
`
const TsgStyle = styled(Tag)`
  margin: 0px;
  border-radius: 12px;
  /* border */
  :hover {
    cursor: pointer;
  }
`
const ContainerRate = styled.div``
const AboveBlock = styled.div`
  background-color: white;
  margin: 25px 0 25px 0;
  padding: 25px;
  border-radius: 12px;
  display: flex;
  flex-flow: nowrap;
`
const MainBlock = styled.div`
  background-color: white;
  margin: 25px 0 25px 0;
  padding: 25px;
  border-radius: 12px;
`
const ProgressStyle = styled(Progress)`
  .ant-progress-text {
    display: none;
  }
  .ant-progress-bg {
    background-color: #fadb14;
  }
`
const RowStore = styled.div`
  cursor: pointer;
  padding: 7px;
  border-radius: 12px;
  :hover {
    background-color: #fff6ea;
  }
`

const ButtonGoogleMap = styled.div`
  background-color: #dee2e6;
  width: 100%;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  :hover {
    background-color: #f26522;
    color: white;
  }
`

const TextGoToMap = styled.div`
  font-size: 11px;
  color: #f26522;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

const WrapInforStore = styled.div`
  width: 67%;
  padding: 0px 0px 0px 10px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export {
  RowStore,
  ProgressStyle,
  MainBlock,
  ContainerRate,
  AboveBlock,
  TsgStyle,
  ImgStyle,
  TabStyle,
  StoreListBlock,
  MapBlock,
  InputBlock,
  WrapStoreItem,
  ListStore,
  ButtonGoogleMap,
  TextGoToMap,
  WrapInforStore,
}
