import Cookie from 'js-cookie'
import moment from 'moment'
export const hasCookie = (nameCookie: string) => {
  const cookie = Cookie.get(nameCookie)
  return cookie && cookie !== 'undefined' ? true : false
}

export const caculateCountDown = (date: any) => {
  return moment(date).diff(moment())
}
