import Icon from '@ant-design/icons'

const LocationSvg = () => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7.48598C0 3.3461 3.45949 0 7.6441 0C11.8405 0 15.3 3.3461 15.3 7.48598C15.3 9.57212 14.5413 11.5089 13.2925 13.1504C11.9149 14.9612 10.2169 16.5388 8.30569 17.7772C7.86826 18.0634 7.47349 18.085 6.9934 17.7772C5.07126 16.5388 3.37328 14.9612 2.00745 13.1504C0.757785 11.5089 0 9.57212 0 7.48598ZM5.12479 7.7191C5.12479 9.10596 6.25648 10.1967 7.64409 10.1967C9.0326 10.1967 10.1752 9.10596 10.1752 7.7191C10.1752 6.34304 9.0326 5.19917 7.64409 5.19917C6.25648 5.19917 5.12479 6.34304 5.12479 7.7191Z"
      fill="#ADB5BD"
    />
  </svg>
)

const LocationIconv2 = (props: any) => (
  <Icon component={LocationSvg} {...props} />
)
export default LocationIconv2
