import Icon from '@ant-design/icons'

const FlagESSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F0F0F0"
    />
    <path
      d="M2.48081 4.69434C1.5382 5.92073 0.827434 7.33424 0.413574 8.86982H6.65629L2.48081 4.69434Z"
      fill="#0052B4"
    />
    <path
      d="M23.5869 8.86977C23.173 7.33424 22.4622 5.92073 21.5196 4.69434L17.3442 8.86977H23.5869Z"
      fill="#0052B4"
    />
    <path
      d="M0.413574 15.1299C0.82748 16.6654 1.53825 18.0789 2.48081 19.3053L6.65615 15.1299H0.413574Z"
      fill="#0052B4"
    />
    <path
      d="M19.3058 2.48037C18.0794 1.53776 16.6659 0.826992 15.1304 0.413086V6.65576L19.3058 2.48037Z"
      fill="#0052B4"
    />
    <path
      d="M4.69434 21.5191C5.92073 22.4617 7.33424 23.1725 8.86977 23.5864V17.3438L4.69434 21.5191Z"
      fill="#0052B4"
    />
    <path
      d="M8.86973 0.413086C7.3342 0.826992 5.92068 1.53776 4.69434 2.48032L8.86973 6.65571V0.413086Z"
      fill="#0052B4"
    />
    <path
      d="M15.1309 23.5864C16.6664 23.1725 18.0799 22.4617 19.3062 21.5191L15.1309 17.3438V23.5864Z"
      fill="#0052B4"
    />
    <path
      d="M17.3442 15.1299L21.5196 19.3053C22.4622 18.079 23.173 16.6654 23.5869 15.1299H17.3442Z"
      fill="#0052B4"
    />
    <path
      d="M23.8984 10.4348H13.5653H13.5653V0.101578C13.0529 0.034875 12.5305 0 12 0C11.4694 0 10.9471 0.034875 10.4348 0.101578V10.4347V10.4347H0.101578C0.034875 10.9471 0 11.4695 0 12C0 12.5306 0.034875 13.0529 0.101578 13.5652H10.4347H10.4347V23.8984C10.9471 23.9651 11.4694 24 12 24C12.5305 24 13.0529 23.9652 13.5652 23.8984V13.5653V13.5653H23.8984C23.9651 13.0529 24 12.5306 24 12C24 11.4695 23.9651 10.9471 23.8984 10.4348Z"
      fill="#D80027"
    />
    <path
      d="M15.1304 15.1309L20.4852 20.4857C20.7315 20.2395 20.9664 19.9821 21.1906 19.7153L16.6061 15.1309H15.1304V15.1309Z"
      fill="#D80027"
    />
    <path
      d="M8.86951 15.1309H8.86941L3.51465 20.4856C3.76084 20.7319 4.01823 20.9668 4.28504 21.191L8.86951 16.6064V15.1309Z"
      fill="#D80027"
    />
    <path
      d="M8.86976 8.8696V8.86951L3.51495 3.51465C3.26866 3.76084 3.03373 4.01823 2.80957 4.28504L7.39409 8.86955H8.86976V8.8696Z"
      fill="#D80027"
    />
    <path
      d="M15.1304 8.86981L20.4852 3.5149C20.239 3.26862 19.9817 3.03368 19.7148 2.80957L15.1304 7.39409V8.86981Z"
      fill="#D80027"
    />
  </svg>
)

const FlagESIcon = (props: any) => <Icon component={FlagESSvg} {...props} />
export default FlagESIcon
