import './Page404.css'
import { useHistory } from 'react-router-dom'
import { ROUTER_PATH } from 'common/config'

export default function Page404() {
  const history = useHistory()
  return (
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <div className="col-sm-10 col-sm-offset-1  text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center ">Network Error</h1>
              </div>

              <div className="contant_box_404">
                <h3 className="h2">Look like you're lost</h3>

                <p>Hãy kiểm tra lại kết nối của bạn!</p>

                <div
                  className="link_404"
                  onClick={() => {
                    history.push(ROUTER_PATH.HOME)
                  }}
                >
                  Go to home page
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
