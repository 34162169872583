import { ROUTER_PATH } from 'common/config'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'

import '../slide.css'

import {
  BannerBlock,
  SliderStyled,
  SliderWrap,
  StyledImage,
  ContainerStyled,
  CategoryContainerStyled,
  CategoryHomeMobileStyled,
} from '../styled'
import CategoryHome from './CategoryHome'
import CategoryHomeMobile from './CategoryHome.Mobile'
const SlideBanner: React.FC<TProps> = ({
  data,
  loading,
  petrol_best_price,
}) => {
  const settings = {
    dots: true,
    // fade: true,
    infinite: true,
    arrows: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 2500,
    // focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
          customPaging: function (i: number) {
            return <></>
          },
        },
      },
    ],
    customPaging: function (i: number) {
      return <div className="dot"></div>
    },
    dotsClass: 'slick-dots slick-thumb',
  }
  const history = useHistory()

  const renderSlide = () => {
    return data?.map((item: any, index: any) => (
      <BannerBlock
        onClick={() => {
          history.push({
            pathname: `${ROUTER_PATH.DETAIL_NEWS}/${item?.id}`,
          })
        }}
        key={item.id}
      >
        <StyledImage key_image={item.id} src={item.image_url} />
      </BannerBlock>
    ))
  }

  return (
    <SliderWrap>
      <ContainerStyled className="container">
        <CategoryContainerStyled
          style={{ height: '100%', alignItems: 'center' }}
        >
          <CategoryHome data={petrol_best_price} />
        </CategoryContainerStyled>
        <div style={{ flex: 1, overflow: 'hidden' }}>
          {loading ? (
            <Skeleton style={{ backgroundColor: '#E6E6E6', height: '200px' }} />
          ) : (
            <SliderStyled {...settings}>{renderSlide()}</SliderStyled>
          )}
        </div>
        <CategoryHomeMobileStyled>
          <CategoryHomeMobile data={petrol_best_price} />
        </CategoryHomeMobileStyled>
      </ContainerStyled>
    </SliderWrap>
  )
}
export default SlideBanner
