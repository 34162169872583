import { ArrowLeftOutlined } from '@ant-design/icons'
import { Divider, Form } from 'antd'
import R from 'assets'
import { notificationError, notificationSuccess } from 'utils/notification'
import { updatePassword } from '../AuthApi'
import {
  AboveBlock,
  ButtonCode,
  CodeBlock,
  ContentBlock,
  FooterCode,
  FormStyle,
  InputPass,
  StepCode,
  SubStep,
  UnderBlock,
} from '../authStyle'

type Props = {
  setStep: any
  setTokenForgot?: any
  tokenForgot?: any
}
export const Step2ForgotPassword: React.FC<Props> = ({
  setStep,
  setTokenForgot,
  tokenForgot,
}: Props) => {
  const [form] = Form.useForm()

  const onFinish = async (values: any) => {
    if (!tokenForgot) return notificationError('Mã xác thực không tồn tại')
    try {
      delete values.confirm
      let newData

      newData = {
        ...values,
        password: values.password,
      }

      const res = await updatePassword(newData, {
        // set header
        headers: {
          'token-reset-password': tokenForgot,
        },
      })

      setTokenForgot('')

      notificationSuccess(res?.message)
      setStep(3)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ContentBlock>
      <div>
        <CodeBlock>
          <AboveBlock justify="space-between">
            <ArrowLeftOutlined onClick={() => setStep(1)} />
            <span>{R.strings().text_form_change_password}</span>
            <ArrowLeftOutlined style={{ color: 'white' }} />
          </AboveBlock>
          <Divider style={{ marginTop: 0 }} />
          <UnderBlock>
            <FormStyle
              layout="vertical"
              labelAlign="left"
              form={form}
              name="infor_signup"
              initialValues={{
                password: null,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                label={
                  <span style={{ fontSize: 16 }}>
                    {R.strings().text_form_password_signup}
                  </span>
                }
                labelAlign="right"
                name="password"
                rules={[
                  {
                    required: true,
                    message: R.strings().notify_enter_password,
                  },
                  {
                    min: 6,
                    message: R.strings().err_password,
                  },
                ]}
              >
                <InputPass
                  placeholder={R.strings().text_form_password_signup}
                />
              </Form.Item>
              <Form.Item
                name="confirm"
                label={
                  <span style={{ fontSize: 16 }}>
                    {' '}
                    {R.strings().text_form_confirm_password}
                  </span>
                }
                dependencies={['password']}
                style={{
                  marginBottom: 50,
                }}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: R.strings().notify_enter_password,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error(R.strings().err_confirm))
                    },
                  }),
                ]}
              >
                <InputPass
                  placeholder={R.strings().text_form_confirm_password}
                />
              </Form.Item>
              <Form.Item
                style={{
                  paddingBottom: 15,
                }}
              >
                <ButtonCode>{R.strings().text_form_confirm}</ButtonCode>
              </Form.Item>
            </FormStyle>
          </UnderBlock>
        </CodeBlock>
        <FooterCode>
          <StepCode direction="horizontal" responsive={false} current={1}>
            <SubStep></SubStep>
            <SubStep></SubStep>
            <SubStep></SubStep>
          </StepCode>
        </FooterCode>
      </div>
    </ContentBlock>
  )
}
