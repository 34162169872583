import { Col } from 'antd'
import styled from 'styled-components'

const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const BigNewsBlock = styled(Col)`
  height: 250px;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
`

const SmallNewsBlock = styled(Col)`
  min-height: 220px;
  height: auto;
  cursor: pointer;
  margin-bottom: 10px;
`

const FlexSmallNews = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  cursor: pointer;
`

const ImageNews = styled.div`
  height: 60%;
  position: relative;
  @media only screen and (max-width: 480px) {
    height: 250px;
  }
`

const OverviewNews = styled.div`
  font-weight: 600;
  padding: 0 5px;
  margin-top: 10px;
`

const TimeNews = styled.div`
  color: #8c8c8c;
`

const ImageBigNews = styled.div`
  height: 100%;
  position: relative;
  width: 96%;
`

const OverviewBigNews = styled.div`
  font-weight: 400;
  position: absolute;
  top: 70%;
  padding: 0 15px;
  color: white;
`

const TimeBigNews = styled.div`
  display: flex;
  align-items: center;
`

export {
  BigNewsBlock,
  SmallNewsBlock,
  FlexSmallNews,
  ImageNews,
  OverviewNews,
  TimeNews,
  StyledImage,
  ImageBigNews,
  OverviewBigNews,
  TimeBigNews,
}
