import React from 'react'
import { ContentItem, SmallItem } from './styled'

const PaymentPrivacyPolicy: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '20px',
        listStyleType: ' upper-roman',
        textAlign: 'justify',
      }}
    >
      CHÍNH SÁCH THANH TOÁN
      <SmallItem>
        <b>1. Thanh toán</b>
        <ContentItem>
          <p>
            Khi Người dùng đã hoàn thành việc sử dụng dịch vụ, Người dùng được
            yêu cầu thanh toán đầy đủ cho PYOYO hoặc Cửa hàng bằng cách chọn một
            trong các phương thức thanh toán có sẵn trên ứng dụng.
          </p>
          <p>
            Với thanh toán bằng tiền mặt, Người dùng sẽ thanh toán tất cả các
            khoản phí Người dùng phải trả cho PYOYO và bất kỳ khoản tiền nào
            khác phải trả cho Nhà cung cấp.
          </p>
          <p>
            Người dùng chỉ có thể thay đổi kênh thanh toán trước khi thực hiện
            thanh toán.
          </p>
          <p>Tất cả các khoản thanh toán phải bằng Việt Nam Đồng (Đồng).</p>
          <p>
            Không ảnh hưởng đến các quyền và biện pháp khắc phục khác, PYOYO có
            thể tạm dừng xử lý bất kỳ giao dịch nào khi tin rằng giao dịch đó có
            thể là gian lận, bất hợp pháp hoặc có thể liên quan đến bất kỳ hoạt
            động tội phạm nào hoặc nơi mà được tin rằng Người dùng vi phạm các
            Điều khoản sử dụng này, chính sách, hoặc Luật áp dụng. Trong trường
            hợp như vậy, Người dùng sẽ không buộc PYOYO chịu trách nhiệm cho bất
            kỳ sự từ chối, trì hoãn, đình chỉ hoặc hủy bỏ bất kỳ khoản thanh
            toán hoặc bất kỳ chương trình khuyến mại nào cho Người dùng.
          </p>
          <p>
            Nếu Người dùng bị tính phí không chính xác, Người dùng có thể liên
            hệ với chúng tôi qua ứng dụng Người dùng hoặc qua email tới
            sale@yoyojsc.com.vn để được hỗ trợ. PYOYO có toàn quyền quyết định
            trong việc xem xét có nên xử lý bất kỳ khoản hoàn trả hoặc trả lại
            nào cho Người dùng hay không và phương thức hoàn trả hoặc trả lại
            khoản thanh toán đó.
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>2. Phí</b>
        <ContentItem>
          <p>
            Nếu Điều khoản dịch vụ này không có quy định nào khác hoặc các bên
            không có thỏa thuận nào khác, Khách hàng không phải thanh toán bất
            kỳ khoản phí nào khi sử dụng sàn giao dịch thương mại điện tử YOYO.
            Nếu YOYO có cơ chế thu phí với người dùng sẽ thông báo tới người
            dùng thông qua ứng dụng và website chính thức của YOYO.
          </p>
          <p>
            Nếu có bất kỳ khoản phí nào phải trả, khoản phí đó cũng là đối tượng
            chịu thuế theo quy định pháp luật có liên quan. Nhà cung cấp thừa
            nhận và đồng ý rằng YOYO có thể khấu trừ các khoản phí phải trả cho
            YOYO và các khoản thuế áp dụng từ số tiền bán hàng được thanh toán
            bởi Người mua. YOYO sẽ xuất biên lai hoặc hóa đơn tài chính cho
            khoản phí và khoản thuế do Nhà cung cấp chi trả nếu có yêu cầu.
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>3. Hình thức thanh toán</b>
        <ContentItem>
          <p>Có các hình thức thanh toán sau đây tại ứng dụng PYOYO:</p>
          <ol>
            <li>Thanh toán bằng tiền mặt: Đặt hàng, nhận hàng và thanh toán</li>
            <li>
              Thanh toán bằng quẹt mã QR code thông qua ứng dụng của ngân hàng
              của người dùng: đặt hàng, nhận hàng và thanh toán bằng QR code
            </li>
            <li>
              Thanh toán bằng quẹt thẻ ATM: Đặt hàng, nhận hàng và thanh toán
              qua thẻ ATM nội địa hoặc thẻ quốc tế
            </li>
            <li>
              Thanh toán bằng VNpay: Người dùng liên kết thẻ ngân hàng vào ứng
              dụng PYOYO theo các bước được hướng dẫn trên đường link. Khi thực
              hiện đặt hàng, khách hàng lựa chọn thanh toán qua VNpay thì đơn
              hàng sẽ được thanh toán ngay lập tức.
            </li>
            <li>
              Thanh toán bằng chuyển khoản: Đặt hàng và sau đó khách hàng copy
              nội dung chuyển khoản theo cú pháp mà PYOYO đã sắp đặt trước. Truy
              cập ứng dụng ngân hàng của khách hàng, và thực hiện thao tác
              chuyển khoản về tài khoản đích của PYOYO.
            </li>
          </ol>
        </ContentItem>
      </SmallItem>
    </div>
  )
}
export default PaymentPrivacyPolicy
