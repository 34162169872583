import { Button, Input, Space } from 'antd'
import R from 'assets'
import React from 'react'
import { ModalStyle, RadioStyle } from '../styled'

type Props = {
  isModalVisible: any
  setIsModalVisible: any
  id_order: number
  cancelOrder: any
  setReasons: any
  reasons: any
  dataReasons: any
}

const ReasonCancel: React.FC<Props> = ({
  isModalVisible,
  setIsModalVisible,
  id_order,
  cancelOrder,
  setReasons,
  reasons,
  dataReasons,
}) => {
  const [other, setOther] = React.useState('')

  return (
    <>
      <ModalStyle
        title={R.strings().reason_cancel}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        footer={[
          <Button
            style={{
              backgroundColor: '#f26522',
              color: 'white',
              fontWeight: 600,
              border: 'none',
              borderRadius: 12,
              width: 100,
            }}
            onClick={() => {
              setIsModalVisible(false)
              cancelOrder(id_order, reasons)
            }}
          >
            {R.strings().send}
          </Button>,
        ]}
      >
        <RadioStyle.Group
          style={{ width: '100%' }}
          defaultValue={dataReasons?.data?.[0]?.id}
          onChange={(e: any) => {
            setOther(e.target.value)
          }}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            {dataReasons?.data?.map((item: any) => (
              <RadioStyle
                value={item?.id}
                onClick={() => {
                  setReasons({
                    ...reasons,
                    reason_id: item?.id,
                    reason: item?.content,
                  })
                }}
              >
                {localStorage.getItem('i18nextLng') === 'vi'
                  ? item?.content
                  : item?.content_en}
              </RadioStyle>
            ))}

            <RadioStyle value="other">Khác</RadioStyle>
            {other === 'other' && (
              <Input.TextArea
                style={{ width: '100%' }}
                onChange={e => {
                  setReasons({
                    reason: e.target.value,
                  })
                }}
                rows={4}
                placeholder="Nhập lý do"
              />
            )}
          </Space>
        </RadioStyle.Group>
      </ModalStyle>
    </>
  )
}

export default ReasonCancel
