import Icon from '@ant-design/icons'

const MinusSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 8H12.3333"
        stroke="#69747E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MinusIcon = (props: any) => <Icon component={MinusSvg} {...props} />
export default MinusIcon
