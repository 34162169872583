import Icon from '@ant-design/icons'

const MedalSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0215 1.8623H6.77148V7.00496C6.77148 7.33721 6.94727 7.64462 7.23367 7.81299L9.95899 9.41612H14.834L17.5593 7.81299C17.8456 7.64452 18.0215 7.33712 18.0215 7.00496V1.8623Z"
      fill="#ADB5BD"
    />
    <path
      d="M18.6199 1.86276H6.17308C5.83052 1.86276 5.55273 1.58507 5.55273 1.24241C5.55273 0.899851 5.83042 0.62207 6.17308 0.62207H18.6199C18.9625 0.62207 19.2402 0.899758 19.2402 1.24241C19.2402 1.58507 18.9625 1.86276 18.6199 1.86276Z"
      fill="#868E96"
    />
    <path d="M10.5212 1.8623H14.2713V9.41612H10.5212V1.8623Z" fill="white" />
    <path d="M10.5212 8.94824H14.2713V9.41699H10.5212V8.94824Z" fill="white" />
    <path
      d="M18.0215 19.7342V14.3216C18.0215 13.9866 17.8428 13.6771 17.5527 13.5097L12.8652 10.8033C12.5752 10.6359 12.2178 10.6359 11.9277 10.8033L7.24023 13.5097C6.95017 13.6771 6.77148 13.9867 6.77148 14.3216V19.7342C6.77148 20.0692 6.95017 20.3787 7.24023 20.5461L11.9277 23.2525C12.2178 23.4199 12.5752 23.4199 12.8652 23.2525L17.5527 20.5461C17.8428 20.3787 18.0215 20.0692 18.0215 19.7342Z"
      fill="#868E96"
    />
    <path
      d="M16.7235 18.9848V15.0711C16.7235 14.7361 16.5448 14.4266 16.2547 14.2592L12.8653 12.3023C12.5752 12.1349 12.2178 12.1349 11.9278 12.3023L8.53833 14.2592C8.24827 14.4266 8.06958 14.7362 8.06958 15.0711V18.9848C8.06958 19.3198 8.24827 19.6293 8.53833 19.7967L11.9278 21.7536C12.2178 21.921 12.5752 21.921 12.8653 21.7536L16.2547 19.7967C16.5448 19.6293 16.7235 19.3198 16.7235 18.9848Z"
      fill="#ADB5BD"
    />
    <path
      d="M12.5748 14.4733L13.1456 16.2298H14.9926C15.1742 16.2298 15.2497 16.4622 15.1028 16.569L13.6086 17.6547L14.1794 19.4112C14.2355 19.5839 14.0378 19.7276 13.8908 19.6208L12.3966 18.5352L10.9024 19.6208C10.7555 19.7276 10.5578 19.5839 10.6139 19.4112L11.1846 17.6547L9.69043 16.569C9.54352 16.4622 9.61899 16.2298 9.80068 16.2298H11.6476L12.2183 14.4733C12.2743 14.3006 12.5187 14.3006 12.5748 14.4733Z"
      fill="white"
    />
    <path
      d="M4.51878 17.3873L4.57859 17.6659C4.6415 17.9587 4.87016 18.1873 5.16294 18.2502L5.44156 18.31C5.50878 18.3245 5.50878 18.4203 5.44156 18.4347L5.16294 18.4945C4.87016 18.5574 4.6415 18.7861 4.57859 19.0789L4.51878 19.3575C4.50434 19.4247 4.40853 19.4247 4.39409 19.3575L4.33428 19.0789C4.27138 18.7861 4.04272 18.5574 3.74994 18.4945L3.47131 18.4347C3.40409 18.4203 3.40409 18.3245 3.47131 18.31L3.74994 18.2502C4.04272 18.1873 4.27138 17.9587 4.33428 17.6659L4.39409 17.3873C4.40853 17.3201 4.50434 17.3201 4.51878 17.3873Z"
      fill="#868E96"
    />
    <path
      d="M5.71613 20.2262L5.80378 20.6345C5.89594 21.0635 6.231 21.3985 6.66 21.4907L7.06828 21.5783C7.16672 21.5994 7.16672 21.74 7.06828 21.761L6.66 21.8487C6.231 21.9409 5.89594 22.2759 5.80378 22.7049L5.71613 23.1132C5.69503 23.2116 5.5545 23.2116 5.53341 23.1132L5.44575 22.7049C5.35359 22.2759 5.01853 21.9409 4.58953 21.8487L4.18125 21.761C4.08281 21.74 4.08281 21.5994 4.18125 21.5783L4.58953 21.4907C5.01853 21.3985 5.35359 21.0635 5.44575 20.6345L5.53341 20.2262C5.5545 20.1277 5.69503 20.1277 5.71613 20.2262Z"
      fill="#868E96"
    />
    <path
      d="M19.342 11.2905L19.4181 11.6447C19.4979 12.0168 19.7886 12.3075 20.1607 12.3874L20.5149 12.4635C20.6003 12.4818 20.6003 12.6036 20.5149 12.622L20.1607 12.698C19.7886 12.7779 19.498 13.0686 19.4181 13.4407L19.342 13.7949C19.3236 13.8803 19.2019 13.8803 19.1835 13.7949L19.1075 13.4407C19.0276 13.0686 18.7369 12.778 18.3648 12.698L18.0106 12.622C17.9252 12.6036 17.9252 12.4818 18.0106 12.4635L18.3648 12.3874C18.7369 12.3075 19.0275 12.0168 19.1075 11.6447L19.1835 11.2905C19.2019 11.2052 19.3236 11.2052 19.342 11.2905Z"
      fill="#868E96"
    />
  </svg>
)

const MedalIcon = (props: any) => <Icon component={MedalSvg} {...props} />
export default MedalIcon
