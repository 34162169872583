import React, { useEffect, useState } from 'react'
import SlideBanner from './components/SlideBanner'
import CategoryHome from './components/CategoryHome'
import DealsHot from './components/DealsHot'
import SellingProduct from './components/SellingProduct'
import NewsList from './components/NewsList'
import Brands from './components/Brands'
import { ContainerHome, WrapBody } from './styled'
import { getDataHomeScreen } from './ApiHome'

const HomeScreen: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<DataHome>({
    banners: [],
    best_deal: [],
    best_selling: [],
    partners: [],
    news: [],
    petrol_best_price: {},
  })
  useEffect(() => {
    getDataSource()
  }, [])

  const getDataSource = async () => {
    try {
      setIsLoading(true)
      const res = await getDataHomeScreen()
      setDataSource({
        banners: res.data.banners,
        best_deal: res.data.best_deal,
        best_selling: res.data.best_selling,
        partners: res.data.partners,
        news: res.data.news,
        petrol_best_price: res?.data?.petrol_best_price,
      })
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <ContainerHome>
      <SlideBanner
        petrol_best_price={dataSource?.petrol_best_price}
        data={dataSource.banners}
        loading={isLoading}
      />

      <div className="container">
        <WrapBody>
          {dataSource.best_deal && dataSource.best_deal.length > 0 && (
            <DealsHot data={dataSource.best_deal} loading={isLoading} />
          )}

          <SellingProduct data={dataSource.best_selling} loading={isLoading} />

          <NewsList data={dataSource.news} loading={isLoading} />

          <Brands data={dataSource.partners} loading={isLoading} />
        </WrapBody>
      </div>
    </ContainerHome>
  )
}

export default HomeScreen
