import Icon from '@ant-design/icons'

const DebtSvg = () => (
  <svg
    width="16"
    height="22"
    viewBox="0 0 16 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3984 6.9935L9.77337 0.9935C9.73832 0.956117 9.69598 0.926315 9.64896 0.905934C9.60194 0.885553 9.55125 0.875025 9.5 0.875H2.375C1.8779 0.875595 1.40133 1.07333 1.04983 1.42483C0.698331 1.77633 0.500595 2.2529 0.5 2.75V19.25C0.500595 19.7471 0.698331 20.2237 1.04983 20.5752C1.40133 20.9267 1.8779 21.1244 2.375 21.125H13.625C14.1221 21.1244 14.5987 20.9267 14.9502 20.5752C15.3017 20.2237 15.4994 19.7471 15.5 19.25V7.25C15.5 7.15469 15.4636 7.06298 15.3984 6.9935Z"
      fill="#5AAAFF"
    />
    <path
      d="M15.3984 6.99355L9.77338 0.993552C9.72193 0.938762 9.65521 0.900696 9.58186 0.884289C9.50852 0.867882 9.43194 0.873892 9.36205 0.901539C9.29216 0.929187 9.2322 0.977196 9.18993 1.03934C9.14766 1.10148 9.12504 1.17489 9.125 1.25005V6.50005C9.125 6.79842 9.24353 7.08457 9.45451 7.29555C9.66548 7.50653 9.95163 7.62505 10.25 7.62505H15.125C15.1981 7.62502 15.2696 7.6036 15.3308 7.56344C15.3919 7.52329 15.4399 7.46614 15.469 7.39903C15.498 7.33192 15.5068 7.25778 15.4943 7.18574C15.4817 7.1137 15.4484 7.0469 15.3984 6.99355Z"
      fill="#449DE0"
    />
    <path
      d="M5 5.75C4.85166 5.75 4.70666 5.70601 4.58332 5.6236C4.45999 5.54119 4.36386 5.42406 4.30709 5.28701C4.25032 5.14997 4.23547 4.99917 4.26441 4.85368C4.29335 4.7082 4.36478 4.57456 4.46967 4.46967C4.57456 4.36478 4.7082 4.29335 4.85368 4.26441C4.99917 4.23547 5.14997 4.25032 5.28701 4.30709C5.42406 4.36386 5.54119 4.45999 5.6236 4.58332C5.70601 4.70666 5.75 4.85166 5.75 5C5.75 5.09946 5.78951 5.19484 5.85983 5.26517C5.93016 5.33549 6.02554 5.375 6.125 5.375C6.22446 5.375 6.31984 5.33549 6.39017 5.26517C6.46049 5.19484 6.5 5.09946 6.5 5C6.49876 4.66842 6.38768 4.3466 6.18414 4.08484C5.98059 3.82308 5.69605 3.63614 5.375 3.55325V3.125C5.375 3.02554 5.33549 2.93016 5.26517 2.85984C5.19484 2.78951 5.09946 2.75 5 2.75C4.90054 2.75 4.80516 2.78951 4.73483 2.85984C4.66451 2.93016 4.625 3.02554 4.625 3.125V3.55325C4.27189 3.64413 3.96409 3.86077 3.75937 4.16249C3.55465 4.46421 3.46708 4.83028 3.51311 5.19198C3.55914 5.55369 3.7356 5.88615 4.00937 6.12699C4.28313 6.36782 4.63538 6.50045 5 6.5C5.14834 6.5 5.29334 6.54399 5.41668 6.6264C5.54001 6.70881 5.63614 6.82594 5.69291 6.96299C5.74968 7.10003 5.76453 7.25083 5.73559 7.39632C5.70665 7.5418 5.63522 7.67544 5.53033 7.78033C5.42544 7.88522 5.2918 7.95665 5.14632 7.98559C5.00083 8.01453 4.85003 7.99968 4.71299 7.94291C4.57594 7.88614 4.45881 7.79001 4.3764 7.66668C4.29399 7.54334 4.25 7.39834 4.25 7.25C4.25 7.15054 4.21049 7.05516 4.14017 6.98483C4.06984 6.91451 3.97446 6.875 3.875 6.875C3.77554 6.875 3.68016 6.91451 3.60983 6.98483C3.53951 7.05516 3.5 7.15054 3.5 7.25C3.50124 7.58158 3.61232 7.9034 3.81586 8.16516C4.01941 8.42692 4.30395 8.61386 4.625 8.69675V9.125C4.625 9.22446 4.66451 9.31984 4.73483 9.39017C4.80516 9.46049 4.90054 9.5 5 9.5C5.09946 9.5 5.19484 9.46049 5.26517 9.39017C5.33549 9.31984 5.375 9.22446 5.375 9.125V8.69675C5.72811 8.60587 6.03591 8.38923 6.24063 8.08751C6.44535 7.78579 6.53292 7.41972 6.48689 7.05802C6.44086 6.69631 6.2644 6.36385 5.99063 6.12301C5.71687 5.88218 5.36462 5.74955 5 5.75Z"
      fill="#FFDE39"
    />
    <path
      d="M12.125 11.75H3.875C3.77554 11.75 3.68016 11.7105 3.60983 11.6402C3.53951 11.5698 3.5 11.4745 3.5 11.375C3.5 11.2755 3.53951 11.1802 3.60983 11.1098C3.68016 11.0395 3.77554 11 3.875 11H12.125C12.2245 11 12.3198 11.0395 12.3902 11.1098C12.4605 11.1802 12.5 11.2755 12.5 11.375C12.5 11.4745 12.4605 11.5698 12.3902 11.6402C12.3198 11.7105 12.2245 11.75 12.125 11.75Z"
      fill="white"
    />
    <path
      d="M12.125 9.5H7.625C7.52554 9.5 7.43016 9.46049 7.35983 9.39017C7.28951 9.31984 7.25 9.22446 7.25 9.125C7.25 9.02554 7.28951 8.93016 7.35983 8.85983C7.43016 8.78951 7.52554 8.75 7.625 8.75H12.125C12.2245 8.75 12.3198 8.78951 12.3902 8.85983C12.4605 8.93016 12.5 9.02554 12.5 9.125C12.5 9.22446 12.4605 9.31984 12.3902 9.39017C12.3198 9.46049 12.2245 9.5 12.125 9.5Z"
      fill="white"
    />
    <path
      d="M12.125 14H3.875C3.77554 14 3.68016 13.9605 3.60983 13.8902C3.53951 13.8198 3.5 13.7245 3.5 13.625C3.5 13.5255 3.53951 13.4302 3.60983 13.3598C3.68016 13.2895 3.77554 13.25 3.875 13.25H12.125C12.2245 13.25 12.3198 13.2895 12.3902 13.3598C12.4605 13.4302 12.5 13.5255 12.5 13.625C12.5 13.7245 12.4605 13.8198 12.3902 13.8902C12.3198 13.9605 12.2245 14 12.125 14Z"
      fill="white"
    />
    <path
      d="M12.125 16.25H3.875C3.77554 16.25 3.68016 16.2105 3.60983 16.1402C3.53951 16.0698 3.5 15.9745 3.5 15.875C3.5 15.7755 3.53951 15.6802 3.60983 15.6098C3.68016 15.5395 3.77554 15.5 3.875 15.5H12.125C12.2245 15.5 12.3198 15.5395 12.3902 15.6098C12.4605 15.6802 12.5 15.7755 12.5 15.875C12.5 15.9745 12.4605 16.0698 12.3902 16.1402C12.3198 16.2105 12.2245 16.25 12.125 16.25Z"
      fill="white"
    />
    <path
      d="M12.125 18.5H3.875C3.77554 18.5 3.68016 18.4605 3.60983 18.3902C3.53951 18.3198 3.5 18.2245 3.5 18.125C3.5 18.0255 3.53951 17.9302 3.60983 17.8598C3.68016 17.7895 3.77554 17.75 3.875 17.75H12.125C12.2245 17.75 12.3198 17.7895 12.3902 17.8598C12.4605 17.9302 12.5 18.0255 12.5 18.125C12.5 18.2245 12.4605 18.3198 12.3902 18.3902C12.3198 18.4605 12.2245 18.5 12.125 18.5Z"
      fill="white"
    />
  </svg>
)

const DebtIcon = (props: any) => <Icon component={DebtSvg} {...props} />
export default DebtIcon
