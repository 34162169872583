import { Affix, Breadcrumb, Button, Popconfirm, Spin } from 'antd'
import R from 'assets'
import { ROUTER_PATH } from 'common/config'
import React, { useEffect, useState } from 'react'
import { formatPrice } from 'utils/ruleForm'
import { deleteAllProduct, deleteProduct, getCart } from './ApiCart'
import StoreInCart from './components/StoreInCart'
import { getDataCard, setDataCard } from './CartSlice'
import {
  AffixBottomBlock,
  ButtonPurchase,
  CheckAllBlock,
  CheckBoxStyled,
  ColCart,
  HeaderCart,
  TotalPriceBlock,
  WrapCart,
} from './styled'
import { useDispatch, useSelector } from 'react-redux'
import { IProductItem, IStoreItem } from './interface'
import { useHistory, useLocation } from 'react-router-dom'
import { notificationError } from 'utils/notification'
import { EmptyComponent } from 'common/components/Empty'
import { setAmountCart } from 'features/auth/AuthSlice'
import { API_STATUS } from 'utils/constants'

const CartScreen: React.FC = () => {
  const dataCart = useSelector(getDataCard)
  const dispatch = useDispatch()
  const history = useHistory()
  const [params, setParams] = useState({ page: 1 })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [selectedAll, setSelectedAll] = useState<boolean>(false)
  const location: any = useLocation()
  useEffect(() => {
    getDataCart()
  }, [])

  useEffect(() => {
    //Tính giá của sản phẩm đã chọn
    let total: number = 0
    let amount_selected = 0
    dataCart?.map((item: IStoreItem) => {
      item.product_stocks?.map((itemA: IProductItem) => {
        if (itemA.checkedProduct) {
          total +=
            itemA.amount * (itemA.sale_price ? itemA.sale_price : itemA.price)
        }
      })
      if (item.checkedStore) {
        amount_selected += 1
      }
    })
    setTotalPrice(total)
    // Xét checkboxAll
    if (dataCart.length !== 0 && amount_selected === dataCart.length) {
      setSelectedAll(true)
    } else {
      setSelectedAll(false)
    }
  }, [dataCart])

  const getDataCart = async () => {
    try {
      setIsLoading(true)
      const res = await getCart(params)
      const convertData = res.data?.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          product_stocks: item.product_stocks?.map(
            (itemA: any, indexA: number) => {
              if (
                location.state?.cart_id &&
                itemA.cart.id === location.state?.cart_id
              ) {
                return {
                  cart_id: itemA.cart.id,
                  product_stock_id: itemA.cart.product_stock_id,
                  amount: itemA.cart.amount,
                  name_product: itemA.product.name,
                  img_product: itemA.product.product_medias[0].media_url,
                  price: itemA.price,
                  checkedProduct: true,
                  index: indexA,
                  sale_price: itemA.sale_price,
                  status_home: itemA.product.status_home,
                  quantity_home: itemA.product.quantity_home,
                }
              } else {
                return {
                  cart_id: itemA.cart.id,
                  product_stock_id: itemA.cart.product_stock_id,
                  amount: itemA.cart.amount,
                  name_product: itemA.product.name,
                  img_product: itemA.product.product_medias[0].media_url,
                  price: itemA.price,
                  checkedProduct: false,
                  index: indexA,
                  sale_price: itemA.sale_price,
                  status_home: itemA.product.status_home,
                  quantity_home: itemA.product.quantity_home,
                }
              }
            }
          ),
          checkedStore: item.product_stocks
            ?.map((itemA: any, indexA: number) => {
              if (
                location.state?.cart_id &&
                itemA.cart.id === location.state?.cart_id
              ) {
                return {
                  cart_id: itemA.cart.id,
                  product_stock_id: itemA.cart.product_stock_id,
                  amount: itemA.cart.amount,
                  name_product: itemA.product.name,
                  img_product: itemA.product.product_medias[0].media_url,
                  price: itemA.price,
                  checkedProduct: true,
                  index: indexA,
                  sale_price: itemA.sale_price,
                  status_home: itemA.product.status_home,
                  quantity_home: itemA.product.quantity_home,
                }
              } else {
                return {
                  cart_id: itemA.cart.id,
                  product_stock_id: itemA.cart.product_stock_id,
                  amount: itemA.cart.amount,
                  name_product: itemA.product.name,
                  img_product: itemA.product.product_medias[0].media_url,
                  price: itemA.price,
                  checkedProduct: false,
                  index: indexA,
                  sale_price: itemA.sale_price,
                  status_home: itemA.product.status_home,
                  quantity_home: itemA.product.quantity_home,
                }
              }
            })
            .find((itemFind: any) => !itemFind.checkedProduct)
            ? false
            : true,
          name_store: item.name,
          phone_store: item.phone,
          address_store:
            item.location_address +
            ' ' +
            item.ward.name +
            ', ' +
            item.district.name +
            ', ' +
            item.province.name,
        }
      })
      dispatch(setDataCard(convertData))
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  const selectAllProduct = (value: boolean) => {
    let new_array = [...dataCart]
    new_array = new_array?.map((item: any) => ({
      ...item,
      checkedStore: value,
      product_stocks: item.product_stocks?.map((itemA: any) => ({
        ...itemA,
        checkedProduct: value,
      })),
    }))
    dispatch(setDataCard(new_array))
  }

  const onDeleteSelected = async () => {
    let array_delete: Array<any> = []

    dataCart?.map((item: any) => {
      item.product_stocks?.map((itemA: any) => {
        if (itemA.checkedProduct) {
          array_delete.push(itemA.cart_id)
        }
      })
    })
    if (array_delete.length < 1) {
      notificationError(R.strings().err_choose_product)
      return
    }
    try {
      if (selectedAll) {
        await deleteAllProduct()
        dispatch(setAmountCart())
      } else {
        await deleteProduct({ id: array_delete })
        dispatch(setAmountCart())
      }
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      getDataCart()
    }
  }

  const goToPayment = () => {
    //b1: Filter product selected by store
    //b2: Filter stores has product selected
    const filterProduct = dataCart
      ?.map((item: IStoreItem) => ({
        ...item,
        product_stocks: item.product_stocks?.filter(
          (itemA: any) => itemA.checkedProduct
        ),
      }))
      ?.filter((item: IStoreItem) => item.product_stocks.length > 0)
    if (filterProduct.length < 1) {
      notificationError(R.strings().please_choose_a_product)
    } else {
      history.push({
        pathname: ROUTER_PATH.PAYMENT,
        state: {
          type: 'oil',
          data: filterProduct,
        },
      })
    }
  }

  return (
    <WrapCart>
      <Breadcrumb>
        <Breadcrumb.Item href={ROUTER_PATH.HOME}>
          {R.strings().title_home}
        </Breadcrumb.Item>
        <Breadcrumb.Item>{R.strings().title_cart}</Breadcrumb.Item>
      </Breadcrumb>

      <HeaderCart justify="start">
        <ColCart md={10} style={{ fontWeight: 500, fontSize: '16px' }}>
          {R.strings().title_product}
        </ColCart>
        <ColCart md={5} style={{ fontWeight: 500, fontSize: '16px' }}>
          {R.strings().title_unit_price}
        </ColCart>
        <ColCart md={3} style={{ fontWeight: 500, fontSize: '16px' }}>
          {R.strings().title_quantity}
        </ColCart>
        <ColCart md={4} style={{ fontWeight: 500, fontSize: '16px' }}>
          {R.strings().title_amount}
        </ColCart>
        <ColCart md={2} style={{ fontWeight: 500, fontSize: '16px' }}></ColCart>
      </HeaderCart>
      {dataCart?.length > 0 ? (
        <Spin spinning={isLoading} style={{ marginTop: '12px' }}>
          {dataCart?.map((item: any, index: number) => {
            if (item.product_stocks?.length > 0)
              return <StoreInCart key={index} data={item} indexStore={index} />
          })}
        </Spin>
      ) : (
        <EmptyComponent />
      )}

      <Affix offsetBottom={10}>
        <AffixBottomBlock>
          <CheckAllBlock>
            <CheckBoxStyled
              onChange={(e: any) => {
                selectAllProduct(e.target.checked)
              }}
              checked={selectedAll}
            >
              {R.strings().title_select_all}
            </CheckBoxStyled>
            <Popconfirm
              icon={null}
              okText={R.strings().confirm}
              cancelText={R.strings().cancel_order}
              title={R.strings().delete_question}
              onConfirm={onDeleteSelected}
            >
              <Button type="text">{R.strings().title_delete}</Button>
            </Popconfirm>
          </CheckAllBlock>

          <TotalPriceBlock>
            {R.strings().title_total_price}:{' '}
            <span> {formatPrice(totalPrice)} đ</span>
          </TotalPriceBlock>
          <ButtonPurchase onClick={goToPayment}>
            {R.strings().buy}
          </ButtonPurchase>
        </AffixBottomBlock>
      </Affix>
    </WrapCart>
  )
}

export default CartScreen
