import { CalenderIcon } from 'common/components/Icons'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  BodyDetailNews,
  ContentNews,
  ContentNewsWrap,
  NewsCol,
  RelatedPosts,
  SetInnerHTML,
  TitlePost,
} from './styled'
import { SmallNews } from 'common/components/CardNews'
import { getNewsDetail } from './ApiNews'
import { useParams } from 'react-router-dom'
import { TDetailNews } from './interface'
import SkeletonDetail from './components/SkeletonDetail'
import SkeletonNews from 'common/components/CardNews/SkeletonNews'
import R from 'assets'
import { EmptyComponent } from 'common/components/Empty'

const DetailNewsScreen: React.FC = () => {
  const param: any = useParams()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataDetail, setDataDetail] = useState<TDetailNews>({
    detail_news: {
      id: 0,
      description: '',
      title_banner: '',
      image_url: '',
      create_at: '',
    },
    similar_news: [],
  })

  // const onHandleBack = () => {
  //   history.replace(ROUTER_PATH.NEWS)
  // }

  // useEffect(() => {
  //   if (location.pathname.includes(ROUTER_PATH.DETAIL_NEWS))
  //     window.addEventListener('popstate', () => onHandleBack())
  // }, [])

  useEffect(() => {
    getDataSource()
  }, [param.id])

  const getDataSource = async () => {
    try {
      setIsLoading(true)
      const res = await getNewsDetail(param.id)
      console.log('res: ', res)
      const dataConvert = {
        detail_news: {
          id: res.data.detail_new.id,
          description: res.data.detail_new.description,
          title_banner: res.data.detail_new.title_banner,
          image_url: res.data.detail_new.image_url,
          create_at: res.data.detail_new.create_at,
        },
        similar_news: res.data.similar_news?.map((item: any) => ({
          id: item.id,
          description: item.description,
          title_banner: item.title_banner,
          image_url: item.image_url,
          create_at: item.create_at,
        })),
      }

      setDataDetail(dataConvert)
    } catch (error) {
      console.log('error: ', error)
      setDataDetail({
        detail_news: {
          id: 0,
          description: '',
          title_banner: '',
          image_url: '',
          create_at: '',
        },
        similar_news: [],
      })
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <BodyDetailNews>
      {isLoading ? (
        <>
          <SkeletonDetail />
          <RelatedPosts xs={24} sm={8} xl={6}>
            <TitlePost size={20}>{R.strings().title_news}</TitlePost>
            <SkeletonNews />
          </RelatedPosts>
        </>
      ) : dataDetail.detail_news.id ? (
        <>
          <ContentNewsWrap xs={24} sm={16} xl={18}>
            <TitlePost size={28}>
              {dataDetail.detail_news.title_banner}
            </TitlePost>
            <div style={{ color: '#8c8c8c', marginTop: '12px' }}>
              <CalenderIcon style={{ marginRight: '10px' }} />
              {moment(dataDetail.detail_news.create_at).format(
                'HH:mm DD/MM/YYYY'
              )}
            </div>
            <ContentNews>
              <SetInnerHTML
                dangerouslySetInnerHTML={{
                  __html: dataDetail.detail_news.description as string,
                }}
              />
            </ContentNews>
          </ContentNewsWrap>
          <RelatedPosts xs={24} sm={8} xl={6}>
            <TitlePost size={20}>{R.strings().title_news}</TitlePost>
            {dataDetail.similar_news?.map((item: any) => (
              <NewsCol key={item.id}>
                <SmallNews data={item} />
              </NewsCol>
            ))}
          </RelatedPosts>
        </>
      ) : (
        <EmptyComponent text={R.strings().news_not_exist} />
      )}
    </BodyDetailNews>
  )
}
export default DetailNewsScreen
