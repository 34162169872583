import Skeleton from 'react-loading-skeleton'
import { FlexSmallNews, ImageNews, OverviewNews, TimeNews } from './styled'

const SkeletonNews = () => {
  return (
    <FlexSmallNews style={{ height: '150px' }}>
      <div style={{ width: '95%', height: '100%' }}>
        <ImageNews>
          <Skeleton style={{ backgroundColor: '#E6E6E6', height: '100%' }} />
        </ImageNews>
        <OverviewNews>
          <span color="#8c8c8c">
            <Skeleton style={{ backgroundColor: '#E6E6E6', height: '100%' }} />
          </span>
          <TimeNews>
            <Skeleton
              style={{
                backgroundColor: '#E6E6E6',
                height: '50%',
                width: '50%',
              }}
            />
          </TimeNews>
        </OverviewNews>
      </div>
    </FlexSmallNews>
  )
}

export default SkeletonNews
