import { ROUTER_PATH, SESSION_KEY } from 'common/config'
import { Redirect, Route } from 'react-router-dom'
import { hasCookie } from 'utils/functionHelper'

interface PrivateRouteProps {
  path: string
  component: any
  exact?: boolean
}

export default function PrivateRoute({
  path,
  component,
  exact,
}: PrivateRouteProps) {
  const Component = component

  return (
    <Route
      path={path}
      exact={exact}
      render={props =>
        hasCookie(SESSION_KEY.SESSION) ? (
          <Component {...props} />
        ) : (
          <Redirect to={ROUTER_PATH.HOME} />
        )
      }
    />
  )
}
