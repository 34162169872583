import Icon from '@ant-design/icons'

const BagSvg = () => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6799 13.4879C11.5149 13.4879 10.3533 13.4587 9.19498 13.4004C8.56723 13.3285 7.96851 13.0964 7.45632 12.7264C6.94414 12.3564 6.53569 11.8609 6.27022 11.2875C5.05999 8.57078 4.5756 6.40998 4.74304 4.48151C4.75309 4.36525 4.80321 4.2561 4.88484 4.17273C4.96647 4.08935 5.07453 4.03692 5.19055 4.02441C9.83679 3.52202 14.5218 3.48775 19.1748 3.92212C19.5767 3.981 19.9545 4.1495 20.2667 4.40912C20.579 4.66874 20.8137 5.00942 20.945 5.39373C20.953 5.42188 20.9586 5.45067 20.9617 5.47979C21.1267 7.64498 20.7585 9.81761 19.8894 11.8076C19.7716 12.1912 19.5464 12.533 19.2404 12.7926C18.9344 13.0522 18.5605 13.2188 18.1628 13.2725C16.3438 13.4161 14.5097 13.4879 12.6799 13.4879ZM5.7219 4.98114C5.66953 6.63445 6.14039 8.52086 7.1876 10.872C7.37535 11.2785 7.66251 11.6311 8.02256 11.8972C8.38261 12.1634 8.80394 12.3345 9.2476 12.3947C12.1918 12.5421 15.1424 12.5 18.0812 12.2686C18.2739 12.2319 18.4534 12.1443 18.601 12.0149C18.7485 11.8855 18.8588 11.719 18.9204 11.5327C18.9259 11.5095 18.9331 11.4867 18.942 11.4646C19.7492 9.63242 20.1002 7.63194 19.9651 5.63445C19.8857 5.45323 19.7635 5.29398 19.6091 5.17034C19.4546 5.0467 19.2724 4.96238 19.0782 4.92459C14.6348 4.51054 10.1617 4.52948 5.7219 4.98114Z"
      fill="#F26522"
      stroke="#F26522"
      strokeWidth="0.3"
    />
    <path
      d="M5.24388 5.02841C5.12462 5.02823 5.00928 4.98576 4.91838 4.90855C4.82748 4.83134 4.76691 4.72439 4.74745 4.60672C4.67366 4.21812 4.5597 3.83823 4.4074 3.47319C4.40097 3.45651 4.39539 3.43953 4.39067 3.42229C4.32979 3.2456 4.2294 3.08512 4.09714 2.95307C3.96489 2.82103 3.80425 2.7209 3.62746 2.6603C2.90511 2.38693 2.16409 2.16572 1.41009 1.99839C1.27899 1.97362 1.16308 1.89784 1.08781 1.78769C1.01253 1.67753 0.984048 1.54201 1.00861 1.41087C1.03317 1.27974 1.10876 1.16371 1.2188 1.08826C1.32883 1.01281 1.46431 0.984109 1.59548 1.00846C2.40157 1.18488 3.19369 1.41991 3.96555 1.71168C4.29076 1.824 4.58544 2.01033 4.82635 2.25598C5.06727 2.50162 5.24782 2.79987 5.35379 3.12721C5.52863 3.55104 5.65851 3.99203 5.74131 4.44296C5.76301 4.57475 5.73148 4.70977 5.65364 4.81831C5.57581 4.92686 5.45805 5.00004 5.32626 5.02177C5.29902 5.02615 5.27147 5.02837 5.24388 5.02841Z"
      fill="#F26522"
      stroke="#F26522"
      strokeWidth="0.3"
    />
    <path
      d="M13.1172 16.2573C11.6128 16.2573 10.1123 16.2292 8.61555 16.1732C8.12159 16.1338 7.64346 15.9806 7.21868 15.7254C6.79391 15.4703 6.43403 15.1202 6.1673 14.7025C6.05375 14.4886 6.00143 14.2476 6.01609 14.0058C6.03075 13.7641 6.11182 13.5311 6.25039 13.3325C6.51579 12.9152 6.83388 12.5339 7.19679 12.198C7.2932 12.1059 7.42217 12.0558 7.55545 12.0587C7.68872 12.0616 7.81543 12.1172 7.90778 12.2133C8.00014 12.3094 8.05061 12.4383 8.04814 12.5715C8.04567 12.7048 7.99046 12.8317 7.8946 12.9243C7.58997 13.206 7.32251 13.5253 7.09868 13.8747C7.05483 13.9268 7.02838 13.9914 7.02305 14.0593C7.01771 14.1273 7.03376 14.1952 7.06893 14.2536C7.25556 14.509 7.49384 14.7224 7.7683 14.8798C8.04276 15.0371 8.34725 15.135 8.662 15.167C12.1218 15.2971 15.6367 15.2747 19.1024 15.1004C19.1686 15.0969 19.2348 15.1064 19.2972 15.1285C19.3597 15.1506 19.4171 15.1848 19.4663 15.2292C19.5155 15.2735 19.5555 15.3272 19.5839 15.387C19.6124 15.4468 19.6287 15.5117 19.6321 15.5778C19.6354 15.644 19.6256 15.7102 19.6034 15.7725C19.5811 15.8349 19.5467 15.8923 19.5022 15.9414C19.4577 15.9904 19.404 16.0302 19.344 16.0585C19.2841 16.0868 19.2192 16.1029 19.1531 16.1061C17.1516 16.2069 15.1331 16.2573 13.1172 16.2573Z"
      fill="#F26522"
      stroke="#F26522"
      strokeWidth="0.3"
    />
    <path
      d="M9.81132 20.1353C9.46273 20.1353 9.12197 20.032 8.83213 19.8383C8.54229 19.6446 8.31639 19.3694 8.18299 19.0473C8.04959 18.7253 8.01469 18.3709 8.08269 18.029C8.1507 17.6871 8.31856 17.3731 8.56505 17.1266C8.81154 16.8801 9.12558 16.7122 9.46747 16.6442C9.80936 16.5762 10.1637 16.6111 10.4858 16.7445C10.8078 16.8779 11.0831 17.1038 11.2768 17.3937C11.4704 17.6835 11.5738 18.0242 11.5738 18.3728C11.5732 18.8401 11.3874 19.2881 11.057 19.6185C10.7266 19.9489 10.2786 20.1348 9.81132 20.1353ZM9.81132 17.6175C9.66192 17.6175 9.51588 17.6618 9.39167 17.7448C9.26745 17.8278 9.17064 17.9458 9.11346 18.0838C9.05629 18.2218 9.04133 18.3737 9.07048 18.5202C9.09963 18.6667 9.17157 18.8013 9.2772 18.9069C9.38284 19.0126 9.51743 19.0845 9.66396 19.1137C9.81048 19.1428 9.96236 19.1279 10.1004 19.0707C10.2384 19.0135 10.3564 18.9167 10.4394 18.7925C10.5224 18.6683 10.5667 18.5222 10.5667 18.3728C10.5665 18.1726 10.4868 17.9806 10.3452 17.839C10.2036 17.6974 10.0116 17.6177 9.81132 17.6175Z"
      fill="#F26522"
      stroke="#F26522"
      strokeWidth="0.3"
    />
    <path
      d="M16.8621 20.1353C16.5135 20.1353 16.1727 20.032 15.8829 19.8383C15.5931 19.6446 15.3672 19.3694 15.2338 19.0473C15.1004 18.7253 15.0655 18.3709 15.1335 18.029C15.2015 17.6871 15.3693 17.3731 15.6158 17.1266C15.8623 16.8801 16.1764 16.7122 16.5182 16.6442C16.8601 16.5762 17.2145 16.6111 17.5366 16.7445C17.8586 16.8779 18.1339 17.1038 18.3275 17.3937C18.5212 17.6835 18.6246 18.0242 18.6246 18.3728C18.624 18.8401 18.4381 19.2881 18.1077 19.6185C17.7773 19.9489 17.3294 20.1348 16.8621 20.1353ZM16.8621 17.6175C16.7127 17.6175 16.5667 17.6618 16.4424 17.7448C16.3182 17.8278 16.2214 17.9458 16.1642 18.0838C16.1071 18.2218 16.0921 18.3737 16.1213 18.5202C16.1504 18.6667 16.2223 18.8013 16.328 18.9069C16.4336 19.0126 16.5682 19.0845 16.7147 19.1137C16.8613 19.1428 17.0131 19.1279 17.1512 19.0707C17.2892 19.0135 17.4071 18.9167 17.4901 18.7925C17.5731 18.6683 17.6174 18.5222 17.6174 18.3728C17.6172 18.1726 17.5376 17.9806 17.396 17.839C17.2544 17.6974 17.0624 17.6177 16.8621 17.6175Z"
      fill="#F26522"
      stroke="#F26522"
      strokeWidth="0.3"
    />
  </svg>
)

const BagIcon = (props: any) => <Icon component={BagSvg} {...props} />
export default BagIcon
