import Icon from '@ant-design/icons'

const OilSvg = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.8438 2.34375H24.2188C23.3899 2.34375 22.5951 2.67299 22.009 3.25904C21.423 3.84509 21.0938 4.63995 21.0938 5.46875V9.02969C21.0942 9.35802 20.9911 9.67815 20.7993 9.9446C20.6074 10.2111 20.3365 10.4103 20.025 10.5141L8.1 14.4875C7.78874 14.5912 7.51801 14.7902 7.32618 15.0563C7.13436 15.3225 7.03117 15.6422 7.03125 15.9703V44.5312C7.03125 45.3601 7.36049 46.1549 7.94654 46.741C8.53259 47.327 9.32745 47.6562 10.1562 47.6562H39.8438C40.6726 47.6562 41.4674 47.327 42.0535 46.741C42.6395 46.1549 42.9688 45.3601 42.9688 44.5312V5.46875C42.9688 4.63995 42.6395 3.84509 42.0535 3.25904C41.4674 2.67299 40.6726 2.34375 39.8438 2.34375ZM39.8438 7.03125V11.7188H24.2188V7.03125C24.2188 6.61685 24.3834 6.21942 24.6764 5.9264C24.9694 5.63337 25.3668 5.46875 25.7812 5.46875H38.2812C38.6957 5.46875 39.0931 5.63337 39.3861 5.9264C39.6791 6.21942 39.8438 6.61685 39.8438 7.03125Z"
      fill="#FAAD14"
    />
    <path
      d="M9.375 44.5312V15.9703C9.37459 15.642 9.47763 15.3219 9.66948 15.0554C9.86133 14.7889 10.1322 14.5897 10.4437 14.4859L22.3687 10.5109C22.6798 10.4074 22.9503 10.2086 23.1421 9.94274C23.3339 9.67691 23.4373 9.35749 23.4375 9.02969V5.46875C23.4375 4.63995 23.7667 3.84509 24.3528 3.25904C24.9388 2.67299 25.7337 2.34375 26.5625 2.34375H24.2188C23.3899 2.34375 22.5951 2.67299 22.009 3.25904C21.423 3.84509 21.0938 4.63995 21.0938 5.46875V9.02969C21.0942 9.35802 20.9911 9.67815 20.7993 9.9446C20.6074 10.2111 20.3365 10.4103 20.025 10.5141L8.1 14.4875C7.78874 14.5912 7.51801 14.7902 7.32618 15.0563C7.13436 15.3225 7.03117 15.6422 7.03125 15.9703V44.5312C7.03125 45.3601 7.36049 46.1549 7.94654 46.741C8.53259 47.327 9.32745 47.6562 10.1562 47.6562H12.5C11.6712 47.6562 10.8763 47.327 10.2903 46.741C9.70424 46.1549 9.375 45.3601 9.375 44.5312Z"
      fill="#FA8C16"
    />
    <path
      d="M9.96614 10.4797L16.3677 8.34608C16.5722 8.27801 16.7886 8.25299 17.0032 8.27258C17.2178 8.29218 17.4261 8.35597 17.6149 8.45994C17.8037 8.56391 17.9689 8.70582 18.1002 8.87674C18.2315 9.04766 18.3261 9.24389 18.3779 9.45311L18.7497 10.9375L9.37473 14.0625L8.94426 12.3437C8.84801 11.9614 8.89983 11.5568 9.08935 11.2111C9.27886 10.8654 9.59206 10.6041 9.96614 10.4797Z"
      fill="#F26522"
    />
    <path
      d="M35.1562 17.9688H14.8438C13.9808 17.9688 13.2812 18.6683 13.2812 19.5312V39.8438C13.2812 40.7067 13.9808 41.4062 14.8438 41.4062H35.1562C36.0192 41.4062 36.7188 40.7067 36.7188 39.8438V19.5312C36.7188 18.6683 36.0192 17.9688 35.1562 17.9688Z"
      fill="#E2E7F6"
    />
    <path
      d="M30.4688 31.25C30.4688 32.7004 29.8926 34.0914 28.867 35.117C27.8414 36.1426 26.4504 36.7188 25 36.7188C23.5496 36.7188 22.1586 36.1426 21.133 35.117C20.1074 34.0914 19.5312 32.7004 19.5312 31.25C19.5312 28.2297 25 22.6562 25 22.6562C25 22.6562 30.4688 28.2297 30.4688 31.25Z"
      fill="#F26522"
    />
  </svg>
)

const OilIcon = (props: any) => <Icon component={OilSvg} {...props} />
export default OilIcon
