import {
  ArrowRightOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons'
import Slider from 'react-slick'
import React, { useRef, useState } from 'react'
import {
  ButtonArrow,
  HeaderBox,
  SlideDealsHot,
  StyledArrow,
  WrapBox,
} from '../styled'
import { StyledCol } from 'common/components/CardProduct/styled'
import { ProductCard } from 'common/components/CardProduct'
import R from 'assets'
import SkeletonProduct from 'common/components/CardProduct/SkeletonProduct'
import { useHistory } from 'react-router-dom'
import { ROUTER_PATH } from 'common/config'

const SLIDETOSHOW: Array<number> = [4, 3.2, 2.3]

const DealsHot: React.FC<TProps> = ({ data, loading }) => {
  const ref: any = useRef({})
  const history = useHistory()
  const [hiddenArrow, setHiddenArrow] = useState<any>({
    prev: true,
    next: false,
  })
  const settingSlider = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: SLIDETOSHOW[0],
          slidesToScroll: data.length / SLIDETOSHOW[0],
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: SLIDETOSHOW[1],
          slidesToScroll: data.length / SLIDETOSHOW[1],
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: SLIDETOSHOW[2],
          slidesToScroll: data.length / SLIDETOSHOW[2],
        },
      },
    ],
    afterChange: (current: number) => {
      if (current === 0) {
        setHiddenArrow({ ...hiddenArrow, prev: true })
      } else {
        if (current === data.length - 5) {
          setHiddenArrow({ ...hiddenArrow, prev: false, next: true })
        } else {
          setHiddenArrow({
            ...hiddenArrow,
            prev: false,
            next: false,
          })
        }
      }
    },
  }

  const renderArrows = () => {
    return (
      <StyledArrow>
        <ButtonArrow
          id="prev"
          onClick={() => previous()}
          visibility={hiddenArrow.prev}
        >
          <LeftOutlined style={{ color: '#f26522' }} />
        </ButtonArrow>

        <ButtonArrow
          id="next"
          onClick={() => next()}
          visibility={hiddenArrow.next}
        >
          <RightOutlined style={{ color: '#f26522' }} />
        </ButtonArrow>
      </StyledArrow>
    )
  }

  const next = () => {
    ref.current.slickNext()
  }

  const previous = () => {
    ref.current.slickPrev()
  }
  return (
    <WrapBox>
      <HeaderBox>
        {R.strings().title_great_deal}
        <span
          onClick={() => {
            history.push({
              pathname: `${ROUTER_PATH.PRODUCT}`,
              search: 'type=deal',
            })
          }}
        >
          {R.strings().title_see_more}
          <ArrowRightOutlined style={{ marginLeft: '12px' }} />
        </span>
      </HeaderBox>

      <SlideDealsHot>
        {data && data?.length > 5 ? renderArrows() : ''}
        {loading ? (
          <Slider ref={ref} {...settingSlider}>
            {[1, 2]?.map((item: any) => (
              <StyledCol paddingleft={10} key={item.id}>
                <SkeletonProduct />
              </StyledCol>
            ))}
          </Slider>
        ) : (
          <Slider ref={ref} {...settingSlider}>
            {data?.map((item: any) => (
              <StyledCol paddingleft={10} key={item.id}>
                <ProductCard
                  dataProduct={item}
                  onClickProduct={() => {
                    const petrol_id = item?.percent_discount
                      ? item?.petrol_store_id
                      : item?.product_stocks[0]?.petrol_store_id
                    history.push({
                      pathname: `${ROUTER_PATH.DETAIL_PRODUCT}/${item?.id}`,
                      search: `type='oil'&category=${item?.product_category_name}&product_id=${item.id}&petrol_store_id=${petrol_id}&nameProduct=${item.name}`,
                    })
                  }}
                />
              </StyledCol>
            ))}
          </Slider>
        )}
      </SlideDealsHot>
    </WrapBox>
  )
}
export default DealsHot
