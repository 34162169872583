import { Pagination, Row } from 'antd'
import R from 'assets'
import { SmallNews } from 'common/components/CardNews'
import SkeletonNews from 'common/components/CardNews/SkeletonNews'
import { ROUTER_PATH } from 'common/config'
import { HeaderBox, WrapBox } from 'features/home/styled'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getNewsList } from './ApiNews'
import { NewsCol, WrapSmallNews } from './styled'

const NewsScreen: React.FC = () => {
  const history = useHistory()
  const [newsList, setNewsList] = useState<Array<any>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [params, setParams] = useState({ page: 1 })
  const [paging, setPaging] = useState({
    current: 1,
    pageSize: 9,
    total: 5,
  })

  useEffect(() => {
    getDataSource()
  }, [params])

  const getDataSource = async () => {
    try {
      setIsLoading(true)
      const res = await getNewsList(params)
      setNewsList(res.data)
      setPaging({
        current: res.meta.pagination.page,
        pageSize: res.meta.pagination.limit,
        total: res.meta.pagination.totalItems,
      })
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div
      style={{
        padding: '20px 0',
      }}
    >
      <WrapBox>
        <HeaderBox>{R.strings().title_news}</HeaderBox>
        <Row justify="start">
          {isLoading ? (
            <>
              {[1, 2, 3]?.map((item: any, index: number) => (
                <NewsCol xs={24} sm={12} lg={8} key={index}>
                  <WrapSmallNews>
                    <SkeletonNews />
                  </WrapSmallNews>
                </NewsCol>
              ))}
            </>
          ) : (
            <>
              {newsList?.map((item: any, index: number) => {
                return (
                  <NewsCol xs={24} sm={12} lg={8} key={index}>
                    <WrapSmallNews>
                      <SmallNews data={item} />
                    </WrapSmallNews>
                  </NewsCol>
                )
              })}
            </>
          )}
        </Row>

        <Row justify="end">
          <Pagination
            defaultCurrent={paging.current}
            total={paging.total}
            pageSize={paging.pageSize}
            onChange={(page: number) => {
              setParams({ page: page })
            }}
          />
        </Row>
      </WrapBox>
    </div>
  )
}
export default NewsScreen
