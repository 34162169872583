import R from 'assets'
import images from 'assets/imagesAsset'
import { ROUTER_PATH } from 'common/config'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { WebSocketOnline } from 'services/WebSocketOnline'
import { FacebookIcon, ZaloIcon } from '../Icons'
import { getHits } from './ApiContainer'
import {
  ColFooter,
  FooterBlock,
  ImageDownload,
  TextStyles,
  TitleStyles,
} from './styles'
export default function Footer() {
  const [hits, setHits] = useState({ online: 0, in_month: 0, all: 0 })
  const history = useHistory()
  useEffect(() => {
    WebSocketOnline.init()
    WebSocketOnline.socketClient?.on(
      `customer_notification`,
      async (datas: any) => {
        setHits({
          online: datas.data.count_online,
          in_month: datas.data.total_hits_monthly,
          all: datas.data.total_hits,
        })
      }
    )
    getAccess()
  }, [])

  const getAccess = async () => {
    try {
      const res = await getHits()
      setHits({
        online: res.data.count_online || 0,
        in_month: res.data.total_hits_monthly,
        all: res.data.total_hits,
      })
    } catch (error) {
      console.error('Exception ' + error)
    }
  }
  return (
    <FooterBlock>
      <ColFooter md={14}>
        <TitleStyles>{R.strings().contact_information}</TitleStyles>
        <ul>
          <li>
            {R.strings().text_form_company}: Công ty Cổ phần Hệ sinh thái Công
            nghệ YoYo
          </li>
          <li>Hotline: 0899.743.888</li>
          <li>Email: sales@yoyojsc.com.vn</li>
          <li>MST: 0109083491</li>
          <li>Nơi cấp: Sở Kế hoạch và Đầu tư Thành phố Hà Nội</li>
          <li>Ngày cấp: 10/02/2020</li>
          <li>
            {R.strings().headquarters}: Tầng 5, số 11 ngõ 84 Ngọc Khánh, Phường
            Giảng Võ, Quận Ba Đình, Thành phố Hà Nội
          </li>
          {/* <li>
            {R.strings().sales_office}: Tầng 5, số 11 ngõ 84 Ngọc Khánh, Ba
            Đình, Hà Nội
          </li> */}
        </ul>
      </ColFooter>

      <ColFooter md={6}>
        <TitleStyles>{R.strings().about_pyoyo}</TitleStyles>
        <span
          onClick={() => {
            history.push(ROUTER_PATH.INTRODUCE_YOYO)
            document.body.scrollTo({
              top: 0,
              behavior: 'smooth',
              /* you can also use 'auto' behaviour
         in place of 'smooth' */
            })
          }}
        >
          {R.strings().introduction_PYOYO}
        </span>
        <span
          onClick={() => {
            history.push(
              ROUTER_PATH.POLICY + '?type=information_privacy_policy'
            )
            document.body.scrollTo({
              top: 0,
              behavior: 'smooth',
              /* you can also use 'auto' behaviour
         in place of 'smooth' */
            })
          }}
        >
          {R.strings().information_privacy_policy}
        </span>
        <span
          onClick={() => {
            history.push(ROUTER_PATH.POLICY + '?type=delivery_policy')
            document.body.scrollTo({
              top: 0,
              behavior: 'smooth',
              /* you can also use 'auto' behaviour
         in place of 'smooth' */
            })
          }}
        >
          {R.strings().delivery_policy}
        </span>
        <span
          onClick={() => {
            history.push(
              ROUTER_PATH.POLICY + '?type=return_and_exchange_policy'
            )
            document.body.scrollTo({
              top: 0,
              behavior: 'smooth',
              /* you can also use 'auto' behaviour
         in place of 'smooth' */
            })
          }}
        >
          {R.strings().return_and_exchange_policy}
        </span>
        <span
          onClick={() => {
            history.push(ROUTER_PATH.POLICY + '?type=payment_policy')
            document.body.scrollTo({
              top: 0,
              behavior: 'smooth',
              /* you can also use 'auto' behaviour
         in place of 'smooth' */
            })
          }}
        >
          {R.strings().payment_policy}
        </span>
        <span
          onClick={() => {
            history.push(ROUTER_PATH.TERM_OF_USE)
            document.body.scrollTo({
              top: 0,
              behavior: 'smooth',
              /* you can also use 'auto' behaviour
         in place of 'smooth' */
            })
          }}
        >
          {R.strings().terms_of_use}
        </span>
      </ColFooter>

      <ColFooter md={4}>
        <TitleStyles>{R.strings().customer_support}</TitleStyles>
        <span>{R.strings().frequently_asked_questions}</span>
        <span>{R.strings().require_to_be_supported}</span>
        <span
          onClick={() => {
            history.push(ROUTER_PATH.SHOPPING_GUIDE)
            document.body.scrollTo({
              top: 0,
              behavior: 'smooth',
              /* you can also use 'auto' behaviour
         in place of 'smooth' */
            })
          }}
        >
          {R.strings().shopping_guide}
        </span>
        <span
          onClick={() => {
            history.push(ROUTER_PATH.REFUND_POLICY)
            document.body.scrollTo({
              top: 0,
              behavior: 'smooth',
              /* you can also use 'auto' behaviour
         in place of 'smooth' */
            })
          }}
        >
          {R.strings().refund_policy}
        </span>
      </ColFooter>

      <ColFooter md={14}>
        <TitleStyles>{R.strings().cooperation_and_association}</TitleStyles>
        {/* <span>{R.strings().regulations_on_operation_in_transactions_EC}</span> */}
        <span>{R.strings().selling_with_PYOYO}</span>
        <a
          target="_blank"
          href="http://online.gov.vn/Home/WebDetails/104758?AspxAutoDetectCookieSupport=1"
        >
          {' '}
          <img
            src={images.bocongthuong}
            height={80}
            alt="Chứng nhận"
            width={200}
            style={{ marginTop: '10px' }}
          />
        </a>
      </ColFooter>
      <ColFooter md={6}>
        <TitleStyles>{R.strings().title_payment} </TitleStyles>
        <TextStyles>
          <img src={images.viettin} alt="mobile" width={22} height={17.16} />
          &nbsp;&nbsp;&nbsp;VietinBank
        </TextStyles>
        <TextStyles>
          <img
            src={R.images.img_footer1}
            alt="mobile"
            width={22}
            height={17.16}
          />
          &nbsp;&nbsp;&nbsp;{R.strings().title_VN_Pay}
        </TextStyles>
        <TextStyles>
          <img
            src={R.images.img_footer2}
            alt="mobile"
            width={15}
            height={20.25}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{R.strings().title_debt}
        </TextStyles>
        <TextStyles>
          <img
            src={R.images.img_footer3}
            alt="mobile"
            width={20}
            height={17.11}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;{R.strings().title_transfer}
        </TextStyles>
        <TextStyles>
          <img
            src={R.images.img_footer4}
            alt="mobile"
            width={18.82}
            height={20}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{R.strings().title_cash}
        </TextStyles>
        <b>
          {R.strings().online}: {hits.online}
        </b>
        <b>
          {R.strings().total_hits_monthly}: {hits.in_month}
        </b>
        <b>
          {R.strings().total_hits} : {hits.all}
        </b>
      </ColFooter>
      <ColFooter md={4}>
        <TitleStyles>{R.strings().connect_with_us}</TitleStyles>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <a
            rel="noreferrer"
            href="https://www.facebook.com/yoyojsc/"
            target="_blank"
          >
            <FacebookIcon style={{ marginRight: '10px' }} />
          </a>
          <a rel="noreferrer" href="https://zalo.me/0899743888" target="_blank">
            <ZaloIcon style={{ marginRight: '10px' }} />
          </a>
        </div>

        <TitleStyles>{R.strings().title_download_app}</TitleStyles>

        <ImageDownload>
          <img
            src={R.images.img_google_play}
            alt="Google Play"
            width={162}
            height={48}
          />
        </ImageDownload>
        <ImageDownload>
          <img
            src={R.images.img_app_store}
            alt="App Store"
            width={162}
            height={48}
          />
        </ImageDownload>
      </ColFooter>
    </FooterBlock>
  )
}
