import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAmountProductInCart } from 'common/components/ContainerPage/ApiContainer'
import { SESSION_KEY } from 'common/config'
import Cookie from 'js-cookie'
import { requestGetUserInfo, requestLogout } from './AuthApi'

let initialState: any = {
  isLoading: true,
  dialogLoading: false,
  userInfo: null,
  count_cart: 0,
}

export const getUserInfoAction = createAsyncThunk(
  'auth/info',
  async (payload, thunkApi) => {
    const res = await requestGetUserInfo()
    return res.data
  }
)

export const getCountCart = (state: any) => state.authReducer.count_cart
export const setAmountCart = createAsyncThunk(
  'customer/cart/count-cart',
  async () => {
    const res = await getAmountProductInCart()
    return res.data
  }
)

export const logoutAction = createAsyncThunk('auth/logout', async () => {
  const res = await requestLogout()
  return res.data
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // setCountCart: (state, action) => {
    //   state.count_cart = action.payload
    // },
  },
  extraReducers: builder => {
    builder.addCase(getUserInfoAction.pending, (state, action) => {
      state.isLoading = true
      return state
    })
    builder.addCase(getUserInfoAction.fulfilled, (state, action) => {
      state.userInfo = action.payload
      state.isLoading = false
      return state
    })
    builder.addCase(getUserInfoAction.rejected, (state, action) => {
      state.isLoading = false
      state.userInfo = null
      return state
    })

    builder.addCase(setAmountCart.pending, (state, action) => {
      state.isLoading = true
      return state
    })
    builder.addCase(setAmountCart.fulfilled, (state, action) => {
      state.count_cart = action.payload
      state.isLoading = false
      return state
    })
    builder.addCase(setAmountCart.rejected, (state, action) => {
      state.isLoading = false
      state.count_cart = null
      return state
    })
    builder.addCase(logoutAction.pending, (state, action) => {
      state.isLoading = true
      return state
    })
    builder.addCase(logoutAction.fulfilled, (state, action) => {
      state = initialState
      Cookie.remove(SESSION_KEY.SESSION)
      window.location.href = '/'
      return state
    })
    builder.addCase(logoutAction.rejected, (state, action) => {
      state.isLoading = false
      return state
    })
  },
})

export const selectCount = (state: any) => state.account
// export const { setCountCart } = authSlice.actions
export const { actions, reducer } = authSlice

export default reducer
