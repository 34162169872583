import R from 'assets'
import { Rate, Input, Modal, Row } from 'antd'
import { useEffect, useState } from 'react'
import { addReviewOrder, reasonCancel } from '../ApiOrder'
import { ModalStyle, ReviewButton, UploadStyled, UploadButton } from '../styled'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { notificationError, notificationSuccess } from 'utils/notification'
import { uploadImageToServer } from 'utils/uploadImageToServer'

type Props = {
  isModalVisible: any
  setIsModalVisible: any
  petrol_store_id: number
  order_id: number
  check_review: any
}
const ReviewModal: React.FC<Props> = ({
  isModalVisible,
  setIsModalVisible,
  petrol_store_id,
  order_id,
  check_review,
}) => {
  const [indexImg, setIndexImg] = useState<number>(0)
  const [uploadImg, setUploadImg] = useState<boolean>(false)

  const [previewImage, setPreviewImage] = useState<string>('')
  const [isShowModalPreview, setShowModalPreview] = useState<boolean>(false)
  const [stars, setStars] = useState<any>(5)
  const [textReview, setTextReview] = useState<any>()
  const [listImages, setListImages] = useState<Array<any>>(
    Array.from(Array(4).keys()).map(i => {
      return {
        id: i,
        fileList: [],
        buffer: null,
        url: '',
      }
    })
  )

  const reviewOrder = async () => {
    try {
      let newData

      newData = {
        star: stars,
        array_images: [
          ...listImages
            .filter(x => x.url !== '')
            .map(x => x.url.slice(x.url.indexOf('upload'))),
        ],
        description: textReview,
      }
      const res = await addReviewOrder(petrol_store_id, order_id, newData)
      setIsModalVisible(false)
      notificationSuccess('Đánh giá thành công')
      window.location.reload()
    } catch (error) {
    } finally {
    }
  }
  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  const handlePreviewImage = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setShowModalPreview(true)
  }

  const handleChangeImage = async (file: any, index: number) => {
    // case remove image
    var resUploadImage: any

    if (file.file.status === 'removed') {
      setIndexImg(indexImg - 1)
      listImages[index].fileList = []
      listImages[index].url = ''
      setListImages([...listImages])
      return
    }
    const isLt3M = file.file.type ? file.file.size / 1024 / 1024 < 3 : true
    const isJpgOrPng =
      file.file.type === 'image/jpeg' || file.file.type === 'image/png'
    if (!isJpgOrPng) {
      notificationError('Bạn chỉ có thể upload ảnh có định dạng JPG/PNG!')
      return
    } else if (!isLt3M) {
      notificationError('Dung lượng ảnh tối đa là 3MB!')
      return
    }
    // case uploading image
    if (file?.fileList[0]?.status === 'uploading') {
      listImages[index].fileList = file.fileList || []
      setListImages([...listImages])
    }
    // case upload image
    else if (file.file.status !== 'removed') {
      try {
        setIndexImg(indexImg + 1)
        resUploadImage = await uploadImageToServer(file.fileList)
        listImages[index].fileList = [
          {
            status: 'done',
            size: '10000',
            type: 'image/jpeg',
            uid: index,
            url: resUploadImage?.location,
          },
        ]
        listImages[index].url = resUploadImage?.location
        setListImages([...listImages])
      } catch (error) {
        notificationError(
          'Tải ảnh thất bại, vui lòng kiểm tra kết nối và thử lại.'
        )
      }
    }
  }
  return (
    <>
      <ModalStyle
        width={350}
        title={R.strings().evaluate}
        footer={null}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
          setTextReview('')
          setIndexImg(0)
          setListImages(
            Array.from(Array(4).keys()).map(i => {
              return {
                id: i,
                fileList: [],
                buffer: null,
                url: '',
              }
            })
          )
        }}
      >
        <>
          <div style={{ fontWeight: 500 }}>{R.strings().review_order}</div>
          <Rate
            allowClear={false}
            defaultValue={5}
            style={{ fontSize: 30, margin: '10px 0 10px 0' }}
            onChange={(value: number) => {
              setStars(value)
            }}
          />
          <div>
            {indexImg === 0 ? (
              <UploadButton
                onClick={() => {
                  setUploadImg(!uploadImg)
                }}
              >
                <div style={{ padding: '4px 10px' }}>
                  {R.strings().upload}&nbsp;
                  <UploadOutlined />
                </div>
              </UploadButton>
            ) : (
              ''
            )}
            {uploadImg ? (
              <Row tabIndex={-1} id="image_product">
                {listImages.map((item: any, index: number) => {
                  return indexImg >= index ? (
                    <UploadStyled
                      listType="picture-card"
                      accept="image/jpeg,image/png,image/jpg"
                      fileList={item?.fileList}
                      onPreview={handlePreviewImage}
                      onChange={(value: any) => {
                        handleChangeImage(value, index)
                      }}
                    >
                      {item?.fileList.length >= 1 ? null : (
                        <div>
                          <PlusOutlined />
                        </div>
                      )}
                    </UploadStyled>
                  ) : (
                    <></>
                  )
                })}
              </Row>
            ) : (
              <></>
            )}
          </div>
          <Input.TextArea
            rows={3}
            style={{ margin: '0 0 15px 0' }}
            placeholder={R.strings().placeholder_review}
            value={textReview}
            onChange={(e: any) => {
              setTextReview(e.target.value)
            }}
          />
          <ReviewButton
            onClick={() => {
              reviewOrder()
            }}
          >
            <div style={{ padding: 5, fontSize: 16 }}>
              {R.strings().evaluate}
            </div>
          </ReviewButton>
          <Modal
            visible={isShowModalPreview}
            footer={null}
            onCancel={() => setShowModalPreview(false)}
          >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </>
      </ModalStyle>
    </>
  )
}

export default ReviewModal
