import Icon from '@ant-design/icons'

const PetrolSvg = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4328_11479)">
      <path
        d="M30.4308 14.144L26.6781 11.1051C21.9936 7.16927 15.0112 7.7791 11.0773 12.4689L0.288166 25.3799C-0.153408 25.9047 -0.0754785 26.6817 0.439596 27.1126L4.21033 30.2766C4.73861 30.7195 5.51421 30.6389 5.94307 30.1251L8.03265 27.5823L12.6023 31.4186C15.283 33.6657 19.2336 33.3776 21.4126 30.7793L24.5765 27.0086L30.9571 19.4047C32.2821 17.8256 32.0165 15.474 30.4308 14.144ZM19.5285 29.198C18.2176 30.7565 15.8215 30.9055 14.1837 29.5333L9.61422 25.698L11.9871 22.8702C12.6143 22.124 13.5132 21.6782 14.5189 21.6145C15.0884 21.5877 15.6379 21.6808 16.1543 21.8718C15.0909 23.4761 14.8783 25.4978 15.7025 27.3271C15.9805 27.9434 16.7049 28.2221 17.3307 27.9435C17.9496 27.6636 18.2259 26.9354 17.9472 26.3153C17.4796 25.278 17.589 24.141 18.1728 23.2408L21.9018 26.3697L19.5285 29.198Z"
        fill="#FB8500"
      />
      <path
        d="M40.9122 27.6695L36.6908 20.3575C36.4709 19.9766 36.0648 19.7423 35.625 19.7423C35.1852 19.7423 34.779 19.9766 34.5592 20.3575L30.2453 27.8269C29.7394 28.7353 29.4727 29.7687 29.4727 30.8165C29.4727 34.2088 32.2328 36.9689 35.625 36.9689C39.0172 36.9689 41.7773 34.2087 41.7773 30.8165C41.7773 29.7086 41.4782 28.62 40.9122 27.6695Z"
        fill="#FB8500"
      />
      <path
        d="M41.2341 7.27667L37.3264 5.68092C33.8922 4.27859 30.0301 5.19546 27.7158 7.95441L27.248 8.51182C27.5866 8.747 27.9424 8.95405 28.2601 9.22089L31.9792 12.2309C32.3387 12.5324 32.6487 12.8702 32.9277 13.2272L33.3707 12.6996C33.6807 12.3247 34.2167 12.2069 34.6985 12.4064L38.6038 13.9998C39.2416 14.2628 39.9728 13.9424 40.2176 13.3005L41.918 8.85561C42.157 8.23078 41.8529 7.53015 41.2341 7.27667Z"
        fill="#FB8500"
      />
    </g>
    <defs>
      <clipPath id="clip0_4328_11479">
        <rect width="42" height="42" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const PetrolIcon = (props: any) => <Icon component={PetrolSvg} {...props} />
export default PetrolIcon
