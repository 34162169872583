import {
  Breadcrumb,
  Button,
  Col,
  Modal,
  Radio,
  Row,
  Tabs,
  Tag,
  Typography,
  Upload,
} from 'antd'
import styled, { css } from 'styled-components'
const { TabPane } = Tabs
const { Paragraph } = Typography

const BreadcrumbStyle = styled(Breadcrumb)`
  /* margin: 20px 0px 20px 0px; */
  padding: 10px 10px 15px 10px;
  font-weight: 500;
`
const TabsStyled = styled(Tabs)`
  background-color: #efefef;
  .ant-tabs-nav {
    padding: 0px;
    border-radius: 12px;
  }
  .ant-tabs-tab {
    width: 25%;
    padding: 0px;
  }
  .ant-tabs-nav-list {
    width: 100%;
    border-radius: 12px;
  }
  .ant-tabs-nav-wrap {
    border-radius: 12px;
  }
  .ant-tabs-tab-btn {
    width: 100%;
    padding: 5px 0 10px 0;
    text-align: center;
    white-space: normal !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #f26522;
    border-bottom: 1px solid #f26522;
  }
  .ant-tabs-tab {
    padding: 0px !important;
  }
`
const TabPaneStyled = styled(TabPane)`
  /* background-color: blue; */
`
const ListProductBlock = styled.div`
  background-color: white;
  border-radius: 12px;
  margin: 0 0 16px 0;
`
const TagStyle = styled(Tag)`
  border: none;
  border-radius: 12px;
  font-weight: 500;
  font-size: 12px;
  height: fit-content;
`
const ContainerOrder = styled.div`
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  margin: 30px 0 40px 0;
  @media only screen and (max-width: 687px) {
    display: unset;
  }
`
const LeftBlock = styled.div`
  width: 67%;
  @media only screen and (max-width: 687px) {
    width: 100%;
    margin: 30px 0 30px 0;
  }
`
const RightBlock = styled.div`
  width: 31%;
  background-color: white;
  border-radius: 12px;
  height: fit-content;
  margin: 47px 0 0 0;
  @media only screen and (max-width: 687px) {
    width: 100%;
    margin: 0px 0 30px 0;
  }
`
const AboveBlock = styled.div`
  background-color: white;
  padding: 16px;
  margin: 0 0 16px 0;
  border-radius: 12px;
`
const InfoBlock = styled.div`
  background-color: white;
  margin: 0 0 16px 0;
  border-radius: 12px;
  padding: 16px 0 0px 0;
  font-size: 16px;
`
const BottomBlock = styled.div`
  background-color: white;
  padding: 16px 0 16px 0;
  border-radius: 12px;
`
const StatusBlock = styled.div`
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: #fff5eb;
  border-radius: 12px;
  padding: 16px;
  color: #f26522;
  font-weight: 500;
`
const BankBlock = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 16px 0 16px 0;
  margin: 0 0 16px 0;
`
const AboveBank = styled.div`
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  padding: 0 16px 0 16px;
`
const ItemBank = styled.div<{
  active: boolean
}>`
  border: 1px solid #ced4da;
  border-radius: 12px;
  padding: 5px 10px 5px 10px;
  margin: 0 10px 0 0;
  cursor: pointer;
  :hover {
    border: 1px solid #f26522;
    color: #f26522;
  }
  ${(props: any) =>
    props.active &&
    css`
      border: 1px solid #f26522;
      color: #f26522;
    `}
`
const ParagraphStyle = styled(Paragraph)`
  margin: 0px !important;
  display: flex;
  justify-content: space-between;
  .ant-typography-copy {
    color: '#f26522' !important;
  }
`
const InputInfoBank = styled.div`
  background-color: #f1f3f5;
  border-radius: 12px;
  width: 100%;
  padding: 7px;
  margin: 5px 0 0 0;
`
const ProductRecord = styled.div`
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  justify-content: space-between;
`
const CodeBlock = styled.div`
  background-color: #f1f3f5;
  font-weight: 500;
  border-radius: 12px;
  padding: 5px;
  cursor: pointer;
  :hover {
    background-color: lightgray;
  }
`
const PaidBlock = styled.div`
  border: 1px solid #f26522;
  border-radius: 12px;
  padding: 15px;
  display: flex;
  align-items: center;
`
const CancelButton = styled.button`
  background-color: unset;
  border-radius: 12px;
  width: 100px;
  border: 1px solid #868e96;
  cursor: pointer;
  font-weight: 500;
  :hover {
    color: #f26522;
    border-color: #f26522;
  }
`
const ReviewButton = styled(Button)`
  background-color: #f26522;
  border-radius: 12px;
  width: 100%;
  border: none;
  cursor: pointer;
  font-weight: 500;
  color: white;
  margin: 10px 0 0 0;
  :hover {
    background-color: #f3763a;
    color: white;
  }
  :focus {
    background-color: #f3763a;
    color: white;
  }
`
const TabsBank = styled(Tabs)`
  .ant-tabs-nav {
    padding: 0px;
    border-radius: 12px;
  }
  .ant-tabs-tab {
    width: 25%;
    padding: 0px;
  }
  .ant-tabs-nav-list {
    width: 100%;
    border-radius: 12px;
  }
  .ant-tabs-nav-wrap {
    border-radius: 12px;
  }
  .ant-tabs-tab-btn {
    width: 100%;
    padding: 5px 0 10px 0;
    text-align: center;
    white-space: normal !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #f26522;
    border-bottom: 1px solid #f26522;
  }
  .ant-tabs-tab {
    padding: 0px !important;
  }
`
const ModalStyle = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px !important;
  }
  .ant-modal-header {
    border-radius: 12px 12px 0 0;
  }
`
const RadioStyle = styled(Radio)`
  font-size: 16px;
  .ant-radio-inner {
    border-color: #f26522 !important;
    ::after {
      background-color: #f26522 !important;
    }
  }
`
const UploadButton = styled.button`
  padding: 2px;
  margin: 0 0 10px 0;
  background-color: unset;
  border: 1px solid #ced4da;
  /* border: none; */
  border-radius: 5px;
  cursor: pointer;
  :hover {
    color: #f26522;
    border-color: #f26522;
  }
`
const UploadStyled = styled(Upload)`
  .ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 4rem;
    height: 4rem;
    @media only screen and (max-width: 330px) {
      width: 3rem;
      height: 3rem;
    }
  }
  .ant-upload-list-picture-card-container {
    width: 4rem;
    height: 4rem;
    @media only screen and (max-width: 330px) {
      width: 3rem;
      height: 3rem;
    }
  }
  width: 25%;
  margin: 0 0 10px 0;
`
const InfoProduct = styled.div`
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  @media only screen and (max-width: 465px) {
    width: 100%;
    margin: 0 0 15px 0;
  }
`
const ColStyle = styled(Col)`
  display: none;
  color: white;
  @media only screen and (max-width: 465px) {
    display: unset;
  }
`
const PriceMobile = styled(Row)`
  display: none;
  @media only screen and (max-width: 465px) {
    display: flex;
  }
`
const PriceNotMobile = styled.div`
  @media only screen and (max-width: 465px) {
    display: none;
  }
`
const PaymentButton = styled.button`
  background-color: #f26522;
  border-radius: 7px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  color: white;
  margin: 0px 0 10px 15px;
  :hover {
    background-color: #f3763a;
  }
`
export {
  PaymentButton,
  PriceNotMobile,
  PriceMobile,
  ColStyle,
  UploadButton,
  InfoProduct,
  UploadStyled,
  ReviewButton,
  RadioStyle,
  ModalStyle,
  ItemBank,
  AboveBank,
  CancelButton,
  PaidBlock,
  CodeBlock,
  BankBlock,
  BreadcrumbStyle,
  TagStyle,
  ListProductBlock,
  TabsStyled,
  TabPaneStyled,
  ContainerOrder,
  LeftBlock,
  RightBlock,
  AboveBlock,
  BottomBlock,
  InfoBlock,
  StatusBlock,
  ProductRecord,
  InputInfoBank,
  ParagraphStyle,
  TabsBank,
}
