import { EnvironmentFilled, StarFilled } from '@ant-design/icons'
import {
  Avatar,
  Col,
  Comment,
  Divider,
  Empty,
  Image,
  Rate,
  Row,
  Skeleton,
  Spin,
  Tabs,
  Tooltip,
  Typography,
} from 'antd'
import R from 'assets'
import { InsuranceCard } from 'common/components/CardInsurance'
import { ProductCard } from 'common/components/CardProduct'
import { StyledCol } from 'common/components/CardProduct/styled'
import { MinusIcon, PlusIcon } from 'common/components/Icons'
import { ROUTER_PATH, SESSION_KEY } from 'common/config'
import { setAmountCart } from 'features/auth/AuthSlice'
import { ButtonChangeQuantity, InputAmount } from 'features/cart/styled'
import { WrapBox } from 'features/home/styled'
import { TabPaneStyled, TabsStyled } from 'features/order/styled'
import {
  getDataCoordinates,
  setDataCoordinates,
} from 'features/stores/StoreSlice'
import { RowStore } from 'features/stores/styled'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import swal from 'sweetalert'
import { API_STATUS } from 'utils/constants'
import { hasCookie } from 'utils/functionHelper'
import history from 'utils/history'
import { notificationError, notificationSuccess } from 'utils/notification'
import { enterNumbersOnly, formatPrice } from 'utils/ruleForm'
import { addCart, getDetail } from '../ApiProducts'
import {
  ButtonCart,
  ButtonMore,
  ButtonSell,
  ButtonSellInsurance,
  ColMoreImg,
  DesctiptionBlock,
  ImageBlock,
  ImageInsurance,
  ImageProduct,
  ImageStyled,
  InforDetailBlock,
  InforImage,
  NameProduct,
  NameStore,
  StoreBlock,
  TitleBlock,
} from '../styles'
import CarouselStore from './Carousel'
import ModalStore from './ModalStore'

const { Text } = Typography

const DetailOilInsurance: React.FC = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const coordinates = useSelector(getDataCoordinates)
  const userState: any = useSelector((state: any) => state.authReducer)
    ?.userInfo
  const searchParams = new URLSearchParams(window.location.search)
  const typeProduct: string = searchParams.get('type') as string
  const idProduct: string = searchParams.get('product_id') as string
  const idPetrol: string = searchParams.get('petrol_store_id') as string
  const searchProduct: string = searchParams.get('search') as string
  const idCategoryProduct: string = searchParams.get('idCategory') as string
  const categoryProduct: string = searchParams.get('category') as string
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataDetail, setDataDetail] = useState<any>()

  const [dataSimilar, setDataSimilar] = useState([])
  const [dataStore, setDataStore] = useState<any>()
  const [amount, setAmount] = useState<number>(1)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [idStore, setIdStore] = useState<any>()
  const [geoLocation, setGeoLocation] = useState<boolean>(false)
  const [params, setParams] = useState<any>({
    user_id: userState?.id,
    lat: undefined,
    lng: undefined,
  })
  const [pages, setPages] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  })

  useEffect(() => {
    getLocation()
  }, [])

  useEffect(() => {
    if ((coordinates.location_lat && coordinates.location_long) || userState) {
      setParams({
        user_id: userState?.id,
        lat: coordinates.location_lat,
        lng: coordinates.location_long,
      })
    }
  }, [coordinates, userState])

  useEffect(() => {
    getDataProduct()
  }, [idStore, params, idProduct])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const getDataProduct = async () => {
    setIsLoading(true)
    try {
      if (idStore) {
        const res = await getDetail(Number(idProduct), idStore, params)

        setDataStore(res?.data?.product_stocks)
        setDataSimilar(res?.data?.similarProducts)
        setDataDetail(res?.data)
        setPages({
          ...pages,
          current: res.meta?.pagination?.page,
          total: res.meta?.pagination?.totalItems,
          pageSize: res.meta?.pagination?.limit,
        })
      } else {
        const res = await getDetail(Number(idProduct), Number(idPetrol), params)

        setDataStore(res?.data?.product_stocks)
        setDataSimilar(res?.data?.similarProducts)
        setDataDetail(res?.data)
        setPages({
          ...pages,
          current: res.meta?.pagination?.page,
          total: res.meta?.pagination?.totalItems,
          pageSize: res.meta?.pagination?.limit,
        })
      }
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  const AddCartOil = async (type: string) => {
    setIsLoading(true)
    try {
      let cartData
      cartData = {
        product_stock_id: dataDetail?.detail_petrol?.id,
        petrol_store_id: dataDetail?.detail_petrol?.petrol_store_id,
        amount: amount,
      }
      const res = await addCart(cartData)

      if (res.code === API_STATUS.CREATED) {
        notificationSuccess(res.message)
        dispatch(setAmountCart())
        setTimeout(() => {
          if (type === 'buy_now') {
            history.push({
              pathname: ROUTER_PATH.CART,
              state: {
                cart_id: res.data,
              },
            })
          }
        }, 500)
      }
    } catch (error) {
      console.error('Exception ' + error)
    }
    setIsLoading(false)
  }

  const getLocation = () => {
    if (!navigator.geolocation) {
      notificationError('Geolocation is not supported by this browser.')
      setGeoLocation(false)
    } else {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          setGeoLocation(true)
          dispatch(
            setDataCoordinates({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
          )
        },
        (error: any) => {
          setGeoLocation(false)
          // notificationError(R.strings().warning_geolocation)
        }
      )
    }
  }
  return (
    <>
      <Spin spinning={isLoading} size="large">
        <ImageBlock>
          {typeProduct === 'insurrance' || typeProduct === '3' ? (
            <ImageInsurance>
              <CarouselStore
                type={typeProduct}
                loading={isLoading}
                data={dataDetail?.product?.product_medias}
              />
            </ImageInsurance>
          ) : (
            <ImageProduct>
              <CarouselStore
                type={typeProduct}
                loading={isLoading}
                data={dataDetail?.product?.product_medias}
              />
            </ImageProduct>
          )}

          <Skeleton active loading={isLoading}>
            <InforImage>
              <NameStore
                onClick={() => {
                  history.push({
                    pathname: `${ROUTER_PATH.DETAIL_STORES}/${dataDetail?.detail_petrol?.petrol_store_id}`,
                  })
                }}
              >
                {dataDetail?.detail_petrol?.petrol_store?.name}
              </NameStore>
              <Row>
                {!geoLocation || !dataDetail?.detail_petrol?.distance ? (
                  <></>
                ) : (
                  <>
                    <Col>
                      <div style={{ fontSize: 16, fontWeight: 400 }}>
                        <EnvironmentFilled
                          style={{ color: '#F26522', paddingRight: 5 }}
                        />
                        {formatPrice(dataDetail?.detail_petrol?.distance)} km
                      </div>
                    </Col>
                    &nbsp; &nbsp;
                  </>
                )}
                <Col>
                  <div style={{ fontSize: 16 }}>
                    <StarFilled style={{ color: '#FFC53D', paddingRight: 5 }} />
                    {
                      +Number(
                        dataDetail?.detail_petrol?.petrol_store_star
                      ).toFixed(1)
                    }
                  </div>
                </Col>
                <Divider style={{ margin: '10px 0 20px 0' }} />
              </Row>
              <NameProduct>
                {dataDetail?.product?.name}
                {/* {dataDetail?.product?.star && ( */}
                <Row align="bottom">
                  <Rate
                    style={{ fontSize: 18, color: 'rgb(255, 197, 61)' }}
                    disabled
                    allowHalf
                    defaultValue={
                      +Number(dataDetail?.product?.star || 0)?.toFixed(1)
                    }
                  />
                  {dataDetail?.product?.star && (
                    <span
                      style={{
                        fontSize: '14px',
                        color: '#333',
                        marginLeft: '6px',
                      }}
                    >
                      {Number(dataDetail?.product?.star || 0).toFixed(1)}
                    </span>
                  )}
                  {dataDetail?.product?.star && (
                    <span
                      style={{
                        fontSize: '16px',
                        color: '#666',
                        marginLeft: '10px',
                      }}
                    >
                      ({Number(dataDetail?.product?.reviewed_count)}{' '}
                      {R.strings().evaluate})
                    </span>
                  )}
                </Row>
                {/* )} */}
              </NameProduct>

              <Row>
                <Text
                  delete
                  style={
                    !dataDetail?.product?.min_price
                      ? { display: 'none' }
                      : {
                          fontSize: 16,
                          paddingRight: 10,
                          display: 'flex',
                          alignItems: 'center',
                        }
                  }
                >
                  {dataDetail?.detail_petrol?.price === 0
                    ? '0'
                    : formatPrice(dataDetail?.detail_petrol?.price)}
                  đ
                </Text>
                <Row align="middle">
                  <div
                    style={{ fontSize: 26, color: '#F26522', fontWeight: 600 }}
                  >
                    {dataDetail?.product?.min_price
                      ? formatPrice(dataDetail?.product?.min_price)
                      : formatPrice(dataDetail?.detail_petrol?.price)}
                    đ
                  </div>
                  {dataDetail?.product?.unit && (
                    <span style={{ fontSize: '14px', marginLeft: '10px' }}>
                      {' '}
                      / {dataDetail?.product?.unit}
                    </span>
                  )}
                </Row>
              </Row>
              {typeProduct !== 'insurrance' && typeProduct !== '3' ? (
                <Row
                  style={{ margin: '50px 0px 60px 0px' }}
                  justify="space-between"
                >
                  <Row>
                    <div style={{ fontWeight: 500, paddingRight: 30 }}>
                      {R.strings().amount}
                    </div>
                    <ButtonChangeQuantity
                      disabled={amount < 2 ? true : false}
                      onClick={() => {
                        if (amount > 1) {
                          setAmount(amount - 1)
                        }
                      }}
                    >
                      <MinusIcon />
                    </ButtonChangeQuantity>

                    <InputAmount
                      max={999}
                      min={1}
                      value={formatPrice(amount)}
                      onChange={(e: any) => {
                        let values = enterNumbersOnly(e.target.value, 0)
                        if (Number(values) === 0 && Number(values) > 999) {
                          notificationError(
                            'Vui lòng chọn tù 1 đến 999 sản phẩm'
                          )
                        } else {
                          setAmount(Number(values))
                        }
                      }}
                    />
                    <ButtonChangeQuantity
                      disabled={amount > 999 ? true : false}
                      onClick={() => {
                        setAmount(amount + 1)
                      }}
                    >
                      <PlusIcon />
                    </ButtonChangeQuantity>
                  </Row>
                  <div>
                    {R.strings().sell_amount}:{' '}
                    {formatPrice(Number(dataDetail?.product?.selling_amount))}
                  </div>
                  <div style={{ color: 'white' }}>
                    {R.strings().sell_amount}
                  </div>
                </Row>
              ) : (
                <div style={{ margin: '20px 0 30px 0' }}>
                  {R.strings().sell_amount}:{' '}
                  {Number(dataDetail?.product?.selling_amount)}
                </div>
              )}
              <div style={{ textAlign: 'center' }}>
                {typeProduct === 'insurrance' || typeProduct === '3' ? (
                  <ButtonSellInsurance
                    onClick={() => {
                      if (hasCookie(SESSION_KEY.SESSION)) {
                        history.push({
                          pathname: ROUTER_PATH.PAYMENT,
                          state: {
                            type: 'insurance',
                            product_stock_id: dataDetail?.detail_petrol?.id,
                            petrol_store_id:
                              dataDetail?.detail_petrol?.petrol_store_id,
                            amount: amount,
                            total_price: dataDetail?.product?.min_price
                              ? dataDetail?.product?.min_price
                              : dataDetail?.detail_petrol?.price,
                            media:
                              dataDetail?.product?.product_medias[0].media_url,
                            product: {
                              name: dataDetail?.product?.name,
                              price: dataDetail?.product?.min_price
                                ? dataDetail?.product?.min_price
                                : dataDetail?.detail_petrol?.price,
                              media:
                                dataDetail?.product?.product_medias[0]
                                  .media_url,
                            },
                          },
                        })
                      } else {
                        swal({
                          title: R.strings().notification,
                          text: R.strings().err_login,
                          icon: 'error',
                        }).then(function (isConfirm) {
                          if (isConfirm) {
                            history.push({
                              pathname: ROUTER_PATH.LOGIN,
                              hash: location.pathname,
                            })
                          }
                        })
                      }
                    }}
                  >
                    {R.strings().buy_now}
                  </ButtonSellInsurance>
                ) : (
                  <>
                    <ButtonCart
                      onClick={() => {
                        if (amount > 999 || amount === 0) {
                          notificationError('Nhập từ 1 đến 999 sản phẩm')
                        } else {
                          AddCartOil('add_cart')
                        }
                      }}
                    >
                      {R.strings().add_cart}
                    </ButtonCart>
                    <ButtonSell
                      onClick={() => {
                        if (amount > 999 || amount === 0) {
                          notificationError('Nhập từ 1 đến 999 sản phẩm')
                        } else {
                          AddCartOil('buy_now')
                        }
                      }}
                    >
                      {R.strings().buy_now}
                    </ButtonSell>
                  </>
                )}
              </div>
            </InforImage>
          </Skeleton>
        </ImageBlock>
        <InforDetailBlock>
          <DesctiptionBlock>
            <TabsStyled style={{ backgroundColor: '#fff' }}>
              <TabPaneStyled tab={R.strings().description_product} key="item-1">
                <div
                  className="style_scroll"
                  style={{
                    height: '465px',
                    overflowY: 'auto',
                    paddingRight: '10px',
                  }}
                >
                  <div
                    className="description_editor"
                    style={{ textAlign: 'justify' }}
                    dangerouslySetInnerHTML={{
                      __html: dataDetail?.product?.description as string,
                    }}
                  />
                </div>
              </TabPaneStyled>
              <TabPaneStyled tab={R.strings().evaluate} key="item-2">
                <div
                  className="style_scroll"
                  style={{
                    height: '465px',
                    overflowY: 'auto',
                    paddingRight: '10px',
                  }}
                >
                  {dataDetail?.product?.product_reviews &&
                  dataDetail?.product?.product_reviews.length > 0 ? (
                    dataDetail?.product?.product_reviews
                      .sort((a: any, b: any) =>
                        moment(b.create_at).diff(moment(a.create_at))
                      )
                      .map((comment: any) => (
                        <Comment
                          author={<a>{comment?.reviewed_name}</a>}
                          avatar={
                            <Avatar
                              src={
                                comment?.avatar ||
                                'https://res.cloudinary.com/hunre/image/upload/v1622210328/samples/t%E1%BA%A3i_xu%E1%BB%91ng_2_sf3hpq.jpg'
                              }
                              alt={comment?.reviewed_name}
                            />
                          }
                          content={
                            <p>
                              <Row>
                                <Rate
                                  style={{
                                    fontSize: '14px',
                                    color: 'rgb(255, 197, 61)',
                                    marginBottom: '6px',
                                  }}
                                  disabled
                                  allowHalf
                                  defaultValue={Number(
                                    dataDetail?.product?.star
                                  )}
                                />
                              </Row>
                              <Row gutter={[10, 10]}>
                                {comment?.reviews?.review_media &&
                                  comment?.reviews?.review_media.map(
                                    (item: any, index: number) => {
                                      return (
                                        <Col span={4}>
                                          <ImageStyled src={item?.media_url} />
                                        </Col>
                                      )
                                    }
                                  )}
                                {/* {comment?.reviews?.review_media.length > 2 && (
                                  <Col span={4}>
                                    <Tooltip title="Xem thêm">
                                      <ColMoreImg
                                        onClick={() => setIsLimit(false)}
                                      >
                                        +
                                        {comment?.reviews?.review_media.length -
                                          2}
                                      </ColMoreImg>
                                    </Tooltip>
                                  </Col>
                                )} */}
                              </Row>
                              <div>{comment?.description || '---'}</div>
                            </p>
                          }
                          datetime={
                            <Tooltip
                              title={moment(comment?.create_at).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )}
                            >
                              <span>
                                {moment(comment?.create_at).fromNow()}
                              </span>
                            </Tooltip>
                          }
                        />
                      ))
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Chưa có đánh giá"
                    />
                  )}
                </div>
              </TabPaneStyled>
            </TabsStyled>
          </DesctiptionBlock>
          <StoreBlock>
            <TitleBlock>{R.strings().list_store}</TitleBlock>
            {dataStore?.length > 0 ? (
              dataStore?.map((item: any, index: number) => (
                <RowStore style={index > 4 ? { display: 'none' } : {}}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexFlow: 'nowrap',
                    }}
                    onClick={() => {
                      window.scrollTo(0, 0)
                      setIdStore(item.petrol_store_id)
                      setIsModalVisible(false)
                    }}
                  >
                    <div>
                      <img src={R.images.icon_store_gas} alt="" />
                    </div>

                    <div
                      style={{
                        width: '100%',
                        padding: '0px 0px 0px 10px',
                        display: 'flex',
                        flexFlow: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ fontSize: 15, fontWeight: 500 }}>
                        {item?.petrol_store?.name}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexFlow: 'nowrap',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Row>
                          {!geoLocation || !item?.distance ? (
                            <></>
                          ) : (
                            <>
                              <Col>
                                <div style={{ fontSize: 14 }}>
                                  <EnvironmentFilled
                                    style={{
                                      color: '#F26522',
                                      paddingRight: 5,
                                    }}
                                  />
                                  {item?.distance} km
                                </div>
                              </Col>
                              &nbsp; &nbsp;
                            </>
                          )}
                          <Col>
                            <div style={{ fontSize: 14 }}>
                              <StarFilled
                                style={{ color: '#FFC53D', paddingRight: 5 }}
                              />
                              {+Number(item.petrol_store_star).toFixed(1)}
                            </div>
                          </Col>
                        </Row>
                        <Col>
                          <div
                            style={{
                              fontSize: 15,
                              fontWeight: 500,
                              color: '#F26522',
                            }}
                          >
                            {item?.min_price ? (
                              <>
                                <Text
                                  style={{ fontSize: 14, color: 'gray' }}
                                  delete
                                >
                                  {formatPrice(item?.price)}{' '}
                                </Text>
                                {formatPrice(item?.min_price)}
                              </>
                            ) : (
                              formatPrice(item?.price)
                            )}
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                  <Divider style={{ margin: '10px 0 10px 0' }} />
                </RowStore>
              ))
            ) : (
              <>
                <Empty description={R.strings().empty} />
              </>
            )}

            {dataStore?.length > 4 ? (
              <ButtonMore onClick={showModal}>
                {R.strings().title_see_more}
              </ButtonMore>
            ) : (
              <></>
            )}
            <ModalStore
              setIdStore={setIdStore}
              idStore={idStore}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              dataLocation={params}
              geoLocation={geoLocation}
              product_id={Number(idProduct)}
              petrol_store_id={Number(idPetrol)}
            />
          </StoreBlock>
        </InforDetailBlock>
        {dataSimilar.length > 0 ? (
          <WrapBox style={{ marginBottom: 50 }}>
            <TitleBlock>{R.strings().similar_product}</TitleBlock>
            <Row justify="start">
              {dataSimilar?.map((item: any) => (
                <>
                  <StyledCol
                    xs={12}
                    sm={8}
                    xl={6}
                    key={item.id}
                    style={
                      typeProduct === 'insurrance' || typeProduct === '3'
                        ? { display: 'none' }
                        : {}
                    }
                  >
                    <ProductCard
                      dataProduct={item}
                      onClickProduct={() => {
                        const petrol_id = item?.percent_discount
                          ? item?.petrol_store_id
                          : item?.product_stocks[0]?.petrol_store_id
                        const category_name = searchProduct
                          ? searchProduct
                          : categoryProduct
                        history.push({
                          pathname: `${ROUTER_PATH.DETAIL_PRODUCT}/${item?.id}`,
                          search: `type=${typeProduct}&category=${category_name}&product_id=${item.id}&petrol_store_id=${petrol_id}&nameProduct=${item.name}&idCategoryProduct=${idCategoryProduct}`,
                        })
                      }}
                    />
                  </StyledCol>
                  <StyledCol
                    xs={24}
                    sm={12}
                    xl={12}
                    key={item.id}
                    style={
                      typeProduct === 'insurrance' || typeProduct === '3'
                        ? {}
                        : { display: 'none' }
                    }
                  >
                    <InsuranceCard
                      dataProduct={item}
                      onClickProduct={() => {
                        const petrol_id = item?.percent_discount
                          ? item?.petrol_store_id
                          : item?.product_stocks[0]?.petrol_store_id
                        history.push({
                          pathname: `${ROUTER_PATH.DETAIL_PRODUCT}/${item?.id}`,
                          search: `type=${typeProduct}&category=${categoryProduct}&product_id=${item.id}&petrol_store_id=${petrol_id}&nameProduct=${item.name}&idCategoryProduct=${idCategoryProduct}`,
                        })
                      }}
                    />
                  </StyledCol>
                </>
              ))}
            </Row>
          </WrapBox>
        ) : (
          <div style={{ marginBottom: 50 }}></div>
        )}
      </Spin>
    </>
  )
}

export default DetailOilInsurance
