import { createSlice } from '@reduxjs/toolkit'

let initialState: any = {
  dataCart: [],
}

export const getDataCard = (state: any) => state.cartReducer.dataCart

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setDataCard: (state, action) => {
      state.dataCart = action.payload
    },
  },
})

export const { setDataCard } = cartSlice.actions

export default cartSlice.reducer
