import { ApiClient } from 'services/ApiService'

interface PayloadLogin {
  username: string
  password: string
}
interface PayloadSignup {
  phone: string
}
interface PayloadVerify {
  code: string
}
interface PayloadVerifyPass {
  otp: string
  email: string
}
interface PayloadUpdate {
  device_id: string
  email: string
  name: string
  password: string
  referral_code: string
  avatar: string
  enterprise: {
    name: string
    tax: string
    tax_address: string
  }
}

//Login
export const requestLogin = (payload: PayloadLogin) =>
  ApiClient.post('/customer/auth/login', payload)
export const requestLogout = () => ApiClient.put('/users/logout')
export const requestGetUserInfo = () => ApiClient.get('user/customer/info')
//Signup
export const requestSignup = (payload: PayloadSignup) =>
  ApiClient.post('/customer/auth/register', payload)
export const verifySignup = (payload: PayloadVerify, user_id: number) =>
  ApiClient.put(`/customer/auth/${user_id}/verify`, payload)
export const updateRegister = (payload: PayloadUpdate) =>
  ApiClient.post(`/customer/auth/update-register`, payload)
//Forgot Password
export const requestPassword = (payload: PayloadSignup) =>
  ApiClient.post('/customer/auth/request/reset-password', payload)
export const verifyPassword = (payload: PayloadVerifyPass) =>
  ApiClient.post(`/customer/auth/verify/reset-password`, payload)
export const updatePassword = (payload: PayloadUpdate, config?: any) =>
  ApiClient.post(`/customer/auth/reset-password`, payload, config)
