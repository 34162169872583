import { Button, Form, Input, Row, Steps } from 'antd'
import OtpInput from 'react-otp-input'
import styled from 'styled-components'
const { Step } = Steps
const ContentBlock = styled.div`
  background-color: #e5e5e5;
  height: 100vh;
  display: flex;
  flex-flow: nowrap;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1073px) {
    flex-flow: column;
    height: 100%;
  }
`
const LeftBlock = styled.div`
  display: block;
  position: relative;
  aspect-ratio: 61/64;
  height: 70vh;
  border-radius: 20px 0px 0px 20px;
  @media only screen and (max-width: 480px) {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 35%;
  }
  @media only screen and (max-width: 1073px) {
    width: 100%;
    height: 250px;
  }
  @media only screen and (max-height: 665px) {
    height: 470px;
  }
`
const ImageAuth = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 20px 0px 0px 20px;
  @media only screen and (max-width: 1073px) {
    border-radius: 0px;
  }
`

const ImageLogo = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`

const TitleForm = styled.div`
  padding: 3vh 0px 2vh 0px;
  font-weight: 700;
  font-size: 32px;
  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 480px) {
    font-size: 24px;
    padding: 10px 0px 20px 0px;
  }
`
const LogoForm = styled.div`
  height: 11%;
  aspect-ratio: 140/47.55;
  @media only screen and (max-width: 1073px) {
    height: 2%;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
  @media only screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
    height: 6%;
  }
`
const TextForget = styled.div`
  font-size: 14px;
  cursor: pointer;
  :hover {
    color: #f47458;
  }
`

export const ButtonFormRegister = styled(Button)`
  background-color: #f47458;
  color: white;
  height: 37px;
  width: 10vw;
  border: none;
  border-radius: 12px;
  margin: 3vh 0px 2vh 0px;
  cursor: pointer;

  &:hover {
    background-color: #f47458;
    color: white;
    opacity: 0.8;
  }
  &:focus {
    background-color: #f47458;
    color: white;
    opacity: 0.8;
  }

  @media only screen and (max-width: 768px) {
    width: 50vw;
  }
`

const ButtonForm = styled.button`
  background-color: #f47458;
  color: white;
  height: 37px;
  width: 10vw;
  border: none;
  border-radius: 12px;
  margin: 3vh 0px 2vh 0px;
  cursor: pointer;

  &:hover {
    background-color: #f47458;
    color: white;
    opacity: 0.8;
  }
  &:focus {
    background-color: #f47458;
    color: white;
    opacity: 0.8;
  }

  @media only screen and (max-width: 768px) {
    width: 50vw;
  }
`
const RightBlock = styled.div`
  height: 70vh;
  aspect-ratio: 464/640;
  padding: 4vh 30px 0px 30px;
  background-color: white;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 65%;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    padding: 10px 100px 0px 100px;
  }
  @media only screen and (max-width: 480px) {
    height: 100vh;
    padding: 50px 25px 0px 25px;
  }
  @media only screen and (max-width: 1073px) {
    width: 100%;
    aspect-ratio: unset;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }
  @media only screen and (max-height: 665px) {
    height: 470px;
  }
`
const HaveCount = styled(Row)`
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  /* font-size: ; */
  color: #8c8c8c;
  p {
    color: #f26522;
    cursor: pointer;
  }
`
const InputForm = styled(Input)`
  input.ant-input {
    /* font-size: 16px; */
  }
  border-radius: 12px;
  height: 37px;
`
const InputFormPassword = styled(Input.Password)`
  input.ant-input {
    /* font-size: 2vh; */
  }
  border-radius: 12px;
  height: 37px;
`
//Signup

const CodeBlock = styled.div`
  width: 45vw;
  /* height: calc(100vh - 35vh); */
  background-color: #ffffff;
  border-radius: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 108px;
  @media only screen and (max-width: 480px) {
    height: 90vh;
    width: 100vw;
    border-radius: 0px;
    margin-bottom: 0px;
  }
  @media only screen and (max-width: 768px) {
    height: 90vh;
    width: 100vw;
    padding-bottom: 100px;
    border-radius: 0px;
    margin-bottom: 0px;
  }
`
const InforBlock = styled.div`
  width: 45vw;
  height: calc(100vh - 20vh);
  background-color: #ffffff;
  border-radius: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 50px;
  @media only screen and (max-width: 480px) {
    height: 90vh;
    width: 100vw;
    border-radius: 0px;
    margin-bottom: 0px;
  }
  @media only screen and (max-width: 820px) {
    height: 90vh;
    width: 100vw;
    border-radius: 0px;
    margin-bottom: 0px;
  }
`
const AboveBlock = styled(Row)`
  width: 45vw;
  height: 9vh;
  padding: 3vh 34px 3vh 34px;
  font-size: 20px;
  font-weight: 600;
  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
  @media only screen and (max-width: 480px) {
    padding: 2.3vh 10px 2.3vh 10px;
  }
`
const TextCode = styled.div`
  font-size: 14px;
  text-align: center;
  margin: 40px 0px 64px 0px;
`
const UnderBlock = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 20px 0;
`
const InputCode = styled(Input)`
  letter-spacing: 20px;
  height: 70px;
  font-size: 40px;
  /* text-align: center; */
  border-radius: none !important;
  border-width: 0 0 3px 0;
  /* border-color: #353535; */
  border-color: white;
  padding: 0 5px 0 35px;
  max-width: 310px;
  :focus {
    box-shadow: none;
    border-radius: none;
    /* border-color: #353535; */
    border-color: white;
    border-right-width: 0px !important;
  }
  :hover {
    border-radius: none;
    /* border-color: #353535; */
    border-color: white;
    border-right-width: 0px !important;
  }
  .ant-input {
    border-radius: none !important;
  }
`
const FormStyle = styled(Form)`
  padding-top: 10;
  width: 60%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  @media only screen and (max-width: 480px) {
    width: 80%;
  }
`
const InputInfor = styled(Input)`
  border-radius: 10rem;
`
const InputPass = styled(Input.Password)`
  border-radius: 10rem;
  height: 43px;
`
const ButtonCode = styled.button`
  background-color: #f47458;
  color: white;
  height: 46px;
  width: 100%;
  border: none;
  border-radius: 16px;
  font-size: 16px;
  cursor: pointer;
`
const ButtonComplete = styled.button`
  background-color: #f47458;
  color: white;
  height: 5vh;
  width: 20vw;
  border: none;
  border-radius: 16px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10vh;
  @media only screen and (max-width: 768px) {
    width: 50vw;
  }
`
const StepCode = styled(Steps)`
  width: 350px;
  @media only screen and (max-width: 480px) {
    width: 250px;
  }
`
const SubStep = styled(Step)`
  .ant-steps-item-icon > .ant-steps-icon {
    color: white;
  }
  .ant-steps-item-container .ant-steps-item-icon {
    background-color: #f47458;
  }
  .ant-steps-item-container > .ant-steps-item-icon {
    border-color: #f47458;
  }
  .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #f47458;
  }
`
const FooterCode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 480px) {
    background-color: white;
    padding-bottom: 5vh;
  }
  @media only screen and (max-width: 768px) {
    background-color: white;
    padding-bottom: 5vh;
  }
`
const TermLink = styled.span`
  color: #f26522;
  :hover {
    text-decoration-line: underline;
  }
`

export {
  TermLink,
  ContentBlock,
  LeftBlock,
  RightBlock,
  TitleForm,
  ButtonForm,
  LogoForm,
  TextForget,
  HaveCount,
  ImageAuth,
  InputForm,
  InputFormPassword,
  ImageLogo,
  CodeBlock,
  AboveBlock,
  TextCode,
  UnderBlock,
  InputCode,
  ButtonCode,
  StepCode,
  FooterCode,
  InputInfor,
  InputPass,
  InforBlock,
  FormStyle,
  ButtonComplete,
  SubStep,
}
