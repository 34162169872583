import Icon from '@ant-design/icons'

const ReloadSvg = () => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.16632 22.9997C7.16382 20.1655 7.92278 17.3827 9.36385 14.9423C10.8049 12.5018 12.8752 10.4934 15.3581 9.12686C17.8411 7.76036 20.6456 7.08601 23.4784 7.17433C26.3111 7.26265 29.0682 8.11039 31.4612 9.62892L28.937 11.7697C28.8215 11.8675 28.7348 11.9948 28.6862 12.138C28.6376 12.2812 28.6288 12.435 28.661 12.5828C28.6931 12.7307 28.7648 12.8669 28.8685 12.9771C28.9722 13.0872 29.1039 13.1671 29.2495 13.2081L38.3053 15.7314C38.378 15.7519 38.4531 15.7623 38.5286 15.7622C38.6469 15.7622 38.7637 15.737 38.8715 15.6884C38.9792 15.6397 39.0754 15.5687 39.1535 15.48C39.2317 15.3914 39.2902 15.2871 39.3249 15.1741C39.3597 15.0611 39.3701 14.942 39.3553 14.8247L38.1995 5.60228C38.1809 5.45314 38.1222 5.31182 38.0299 5.19328C37.9375 5.07473 37.8148 4.98338 37.6747 4.92889C37.5346 4.8744 37.3824 4.85881 37.2342 4.88375C37.086 4.9087 36.9473 4.97327 36.8328 5.07061L34.1095 7.38143C31.2424 5.34196 27.8693 4.13103 24.3597 3.88134C20.8502 3.63165 17.3396 4.35283 14.2127 5.96586C11.0857 7.57889 8.46317 10.0215 6.63232 13.0261C4.80147 16.0307 3.83299 19.4812 3.83301 22.9997C3.83301 23.4417 4.0086 23.8656 4.32116 24.1782C4.63372 24.4907 5.05764 24.6663 5.49966 24.6663C5.94169 24.6663 6.36561 24.4907 6.67817 24.1782C6.99073 23.8656 7.16632 23.4417 7.16632 22.9997Z"
      fill="#F26522"
    />
    <path
      d="M40.4992 21.333C40.0572 21.333 39.6333 21.5086 39.3207 21.8212C39.0082 22.1337 38.8326 22.5576 38.8326 22.9997C38.8353 25.8338 38.0764 28.6166 36.6355 31.0571C35.1945 33.4976 33.1243 35.5061 30.6414 36.8726C28.1585 38.2392 25.354 38.9135 22.5213 38.8252C19.6885 38.7368 16.9315 37.889 14.5386 36.3704L17.0619 34.2296C17.1774 34.1319 17.2641 34.0046 17.3127 33.8613C17.3613 33.7181 17.3701 33.5643 17.3379 33.4165C17.3058 33.2687 17.2341 33.1324 17.1304 33.0223C17.0267 32.9121 16.895 32.8323 16.7494 32.7913L7.69027 30.268C7.55801 30.2312 7.41872 30.2277 7.28478 30.2577C7.15084 30.2878 7.02639 30.3504 6.92249 30.4401C6.81859 30.5298 6.73847 30.6438 6.68923 30.772C6.64 30.9001 6.62318 31.0384 6.64028 31.1746L7.79943 40.3971C7.81815 40.546 7.87678 40.6872 7.96911 40.8056C8.06144 40.924 8.18405 41.0153 8.32397 41.0697C8.4639 41.1242 8.61594 41.1399 8.76403 41.115C8.91212 41.0902 9.05074 41.0258 9.16526 40.9287L11.8894 38.6179C14.7565 40.6574 18.1296 41.8683 21.6392 42.118C25.1487 42.3677 28.6593 41.6465 31.7862 40.0335C34.9132 38.4205 37.5357 35.9778 39.3666 32.9732C41.1974 29.9687 42.1659 26.5181 42.1659 22.9997C42.1659 22.5576 41.9903 22.1337 41.6777 21.8212C41.3652 21.5086 40.9413 21.333 40.4992 21.333Z"
      fill="#F26522"
    />
    <circle
      r="10.4166"
      transform="matrix(1 0 0 -1 23.4166 23.4164)"
      fill="#F26522"
    />
    <g clipPath="url(#clip0_4269_10577)">
      <path
        d="M23.415 17.96V28.8726"
        stroke="white"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.8958 19.9443H22.1756C21.7151 19.9443 21.2735 20.1272 20.9479 20.4528C20.6224 20.7784 20.4395 21.22 20.4395 21.6804C20.4395 22.1409 20.6224 22.5825 20.9479 22.908C21.2735 23.2336 21.7151 23.4165 22.1756 23.4165H24.6557C25.1161 23.4165 25.5577 23.5994 25.8833 23.925C26.2089 24.2506 26.3918 24.6922 26.3918 25.1526C26.3918 25.6131 26.2089 26.0547 25.8833 26.3802C25.5577 26.7058 25.1161 26.8887 24.6557 26.8887H20.4395"
        stroke="white"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4269_10577">
        <rect
          width="11.9047"
          height="11.9047"
          fill="white"
          transform="translate(17.4639 17.4639)"
        />
      </clipPath>
    </defs>
  </svg>
)

const ReloadIcon = (props: any) => <Icon component={ReloadSvg} {...props} />
export default ReloadIcon
