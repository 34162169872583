import React from 'react'
import { useLocation } from 'react-router-dom'
import DeliveryPolicy from './policyComponent/DeliveryPolicy'
import InformationPrivacyPolicy from './policyComponent/InformationPrivacyPolicy'
import PaymentPolicy from './policyComponent/PaymentPolicy'
import ReturnAndExchangePolicy from './policyComponent/ReturnAndExchangePolicy'
import { ContentItem, SmallItem } from './styled'

const DEFINE_POLICY = {
  information_privacy_policy: 'information_privacy_policy',
  delivery_policy: 'delivery_policy',
  return_and_exchange_policy: 'return_and_exchange_policy',
  payment_policy: 'payment_policy',
}

const convertSearchParams = (location: any) => {
  var search = location.search.substring(1)
  return JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  )
}

const Policy = () => {
  const location = useLocation()

  const switchRender = (type: string) => {
    switch (type) {
      case DEFINE_POLICY.information_privacy_policy:
        return <InformationPrivacyPolicy />
      case DEFINE_POLICY.delivery_policy:
        return <DeliveryPolicy />
      case DEFINE_POLICY.return_and_exchange_policy:
        return <ReturnAndExchangePolicy />
      case DEFINE_POLICY.payment_policy:
        return <PaymentPolicy />
      default:
        return <div>Policy</div>
    }
  }

  return <div>{switchRender(convertSearchParams(location)?.type)}</div>
}

export default Policy
