import { UserOutlined } from '@ant-design/icons'
import { Checkbox, Form, Row, Spin } from 'antd'
import R from 'assets'
import { ROUTER_PATH, SESSION_KEY } from 'common/config'
import Cookie from 'js-cookie'
import moment from 'moment'
import { useState } from 'react'
import { REG_PHONE } from 'utils/constants'
import { caculateCountDown } from 'utils/functionHelper'
import history from 'utils/history'
import { requestSignup } from '../AuthApi'
import {
  ButtonForm,
  ButtonFormRegister,
  ContentBlock,
  HaveCount,
  ImageAuth,
  ImageLogo,
  InputForm,
  LeftBlock,
  LogoForm,
  RightBlock,
  TitleForm,
} from '../authStyle'
import { Link } from 'react-router-dom'

type Props = {
  setStep: any
  setUserId: any
  setPhoneSignup: any
  setTime: any
}
function BeginSignupScreen({
  setStep,
  setUserId,
  setPhoneSignup,
  setTime,
}: Props) {
  const [isLoading, setLoading] = useState(false)
  const [accept, setAccept] = useState(false)

  const [form] = Form.useForm()
  const onFinish = async (values: any) => {
    try {
      setLoading(true)
      const res = await requestSignup({
        phone: values.phone,
      })

      setTime(caculateCountDown(res.data.expired_at))

      // dispatch(getUserInfoAction())
      setUserId(res?.data?.user_id)
      setStep(1)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <ContentBlock>
      <LeftBlock>
        <ImageAuth
          src={R.images.image_login}
          alt="Image Banner "
          loading="eager"
        />
      </LeftBlock>
      <RightBlock>
        <LogoForm>
          <ImageLogo src={R.images.logo_yoyo} alt="App icon" loading="eager" />
        </LogoForm>
        <TitleForm>{R.strings().title_signup}</TitleForm>
        <Form
          layout="vertical"
          form={form}
          name="normal_login"
          style={{ padding: 0 }}
          initialValues={{
            phone: null,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={<span>{R.strings().text_form_phone_signup}</span>}
            name="phone"
            rules={[
              {
                required: true,
                message: R.strings().notify_enter_phone,
              },
              {
                pattern: REG_PHONE,
                max: 10,
                message: R.strings().err_phone,
              },
            ]}
          >
            <InputForm
              size="large"
              prefix={<UserOutlined />}
              placeholder={R.strings().text_form_placeholder_phone}
              onChange={(e: any) => {
                setPhoneSignup(e.target.value)
              }}
            />
          </Form.Item>
          <Checkbox onChange={e => setAccept(e.target.checked)}>
            Đồng ý với{' '}
            <Link
              style={{ color: '#555', textDecoration: 'underline' }}
              to={ROUTER_PATH.POLICY + '?type=information_privacy_policy'}
            >
              chính sách
            </Link>{' '}
            và{' '}
            <Link
              style={{ color: '#555', textDecoration: 'underline' }}
              to={ROUTER_PATH.TERM_OF_USE}
            >
              điều khoản
            </Link>
          </Checkbox>
          <Form.Item style={{ margin: 0 }}>
            <Row justify="center">
              <Spin spinning={isLoading}>
                <ButtonFormRegister
                  htmlType="submit"
                  disabled={!accept}
                  style={{ cursor: 'pointer' }}
                >
                  {R.strings().text_form_next}
                </ButtonFormRegister>
              </Spin>
            </Row>
          </Form.Item>
          <HaveCount>
            {R.strings().text_form_have_acc}?&nbsp;
            <p
              onClick={() => {
                history.push(ROUTER_PATH.LOGIN)
              }}
            >
              {R.strings().title_login}
            </p>
          </HaveCount>
        </Form>
      </RightBlock>
    </ContentBlock>
  )
}

export default BeginSignupScreen
