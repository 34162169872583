import Icon from '@ant-design/icons'

const ZaloSvg = () => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.5" cy="22.5" r="22.5" fill="#2E6DF6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5 35C30.9558 35 37 29.4036 37 22.5C37 15.5964 30.9558 10 23.5 10C16.0442 10 10 15.5964 10 22.5C10 25.6237 11.2374 28.4798 13.2832 30.6709L12.2134 34.6838C12.1068 35.0837 12.5045 35.4326 12.8872 35.2748L17.1312 33.5243C19.0284 34.466 21.1968 35 23.5 35Z"
      fill="white"
    />
    <path
      d="M16.8467 24.5633C17.6864 24.5633 18.4759 24.5574 19.2599 24.5633C19.6992 24.5691 19.9383 24.762 19.9828 25.1301C20.0328 25.5918 19.777 25.9015 19.2989 25.9074C18.3981 25.9191 17.5029 25.9132 16.6021 25.9132C16.3408 25.9132 16.085 25.9249 15.8236 25.9074C15.5011 25.8898 15.1842 25.8197 15.0285 25.4691C14.8728 25.1185 14.984 24.8029 15.1953 24.5165C16.0516 23.3711 16.9135 22.2199 17.7753 21.0745C17.8254 21.0043 17.8754 20.9342 17.9255 20.8699C17.8698 20.7706 17.792 20.8173 17.7253 20.8115C17.1248 20.8057 16.5187 20.8115 15.9182 20.8057C15.7792 20.8057 15.6402 20.7881 15.5067 20.7589C15.1898 20.6829 14.9952 20.3498 15.0674 20.0226C15.1175 19.8005 15.2843 19.6193 15.4956 19.5667C15.629 19.5317 15.768 19.5141 15.9071 19.5141C16.8968 19.5083 17.8921 19.5083 18.8818 19.5141C19.0598 19.5083 19.2321 19.5317 19.4045 19.5784C19.7826 19.7128 19.9439 20.081 19.7937 20.4667C19.6603 20.7998 19.449 21.0862 19.2377 21.3725C18.5093 22.3484 17.7809 23.3185 17.0525 24.2828C16.9913 24.3587 16.9357 24.4347 16.8467 24.5633Z"
      fill="#2E6DF6"
    />
    <path
      d="M23.2965 21.4896C23.4299 21.3084 23.5689 21.139 23.7969 21.0922C24.2362 20.9987 24.6476 21.2967 24.6532 21.7643C24.6699 22.933 24.6643 24.1018 24.6532 25.2706C24.6532 25.5745 24.4641 25.8433 24.1917 25.931C23.9137 26.042 23.5967 25.9543 23.4132 25.703C23.3187 25.5803 23.2798 25.5569 23.1463 25.668C22.6403 26.1004 22.0676 26.1764 21.4504 25.966C20.4607 25.6271 20.0548 24.8148 19.9436 23.8272C19.8268 22.7577 20.166 21.8461 21.0779 21.2851C21.8341 20.8117 22.6014 20.8526 23.2965 21.4896ZM21.3281 23.6226C21.3392 23.8797 21.4171 24.1252 21.5616 24.3297C21.8619 24.7505 22.4346 24.8381 22.8405 24.5226C22.9072 24.47 22.9684 24.4057 23.024 24.3297C23.3354 23.8856 23.3354 23.1551 23.024 22.711C22.8683 22.4831 22.6237 22.3486 22.3623 22.3428C21.7507 22.3019 21.3225 22.7986 21.3281 23.6226ZM27.1498 23.6577C27.1053 22.1558 28.045 21.0338 29.3795 20.9929C30.7974 20.9461 31.8316 21.9454 31.8761 23.4064C31.9206 24.8849 31.0587 25.931 29.7298 26.0712C28.2785 26.2232 27.1275 25.1187 27.1498 23.6577ZM28.5454 23.5174C28.5343 23.8096 28.6177 24.096 28.7845 24.3356C29.0903 24.7563 29.6631 24.8381 30.0634 24.5109C30.1246 24.4641 30.1746 24.4057 30.2247 24.3473C30.5472 23.9031 30.5472 23.1551 30.2302 22.711C30.0745 22.4889 29.8299 22.3486 29.5685 22.3428C28.968 22.3077 28.5454 22.7869 28.5454 23.5174ZM26.6605 22.4947C26.6605 23.4005 26.666 24.3064 26.6605 25.2122C26.666 25.6271 26.3547 25.9719 25.9599 25.9836C25.8931 25.9836 25.8209 25.9777 25.7541 25.9602C25.4761 25.8842 25.2648 25.5745 25.2648 25.2063V20.5604C25.2648 20.2857 25.2593 20.0169 25.2648 19.7423C25.2704 19.2923 25.5428 19.0001 25.9543 19.0001C26.3769 18.9942 26.6605 19.2864 26.6605 19.754C26.666 20.6656 26.6605 21.5831 26.6605 22.4947Z"
      fill="#2E6DF6"
    />
  </svg>
)

const ZaloIcon = (props: any) => <Icon component={ZaloSvg} {...props} />
export default ZaloIcon
