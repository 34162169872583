import { UserOutlined } from '@ant-design/icons'
import { Form, Spin } from 'antd'
import R from 'assets'
import { ROUTER_PATH, SESSION_KEY } from 'common/config'
import Cookie from 'js-cookie'
import { useState } from 'react'
import { REG_PHONE } from 'utils/constants'
import { caculateCountDown } from 'utils/functionHelper'
import { requestPassword } from '../AuthApi'
import {
  ButtonForm,
  ContentBlock,
  ImageAuth,
  ImageLogo,
  InputForm,
  LeftBlock,
  LogoForm,
  RightBlock,
} from '../authStyle'

type Props = {
  setStep: any
  setUserId: any
  setData: any
  setPhoneSignup: any
  setTime: any
}
function BeginSignupScreen({
  setStep,
  setUserId,
  setData,
  setPhoneSignup,
  setTime,
}: Props) {
  const [isLoading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const onFinish = async (values: any) => {
    try {
      setLoading(true)
      const res = await requestPassword({
        phone: values.phone,
      })
      // Cookie.set(SESSION_KEY.SESSION, res.data.token, {
      //   expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
      // })
      setTime(caculateCountDown(res.data.expired_at))
      // dispatch(getUserInfoAction())
      setUserId(res?.data?.user_id)
      setStep(1)
      setData(res)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <ContentBlock>
      <LeftBlock>
        <ImageAuth
          src={R.images.image_login}
          alt="Image Banner "
          loading="eager"
        />
      </LeftBlock>
      <RightBlock>
        <LogoForm>
          <ImageLogo src={R.images.logo_yoyo} alt="App icon" loading="eager" />
        </LogoForm>
        <div
          style={{
            fontSize: 14,
            letterSpacing: -0.5,
            textAlign: 'justify',
            padding: '5vh 0px 3vh 0px',
          }}
        >
          {R.strings().text_form_text_forgot_password}
        </div>
        <Form
          layout="vertical"
          form={form}
          name="normal_login"
          style={{ padding: 0 }}
          initialValues={{
            phone: null,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={<span>{R.strings().text_form_phone_forgot_password}:</span>}
            name="phone"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập số điện thoại',
              },
              {
                pattern: REG_PHONE,
                max: 10,
                message: 'Số điện thoại không hợp lệ',
              },
            ]}
          >
            <InputForm
              size="large"
              prefix={<UserOutlined />}
              placeholder={R.strings().text_form_phone_forgot_password}
              onChange={(e: any) => {
                setPhoneSignup(e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <div style={{ textAlign: 'center' }}>
              <Spin spinning={isLoading}>
                <ButtonForm style={{ cursor: 'pointer' }}>
                  {R.strings().text_form_next}
                </ButtonForm>
              </Spin>
              <a href={ROUTER_PATH.LOGIN}>{R.strings().title_login}</a>
            </div>
          </Form.Item>
        </Form>
      </RightBlock>
    </ContentBlock>
  )
}

export default BeginSignupScreen
