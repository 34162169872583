import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import {
  ModalImg,
  SliderMain,
  SliderMainInsurance,
  SliderStyle,
  SliderThumb,
  SliderThumbInsurance,
  SliderThumbStyle,
} from '../styles'

const SlideBanner: React.FC<any> = ({ data, type }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [srcImg, setSrcImg] = useState<any>()
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  const [nav, setNav] = useState()
  const [slider1, setSlider1] = useState<any>()
  const [slider2, setSlider2] = useState<any>()
  useEffect(() => {
    setNav1(slider1)
    setNav2(slider2)
    setNav(slider1)
  })
  const [active, setActive] = useState<number>(0)
  return (
    <>
      <SliderStyle
        slidesToShow={1}
        // asNavFor={nav}
        ref={slider => setSlider1(slider)}
      >
        {data?.map((item: any) =>
          type === 'insurrance' || type === '3' ? (
            <SliderMainInsurance
              src={item.media_url}
              onClick={() => {
                setSrcImg(item.media_url)
                setVisible(!visible)
              }}
            />
          ) : (
            <SliderMain
              src={item.media_url}
              onClick={() => {
                setSrcImg(item.media_url)
                setVisible(true)
              }}
            />
          )
        )}
      </SliderStyle>
      <div
        style={{
          overflowX: 'hidden',
          overflowY: 'auto',
          padding: '10px 5px 0px 5px',
        }}
      >
        <SliderThumbStyle
          asNavFor={nav}
          ref={slider => setSlider2(slider)}
          slidesToShow={data?.length}
          focusOnSelect={true}
        >
          {data?.map((item: any, index: number) =>
            type === 'insurrance' || type === '3' ? (
              <SliderThumbInsurance
                src={item.media_url}
                className={index === active ? 'active' : ''}
                onClick={() => {
                  setActive(index)
                }}
              />
            ) : (
              <SliderThumb
                src={item.media_url}
                className={index === active ? 'active' : ''}
                onClick={() => {
                  setActive(index)
                }}
              />
            )
          )}
        </SliderThumbStyle>
      </div>
      <ModalImg
        visible={visible}
        onCancel={() => {
          setVisible(false)
        }}
        footer={null}
        style={{ backgroundColor: 'unset' }}
      >
        <img src={srcImg} alt="" style={{ width: '100%' }} />
      </ModalImg>
    </>
  )
}
export default SlideBanner
