import R from 'assets'
import React, { useEffect, useState } from 'react'
import { TYPE_VEHICLE } from 'utils/constants'
import { notificationError } from 'utils/notification'
import { getUserVehicle } from '../ApiPayment'
import { TVehicleData } from '../interface'
import {
  InputPayment,
  RadioGroup,
  RadioStyled,
  SelectionBlock,
  TitleBlock,
  WhiteBlock,
  WrapInputPayment,
} from '../styled'

type Props = { data: TVehicleData; setVehicleData: any }
const Transport: React.FC<Props> = ({ data, setVehicleData }) => {
  const [paramsTypeVehicleId, setParamsTypeVehicleId] = useState<number>(0)

  useEffect(() => {
    getVehicles()
  }, [paramsTypeVehicleId])

  const getVehicles = async () => {
    try {
      const res = await getUserVehicle({ type: paramsTypeVehicleId })
      setVehicleData({
        ...data,
        number_plate: res.data?.number_plate,
        type_vehicle_id: res.data
          ? res.data?.type_vehicle_id
          : data.type_vehicle_id,
      })
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  return (
    <WhiteBlock>
      <TitleBlock style={{ marginBottom: '12px' }}>
        {R.strings().title_vehicle_information}{' '}
        <b style={{ color: 'red' }}>&#42;</b>
      </TitleBlock>

      <RadioGroup value={data.type_vehicle_id}>
        <SelectionBlock
          className={
            data.type_vehicle_id === TYPE_VEHICLE.MOTORCYCLE ? 'selected' : ''
          }
          onClick={() => {
            setVehicleData({
              ...data,
              type_vehicle_id: TYPE_VEHICLE.MOTORCYCLE,
            })
            setParamsTypeVehicleId(TYPE_VEHICLE.MOTORCYCLE)
          }}
        >
          <RadioStyled value={1}>{R.strings().motorcycle}</RadioStyled>
        </SelectionBlock>

        <SelectionBlock
          className={
            data.type_vehicle_id === TYPE_VEHICLE.CAR ? 'selected' : ''
          }
          onClick={() => {
            setVehicleData({ ...data, type_vehicle_id: TYPE_VEHICLE.CAR })
            setParamsTypeVehicleId(TYPE_VEHICLE.CAR)
          }}
        >
          <RadioStyled value={2}>{R.strings().car}</RadioStyled>
        </SelectionBlock>
      </RadioGroup>
      <WrapInputPayment>
        <InputPayment
          id="number_plate"
          placeholder={R.strings().number_plate}
          value={data.number_plate}
          onChange={(e: any) => {
            if (e.target.value.length > 255) {
              notificationError(R.strings().err_length_255)
            } else {
              setVehicleData({ ...data, number_plate: e.target.value })
            }
          }}
          addonBefore={<b style={{ color: 'red' }}>&#42;</b>}
          bordered={false}
        />
      </WrapInputPayment>
    </WhiteBlock>
  )
}
export default Transport
