import { Form, Row } from 'antd'
import { TitleStyles } from 'common/components/ContainerPage/styles'
import React, { useState } from 'react'
import { notificationSuccess } from 'utils/notification'
import { ButtonStyled, PasswordInput } from '../styled'
import { changePassword } from '../ApiAccount'
import R from 'assets'

const ChangePasswordTab: React.FC = () => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true)
      const dataPush = {
        old_password: values.old_password,
        new_password: values.new_password,
      }
      const res = await changePassword(dataPush)

      notificationSuccess(res.message)

      form.resetFields()
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form
      form={form}
      layout="vertical"
      labelAlign="left"
      scrollToFirstError
      onFinish={values => onFinish(values)}
      style={{ padding: '25px' }}
    >
      <TitleStyles>{R.strings().change_password}</TitleStyles>
      <Form.Item
        name="old_password"
        label={R.strings().old_pass}
        rules={[
          {
            required: true,
            message: R.strings().text_form_password_required,
          },
          {
            min: 6,
            max: 55,
            message: R.strings().between6and55characters,
          },
        ]}
        // hasFeedback
      >
        <PasswordInput
          placeholder={R.strings().text_form_placeholder_password}
        />
      </Form.Item>

      <Form.Item
        name="new_password"
        label={R.strings().new_pass}
        rules={[
          {
            required: true,
            message: R.strings().text_form_password_required,
          },
          {
            min: 6,
            max: 55,
            message: R.strings().between6and55characters,
          },
        ]}
        hasFeedback
      >
        <PasswordInput
          placeholder={R.strings().text_form_placeholder_password}
        />
      </Form.Item>

      <Form.Item
        name="confirm"
        label={R.strings().text_form_confirm_password}
        dependencies={['new_password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Nhập lại mật khẩu!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('new_password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error(R.strings().err_confirm))
            },
          }),
        ]}
      >
        <PasswordInput
          placeholder={R.strings().text_form_placeholder_password}
        />
      </Form.Item>

      <Row justify="center">
        <ButtonStyled
          htmlType="submit"
          style={{ width: '50%' }}
          loading={isLoading}
        >
          {R.strings().text_form_confirm}
        </ButtonStyled>
      </Row>
    </Form>
  )
}
export default ChangePasswordTab
