import { ApiClient } from 'services/ApiService'

//Api tạo thanh toán
export const createPayment = (payload: any) =>
  ApiClient.post('/client/order', payload)

// Api lấy danh sách hình thức thanh toán
export const getListPayment = () =>
  ApiClient.get('/client/order/list-order-payment')

//Api lấy cấu hình điểm
export const getPercentage = () =>
  ApiClient.get('/customer/config-systems/percentage')

// Api địa chỉ người dùng
export const getListAddress = (payload: any) =>
  ApiClient.get('/customer/address', payload)

export const getDefaultAddress = () =>
  ApiClient.get('/customer/address/default')

export const createAddress = (payload: any) =>
  ApiClient.post('/customer/address', payload)

export const getAddress = (id: number) =>
  ApiClient.get(`/customer/address/${id}`)

export const updateAddress = (id: number, payload: any) =>
  ApiClient.put(`/customer/address/${id}`, payload)

export const deleteAddress = (id: number) =>
  ApiClient.delete(`/customer/address/${id}`, {})

// Api google-map
export const getProvince = () => ApiClient.get('/google-address/province')
export const getDistrict = (payload: any) =>
  ApiClient.get('/google-address/district', payload)
export const getWard = (payload: any) =>
  ApiClient.get('/google-address/ward', payload)
export const getPlaceAuto = (payload: any) =>
  ApiClient.get('/google-address/place-auto', payload)
export const getPlace = (payload: any) =>
  ApiClient.get('/google-address/place', payload)

//Api lấy địa chỉ cửa hàng
export const getAddressStore = (id: number) =>
  ApiClient.get(`/customer/petrol-store/${id}`)

//Api gợi ý phương tiện
export const getUserVehicle = (payload: any) =>
  ApiClient.get('/client/order/user-vehicles', payload)

// Api thanh toán VNPay
export const createPaymentUrl = (payload: any) =>
  ApiClient.post('/payment/vn-pay/create-payment-url', payload)
