const images = {
  // img_background: require('./img_background.jpg').default,
  img_logo: require('./img_logo.png').default,
  img_google_play: require('./GooglePlayBadge.png').default,
  img_app_store: require('./AppStoreBadge.png').default,
  img_footer1: require('./footer1.png').default,
  img_footer2: require('./footer2.png').default,
  img_footer3: require('./footer3.png').default,
  img_footer4: require('./footer4.png').default,
  img_news1: require('./news1.png').default,
  img_news2: require('./news2.png').default,
  img_product: require('./ImageProduct.png').default,
  image_login: require('./image_login.png').default,
  logo_yoyo: require('./logo_yoyo.png').default,
  image_complete: require('./image_complete.png').default,
  icon_store_gas: require('./icon_store_gas.png').default,
  icon_gas: require('./icon_gas.png').default,
  img_petrol: require('./img_petrol.png').default,
  symbol_gas: require('./symbol_gas.png').default,
  icon_pending: require('./icon_pending.png').default,
  icon_complete: require('./icon_complete.png').default,
  icon_confirm: require('./icon_confirm.png').default,
  icon_cancel: require('./icon_cancel.png').default,
  icon_payment: require('./icon_payment.png').default,
  icon_vnpay: require('./icon_vnpay.png').default,
  icon_banking: require('./icon_banking.png').default,
  avatar_default: require('./avatar_default.png').default,
  introduction_img: require('./introduction_img.jpg').default,
  icon_notify_new: require('./icon_notify_new.png').default,
  icon_notify_order: require('./icon_notify_order.png').default,
  icon_notify_rank: require('./icon_notify_rank.png').default,
  icon_notify_points: require('./icon_notify_point.png').default,
  signup1: require('./signup1.jpg').default,
  signup2: require('./signup2.jpg').default,
  signup3: require('./signup3.jpg').default,
  payment: require('./payment.jpg').default,
  buy_now_1: require('./buy_now_1.jpg').default,
  buy_now_2: require('./buy_now_2.jpg').default,
  cart: require('./cart.jpg').default,
  forgetPassword: require('./forgetPassword.jpg').default,
  icon_avatar_default: require('./icon_avatar_default.png').default,
  logo_vnpay: require('./logo_vnpay.png').default,
  default_fill: require('./default_fill.jpeg').default,
  xang: require('./xang.png').default,
}
export default images
