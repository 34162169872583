import { ApiClient } from 'services/ApiService'

export const changePassword = (payload: any) =>
  ApiClient.put(`/customer/auth/change-password`, payload)

export const updateUserInfor = (payload: any) =>
  ApiClient.post(`/user`, payload)

export const requestPartner = (payload: any) =>
  ApiClient.post(`/partner/request-partner`, payload)
// Api lấy ví điểm
export const getWalletPoint = (payload: any) =>
  ApiClient.get(`/customer/point`, payload)
//Api lịch sử công nợ
export const getHistoryDebt = (payload: any) =>
  ApiClient.get(`/customer/debt`, payload)

export const getPlaceAuto = (payload: any) =>
  ApiClient.get('/google-address/place-auto', payload)
export const getPlace = (payload: any) =>
  ApiClient.get('/google-address/place', payload)
export const getProvince = () => ApiClient.get('/google-address/province')
export const getDistrict = (payload: any) =>
  ApiClient.get('/google-address/district', payload)
export const getWard = (payload: any) =>
  ApiClient.get('/google-address/ward', payload)
