import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Checkbox, Divider, Form, Modal, Spin, Steps } from 'antd'
import R from 'assets'
import UploadComponent from 'common/components/Upload'
import { ROUTER_PATH } from 'common/config'
import { TermsOfUse } from 'features/web-view'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { notificationSuccess } from 'utils/notification'
import { updateRegister } from '../AuthApi'
import { getUserInfoAction } from '../AuthSlice'
import {
  AboveBlock,
  ButtonCode,
  ContentBlock,
  FooterCode,
  FormStyle,
  InforBlock,
  InputInfor,
  InputPass,
  StepCode,
  SubStep,
  TermLink,
  UnderBlock,
} from '../authStyle'
import { UploadOutlined } from '@ant-design/icons'

type Props = {
  setStep: any
}
const { Step } = Steps
export const Step2SignupScreen: React.FC<Props> = ({ setStep }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const [check, setCheck] = useState<boolean>(false)
  const [readRule, setReadRule] = useState<boolean>(false)
  const [urls, setUrls] = useState<any>([])

  const onClick = () => {
    setCheck(!check)
  }
  const onReadRule = () => {
    setReadRule(!readRule)
  }
  const onFinish = async (values: any) => {
    try {
      setLoading(true)
      delete values.confirm
      let newData
      if (check === true) {
        newData = {
          ...values,
          device_id: values.device_id,
          email: values.email,
          name: values.name,
          password: values.password,
          referral_code: values.referral_code,
          enterprise: {
            name: values.enterprise_name,
            tax: values.tax,
            tax_address: values.tax_address,
          },
        }
      } else {
        newData = {
          ...values,
          device_id: values.device_id,
          email: values.email,
          name: values.name,
          password: values.password,
          referral_code: values.referral_code,
        }
      }
      delete newData.enterprise_name
      delete newData.tax
      delete newData.tax_address
      const res = await updateRegister({ ...newData, media_url: urls.join() })
      //   Cookies.set(SESSION_KEY.SESSION, res.data.token, {
      //     expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
      //   })
      notificationSuccess(res?.message)
      dispatch(getUserInfoAction())
      setStep(3)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <Spin spinning={loading}>
      <ContentBlock>
        <div>
          <InforBlock>
            <AboveBlock justify="space-between">
              <ArrowLeftOutlined onClick={() => setStep(0)} />
              <span>{R.strings().text_form_register}</span>
              <ArrowLeftOutlined style={{ color: 'white' }} />
            </AboveBlock>
            <Divider style={{ margin: 0 }} />
            <UnderBlock>
              <FormStyle
                autoComplete="off"
                layout="vertical"
                labelAlign="left"
                form={form}
                name="infor_signup"
                initialValues={{
                  name: undefined,
                  email: undefined,
                  code: undefined,
                  password: undefined,
                  company: undefined,
                  tax: undefined,
                  tax_address: undefined,
                  enterprise: undefined,
                }}
                onFinish={onFinish}
              >
                <Form.Item
                  label={
                    <span style={{ fontSize: 16 }}>
                      {R.strings().text_form_name}
                    </span>
                  }
                  labelAlign="right"
                  name="name"
                  style={{
                    marginBottom: 10,
                  }}
                  rules={[
                    {
                      required: true,
                      message: R.strings().enter_name,
                    },
                    {
                      min: 4,
                      max: 55,
                      message: R.strings().err_name,
                    },
                  ]}
                >
                  <InputInfor
                    size="large"
                    placeholder={R.strings().text_form_name}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span style={{ fontSize: 16 }}>
                      {R.strings().text_form_email}
                    </span>
                  }
                  labelAlign="right"
                  name="email"
                  style={{
                    marginBottom: 10,
                  }}
                  rules={[
                    {
                      required: true,
                      message: R.strings().enter_email,
                    },
                    {
                      type: 'email',
                      message: R.strings().err_email,
                    },
                    {
                      min: 6,
                      max: 56,
                      message: R.strings().err_email_lenght,
                    },
                  ]}
                >
                  <InputInfor
                    autoComplete="off"
                    size="large"
                    placeholder={R.strings().text_form_email}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span style={{ fontSize: 16 }}>
                      {R.strings().text_form_referral}
                    </span>
                  }
                  labelAlign="right"
                  name="referral_code"
                  style={{
                    marginBottom: 10,
                  }}
                  rules={[
                    {
                      min: 10,
                      max: 10,
                      message: R.strings().err_referral,
                    },
                  ]}
                >
                  <InputInfor
                    size="large"
                    placeholder={R.strings().text_form_referral}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span style={{ fontSize: 16 }}>
                      {R.strings().text_form_password_signup}
                    </span>
                  }
                  labelAlign="right"
                  name="password"
                  style={{
                    marginBottom: 10,
                  }}
                  rules={[
                    {
                      required: true,
                      message: R.strings().notify_enter_password,
                    },
                    {
                      min: 6,
                      max: 55,
                      message: R.strings().err_password_lenght,
                    },
                  ]}
                >
                  <InputPass
                    autoComplete="off"
                    placeholder={R.strings().text_form_password_signup}
                  />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  label={
                    <span style={{ fontSize: 16 }}>
                      {' '}
                      {R.strings().text_form_confirm_password}
                    </span>
                  }
                  dependencies={['password']}
                  hasFeedback
                  style={{
                    marginBottom: 10,
                  }}
                  rules={[
                    {
                      required: true,
                      message: R.strings().notify_enter_password,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(R.strings().notify_comfirm_password)
                        )
                      },
                    }),
                  ]}
                >
                  <InputPass
                    placeholder={R.strings().text_form_confirm_password}
                  />
                </Form.Item>
                <Form.Item
                  name="enterprise"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <Checkbox onClick={onClick}>
                    {R.strings().text_form_enterprise}
                  </Checkbox>
                </Form.Item>
                {check === true ? (
                  <>
                    <Form.Item
                      label={
                        <span style={{ fontSize: 16 }}>
                          {R.strings().text_form_company}
                        </span>
                      }
                      labelAlign="right"
                      name="enterprise_name"
                      style={{
                        marginBottom: 10,
                      }}
                      rules={[
                        {
                          required: true,
                          message: R.strings().enter_company,
                        },
                        {
                          min: 1,
                          max: 55,
                          message: R.strings().err_company,
                        },
                      ]}
                    >
                      <InputInfor
                        size="large"
                        placeholder={R.strings().text_form_company}
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <span style={{ fontSize: 16 }}>
                          {R.strings().text_form_tax}
                        </span>
                      }
                      labelAlign="right"
                      name="tax"
                      style={{
                        marginBottom: 10,
                      }}
                      rules={[
                        // {
                        //   min: 10,
                        //   max: 13,
                        //   message: R.strings().err_tax,
                        // },
                        {
                          required: true,
                          validator: (_, value) => {
                            const reg = /^\d+$/
                            if (reg.test(value)) {
                              if (value.length <= 13 && value.length >= 10) {
                                return Promise.resolve()
                              } else {
                                return Promise.reject()
                              }
                            }
                            return Promise.reject()
                          },
                          message: R.strings().err_tax,
                        },
                      ]}
                    >
                      <InputInfor
                        size="large"
                        placeholder={R.strings().text_form_tax}
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <span style={{ fontSize: 16 }}>
                          {R.strings().text_form_tax_address}
                        </span>
                      }
                      labelAlign="right"
                      name="tax_address"
                      style={{
                        marginBottom: 20,
                      }}
                      rules={[
                        {
                          min: 1,
                          max: 255,
                          message: R.strings().err_tax_address,
                        },
                        {
                          required: true,
                          message: R.strings().enter_tax_address,
                        },
                      ]}
                    >
                      <InputInfor
                        size="large"
                        placeholder={R.strings().text_form_tax_address}
                      />
                    </Form.Item>

                    <Form.Item label={R.strings().text_form_images}>
                      <UploadComponent
                        isUploadServerWhenUploading
                        uploadType="list"
                        listType="picture-card"
                        onSuccessUpload={url => {
                          setUrls([...urls, url])
                        }}
                      >
                        +
                      </UploadComponent>
                    </Form.Item>
                    <Form.Item label={R.strings().text_form_files}>
                      <UploadComponent
                        isUploadServerWhenUploading
                        accept=".pdf,.doc,.docx,.txt,.xls,.xlsx,.zip"
                        uploadType="list"
                        listType="text"
                        maxLength={2}
                        onSuccessUpload={url => {
                          setUrls([...urls, url])
                        }}
                      >
                        <Button icon={<UploadOutlined />}>
                          {R.strings().title_upload}
                        </Button>
                      </UploadComponent>
                    </Form.Item>
                  </>
                ) : (
                  <></>
                )}
                <Form.Item
                  style={{
                    paddingBottom: 15,
                  }}
                >
                  <ButtonCode
                    disabled={readRule ? true : false}
                    style={readRule ? { backgroundColor: '#f59f8c' } : {}}
                  >
                    {R.strings().title_signup}
                  </ButtonCode>
                  <Checkbox
                    defaultChecked
                    onClick={() => {
                      onReadRule()
                    }}
                    style={{ margin: '15px 0 0 0' }}
                  >
                    {R.strings().i_have_read_and_agree_to}&nbsp;
                    <TermLink
                      onClick={() => {
                        Modal.info({
                          centered: true,
                          content: <TermsOfUse />,
                          width: '100vw',
                          bodyStyle: { height: '84vh', overflowY: 'auto' },
                          closable: true,
                          okText: 'Đóng',
                        })
                        // setLoading(true)
                        // history.push(ROUTER_PATH.TERM_OF_USE)
                      }}
                    >
                      {R.strings().rules}
                    </TermLink>
                  </Checkbox>
                </Form.Item>
              </FormStyle>
            </UnderBlock>
          </InforBlock>
          <FooterCode>
            <StepCode direction="horizontal" responsive={false} current={1}>
              <SubStep></SubStep>
              <SubStep></SubStep>
              <SubStep></SubStep>
            </StepCode>
          </FooterCode>
        </div>
      </ContentBlock>
    </Spin>
  )
}
