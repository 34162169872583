import { Rate, Row, Typography } from 'antd'
import R from 'assets'
import React from 'react'
import { formatPrice } from 'utils/ruleForm'
import {
  BadgeSale,
  ImageProduct,
  NameProduct,
  PriceProduct,
  ProductBlock,
} from './styled'
type Props = {
  // promotion?: any
  dataProduct: any
  onClickProduct?: () => void
  // typeProduct?: any
  // idCategoryProduct?: any
  // searchProduct?: any
}
export const ProductCard: React.FC<Props> = ({
  dataProduct,
  onClickProduct,
}: // promotion,
// typeProduct,
// idCategoryProduct,
// searchProduct,
Props) => {
  const { Paragraph, Text } = Typography
  const petrol_id = dataProduct?.percent_discount
    ? dataProduct?.petrol_store_id
    : dataProduct?.product_stocks[0]?.petrol_store_id
  return (
    <ProductBlock onClick={onClickProduct}>
      <div
        // to={`${ROUTER_PATH.DETAIL_PRODUCT}/${dataProduct?.id}?type=${typeProduct}&product_id=${dataProduct.id}&petrol_store_id=${petrol_id}&nameProduct=${dataProduct.name}&idCategoryProduct=${idCategoryProduct}&search=${searchProduct}`}
        style={{ color: 'black' }}
      >
        {dataProduct?.percent_discount ? (
          <ImageProduct>
            <BadgeSale
              placement="start"
              color="#F26522"
              text={dataProduct.percent_discount + '%' || '15%'}
              style={{
                borderRadius: '0px 5px 5px 0px',
                width: 39,
                top: 15,
              }}
            >
              <img
                style={{
                  width: '100%',
                  aspectRatio: '1/1',
                  borderRadius: '15px 15px 0 0',
                  objectFit: 'cover',
                }}
                src={
                  dataProduct.product_media_url
                    ? dataProduct.product_media_url
                    : R.images.img_product
                }
                alt="Ảnh sản phẩm"
              />
            </BadgeSale>
          </ImageProduct>
        ) : (
          <ImageProduct>
            <img
              style={{
                width: '100%',
                aspectRatio: '1/1',
                borderRadius: '15px 15px 0 0',
                objectFit: 'cover',
              }}
              src={
                dataProduct.product_media_url
                  ? dataProduct.product_media_url
                  : R.images.img_product
              }
              alt="Ảnh sản phẩm"
            />
          </ImageProduct>
        )}

        <NameProduct>
          <Paragraph
            style={{ margin: 0, minHeight: '3.6em', lineHeight: '1.8em' }}
            ellipsis={{ rows: 2 }}
          >
            {dataProduct.name}
          </Paragraph>
          {/* {dataProduct.star && ( */}
          <Row align="middle">
            <Rate
              style={{ fontSize: 15, color: 'rgb(255, 197, 61)' }}
              disabled
              allowHalf
              value={Number(dataProduct.star || 0)}
            />{' '}
            <span
              style={{ fontSize: '15px', color: '#555', marginLeft: '10px' }}
            >
              {dataProduct.star &&
                `(${Number(dataProduct?.reviewed_count)} ${
                  R.strings().evaluate
                })`}
            </span>
          </Row>
          {/* )} */}
        </NameProduct>

        {dataProduct?.percent_discount ? (
          <Row justify="space-between">
            <PriceProduct>
              <Text delete style={{ fontSize: 14, color: 'gray' }}>
                {formatPrice(dataProduct?.price)} đ
              </Text>
              <br />
              {formatPrice(dataProduct.min_price)} đ
            </PriceProduct>
            <PriceProduct
              style={{
                color: 'gray',
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {R.strings().sell_amount}&nbsp;
              {formatPrice(+dataProduct?.selling_amount)}
            </PriceProduct>
          </Row>
        ) : (
          <Row justify="space-between">
            <PriceProduct>
              {dataProduct.min_price
                ? formatPrice(dataProduct.min_price)
                : formatPrice(dataProduct?.price)}{' '}
              đ
            </PriceProduct>
            <PriceProduct
              style={{
                color: 'gray',
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {R.strings().sell_amount}&nbsp;
              {formatPrice(+dataProduct?.selling_amount)}
            </PriceProduct>
          </Row>
        )}
      </div>
    </ProductBlock>
  )
}
