import React from 'react'
import './App.css'
import 'antd/dist/antd.css'
import GlobalStyles from './global-styles'
import AppNavigator from './navigation'
import './global-styles.css'
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
  return (
    <React.Fragment>
      <GlobalStyles />
      <AppNavigator />
    </React.Fragment>
  )
}

export default App
