import { notification } from 'antd'

export const notificationSuccess = (message: string) => {
  notification.open({
    message: message,
    className: 'custom-class-notification',
    style: {
      width: 'auto',
      backgroundColor: '#50C783',
    },
    duration: 2,
  })
}

export const notificationError = (message: string) => {
  notification.open({
    message: message,
    style: {
      width: 'auto',
      fontWeight: 600,
      backgroundColor: '#FA695F',
      borderStyle: '1px solid #f26522',
      borderRadius: 5,
      color: '#403E3E',
    },
    duration: 2.5,
  })
}

export const notificationNote = (message: string) => {
  notification.open({
    message: message,
    className: 'note-notification',
    style: {
      width: 'auto',
      fontWeight: 600,
      backgroundColor: '#feecd9',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#f26522',
      borderRadius: 5,
    },
    duration: 4,
  })
}
