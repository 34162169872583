import Icon from '@ant-design/icons'

const SearchSvg = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9.76639"
      cy="9.76663"
      r="8.98856"
      stroke="#868E96"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0181 16.4852L19.5421 20.0001"
      stroke="#868E96"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const SearchIcon = (props: any) => <Icon component={SearchSvg} {...props} />
export default SearchIcon
