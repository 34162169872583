import { Input, Select } from 'antd'
import { SearchIcon } from 'common/components/Icons'
import React, { useRef } from 'react'
import * as Styled from '../styled'
import StoreItem from './StoreItem'
import R from 'assets'
import { LoadingOutlined } from '@ant-design/icons'
import { EmptyComponent } from 'common/components/Empty'

type TProps = {
  listStore: Array<any>
  params: any
  setParams: any
  isLoading: boolean
  loadingMore: boolean
  active: any
  setActive: any
  onChooseLocation: (data: any) => void
  getMoreData: () => void
  geoLocation: boolean
}
const StoreList: React.FC<TProps> = ({
  listStore,
  params,
  setParams,
  isLoading,
  loadingMore,
  getMoreData,
  active,
  setActive,
  onChooseLocation,
  geoLocation,
}) => {
  const scrollRef: any = useRef(null)

  return (
    <Styled.StoreListBlock>
      <Styled.InputBlock>
        <Input
          placeholder={R.strings().search_for_petrol_stations}
          prefix={<SearchIcon />}
          bordered={false}
          onChange={e => {
            setTimeout(() => {
              setParams({ ...params, search: e.target.value })
            }, 700)
          }}
          suffix={
            <Select
              bordered={false}
              options={[
                { label: '1 km', value: 1 },
                { label: '3 km', value: 3 },
                { label: '5 km', value: 5 },
                { label: '10 km', value: 10 },
                { label: '15 km', value: 15 },
                { label: '20 km', value: 20 },
                { label: '30 km', value: 30 },
                { label: '50 km', value: 50 },
              ]}
              defaultValue={5}
              onChange={(value: number) => {
                setParams({ ...params, distance: value })
              }}
            />
          }
        />
      </Styled.InputBlock>

      <Styled.ListStore
        ref={scrollRef}
        onScroll={() => {
          if (
            scrollRef.current!.scrollTop + scrollRef.current!.clientHeight >=
            scrollRef.current!.scrollHeight
          ) {
            let old = scrollRef.current!.scrollTop
            if (!isLoading && loadingMore) {
              scrollRef.current!.scrollTop = old - 50
              getMoreData()
            }
          }
        }}
      >
        {listStore.length > 0 ? (
          listStore.map((item: any) => (
            <StoreItem
              data={item}
              active={active}
              setActive={setActive}
              onChooseLocation={onChooseLocation}
              geoLocation={geoLocation}
            />
          ))
        ) : (
          <EmptyComponent />
        )}
        <span style={{ display: 'flex', justifyContent: 'center' }}>
          {isLoading ? <LoadingOutlined /> : ''}
        </span>
      </Styled.ListStore>
    </Styled.StoreListBlock>
  )
}
export default StoreList
