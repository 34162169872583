import { CloseOutlined, HeartFilled, UpOutlined } from '@ant-design/icons'
import { BackTop, Row } from 'antd'
import React, { useState } from 'react'
import {
  ChildButton,
  FloatingMenu,
  MainButton,
} from 'react-floating-button-menu'
import { useLocation } from 'react-router-dom'
import { PlusIcon } from '../Icons'
import Footer from './Footer'
import Header from './Header'
import { ContainerFooter, ContainerHeader, StyledContainer } from './styles'

const ContainerPage: React.FC<any> = ({ children, language, setLanguage }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { pathname } = useLocation()
  console.log('🚀 ~ file: ContainerPage.tsx ~ line 18 ~ pathname', pathname)

  return (
    <>
      <ContainerHeader>
        <Header language={language} setLanguage={setLanguage} />
      </ContainerHeader>
      <StyledContainer>
        <div className={pathname === '/' ? '' : 'container'}>{children}</div>

        <BackTop>
          <div
            style={{
              backgroundColor: 'rgba(255, 255, 255, .9)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 45,
              width: 45,
              borderRadius: 30,
              color: '#F26522',
              opacity: '90%',
              boxShadow: ' 0px 4px 14px 1px rgba(0, 0, 0, 0.16)',
            }}
          >
            <UpOutlined color="#F26522" />
          </div>
        </BackTop>
        {/* <FloatingMenu slideSpeed={500} direction="up" spacing={8} isOpen={isOpen}>
        <MainButton
          iconResting={<PlusIcon style={{ fontSize: 20 }} />}
          iconActive={<CloseOutlined style={{ fontSize: 20 }} />}
          onClick={() => setIsOpen(!isOpen)}
          size={56}
          background="#F26522"
        />
        <ChildButton
          icon={<HeartFilled style={{ fontSize: 20 }} />}
          size={40}
          onClick={() => console.log('First button clicked')}
        />
        <ChildButton
          icon={<HeartFilled style={{ fontSize: 20 }} />}
          size={40}
        />
        <ChildButton
          icon={<HeartFilled style={{ fontSize: 20 }} />}
          size={40}
        />
      </FloatingMenu> */}
      </StyledContainer>
      <ContainerFooter>
        <Footer />
      </ContainerFooter>
    </>
  )
}

export default ContainerPage
