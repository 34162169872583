import { PlusCircleOutlined } from '@ant-design/icons'
import { Badge, Popconfirm, Tag } from 'antd'
import R from 'assets'
import {
  LocationIcon,
  PetrolIcon,
  StarIcon,
  YoYoCoinIcon,
} from 'common/components/Icons'
import { ImageStyled } from 'global-styles'
import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { notificationSuccess } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import { deleteAddress, getListAddress } from '../ApiPayment'
import { IAddress, TDataPetrolItem } from '../interface'
import {
  AddAddressBlock,
  AddressBlock,
  AddressDetail,
  AddressItem,
  ButtonAddress,
  ButtonUpdateAddress,
  ChangeAddressBlock,
  CheckBoxCircle,
  DefaultTag,
  ImageSymbol,
  InforStore,
  ProductInfor,
  RadioGroup,
  RadioStyled,
  SelectAddressBlock,
  SelectionBlock,
  SwitchStyled,
  TitleBlock,
  TotalPriceBlock,
  UsePoint,
  ValueBlock,
  WhiteBlock,
  WrapPoint,
  WrapPrice,
} from '../styled'
import ModalUpdateAddress from './ModalUpdateAddress'

type Props = {
  data: TDataPetrolItem
  setDataPetrol: any
}

const BageRibon = ({
  show = false,
  children,
}: {
  show: boolean
  children: ReactNode
}) => {
  return show ? (
    <Badge.Ribbon
      text={R.strings().title_buy_gasoline_to_keep_stock}
      color="volcano"
    >
      {children}
    </Badge.Ribbon>
  ) : (
    <>{children}</>
  )
}

const PetrolItem: React.FC<Props> = ({ data, setDataPetrol }) => {
  // kiểm tra mua xăng tại nhà
  const [isHome, setIsHome] = React.useState(2)
  const [isChangeAddress, setIsChangeAddress] = React.useState<boolean>(false)

  // get list address
  const [listAddress, setListAddress] = React.useState<Array<IAddress>>([])
  const [visibleModal, setVisibleModal] = React.useState<boolean>(false)
  const [selectedAddress, setSelectedAddress] = React.useState<IAddress>({
    id: 0,
    name: '',
    phone: '',
    address: '',
    is_default: 0,
  })
  const [dataUpdate, setDataUpdate] = React.useState<number | null>(null)

  //Lấy danh sách địa chỉ nhà
  const requestListAddress = async () => {
    try {
      const res = await getListAddress({
        search: undefined,
        page: 1,
      })

      const convertData = res.data?.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          phone: item.phone,
          address: item?.address,
          is_default: item.is_default,
        }
      })
      setListAddress(convertData)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  React.useEffect(() => {
    requestListAddress()
  }, [])

  const product = data?.product

  const userState = useSelector((state: any) => state.authReducer)?.userInfo

  return (
    <BageRibon show={!!data.isKeepStock}>
      <WhiteBlock>
        <TitleBlock>{R.strings().title_delivery_information}</TitleBlock>
        {product &&
        product?.status_home &&
        data?.liters > data?.product?.quantity_home ? (
          <RadioGroup value={isHome}>
            <SelectionBlock
              className={isHome === 1 ? 'selected' : ''}
              onClick={() => {
                setIsHome(1)
                let record = {
                  ...data,
                  user_address_id: selectedAddress.id,
                }

                setDataPetrol(record)
              }}
            >
              <RadioStyled value={1}>{R.strings().take_it_at_home}</RadioStyled>
            </SelectionBlock>
            <SelectionBlock
              className={isHome === 2 ? 'selected' : ''}
              onClick={() => {
                setIsHome(2)

                let record = { ...data, user_address_id: 0 }
                setDataPetrol(record)
                setIsChangeAddress(false)
              }}
            >
              <RadioStyled value={2}>
                {R.strings().pick_up_at_the_store}
              </RadioStyled>
            </SelectionBlock>
          </RadioGroup>
        ) : (
          <></>
        )}

        {/* chỗ này để kiểm tra địa chỉ lấy tại nhà */}
        {isChangeAddress ? (
          <ChangeAddressBlock>
            <AddAddressBlock>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setVisibleModal(true)
                }}
              >
                <PlusCircleOutlined style={{ marginRight: '10px' }} />
                {R.strings().add_address}
              </div>
            </AddAddressBlock>
            {listAddress?.map((item: any, index: number) => (
              <AddressItem key={index}>
                <CheckBoxCircle
                  style={{ marginRight: '12px' }}
                  checked={item.id === selectedAddress.id ? true : false}
                  onClick={() => {
                    let record = { ...data, user_address_id: item.id }
                    // let new_array = [...dataOil]
                    // new_array[indexStore] = record
                    // setDataOil(new_array)
                    setDataPetrol(record)
                    setSelectedAddress({
                      id: item.id,
                      name: item.name,
                      phone: item.phone,
                      address: item.address,
                      is_default: item.is_default,
                    })
                    setIsChangeAddress(false)
                  }}
                />
                <SelectAddressBlock>
                  <div>
                    <div>{item.address}</div>
                    <div style={{ fontWeight: 600 }}>
                      {item.name} | {item.phone}
                      {item.is_default ? (
                        <DefaultTag>{R.strings().title_default}</DefaultTag>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <ButtonUpdateAddress
                      onClick={() => {
                        setDataUpdate(item.id)
                        setVisibleModal(true)
                      }}
                    >
                      {R.strings().title_edit}
                    </ButtonUpdateAddress>

                    <div
                      style={{
                        border: '1px solid #F26522',
                        backgroundColor: '#F26522',
                        margin: '5px',
                      }}
                    ></div>
                    <Popconfirm
                      title={R.strings().delete_address_question}
                      onConfirm={async () => {
                        try {
                          await deleteAddress(item.id)
                          notificationSuccess(R.strings().delete_successful)
                        } catch (error) {
                          console.error('Exception ' + error)
                        }
                      }}
                    >
                      <ButtonUpdateAddress>
                        {R.strings().title_delete}
                      </ButtonUpdateAddress>
                    </Popconfirm>
                    {/* </>
                  )} */}
                  </div>
                </SelectAddressBlock>
              </AddressItem>
            ))}
          </ChangeAddressBlock>
        ) : (
          <AddressBlock>
            {isHome === 1 ? (
              <>
                <AddressDetail>
                  <LocationIcon style={{ marginRight: '12px' }} />
                  {selectedAddress.address ? (
                    <div>
                      <div>{selectedAddress.address}</div>
                      <div style={{ fontWeight: 600 }}>
                        {selectedAddress.name} | {selectedAddress.phone}
                        {selectedAddress.is_default ? (
                          <Tag
                            style={{
                              marginLeft: '5px',
                              borderColor: '#F26522',
                              color: '#F26522',
                            }}
                          >
                            {R.strings().title_default}
                          </Tag>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ) : (
                    R.strings().not_update
                  )}
                </AddressDetail>
                <ButtonAddress
                  onClick={() => {
                    setIsChangeAddress(true)
                  }}
                >
                  {R.strings().title_change}
                </ButtonAddress>
              </>
            ) : (
              <AddressDetail>
                <LocationIcon style={{ marginRight: '12px' }} />
                <div>
                  <div>{data?.petrol_store?.address}</div>
                  <div style={{ fontWeight: 600 }}>
                    {data.petrol_store?.name} |{' '}
                    {data?.petrol_store?.phone_store}
                  </div>
                </div>
              </AddressDetail>
            )}
          </AddressBlock>
        )}

        {/* end */}

        <InforStore>
          <ImageSymbol>
            <ImageStyled src={R.images.img_petrol} />
          </ImageSymbol>
          <div>
            <div style={{ fontWeight: 600 }}>{data?.petrol_store?.name}</div>
            <div>
              <StarIcon style={{ marginRight: '8px' }} />
              {data?.petrol_store?.petrol_store_star} (
              {data?.petrol_store?.review_count} {R.strings().evaluate})
            </div>
            <div>
              <LocationIcon style={{ marginRight: '8px' }} />
              {data?.petrol_store?.address}
            </div>
          </div>
        </InforStore>

        <ProductInfor>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PetrolIcon style={{ marginRight: '10px' }} />
            <div
              style={{
                border: '1px solid #FB8500',
                transform: 'rotate(90deg)',
                width: '30px',
              }}
            ></div>
            <div>
              <div>{data?.name}</div>
              <div id="price-product-small">
                {formatPrice(data?.price_per_liter)} {R.strings().title_unit}
              </div>
            </div>
          </div>
          <div id="price-product">
            {formatPrice(data?.price_per_liter)} {R.strings().title_unit}
          </div>

          <div>
            {data?.is_full_fuel ? R.strings().full_tank : 'x ' + data?.amount}
          </div>
        </ProductInfor>
        {userState?.point && !data?.is_full_fuel ? (
          <UsePoint>
            <WrapPoint>
              <YoYoCoinIcon style={{ marginRight: '10px' }} />
              {R.strings().use}{' '}
              {formatPrice(
                data?.total_price > userState?.point
                  ? userState?.point
                  : data?.total_price
              )}{' '}
              {R.strings().points}
            </WrapPoint>
            <SwitchStyled
              checked={data.isCheckedPoint}
              onChange={(value: boolean) => {
                if (value) {
                  if (data.total_price?.toFixed() > userState?.point) {
                    setDataPetrol({
                      ...data,
                      isCheckedPoint: value,
                      point: userState?.point,
                    })
                  } else {
                    setDataPetrol({
                      ...data,
                      isCheckedPoint: value,
                      point: parseInt(data.total_price?.toFixed()),
                    })
                  }
                } else {
                  setDataPetrol({ ...data, isCheckedPoint: value, point: 0 })
                }
              }}
            />
          </UsePoint>
        ) : (
          <></>
        )}

        <TotalPriceBlock>
          <WrapPrice>
            <ValueBlock>
              {R.strings().title_total_price_of_goods} :
              <span>
                {data?.is_full_fuel
                  ? R.strings().unknown
                  : formatPrice(data?.total_price) + ' đ'}
              </span>
            </ValueBlock>
            {data?.is_full_fuel ? (
              <></>
            ) : (
              <ValueBlock>
                {R.strings().title_points_used} :
                <span>{formatPrice(data.point)} đ</span>
              </ValueBlock>
            )}
          </WrapPrice>
        </TotalPriceBlock>
      </WhiteBlock>
      <ModalUpdateAddress
        visibleModal={visibleModal}
        dataUpdate={dataUpdate}
        requestListAddress={requestListAddress}
        onCancel={() => {
          setVisibleModal(false)
          setDataUpdate(null)
        }}
      />
    </BageRibon>
  )
}

export default PetrolItem
