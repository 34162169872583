import React from 'react'
import { ContentItem, SmallItem } from './styled'

const RefundPolicy: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '20px',
        textAlign: 'justify',
        listStyleType: ' upper-roman',
      }}
    >
      <b>CHÍNH SÁCH ĐỔI HỦY HOÀN TRẢ</b>
      <SmallItem>
        <b>1. Chính sách đổi hàng</b>
        <ContentItem>
          <p>
            Trường hợp khách hàng đã đặt đơn, nhưng khi tới cửa hàng xăng dầu
            nhận hàng và phát hiện sản phẩm đã đặt chưa đúng nhu cầu cần sử
            dụng. Khách hàng được đổi mặt hàng khác thay thế, cùng giá trị tại
            cửa hàng xăng dầu đó.
          </p>
          <p>
            Nếu mặt hàng khách hàng cần đổi, có giá trị thấp hơn hoặc cao hơn
            giá trị đơn hàng trên ứng dụng, khách hàng có thể đặt đơn hàng mới
            để mua đúng chủng loại. Hoặc khách hàng thực hiện hủy đơn trên ứng
            dụng, và sau đó đặt hàng lại trên ứng dụng để thực hiện mua mới.
          </p>
          <p>
            Với các đơn hàng đã được khách thanh toán online thì sau khi hủy,
            tiền sẽ được hoàn về ví của quý khách trên ứng dụng thông qua cách
            quy đổi điểm 1000đ = 1 điểm. Số điểm này sẽ được dùng để mua hàng
            cho lần tiếp theo của quý khách.
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>2. Chính sách hủy đơn hàng</b>
        <ContentItem>
          <p>
            Nếu các đơn hàng đã được đặt, và hình thức thanh toán là chuyển
            khoản, tiền mặt, QR code, ATM thì đơn hàng cần được chờ xác nhận của
            PYOYO. Đơn hàng chưa được xác nhận thì khách hàng hoàn toàn có thể
            hủy đơn bình thường. Đơn hàng đã được xác nhận, trường hợp khách
            hàng muốn hủy, hãy liên hệ hotline của PYOYO để được hỗ trợ hủy đơn
            hàng. Khách hàng có thể hủy đơn hàng bằng cách vào mục “Lịch sử Giao
            dịch” và chọn “Hủy Giao dịch” hoặc sử dụng chức năng chat hoặc gọi
            trực tiếp đến tổng đài của YOYO. Trong trường hợp cần thiết, nhân
            viên tổng đài YOYO sẽ gọi để xác nhận với Người dùng. Trong trường
            hợp Khách hàng hủy nhiều đơn hàng gây ảnh hưởng đến hệ thống, YOYO
            có thể chặn Khách hàng sử dụng mã khuyến mại hoặc tạm khoá dịch vụ
            của Khách hàng trong một thời gian
          </p>
          <p>
            Tại đây, nếu đơn hàng đã được thanh toán thì với đơn hàng đã được hỗ
            trợ hủy, PYOYO sẽ hoàn tiền lại cho khách hàng qua ví điểm trên ứng
            dụng PYOYO trong vòng 1-3 ngày hành chính. Điểm hoàn sẽ được sử dụng
            cho lần mua hàng tiếp theo của khách hàng, với mức quy đổi là 1000 đ
            = 1 điểm.
          </p>
          <p>
            Khách hàng có thể được hoàn tiền cho các đơn hàng hủy, bị hủy, do
            lỗi của Nhà cung cấp, do lỗi của bên thực hiện dịch vụ giao nhận
            hoặc các trường hợp khác không phải lỗi của Khách hàng.
          </p>
        </ContentItem>
      </SmallItem>
    </div>
  )
}
export default RefundPolicy
