import { Breadcrumb } from 'antd'
import R from 'assets'
import { ROUTER_PATH } from 'common/config'
import { useEffect, useState } from 'react'
import history from 'utils/history'
import DetailOilInsurance from './components/DetailOilInsurance'
import { BreadcrumbStyle } from './styles'
const DetailProductScreen: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const typeProduct: string = searchParams.get('type') as string
  const searchProduct: string = searchParams.get('search') as string
  const categoryProduct: string = searchParams.get('category') as string
  const nameProduct: string = searchParams.get('nameProduct') as string
  const nameCategory: string = searchParams.get('nameCategory') as string
  const idCategoryProduct: string = searchParams.get('idCategory') as string

  return (
    <>
      <BreadcrumbStyle>
        <Breadcrumb.Item>
          <a href={ROUTER_PATH.HOME}>{R.strings().title_home}</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {categoryProduct ? (
            <a
              href=""
              onClick={() => {
                history.push({
                  pathname: `${ROUTER_PATH.PRODUCT}?type=${typeProduct}&category=${categoryProduct}&idCategory=${idCategoryProduct}`,
                })
              }}
            >
              {categoryProduct}
            </a>
          ) : typeProduct === 'search' ? (
            <a
              href=""
              onClick={() => {
                history.push({
                  pathname: `${ROUTER_PATH.PRODUCT}?type=search&search=${searchProduct}`,
                })
              }}
            >
              {searchProduct}
            </a>
          ) : typeProduct === 'deal' ? (
            <a
              href=""
              onClick={() => {
                history.push({
                  pathname: `${ROUTER_PATH.PRODUCT}?type=deal`,
                })
              }}
            >
              {R.strings().title_great_deal}
            </a>
          ) : (
            ''
          )}
        </Breadcrumb.Item>
        <Breadcrumb.Item>{nameProduct}</Breadcrumb.Item>
      </BreadcrumbStyle>
      <DetailOilInsurance />
    </>
  )
}

export default DetailProductScreen
