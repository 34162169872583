import Icon from '@ant-design/icons'

const GoodPriceSvg = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.03125 43.75V9.375C7.03125 8.9606 7.19587 8.56317 7.4889 8.27015C7.78192 7.97712 8.17935 7.8125 8.59375 7.8125H30.4688C30.8832 7.8125 31.2806 7.97712 31.5736 8.27015C31.8666 8.56317 32.0312 8.9606 32.0312 9.375V43.75"
      fill="#5E87CA"
    />
    <path
      d="M7.03125 43.75V9.375C7.03125 8.9606 7.19587 8.56317 7.4889 8.27015C7.78192 7.97712 8.17935 7.8125 8.59375 7.8125H30.4688C30.8832 7.8125 31.2806 7.97712 31.5736 8.27015C31.8666 8.56317 32.0312 8.9606 32.0312 9.375V43.75"
      fill="#FFA940"
    />
    <path
      d="M28.9062 35.1563V7.8125H8.59375C8.17935 7.8125 7.78192 7.97712 7.4889 8.27015C7.19587 8.56317 7.03125 8.9606 7.03125 9.375V37.5H26.5625C27.1841 37.5 27.7802 37.2531 28.2198 36.8135C28.6593 36.374 28.9062 35.7779 28.9062 35.1563Z"
      fill="#FFC069"
    />
    <path
      d="M6.25 43.75H32.8125C33.2269 43.75 33.6243 43.9146 33.9174 44.2076C34.2104 44.5007 34.375 44.8981 34.375 45.3125V48.4375H4.6875V45.3125C4.6875 44.8981 4.85212 44.5007 5.14515 44.2076C5.43817 43.9146 5.8356 43.75 6.25 43.75Z"
      fill="#F26522"
    />
    <path d="M7.03125 40.625H32.0312V43.75H7.03125V40.625Z" fill="#C8CDED" />
    <path d="M10.1562 10.9375H28.9062V21.875H10.1562V10.9375Z" fill="#E2E7F6" />
    <path
      d="M26.5625 16.4062V10.9375H10.1562V19.5312H23.4375C24.2663 19.5312 25.0612 19.202 25.6472 18.616C26.2333 18.0299 26.5625 17.2351 26.5625 16.4062Z"
      fill="#F2F2F2"
    />
    <path
      d="M7.03125 18.75H5.46875C5.05435 18.75 4.65692 18.9146 4.3639 19.2076C4.07087 19.5007 3.90625 19.8981 3.90625 20.3125V25C3.90625 25.4144 4.07087 25.8118 4.3639 26.1049C4.65692 26.3979 5.05435 26.5625 5.46875 26.5625H7.03125"
      fill="#F4A93C"
    />
    <path
      d="M32.0312 18.75H33.5938C34.0082 18.75 34.4056 18.9146 34.6986 19.2076C34.9916 19.5007 35.1562 19.8981 35.1562 20.3125V25C35.1562 25.4144 34.9916 25.8118 34.6986 26.1049C34.4056 26.3979 34.0082 26.5625 33.5938 26.5625H32.0312"
      fill="#F4A93C"
    />
    <path
      d="M19.5312 37.5C22.983 37.5 25.7812 34.7018 25.7812 31.25C25.7812 27.7982 22.983 25 19.5312 25C16.0795 25 13.2812 27.7982 13.2812 31.25C13.2812 34.7018 16.0795 37.5 19.5312 37.5Z"
      fill="#E2E7F6"
    />
    <path
      d="M24.2185 30.4688C24.2187 29.4576 23.9732 28.4614 23.5032 27.5661C23.0331 26.6708 22.3525 25.903 21.52 25.329C20.0941 24.8555 18.5454 24.9115 17.1574 25.4867C15.7694 26.062 14.6351 27.118 13.9622 28.4613C13.2893 29.8047 13.1228 31.3455 13.4933 32.8015C13.8637 34.2576 14.7463 35.5315 15.9794 36.3899C16.919 36.7077 17.9208 36.797 18.9018 36.6502C19.8828 36.5034 20.8147 36.1249 21.6201 35.5461C22.4256 34.9672 23.0815 34.2047 23.5333 33.3217C23.9852 32.4387 24.2201 31.4607 24.2185 30.4688Z"
      fill="#F2F2F2"
    />
    <path
      d="M41.4062 40.625C40.5774 40.625 39.7826 40.2958 39.1965 39.7097C38.6105 39.1237 38.2812 38.3288 38.2812 37.5V25C38.2812 24.7928 38.1989 24.5941 38.0524 24.4476C37.9059 24.3011 37.7072 24.2188 37.5 24.2188H35.1562V22.6562H37.5C38.1216 22.6562 38.7177 22.9032 39.1573 23.3427C39.5968 23.7823 39.8438 24.3784 39.8438 25V37.5C39.8438 37.9144 40.0084 38.3118 40.3014 38.6049C40.5944 38.8979 40.9918 39.0625 41.4062 39.0625C41.8207 39.0625 42.2181 38.8979 42.5111 38.6049C42.8041 38.3118 42.9688 37.9144 42.9688 37.5V18.75H44.5312V37.5C44.5312 38.3288 44.202 39.1237 43.616 39.7097C43.0299 40.2958 42.2351 40.625 41.4062 40.625Z"
      fill="#C8CDED"
    />
    <path
      d="M19.5312 28.125L17.6563 30.625C17.352 31.0307 17.1875 31.5241 17.1875 32.0312C17.1875 32.6529 17.4344 33.249 17.874 33.6885C18.3135 34.1281 18.9096 34.375 19.5312 34.375C20.1529 34.375 20.749 34.1281 21.1885 33.6885C21.6281 33.249 21.875 32.6529 21.875 32.0312C21.875 31.5241 21.7105 31.0307 21.4062 30.625L19.5312 28.125Z"
      fill="#F4A93C"
    />
    <path
      d="M13.5071 18.1973L18.9747 12.7296L20.0794 13.8343L14.6118 19.302L13.5071 18.1973Z"
      fill="#C8CDED"
    />
    <path
      d="M18.9753 18.1982L24.443 12.7306L25.5477 13.8353L20.08 19.3029L18.9753 18.1982Z"
      fill="#C8CDED"
    />
    <path
      d="M44.5312 9.375H42.9688C42.1058 9.375 41.4062 10.0746 41.4062 10.9375V17.1875C41.4062 18.0504 42.1058 18.75 42.9688 18.75H44.5312C45.3942 18.75 46.0938 18.0504 46.0938 17.1875V10.9375C46.0938 10.0746 45.3942 9.375 44.5312 9.375Z"
      fill="#FB8500"
    />
    <path
      d="M44.5312 15.625V9.375H42.9688C42.5543 9.375 42.1569 9.53962 41.8639 9.83265C41.5709 10.1257 41.4062 10.5231 41.4062 10.9375V17.1875H42.9688C43.3832 17.1875 43.7806 17.0229 44.0736 16.7299C44.3666 16.4368 44.5312 16.0394 44.5312 15.625Z"
      fill="#FFA940"
    />
    <path
      d="M44.5313 9.3751H42.9688V4.6876C42.9688 4.48041 43.0511 4.28173 43.1977 4.13525L46.3227 1.01025L47.4273 2.11494L44.5313 5.01104V9.3751Z"
      fill="#E2E7F6"
    />
    <path
      d="M3.90625 20.3125V24.2188H4.6875C4.8947 24.2188 5.09341 24.1364 5.23993 23.9899C5.38644 23.8434 5.46875 23.6447 5.46875 23.4375V18.75C5.05435 18.75 4.65692 18.9146 4.3639 19.2076C4.07087 19.5007 3.90625 19.8981 3.90625 20.3125Z"
      fill="#F6B940"
    />
    <path
      d="M32.0312 24.2188H32.8125C33.0197 24.2188 33.2184 24.1364 33.3649 23.9899C33.5114 23.8434 33.5938 23.6447 33.5938 23.4375V18.75H32.0312V24.2188Z"
      fill="#F6B940"
    />
  </svg>
)

const GoodPriceIcon = (props: any) => (
  <Icon component={GoodPriceSvg} {...props} />
)
export default GoodPriceIcon
