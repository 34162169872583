import R from 'assets'
import React from 'react'
import { notificationError } from 'utils/notification'
import { TExportData } from '../interface'
import {
  CheckboxSquare,
  InputPayment,
  WhiteBlock,
  WrapInputPayment,
} from '../styled'
type Props = {
  exportData: TExportData
  setExportData: any
}
const ExportBill: React.FC<Props> = ({ exportData, setExportData }) => {
  return (
    <WhiteBlock>
      <CheckboxSquare
        checked={exportData.is_receipt ? true : false}
        onChange={() => {
          setExportData({
            ...exportData,
            is_receipt: exportData.is_receipt ? 0 : 1,
          })
        }}
      >
        {R.strings().title_invoice}
      </CheckboxSquare>
      {exportData.is_receipt ? (
        <div style={{ borderTop: '1px solid #CED4DA', marginTop: '12px' }}>
          <WrapInputPayment>
            <InputPayment
              id="exportData_tax"
              placeholder={R.strings().placeholder_enter_tax_code}
              value={exportData.tax}
              onChange={(e: any) => {
                if (e.target.value.length > 13) {
                  notificationError(R.strings().err_length_13)
                } else {
                  setExportData({
                    ...exportData,
                    tax: e.target.value,
                  })
                }
              }}
              addonBefore={<b style={{ color: 'red' }}>&#42;</b>}
              bordered={false}
            />
          </WrapInputPayment>
          <WrapInputPayment>
            <InputPayment
              id="exportData_name"
              placeholder={R.strings().placeholder_enter_company_name}
              value={exportData.name}
              onChange={(e: any) => {
                if (e.target.value.length > 55) {
                  notificationError(R.strings().err_length_55)
                } else {
                  setExportData({
                    ...exportData,
                    name: e.target.value,
                  })
                }
              }}
              addonBefore={<b style={{ color: 'red' }}>&#42;</b>}
              bordered={false}
            />
          </WrapInputPayment>
          <WrapInputPayment>
            <InputPayment
              id="exportData_address"
              placeholder={R.strings().placeholder_enter_address}
              value={exportData.address}
              onChange={(e: any) => {
                if (e.target.value.length > 255) {
                  notificationError(R.strings().err_length_255)
                } else {
                  setExportData({
                    ...exportData,
                    address: e.target.value,
                  })
                }
              }}
              addonBefore={<b style={{ color: 'red' }}>&#42;</b>}
              bordered={false}
            />
          </WrapInputPayment>
          <WrapInputPayment>
            <InputPayment
              id="exportData_email"
              placeholder={R.strings().placeholder_enter_email}
              value={exportData.email}
              onChange={(e: any) => {
                if (e.target.value.length > 56) {
                  notificationError(R.strings().err_length_56)
                } else {
                  setExportData({
                    ...exportData,
                    email: e.target.value,
                  })
                }
              }}
              addonBefore={<b style={{ color: 'red' }}>&#42;</b>}
              bordered={false}
            />
          </WrapInputPayment>
        </div>
      ) : (
        <></>
      )}
    </WhiteBlock>
  )
}
export default ExportBill
