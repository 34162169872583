import {
  ArrowRightOutlined,
  EnvironmentFilled,
  StarFilled,
} from '@ant-design/icons'
import { Col, Row } from 'antd'
import R from 'assets'
import { GoogleMapIcon } from 'common/components/Icons'
import { ROUTER_PATH } from 'common/config'
import React from 'react'
import history from 'utils/history'
import * as Styled from '../styled'

type TProp = {
  data: any
  active: any
  setActive: any
  onChooseLocation: (data: any) => void
  geoLocation: boolean
}
const StoreItem: React.FC<TProp> = ({
  data,
  active,
  setActive,
  onChooseLocation,
  geoLocation,
}) => {
  return (
    <Styled.WrapStoreItem
      style={active === data?.id ? { backgroundColor: '#feecd9' } : {}}
      onClick={() => {
        setActive(data?.id)
        onChooseLocation(data)
      }}
    >
      <div>
        <img src={R.images.icon_store_gas} alt="" />
      </div>

      <Styled.WrapInforStore>
        <div style={{ fontSize: 15, fontWeight: 500 }}>{data.name}</div>
        <div
          style={{
            display: 'flex',
            flexFlow: 'nowrap',
            justifyContent: 'space-between',
          }}
        >
          <Row>
            {!geoLocation || !data?.distance ? (
              <></>
            ) : (
              <>
                <Col>
                  <div style={{ fontSize: 14 }}>
                    <EnvironmentFilled
                      style={{ color: '#F26522', paddingRight: 5 }}
                    />
                    {data?.distance} km
                  </div>
                </Col>
                &nbsp; &nbsp;
              </>
            )}

            <Col>
              <div style={{ fontSize: 14 }}>
                <StarFilled style={{ color: '#FFC53D', paddingRight: 5 }} />
                {+data?.petrol_store_star.toFixed(1)}
              </div>
            </Col>
          </Row>
        </div>
      </Styled.WrapInforStore>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <ArrowRightOutlined
          onClick={() => {
            history.push({
              pathname: `${ROUTER_PATH.DETAIL_STORES}/${data?.id}`,
            })
          }}
        />
        <Styled.TextGoToMap
          onClick={() => {
            window.open(
              `https://www.google.com/maps/place/?q=${data.name}/@${data?.location_lat},${data?.location_long}`
            )
          }}
        >
          Chỉ đường
        </Styled.TextGoToMap>
      </div>
    </Styled.WrapStoreItem>
  )
}
export default StoreItem
