import Icon from '@ant-design/icons'

const StoreColorSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.8808 6.57083L19.3808 2.40417C19.3068 2.28091 19.2021 2.17891 19.0769 2.10808C18.9518 2.03726 18.8105 2.00003 18.6667 2H5.33334C5.18954 2.00003 5.0482 2.03726 4.92306 2.10808C4.79791 2.17891 4.69322 2.28091 4.61917 2.40417L2.11917 6.57083C2.04125 6.70042 2.00005 6.84878 2.00001 7C1.99846 7.88207 2.3472 8.72867 2.9696 9.35372C3.59199 9.97877 4.4371 10.3311 5.31917 10.3333H5.32584C6.14895 10.3354 6.94335 10.0309 7.55417 9.47916C8.1632 10.0296 8.9549 10.3344 9.77583 10.3344C10.5968 10.3344 11.3885 10.0296 11.9975 9.47916C12.6068 10.0308 13.3993 10.3363 14.2212 10.3363C15.0431 10.3363 15.8357 10.0308 16.445 9.47916C16.9233 9.91065 17.5166 10.194 18.1528 10.2948C18.7891 10.3956 19.4409 10.3095 20.0291 10.047C20.6174 9.78443 21.1167 9.35676 21.4666 8.81587C21.8165 8.27499 22.0018 7.64416 22 7C21.9999 6.84878 21.9587 6.70042 21.8808 6.57083Z"
      fill="#F26522"
    />
    <path
      d="M16.4599 11.4827C17.1502 11.8235 17.91 12.0005 18.68 11.9999C19.1098 11.9942 19.5 12.3164 19.5 12.7462V18.1666C19.5 19.8234 18.1568 21.1665 16.5 21.1666L14.6667 21.1666C14.1144 21.1666 13.6667 20.7188 13.6667 20.1666V17.1666C13.6667 16.6143 13.2189 16.1666 12.6667 16.1666H11.3333C10.781 16.1666 10.3333 16.6143 10.3333 17.1666V20.1666C10.3333 20.7188 9.88561 21.1666 9.33333 21.1666L7.5 21.1666C5.84314 21.1665 4.5 19.8234 4.5 18.1665V12.755C4.5 12.3215 4.8923 11.9958 5.32583 11.9999C5.86985 12.0008 6.40878 11.9128 6.92167 11.7407C7.33086 11.6035 7.77762 11.603 8.18666 11.7407C8.80603 11.9513 9.46101 12.0369 10.1137 11.9927C10.7664 11.9485 11.4038 11.7754 11.9892 11.4832C11.995 11.4806 12.0016 11.4807 12.0074 11.4835C12.2095 11.583 12.4181 11.6689 12.6317 11.7407C13.2513 11.9508 13.9064 12.0362 14.5592 11.9918C15.2119 11.9475 15.8495 11.7743 16.435 11.4824C16.4429 11.4787 16.452 11.4788 16.4599 11.4827Z"
      fill="#F26522"
    />
  </svg>
)

const StoreColorIcon = (props: any) => (
  <Icon component={StoreColorSvg} {...props} />
)
export default StoreColorIcon
