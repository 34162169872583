import i18n from './translation'
function strings() {
  return {
    title_home: i18n.t('title.home'),
    title_product: i18n.t('title.product'),
    title_gas: i18n.t('title.gas'),
    title_oil: i18n.t('title.oil'),
    title_insurance: i18n.t('title.insurance'),
    title_promotion: i18n.t('title.promotion'),
    title_path: i18n.t('title.path'),
    title_payment: i18n.t('title.payment'),
    title_download_app: i18n.t('title.download_app'),
    title_VN_Pay: i18n.t('title.VN_Pay'),
    title_debt: i18n.t('title.debt'),
    title_transfer: i18n.t('title.transfer'),
    title_cash: i18n.t('title.cash'),
    title_list_store: i18n.t('title.list_store'),
    title_news: i18n.t('title.news'),
    title_good_price_petrol: i18n.t('title.good_price_petrol'),
    title_oil_change_at_home: i18n.t('title.oil_change_at_home'),
    title_battery_rescue: i18n.t('title.battery_rescue'),
    title_insurrance: i18n.t('title.insurrance'),
    title_customer_name: i18n.t('title.customer_name'),
    title_please_customer_name: i18n.t('title.please_customer_name'),
    title_to: i18n.t('title.to'),
    title_phone_contact: i18n.t('title.phone_contact'),
    title_enter_phone_contact: i18n.t('title.enter_phone_contact'),
    title_upload: i18n.t('title.upload'),
    title_apartment_number: i18n.t('title.apartment_number'),
    title_please_apartment_number: i18n.t('title.please_apartment_number'),
    title_storage_period: i18n.t('title.storage_period'),
    title_date_of_purchase: i18n.t('title.date_of_purchase'),
    title_buy_gasoline_to_keep_stock: i18n.t(
      'title.buy_gasoline_to_keep_stock'
    ),
    title_lubricating_oil: i18n.t('title.lubricating_oil'),
    title_car_charging_station: i18n.t('title.car_charging_station'),
    title_login: i18n.t('title.login'),
    title_signup: i18n.t('title.signup'),
    title_unit_price: i18n.t('title.unit_price'),
    title_quantity: i18n.t('title.quantity'),
    title_delete: i18n.t('title.delete'),
    title_amount: i18n.t('title.amount'),
    title_total_price: i18n.t('title.total_price'),
    title_select_all: i18n.t('title.select_all'),
    title_cart: i18n.t('title.cart'),
    select_date: i18n.t('title.select_date'),
    old_pass: i18n.t('title.old_pass'),
    new_pass: i18n.t('title.new_pass'),
    title_personal: i18n.t('title.personal'),
    title_place_order: i18n.t('title.place_order'),
    title_search_product: i18n.t('title.search_product'),
    title_delivery_information: i18n.t('title.delivery_information'),
    title_total_price_of_goods: i18n.t('title.total_price_of_goods'),
    title_payments: i18n.t('title.payments'),
    title_points_used: i18n.t('title.points_used'),
    title_insurance_information: i18n.t('title.insurance_information'),
    title_vehicle_information: i18n.t('title.vehicle_information'),
    title_refueling_history: i18n.t('title.refueling_history'),
    title_default: i18n.t('title.default'),
    title_change: i18n.t('title.change'),
    title_edit: i18n.t('title.edit'),
    paid_info: i18n.t('title.paid_info'),
    take_it_at_home: i18n.t('title.take_it_at_home'),
    pick_up_at_the_store: i18n.t('title.pick_up_at_the_store'),
    motorcycle: i18n.t('title.motorcycle'),
    car: i18n.t('title.car'),
    points_received: i18n.t('title.points_received'),
    billing_information: i18n.t('title.billing_information'),
    total_payment: i18n.t('title.total_payment'),
    unknown: i18n.t('title.unknown'),
    bottle_full: i18n.t('title.bottle_full'),
    register_to_become_a_partner: i18n.t('title.register_to_become_a_partner'),
    terms_of_becoming_a_partner: i18n.t('title.terms_of_becoming_a_partner'),
    contact: i18n.t('title.contact'),
    i_have_read_and_agree_to: i18n.t('title.i_have_read_and_agree_to'),
    rules: i18n.t('title.rules'),
    number_plate: i18n.t('title.number_plate'),
    title_invoice: i18n.t('title.invoice'),
    title_log_out: i18n.t('title.log_out'),
    title_account: i18n.t('title.account'),
    vietnamese: i18n.t('title.vietnamese'),
    english: i18n.t('title.english'),
    wallet: i18n.t('title.wallet'),
    rank: i18n.t('title.rank'),
    referral_code: i18n.t('title.referral_code'),
    address: i18n.t('title.address'),
    become_an_agent: i18n.t('title.become_an_agent'),
    change_password: i18n.t('title.change_password'),
    debt_information: i18n.t('title.debt_information'),
    title_your_point: i18n.t('title.your_point'),
    points: i18n.t('title.points'),
    title_unit: i18n.t('title.unit'),
    use: i18n.t('title.use'),
    points_history: i18n.t('title.points_history'),
    content: i18n.t('title.content'),
    time: i18n.t('title.time'),
    surplus: i18n.t('title.surplus'),
    number_point: i18n.t('title.number_point'),
    delete_question: i18n.t('title.delete_question'),
    confirm: i18n.t('title.confirm'),
    add_address: i18n.t('product.add_address'),
    title_province: i18n.t('title.province'),
    title_district: i18n.t('title.district'),
    title_wards: i18n.t('title.wards'),
    address_detail: i18n.t('title.address_detail'),
    google_map_address: i18n.t('title.google_map_address'),
    address_customer: i18n.t('title.address_customer'),
    vehicle_type: i18n.t('title.vehicle_type'),
    note_more: i18n.t('title.note_more'),
    manufacturing: i18n.t('title.manufacturing'),
    battery_type: i18n.t('title.battery_type'),
    set_as_default_address: i18n.t('title.set_as_default_address'),
    save: i18n.t('title.save'),
    from_date: i18n.t('title.from_date'),
    to_date: i18n.t('title.to_date'),
    my_address: i18n.t('title.my_address'),
    account_information: i18n.t('title.account_information'),
    title_update: i18n.t('title.update'),
    birthday: i18n.t('title.birthday'),
    gender: i18n.t('title.gender'),
    male: i18n.t('title.male'),
    female: i18n.t('title.female'),
    click_to_select_photo: i18n.t('title.click_to_select_photo'),
    not_update: i18n.t('title.not_update'),
    delete_address_question: i18n.t('title.delete_address_question'),
    customer_support: i18n.t('title.customer_support'),
    about_pyoyo: i18n.t('title.about_pyoyo'),
    frequently_asked_questions: i18n.t('title.frequently_asked_questions'),
    require_to_be_supported: i18n.t('title.require_to_be_supported'),
    shopping_guide: i18n.t('title.shopping_guide'),
    refund_policy: i18n.t('title.refund_policy'),
    payment_privacy_policy: i18n.t('title.payment_privacy_policy'),
    information_privacy_policy: i18n.t('title.information_privacy_policy'),
    delivery_policy: i18n.t('title.delivery_policy'),
    return_and_exchange_policy: i18n.t('title.return_and_exchange_policy'),
    payment_policy: i18n.t('title.payment_policy'),
    introduction_PYOYO: i18n.t('title.introduction_PYOYO'),
    privacy_policy_of_personal_information: i18n.t(
      'title.privacy_policy_of_personal_information'
    ),
    complaint_settlement_policy: i18n.t('title.complaint_settlement_policy'),
    cooperation_and_association: i18n.t('title.cooperation_and_association'),
    selling_with_PYOYO: i18n.t('title.selling_with_PYOYO'),
    terms_of_use: i18n.t('title.terms_of_use'),
    connect_with_us: i18n.t('title.connect_with_us'),
    online: i18n.t('title.online'),
    total_hits_monthly: i18n.t('title.total_hits_monthly'),
    total_hits: i18n.t('title.total_hits'),
    regulations_on_operation_in_transactions_EC: i18n.t(
      'title.regulations_on_operation_in_transactions_EC'
    ),
    left: i18n.t('title.left'),
    out_of_date: i18n.t('title.out_of_date'),
    day: i18n.t('title.day'),
    limit: i18n.t('title.limit'),
    availability: i18n.t('title.availability'),
    debt_history: i18n.t('title.debt_history'),
    date_created: i18n.t('title.date_created'),
    debt_term: i18n.t('title.debt_term'),
    accumulated_debt: i18n.t('title.accumulated_debt'),
    title_order: i18n.t('title.order'),
    paid: i18n.t('title.paid'),
    unPaid: i18n.t('title.unPaid'),
    figure: i18n.t('title.figure'),
    debt_status: i18n.t('title.debt_status'),
    contact_information: i18n.t('title.contact_information'),
    headquarters: i18n.t('title.headquarters'),
    sales_office: i18n.t('title.sales_office'),
    shock_absorber: i18n.t('title.shock_absorber'),
    list_is_empty: i18n.t('title.list_is_empty'),
    confirm_text_become_agent: i18n.t('title.confirm_text_become_agent'),

    text_form_phone_signup: i18n.t('form_signup.text_form_phone_signup'),
    text_form_phone_login: i18n.t('form_login.text_form_phone_login'),
    text_form_password: i18n.t('form_login.text_form_password'),
    text_form_phone_required: i18n.t('form_login.text_form_phone_required'),
    text_form_phone_error: i18n.t('form_login.text_form_phone_error'),
    text_form_password_required: i18n.t(
      'form_login.text_form_password_required'
    ),
    text_form_password_error: i18n.t('form_login.text_form_password_error'),
    text_form_placeholder_phone: i18n.t(
      'form_login.text_form_placeholder_phone'
    ),
    text_form_placeholder_password: i18n.t(
      'form_login.text_form_placeholder_password'
    ),
    text_form_forgot: i18n.t('form_login.text_form_forgot'),
    text_form_havent_acc: i18n.t('form_login.text_form_havent_acc'),
    text_form_have_acc: i18n.t('form_signup.text_form_have_acc'),
    text_form_next: i18n.t('form_signup.text_form_next'),
    text_form_enter_phone: i18n.t('form_signup.text_form_enter_phone'),
    text_form_expired: i18n.t('form_signup.text_form_expired'),
    text_form_confirm: i18n.t('form_signup.text_form_confirm'),
    text_form_success: i18n.t('form_signup.text_form_success'),
    text_form_sale: i18n.t('form_signup.text_form_sale'),
    text_form_enterprise: i18n.t('form_signup.text_form_enterprise'),
    text_form_tax_address: i18n.t('form_signup.text_form_tax_address'),
    text_form_tax: i18n.t('form_signup.text_form_tax'),
    text_form_company: i18n.t('form_signup.text_form_company'),
    text_form_images: i18n.t('form_signup.text_form_images'),
    text_form_files: i18n.t('form_signup.text_form_files'),
    text_form_confirm_password: i18n.t(
      'form_signup.text_form_confirm_password'
    ),
    text_form_password_signup: i18n.t('form_signup.text_form_password_signup'),
    text_form_phone_forgot_password: i18n.t(
      'form_forgot_pasword.text_form_phone_forgot_password'
    ),
    text_form_text_forgot_password: i18n.t(
      'form_forgot_pasword.text_form_text_forgot_password'
    ),
    text_form_otp: i18n.t('form_forgot_pasword.text_form_otp'),
    text_form_send: i18n.t('form_forgot_pasword.text_form_send'),
    text_form_havent_send: i18n.t('form_forgot_pasword.text_form_havent_send'),
    text_form_change_password: i18n.t(
      'form_forgot_pasword.text_form_change_password'
    ),
    text_form_success_password: i18n.t(
      'form_forgot_pasword.text_form_success_password'
    ),
    text_form_enter_otp: i18n.t('form_forgot_pasword.text_form_enter_otp'),
    text_form_referral: i18n.t('form_signup.text_form_referral'),
    text_form_email: i18n.t('form_signup.text_form_email'),
    text_form_name: i18n.t('form_signup.text_form_name'),
    text_form_register: i18n.t('form_signup.text_form_register'),

    privacy_policy: i18n.t('title.privacy_policy'),
    operation_regulations: i18n.t('title.operation_regulations'),
    title_see_more: i18n.t('title.see_more'),
    title_great_deal: i18n.t('title.great_deal'),
    title_selling_products: i18n.t('title.selling_products'),
    title_bold_news: i18n.t('title.bold_news'),
    notify_otp_again: i18n.t('notify.otp_again'),
    notify_enter_phone: i18n.t('notify.enter_phone'),
    err_phone: i18n.t('notify.err_phone'),
    err_password_lenght: i18n.t('notify.err_password_lenght'),
    err_password_space: i18n.t('notify.err_password_space'),
    notify_enter_password: i18n.t('notify.enter_password'),
    notify_comfirm_password: i18n.t('notify.comfirm_password'),
    login_success: i18n.t('notify.login_success'),
    signup_success: i18n.t('notify.signup_success'),
    enter_name: i18n.t('notify.enter_name'),
    err_name: i18n.t('notify.err_name'),
    enter_email: i18n.t('notify.enter_email'),
    err_email: i18n.t('notify.err_email'),
    err_email_lenght: i18n.t('notify.err_email_lenght'),
    err_referral: i18n.t('notify.err_referral'),
    err_password: i18n.t('notify.err_password'),
    err_confirm: i18n.t('notify.err_confirm'),
    enter_company: i18n.t('notify.enter_company'),
    between6and20characters: i18n.t('notify.between6and20characters'),
    between6and55characters: i18n.t('notify.between6and55characters'),
    between4and55characters: i18n.t('notify.between4and55characters'),
    between10and13characters: i18n.t('notify.between10and13characters'),
    enter_tax_code: i18n.t('notify.enter_tax_code'),
    enter_contact: i18n.t('notify.enter_contact'),
    err_number_plate_length: i18n.t('notify.err_number_plate_length'),
    err_length_4: i18n.t('notify.err_length_4'),
    err_length_13: i18n.t('notify.err_length_13'),
    err_length_55: i18n.t('notify.err_length_55'),
    err_length_255: i18n.t('notify.err_length_255'),
    err_length_56: i18n.t('notify.err_length_56'),
    please_enter_the_license_plate: i18n.t(
      'notify.please_enter_the_license_plate'
    ),
    please_enter_your_address: i18n.t('notify.please_enter_your_address'),
    please_choose_a_form_of_payment: i18n.t(
      'notify.please_choose_a_form_of_payment'
    ),
    please_enter_owner_name: i18n.t('notify.please_enter_owner_name'),
    please_enter_the_car_brand: i18n.t('notify.please_enter_the_car_brand'),
    please_update_the_address_of_each_order: i18n.t(
      'notify.please_update_the_address_of_each_order'
    ),
    please_select_province: i18n.t('notify.please_select_province'),
    please_select_district: i18n.t('notify.please_select_district'),
    please_select_ward: i18n.t('notify.please_select_ward'),
    please_enter_specific_address: i18n.t(
      'notify.please_enter_specific_address'
    ),
    please_enter_google_maps_address: i18n.t(
      'notify.please_enter_google_maps_address'
    ),
    err_limit_vnpay: i18n.t('notify.err_limit_vnpay'),
    please_choose_vehicle: i18n.t('notify.please_choose_vehicle'),

    update_successful: i18n.t('notify.update_successful'),
    warning_upload_image: i18n.t('notify.warning_upload_image'),
    warning_capacity_image: i18n.t('notify.warning_capacity_image'),
    delete_successful: i18n.t('notify.delete_successful'),
    empty: i18n.t('notify.empty'),
    notification: i18n.t('notify.notification'),
    stop_sell: i18n.t('notify.stop_sell'),
    err_login: i18n.t('notify.err_login'),
    login_session_expired: i18n.t('notify.login_session_expired'),
    warning_geolocation: i18n.t('notify.warning_geolocation'),
    please_choose_a_product: i18n.t('notify.please_choose_a_product'),
    err_choose_product: i18n.t('notify.err_choose_product'),
    news_not_exist: i18n.t('notify.news_not_exist'),

    err_company: i18n.t('notify.err_company'),
    err_tax: i18n.t('notify.err_tax'),
    enter_tax_address: i18n.t('notify.enter_tax_address'),
    err_tax_address: i18n.t('notify.err_tax_address'),
    sort: i18n.t('product.sort'),
    newest: i18n.t('product.newest'),
    sell: i18n.t('product.sell'),
    price: i18n.t('product.price'),
    search_store: i18n.t('product.search_store'),
    price_low: i18n.t('product.price_low'),
    price_hight: i18n.t('product.price_hight'),
    amount: i18n.t('product.amount'),
    add_cart: i18n.t('product.add_cart'),
    buy_now: i18n.t('product.buy_now'),
    evaluate: i18n.t('product.evaluate'),
    full_tank: i18n.t('product.full_tank'),
    by_lit: i18n.t('product.by_lit'),
    error_search: i18n.t('product.error_search'),
    by_money: i18n.t('product.by_money'),
    lits: i18n.t('product.lits'),
    lit: i18n.t('product.lit'),
    numb_liters: i18n.t('product.numb_liters'),
    numb_money: i18n.t('product.numb_money'),
    buy: i18n.t('product.buy'),
    description_product: i18n.t('product.description_product'),
    list_store: i18n.t('product.list_store'),
    similar_product: i18n.t('product.similar_product'),
    district: i18n.t('product.district'),
    star: i18n.t('product.star'),
    province_city: i18n.t('product.province_city'),
    choose_store: i18n.t('product.choose_store'),
    market_price: i18n.t('product.market_price'),
    description_VN_Pay: i18n.t('description.VN_Pay'),
    description_debt: i18n.t('description.debt'),
    description_transfer: i18n.t('description.transfer'),
    description_cash: i18n.t('description.cash'),
    pending_order: i18n.t('order.pending'),
    confirm_order: i18n.t('order.confirm'),
    er_enter_liter: i18n.t('order.er_enter_liter'),
    er_enter_money: i18n.t('order.er_enter_money'),
    complete_order: i18n.t('order.complete'),
    cancel_order: i18n.t('order.cancel'),
    order_not_found: i18n.t('order.order_not_found'),
    total_price_order: i18n.t('order.total_price'),
    total_price_goods_order: i18n.t('title.total_price_goods'),
    wait_payment: i18n.t('order.wait_payment'),
    cacel_after: i18n.t('order.cacel_after'),
    total_price_reality: i18n.t('order.total_price_reality'),
    payment_order: i18n.t('order.payment'),
    not_payment_order: i18n.t('order.not_payment'),
    info_order: i18n.t('order.info_order'),
    request_order: i18n.t('order.request_order'),
    code_order: i18n.t('order.code_order'),
    send: i18n.t('order.send'),
    total_paid: i18n.t('order.total_paid'),
    point_used: i18n.t('order.point_used'),
    point_received: i18n.t('order.point_received'),
    list_banks: i18n.t('order.list_banks'),
    name_bank: i18n.t('order.name_bank'),
    number_bank: i18n.t('order.number_bank'),
    content_bank: i18n.t('order.content_bank'),
    cod_payment: i18n.t('order.cod_payment'),
    cod_payment_content: i18n.t('order.cod_payment_content'),
    debt_payment: i18n.t('order.debt_payment'),
    debt_payment_content: i18n.t('order.debt_payment_content'),
    bank_payment: i18n.t('order.bank_payment'),
    bank_payment_content: i18n.t('order.bank_payment_content'),
    reject_order: i18n.t('order.reject'),
    confirm_buy_gas: i18n.t('order.confirm_buy_gas'),
    type_vehicle: i18n.t('order.type_vehicle'),
    sell_amount: i18n.t('order.sell_amount'),

    //placeholder
    placeholder_enter_tax_code: i18n.t('placeholder.enter_tax_code'),
    placeholder_enter_company_name: i18n.t('placeholder.enter_company_name'),
    placeholder_enter_address: i18n.t('placeholder.enter_address'),
    placeholder_enter_email: i18n.t('placeholder.enter_email'),
    placeholder_owner_name: i18n.t('placeholder.owner_name'),
    placeholder_car_company: i18n.t('placeholder.car_company'),
    placeholder_enter_name: i18n.t('placeholder.enter_name'),
    search_for_petrol_stations: i18n.t(
      'placeholder.search_for_petrol_stations'
    ),

    info_insurance: i18n.t('order.info_insurance'),
    owner_insurance: i18n.t('order.owner_insurance'),
    license_plates: i18n.t('order.license_plates'),
    car_company: i18n.t('order.car_company'),
    share_code: i18n.t('order.share_code'),
    reason_cancel: i18n.t('order.reason_cancel'),
    review_order: i18n.t('order.review_order'),
    placeholder_review: i18n.t('order.placeholder_review'),
    upload: i18n.t('order.upload'),
  }
}
export default strings
