import Icon from '@ant-design/icons'

const FlagVNSvg = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5995_10821)">
      <path
        d="M12.2861 24C18.9135 24 24.2861 18.6274 24.2861 12C24.2861 5.37258 18.9135 0 12.2861 0C5.65872 0 0.286133 5.37258 0.286133 12C0.286133 18.6274 5.65872 24 12.2861 24Z"
        fill="#D80027"
      />
      <path
        d="M12.0223 5.54588C12.1674 5.07605 12.8325 5.07605 12.9777 5.54588L14.1616 9.37711C14.2264 9.58661 14.42 9.72948 14.6393 9.72948H18.5218C19.0001 9.72948 19.2054 10.3365 18.8253 10.6268L15.6418 13.0587C15.4753 13.1859 15.4058 13.4035 15.4677 13.6037L16.6734 17.5054C16.8171 17.9705 16.279 18.3459 15.8921 18.0504L12.8035 15.6908C12.6243 15.5539 12.3756 15.5539 12.1964 15.6908L9.10782 18.0504C8.72097 18.3459 8.18285 17.9705 8.32657 17.5054L9.53228 13.6037C9.59414 13.4035 9.52461 13.1859 9.3581 13.0587L6.17464 10.6268C5.79458 10.3365 5.9999 9.72948 6.47817 9.72948H10.3606C10.5799 9.72948 10.7736 9.58661 10.8383 9.37711L12.0223 5.54588Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_5995_10821">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.286133)"
        />
      </clipPath>
    </defs>
  </svg>
)

const FlagVNIcon = (props: any) => <Icon component={FlagVNSvg} {...props} />
export default FlagVNIcon
