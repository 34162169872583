import { Col, Row } from 'antd'
import styled from 'styled-components'

const NewsCol = styled(Col)`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`

const WrapSmallNews = styled.div`
  width: 96%;
  cursor: pointer;
`

const BodyDetailNews = styled(Row)`
  background-color: white;
  margin: 15px 0;
  border-radius: 12px;
  padding: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const ContentNewsWrap = styled(Col)``

const RelatedPosts = styled(Col)``

const TitlePost = styled.div.attrs((props: { size: number | undefined }) => ({
  size: props.size,
}))`
  font-weight: 600;
  font-size: ${(props: any) => props.size + 'px'};
  line-height: 36px;
`

const ContentNews = styled.div`
  width: 95%;
  margin-top: 12px;
`

const SetInnerHTML = styled.div`
  img {
    max-width: 100%;
  }
`

export {
  NewsCol,
  WrapSmallNews,
  BodyDetailNews,
  ContentNewsWrap,
  RelatedPosts,
  TitlePost,
  ContentNews,
  SetInnerHTML,
}
