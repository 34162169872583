import Icon from '@ant-design/icons'

const PlusSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 3.33337V12.6667"
      stroke="#69747E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33398 8H12.6673"
      stroke="#69747E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const PlusIcon = (props: any) => <Icon component={PlusSvg} {...props} />
export default PlusIcon
