import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { BrandHome, ImageBrand, StyledImage } from '../styled'

const Brands: React.FC<TProps> = ({ data, loading }) => {
  return (
    <BrandHome>
      {data?.map((item: any) => (
        <ImageBrand key={item.id}>
          {loading ? (
            <Skeleton style={{ height: '100%', backgroundColor: '#E6E6E6' }} />
          ) : (
            <StyledImage
              src={item.icon_url}
              alt="Brand"
              style={{ borderRadius: '6px' }}
            />
          )}
        </ImageBrand>
      ))}
    </BrandHome>
  )
}

export default Brands
