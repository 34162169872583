import Icon from '@ant-design/icons'

const FacebookSvg = () => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5 0C34.9269 0 45 10.0731 45 22.5C45 34.9269 34.9269 45 22.5 45C10.0731 45 0 34.9269 0 22.5C0 10.0731 10.0731 0 22.5 0ZM19.5873 33.4283C19.5873 33.5839 19.7139 33.7104 19.8694 33.7104H23.8948C24.0513 33.7104 24.177 33.5839 24.177 33.4283V22.4112H27.0958C27.2426 22.4112 27.3647 22.2996 27.3771 22.1528L27.6574 18.8341C27.6715 18.6688 27.5423 18.5273 27.3762 18.5273H24.177V16.1728C24.177 15.6208 24.6243 15.1734 25.1763 15.1734H27.4263C27.5818 15.1734 27.7084 15.0469 27.7084 14.8904V11.5717C27.7084 11.4161 27.5818 11.2896 27.4254 11.2896H23.6259C21.3952 11.2896 19.5873 13.0975 19.5873 15.3272V18.5273H17.5746C17.4182 18.5273 17.2916 18.6539 17.2916 18.8104V22.1291C17.2916 22.2855 17.4182 22.4112 17.5746 22.4112H19.5873V33.4283Z"
      fill="#3B5999"
    />
  </svg>
)

const FacebookIcon = (props: any) => <Icon component={FacebookSvg} {...props} />
export default FacebookIcon
