import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { Modal } from 'antd'
import React, { useState } from 'react'

const containerStyle = {
  width: '100%',
  height: 'calc(100% - 30px)',
}

type Props = { defaultProps: any; dataMarker: any }

const MapStore: React.FC<Props> = ({ defaultProps, dataMarker }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBnQuI2W5DyQVHJZpOXqiyODTG_d7dkPfk',
    language: 'en',
  })
  const [visibleModal, setVisibleModal] = useState<boolean>(false)

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={defaultProps.center}
      zoom={defaultProps.zoom}
    >
      <>
        <Marker
          position={defaultProps.center}
          onClick={() => {
            setVisibleModal(!visibleModal)
          }}
        />
        <Modal
          visible={visibleModal}
          onCancel={() => setVisibleModal(!visibleModal)}
          footer={null}
        >
          <b>{dataMarker?.name || dataMarker?.petrol_store?.name}</b>
          <p>{dataMarker?.address || dataMarker?.petrol_store?.address}</p>
        </Modal>
      </>
    </GoogleMap>
  ) : (
    <></>
  )
}
export default MapStore
