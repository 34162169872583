import { LoadingOutlined } from '@ant-design/icons'
import { DatePicker, Empty } from 'antd'
import locale from 'antd/lib/date-picker/locale/vi_VN'
import R from 'assets'
import { CoinColorIcon } from 'common/components/Icons'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TYPE_POINT } from 'utils/constants'
import { formatPrice } from 'utils/ruleForm'
import { getWalletPoint } from '../ApiAccount'
import * as Styled from '../styled'
import { getUserInfoAction } from 'features/auth/AuthSlice'

type TParams = {
  page: number
  from_date?: string | Date
  to_date?: string | Date
}

const { RangePicker } = DatePicker

const WalletPointTab: React.FC = () => {
  const userState: any = useSelector((state: any) => state.authReducer)
    ?.userInfo
  const scrollRef: any = useRef(null)
  const dispatch = useDispatch()
  const [params, setParams] = useState<TParams>({
    page: 1,
    from_date: undefined,
    to_date: undefined,
  })
  const [pagination, setPagination] = useState({ page: 1, limit: 12, total: 0 })
  const [listPoint, setListPoint] = useState<Array<any>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    dispatch(getUserInfoAction())
  }, [])
  useEffect(() => {
    getDataSource()
  }, [params])

  const getDataSource = async () => {
    try {
      setIsLoading(true)
      const res = await getWalletPoint(params)

      const convertData = res.data?.map((item: any) => ({
        content:
          localStorage.getItem('i18nextLng') === 'vi'
            ? item.type_point_transaction_history.title
            : item.type_point_transaction_history.title_en,
        time: item.create_at,
        point: item.point,
        current_point: item.current_point,
        scope: item.type_point_transaction_history.scope,
      }))
      setListPoint(convertData)
      setPagination({
        ...params,
        page: res.meta.pagination?.page,
        limit: 12,
        total: res.meta.pagination?.totalItems,
      })
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  const getMoreData = async () => {
    try {
      setIsLoading(true)
      const res = await getWalletPoint({ ...params, page: params.page + 1 })
      const convertData = res.data?.map((item: any) => ({
        content:
          localStorage.getItem('i18nextLng') === 'vi'
            ? item.type_point_transaction_history.title
            : item.type_point_transaction_history.title_en,
        time: item.create_at,
        point: item.point,
        current_point: item.current_point,
        scope: item.type_point_transaction_history.scope,
      }))
      setListPoint(listPoint.concat(convertData))
      setPagination({
        page: res.meta.pagination?.page,
        limit: 12,
        total: res.meta.pagination?.totalItems,
      })
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Styled.HeaderWallet>
        <Styled.TitleWallet>
          <CoinColorIcon style={{ marginRight: '10px' }} />
          {R.strings().title_your_point}
        </Styled.TitleWallet>
        <Styled.SpanPoint>
          <b>{formatPrice(userState?.point)} </b>
          {R.strings().points}
        </Styled.SpanPoint>
      </Styled.HeaderWallet>

      <Styled.BodyWallet>
        <Styled.WrapFilter>
          <span style={{ fontSize: '18px', fontWeight: 600 }}>
            {R.strings().points_history}
          </span>
          <RangePicker
            locale={locale}
            placeholder={[R.strings().from_date, R.strings().to_date]}
            format="DD/MM/YYYY"
            onChange={(date, dateString) => {
              setParams({
                ...params,
                to_date: date ? moment(date?.[1]).toDate() : undefined,
                from_date: date ? moment(date?.[0]).toDate() : undefined,
                page: 1,
              })
            }}
          />
        </Styled.WrapFilter>

        <Styled.ResponsiveForMobile
          ref={scrollRef}
          onScroll={() => {
            if (
              scrollRef.current!.scrollTop + scrollRef.current!.clientHeight >=
              scrollRef.current!.scrollHeight
            ) {
              let old = scrollRef.current!.scrollTop
              if (!isLoading && pagination.total > listPoint.length) {
                scrollRef.current!.scrollTop = old - 50
                getMoreData()
              }
            }
          }}
        >
          {listPoint?.map((item: any, index: number) => (
            <Styled.HistoryItem key={index}>
              <Styled.DateHistory>
                {moment(item.time).format('HH:mm DD/MM/YYYY')}
              </Styled.DateHistory>
              <Styled.HistoryInforBlock>
                <div style={{ display: 'flex' }}>
                  <Styled.WrapIcon>
                    <CoinColorIcon />
                  </Styled.WrapIcon>
                  <div>
                    <div>{item.content}</div>
                    <div>
                      {R.strings().surplus}: {formatPrice(item.current_point)}
                    </div>
                  </div>
                </div>

                <Styled.PointHistory
                  style={{
                    color:
                      item.scope === TYPE_POINT.USE_POINT
                        ? '#F26522'
                        : '#539643',
                  }}
                >
                  {item.scope === TYPE_POINT.USE_POINT ? '- ' : '+ '}
                  {formatPrice(item.point)}
                </Styled.PointHistory>
              </Styled.HistoryInforBlock>
            </Styled.HistoryItem>
          ))}
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            {isLoading ? <LoadingOutlined /> : ''}
          </span>
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            {listPoint.length < 1 ? (
              <Empty description="Không có dữ liệu" />
            ) : (
              ''
            )}
          </span>
        </Styled.ResponsiveForMobile>

        <Styled.HeaderTable justify="space-between">
          <Styled.ColTable md={9}>{R.strings().content}</Styled.ColTable>
          <Styled.ColTable md={7}>{R.strings().time} </Styled.ColTable>
          <Styled.ColTable md={4}>{R.strings().surplus}</Styled.ColTable>
          <Styled.ColTable md={4}>{R.strings().number_point}</Styled.ColTable>
        </Styled.HeaderTable>
        <Styled.BodyTable
          ref={scrollRef}
          onScroll={() => {
            if (
              scrollRef.current!.scrollTop + scrollRef.current!.clientHeight >=
              scrollRef.current!.scrollHeight
            ) {
              let old = scrollRef.current!.scrollTop
              if (!isLoading && pagination.total > listPoint.length) {
                scrollRef.current!.scrollTop = old - 50
                getMoreData()
              }
            }
          }}
        >
          {listPoint?.map((item: any, index: number) => (
            <Styled.RowTable key={index}>
              <Styled.ColTable md={9}>{item.content}</Styled.ColTable>
              <Styled.ColTable md={7}>
                {moment(item.time).format('HH:mm DD/MM/YYYY')}
              </Styled.ColTable>
              <Styled.ColTable md={4}>
                {formatPrice(item.current_point)}
              </Styled.ColTable>
              <Styled.ColTable
                md={4}
                color={
                  item.scope === TYPE_POINT.USE_POINT ? '#F26522' : '#539643'
                }
              >
                {item.scope === TYPE_POINT.USE_POINT ? '- ' : '+ '}
                {formatPrice(item.point)}
              </Styled.ColTable>
            </Styled.RowTable>
          ))}
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            {isLoading ? <LoadingOutlined /> : ''}
          </span>
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            {listPoint.length < 1 ? (
              <Empty description="Không có dữ liệu" />
            ) : (
              ''
            )}
          </span>
        </Styled.BodyTable>
      </Styled.BodyWallet>
    </>
  )
}
export default WalletPointTab
