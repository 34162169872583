import { EnvironmentFilled, StarFilled } from '@ant-design/icons'
import {
  Breadcrumb,
  Col,
  Divider,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd'
import R from 'assets'
import { ROUTER_PATH, SESSION_KEY } from 'common/config'
import MapStore from 'features/stores/components/MapStore'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import { hasCookie } from 'utils/functionHelper'
import history from 'utils/history'
import { notificationError } from 'utils/notification'
import { checkDecimal, checkNumbersOnly, formatPrice } from 'utils/ruleForm'
import { getDetailStation } from '../ApiProducts'
import {
  BreadcrumbStyle,
  ButtonBuyGas,
  GasContainer,
  InputLit,
  MapBlock,
  OkButton,
  RadioChange,
  RadioItem,
  RadioStyled,
  RowAddress,
  SwitchStyled,
  ValueBuy,
} from '../styles'

type Props = {}
const BuyGasoline: React.FC<Props> = ({}) => {
  const { Text } = Typography
  const param: any = useParams()
  const location = useLocation()
  const [dataDetailStation, setDataDetailStation] = useState<any>()

  const [intoMoney, setIntoMoney] = useState<any>(50000)
  const [liters, setLiters] = useState<any>(2)
  const [radio, setRadio] = React.useState(1)
  const [check, setCheck] = useState<number>(0)
  const [isKeepStock, setIsKeepStock] = useState<number>(0)
  const [confirm, setConfirm] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [defaultProps, setDefaultops] = useState<any>({
    center: {
      lat: 21.0031,
      lng: 105.853,
    },
    zoom: 14,
  })

  useEffect(() => {
    DetaiPetrol()
  }, [])

  const DetaiPetrol = async () => {
    setLoading(true)
    try {
      const res = await getDetailStation(param?.id)
      setDataDetailStation(res)
      setDefaultops({
        ...defaultProps,
        center: {
          lat: res?.data?.petrol_store?.location_lat,
          lng: res?.data?.petrol_store?.location_long,
        },
      })
    } catch (error) {
      console.error('Exception ' + error)
    }
    setLoading(false)
  }

  const handleSuccess = () => {
    if (
      radio === 1
        ? liters > 0 && liters < 1000000
        : intoMoney > 0 && intoMoney < 1000000000
      //   &&
      // array[1] < 4
    ) {
      history.push({
        pathname: ROUTER_PATH.PAYMENT,
        state: {
          type: 'petrol',
          name: dataDetailStation?.data?.product?.name,
          price_per_liter: dataDetailStation?.data?.petrol_store?.min_price
            ? dataDetailStation?.data?.petrol_store?.min_price
            : dataDetailStation?.data?.price,
          product_stock_id: dataDetailStation?.data?.id,
          petrol_store_id: dataDetailStation?.data?.petrol_store_id,
          is_full_fuel: check,
          isKeepStock,
          liters: liters,
          petrol_store: {
            phone_store: dataDetailStation?.data?.petrol_store?.phone,
            name: dataDetailStation?.data.petrol_store.name,
            petrol_store_star:
              dataDetailStation?.data.petrol_store.petrol_store_star,
            review_count: dataDetailStation?.data.petrol_store.review_count,
            address: `${dataDetailStation?.data.petrol_store.location_address}, ${dataDetailStation?.data.petrol_store.ward.name} ,${dataDetailStation?.data.petrol_store.district.name},${dataDetailStation?.data.petrol_store.province.name}  `,
          },
          product: dataDetailStation?.data?.product,
          amount:
            radio === 1
              ? liters
              : +(dataDetailStation?.data?.petrol_store?.min_price
                  ? intoMoney / dataDetailStation?.data?.petrol_store?.min_price
                  : intoMoney / dataDetailStation?.data?.price
                ).toFixed(3),
          total_price:
            radio === 1
              ? dataDetailStation?.data?.petrol_store?.min_price
                ? liters * dataDetailStation?.data?.petrol_store?.min_price
                : liters * dataDetailStation?.data?.price
              : intoMoney,
        },
      })
    } else {
      radio === 1
        ? notificationError(R.strings().er_enter_liter)
        : notificationError(R.strings().er_enter_money)
    }
  }

  return (
    <Spin spinning={loading}>
      <BreadcrumbStyle>
        <Breadcrumb.Item>
          <a href={ROUTER_PATH.HOME}>{R.strings().title_home}</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a
            href=""
            onClick={() => {
              history.push({
                pathname: `${ROUTER_PATH.PRODUCT}?type=gas&category=${
                  R.strings().title_gas
                }&idCategory=1`,
              })
            }}
          >
            {R.strings().title_gas}
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {dataDetailStation?.data?.product?.name}
        </Breadcrumb.Item>
      </BreadcrumbStyle>
      {!dataDetailStation ? (
        <div
          style={{ padding: '0 0 50px 0', width: '100%', textAlign: 'center' }}
        >
          <img src={R.images.image_complete} alt="" />
          <br />
          {R.strings().stop_sell}
        </div>
      ) : (
        <GasContainer style={{ backgroundColor: 'white' }}>
          <>
            <MapBlock>
              <MapStore
                defaultProps={defaultProps}
                dataMarker={dataDetailStation?.data}
              />
            </MapBlock>
            <ValueBuy>
              <RowAddress
                style={{ fontSize: 20, fontWeight: 600, color: 'black' }}
                onClick={() => {
                  history.push({
                    pathname: `${ROUTER_PATH.DETAIL_STORES}/${dataDetailStation?.data?.petrol_store_id}`,
                  })
                }}
              >
                {dataDetailStation?.data?.petrol_store.name}
              </RowAddress>
              <div style={{ color: '#595959', fontSize: 16, margin: '4px 0' }}>
                <StarFilled style={{ color: '#FFC53D' }} />
                &nbsp;
                {
                  +dataDetailStation?.data?.petrol_store?.petrol_store_star.toFixed(
                    1
                  )
                }{' '}
                (
                {formatPrice(
                  dataDetailStation?.data?.petrol_store.review_count
                )}{' '}
                {R.strings().evaluate})
              </div>
              <RowAddress
                onClick={() => {
                  window.open(
                    `https://www.google.com/maps/place/?q=${dataDetailStation?.data.petrol_store.name}/@${dataDetailStation?.data.petrol_store?.location_lat},${dataDetailStation?.data.petrol_store?.location_long}`
                  )
                }}
              >
                <EnvironmentFilled style={{ color: '#F26522' }} />
                &nbsp;{
                  dataDetailStation?.data?.petrol_store.location_address
                }, {dataDetailStation?.data?.petrol_store?.ward?.name},
                {dataDetailStation?.data?.petrol_store?.district?.name},
                {dataDetailStation?.data?.petrol_store?.province?.name}
              </RowAddress>
              <Divider style={{ margin: '15px 0 20px 0' }}></Divider>
              <Row
                style={{ width: '100%', paddingBottom: 15 }}
                justify="space-between"
              >
                <div style={{ fontSize: 16, fontWeight: 500 }}>
                  <img src={R.images.symbol_gas} />{' '}
                  {dataDetailStation?.data?.product?.name}
                </div>
                <div style={{ color: '#f26522', fontWeight: 500 }}>
                  {dataDetailStation?.data?.petrol_store?.min_price ? (
                    <>
                      <Text delete style={{ fontSize: 14, color: 'gray' }}>
                        {formatPrice(dataDetailStation?.data?.price)}
                        {R.strings().title_unit}
                      </Text>
                      &nbsp;
                      {formatPrice(
                        dataDetailStation?.data?.petrol_store?.min_price
                      )}
                    </>
                  ) : (
                    formatPrice(dataDetailStation?.data?.price)
                  )}
                  {R.strings().title_unit}
                </div>
              </Row>
              <Row
                style={{
                  width: '100%',
                  border: '1px dashed #f26522',
                  padding: '20px',
                  margin: '10px 0 20px 0',
                }}
                justify="space-between"
              >
                {dataDetailStation?.data?.product?.save_status ? (
                  <Space size="large">
                    <div style={{ fontWeight: 500, fontSize: 16 }}>
                      {R.strings().title_buy_gasoline_to_keep_stock}
                    </div>
                    <SwitchStyled
                      disabled={!!check}
                      style={
                        isKeepStock === 1 ? { backgroundColor: '#f26522' } : {}
                      }
                      defaultChecked={false}
                      onChange={checked => {
                        if (checked === true) {
                          setLiters(0)
                          setIsKeepStock(1)
                        } else {
                          setIsKeepStock(0)
                        }
                      }}
                    />
                  </Space>
                ) : null}

                <Space size="large">
                  <div style={{ fontWeight: 500, fontSize: 16 }}>
                    {R.strings().full_tank}
                  </div>
                  <SwitchStyled
                    disabled={!!isKeepStock}
                    style={check === 1 ? { backgroundColor: '#f26522' } : {}}
                    defaultChecked={false}
                    onChange={checked => {
                      if (checked === true) {
                        setCheck(1)
                      } else {
                        setCheck(0)
                      }
                    }}
                  />
                </Space>
              </Row>
              {check === 0 ? (
                <>
                  {isKeepStock ? (
                    <>
                      <Row
                        wrap={false}
                        style={{ margin: '20px 0' }}
                        align="middle"
                      >
                        <Col
                          span={4}
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            color: '#595959',
                          }}
                        >
                          {/* Số lượng */}
                          {R.strings().amount}
                        </Col>
                        <InputNumber
                          value={liters}
                          min={dataDetailStation?.data?.product?.min_quantity}
                          max={dataDetailStation?.data?.product?.max_quantity}
                          onChange={value => setLiters(value)}
                          placeholder="Số lít"
                        />
                      </Row>
                      <div
                        style={{
                          margin: '10px 0',
                          color: '#aaa',
                          fontSize: '14px',
                        }}
                      >
                        - {R.strings().amount} {R.strings().buy.toLowerCase()}:{' '}
                        {dataDetailStation?.data?.product?.min_quantity}{' '}
                        {R.strings().title_to}{' '}
                        {dataDetailStation?.data?.product?.max_quantity}
                      </div>
                      <div
                        style={{
                          color: '#aaa',
                          fontSize: '14px',
                        }}
                      >
                        - {R.strings().title_storage_period}:{' '}
                        {dataDetailStation?.data?.product?.expire_time}{' '}
                        {R.strings().title_date_of_purchase}
                      </div>
                      <Divider />
                    </>
                  ) : (
                    <>
                      <RadioChange.Group
                        style={{ paddingBottom: 15 }}
                        onChange={e => {
                          setRadio(e.target.value)
                          setLiters(2)
                          setIntoMoney(50000)
                        }}
                        value={radio}
                      >
                        <RadioChange value={1}>
                          {R.strings().by_lit}
                        </RadioChange>
                        <RadioChange value={2}>
                          {R.strings().by_money}
                        </RadioChange>
                      </RadioChange.Group>

                      <RadioStyled defaultValue={'a'}>
                        <Row justify="space-between">
                          <RadioItem
                            style={{
                              borderRadius: 12,
                            }}
                            value="a"
                            onClick={() => {
                              if (radio === 1) {
                                setLiters(2)
                              }
                              if (radio === 2) {
                                setIntoMoney(50000)
                              }
                            }}
                          >
                            {radio === 1 ? '2 ' + R.strings().lit : '50.000'}
                          </RadioItem>

                          <RadioItem
                            value="b"
                            onClick={() => {
                              if (radio === 1) {
                                setLiters(3)
                              }
                              if (radio === 2) {
                                setIntoMoney(100000)
                              }
                            }}
                          >
                            {radio === 1 ? '3 ' + R.strings().lit : '100.000'}
                          </RadioItem>

                          <RadioItem
                            style={{
                              width: '23%',
                              height: 70,
                              borderRadius: '12px',
                              fontWeight: 500,
                            }}
                            value="c"
                            onClick={() => {
                              if (radio === 1) {
                                setLiters(5)
                              }
                              if (radio === 2) {
                                setIntoMoney(200000)
                              }
                            }}
                          >
                            {radio === 1 ? '5 ' + R.strings().lit : '200.000'}
                          </RadioItem>

                          <RadioItem
                            style={{
                              width: '23%',
                              height: 70,
                              borderRadius: '12px',
                              fontWeight: 500,
                            }}
                            value="d"
                            onClick={() => {
                              if (radio === 1) {
                                setLiters(10)
                              }
                              if (radio === 2) {
                                setIntoMoney(500000)
                              }
                            }}
                          >
                            {radio === 1 ? '10 ' + R.strings().lit : '500.000'}
                          </RadioItem>
                        </Row>
                      </RadioStyled>

                      <InputLit
                        placeholder={
                          radio === 1
                            ? R.strings().numb_liters
                            : R.strings().numb_money
                        }
                        value={
                          radio === 1
                            ? formatPrice(liters)
                            : formatPrice(intoMoney)
                        }
                        onChange={e => {
                          if (
                            checkNumbersOnly(
                              e.target?.value?.replaceAll(',', '')
                            )
                          ) {
                            setIntoMoney(e.target.value.replaceAll(',', ''))
                          } else {
                            setIntoMoney('')
                          }
                          if (
                            checkDecimal(e.target.value?.replaceAll(',', ''))
                          ) {
                            setLiters(e.target.value.replaceAll(',', ''))
                          } else {
                            if (e.target.value?.length < 1) {
                              setLiters('')
                            }
                          }
                        }}
                      />
                    </>
                  )}

                  <Row
                    style={{ width: '100%', paddingBottom: 15 }}
                    justify="space-between"
                  >
                    <div
                      style={{
                        fontWeight: 500,
                        fontSize: 16,
                        color: '#595959',
                      }}
                    >
                      {R.strings().lits}
                    </div>
                    <div style={{ fontWeight: 500, fontSize: 16 }}>
                      {radio === 1
                        ? formatPrice(+Number(liters).toFixed(3))
                        : dataDetailStation?.data?.petrol_store?.min_price
                        ? formatPrice(
                            +(
                              intoMoney /
                              dataDetailStation?.data?.petrol_store?.min_price
                            ).toFixed(3)
                          )
                        : formatPrice(
                            +(
                              intoMoney /
                              dataDetailStation?.data?.product?.price
                            ).toFixed(3)
                          )}
                    </div>
                  </Row>
                  <Row
                    style={{ width: '100%', paddingBottom: 15 }}
                    justify="space-between"
                  >
                    <div
                      style={{
                        fontWeight: 500,
                        fontSize: 16,
                        color: '#595959',
                      }}
                    >
                      {R.strings().title_total_price} (VND):
                    </div>
                    <div style={{ fontWeight: 500, fontSize: 16 }}>
                      {radio === 1
                        ? dataDetailStation?.data?.petrol_store?.min_price
                          ? formatPrice(
                              (
                                +Number(liters).toFixed(3) *
                                dataDetailStation?.data?.petrol_store?.min_price
                              ).toFixed(0)
                            )
                          : formatPrice(
                              (
                                +Number(liters).toFixed(3) *
                                dataDetailStation?.data?.price
                              ).toFixed(0)
                            )
                        : formatPrice(intoMoney)}
                    </div>
                  </Row>
                </>
              ) : (
                <></>
              )}
              <div
                style={
                  check === 1
                    ? { paddingTop: '20%', textAlign: 'center' }
                    : { textAlign: 'center', width: '100%' }
                }
              >
                <ButtonBuyGas
                  disabled={!liters}
                  type="primary"
                  style={check === 1 ? { margin: '0 0 140px 0' } : {}}
                  onClick={() => {
                    if (isKeepStock) {
                      handleSuccess()
                    } else {
                      if (hasCookie(SESSION_KEY.SESSION)) {
                        setConfirm(!confirm)
                      } else {
                        swal({
                          title: R.strings().notification,
                          text: R.strings().err_login,
                          icon: 'error',
                        }).then(function (isConfirm) {
                          if (isConfirm) {
                            history.push({
                              pathname: ROUTER_PATH.LOGIN,
                              hash: location.pathname,
                            })
                          }
                        })
                      }
                    }
                  }}
                >
                  {R.strings().buy}
                </ButtonBuyGas>
                <Modal
                  title={null}
                  footer={null}
                  visible={confirm && !isKeepStock}
                  onOk={() => {
                    setConfirm(false)
                  }}
                  onCancel={() => {
                    setConfirm(false)
                  }}
                >
                  <b style={{ fontSize: 18, textAlign: 'justify' }}>
                    {R.strings().confirm_buy_gas}
                  </b>
                  <div style={{ textAlign: 'center' }}>
                    <OkButton onClick={handleSuccess}>OK</OkButton>
                  </div>
                </Modal>
              </div>
            </ValueBuy>
          </>
        </GasContainer>
      )}
    </Spin>
  )
}

export default BuyGasoline
