import { Button, Col, Input, Row, Upload } from 'antd'
import styled from 'styled-components'

const RowAccount = styled.div`
  padding: 22px 0;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const ColAccount = styled(Col).attrs(
  (props: { backgroundColor: string | undefined }) => ({
    backgroundColor: props.backgroundColor || 'white',
  })
)`
  background-color: ${props => props.backgroundColor};
  border-radius: 12px;
  &&.menu {
    @media only screen and (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
`

const MenuItem = styled.div`
  line-height: 50px;
  color: #8c8c8c;
  font-weight: 600;
  padding-left: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    color: #f26522;
  }
  #icon {
    display: block;
  }
  #icon_hover {
    display: none;
  }
  &&.active {
    color: #f26522;
    #icon {
      display: none;
    }
    #icon_hover {
      display: block;
    }
  }

  &&:hover {
    #icon {
      display: none;
    }
    #icon_hover {
      display: block;
    }
  }
`

const AvatarBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  color: #262626;
  border-bottom: 1px solid #f1f3f5;
  #name-user {
    font-weight: 600;
  }
  #phone-user {
    font-weight: 500;
  }
`

const PasswordInput = styled(Input.Password)`
  border-radius: 12px;
`

const ButtonStyled = styled(Button)`
  background-color: #f26522;
  border-radius: 12px;
  color: white;
  :active,
  :focus,
  :hover {
    background-color: #f26522;
    border-radius: 12px;
    border: none;
    color: white;
  }
`
const ContentRules = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
`

const UploadStyled = styled(Upload)`
  display: flex;
  justify-content: center;
`

const HeaderWallet = styled.div`
  background: #ffffff;
  border-radius: 12px;
  line-height: 30px;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 480px) {
    padding: 10px 10px;
  }
`

const SpanPoint = styled.span`
  color: #f26522;
  font-size: 20px;
`

const TitleWallet = styled.span`
  display: flex;
  align-items: center;
  font-weight: 500;
`
const BodyWallet = styled.div`
  background: #ffffff;
  border-radius: 12px;
  margin-top: 10px;
  height: calc(100% - 60px);
  padding: 16px;
`

const HeaderTable = styled(Row)`
  margin-top: 10px;
  line-height: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e9ecef;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const ColTable = styled(Col).attrs((props: { color: string | undefined }) => ({
  color: props.color || '',
}))`
  color: ${props => props.color};
  p {
    margin: 0;
  }
`
const BodyTable = styled.div`
  overflow-y: scroll;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  height: calc(100% - 120px);
`
const RowTable = styled(Row)`
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  border-bottom: 1px solid #ced4da;
`

const ResponsiveForMobile = styled.div`
  height: calc(100% - 120px);
  overflow-y: scroll;
  padding: 10px;
  margin-top: 10px;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`

const WrapIcon = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HistoryItem = styled.div`
  border-bottom: 1px solid #e9ecef;
  padding: 8px 0;
  font-weight: 500;
  font-size: 16px;
`

const DateHistory = styled.div`
  color: #595959;
`

const HistoryInforBlock = styled.div`
  display: flex;
  justify-content: space-between;
`

const PointHistory = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const WrapFilter = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 480px) {
    display: unset;
  }
`

const HeaderLocation = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  padding: 12px;

  #title {
    font-weight: 600;
    font-size: 18px;
  }
`

const BodyAddress = styled.div`
  padding: 12px;
  overflow-y: scroll hidden;
`

const ButtonAdd = styled(Button)`
  border-radius: 12px;
  border-color: #f26522;
  color: #f26522;
`

const RowDebt = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

const ColItemBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
  }
`

const ColItemDebt = styled.div`
  width: 98%;
  background-color: white;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 6px 20px;
  p {
    margin: 0px;
  }
`

const DebtItem = styled.div`
  padding: 6px;
  border-radius: 12px;
  border: 1px solid #ced4da;
  margin-top: 8px;
  background: #f8f9fa;
`

const TopDebtItem = styled.div`
  display: flex;
  justify-content: space-between;
`

const MiddleDebtItem = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px dashed #ced4da;
  border-bottom: 1px dashed #ced4da;
  padding: 10px 0;
`

const BottomDebtItem = styled.div`
  display: flex;
  justify-content: space-between;
`

export {
  RowAccount,
  ColAccount,
  MenuItem,
  AvatarBlock,
  PasswordInput,
  ButtonStyled,
  ContentRules,
  UploadStyled,
  HeaderWallet,
  BodyWallet,
  SpanPoint,
  TitleWallet,
  HeaderTable,
  ColTable,
  BodyTable,
  RowTable,
  ResponsiveForMobile,
  HistoryItem,
  DateHistory,
  HistoryInforBlock,
  WrapIcon,
  PointHistory,
  WrapFilter,
  HeaderLocation,
  BodyAddress,
  ButtonAdd,
  ColItemDebt,
  DebtItem,
  TopDebtItem,
  MiddleDebtItem,
  BottomDebtItem,
  RowDebt,
  ColItemBlock,
}
