import Icon from '@ant-design/icons'

const GasStationSvg = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3176 19.0251V9.4014H19.2072V8.43371H19.4804C19.7594 8.43371 19.9864 8.66285 19.9864 8.94449V15.6649C19.9864 15.9465 19.7594 16.1757 19.4804 16.1757H18.3177V17.5735H19.4804C20.5229 17.5735 21.3711 16.7173 21.3711 15.6649V8.94449C21.3711 7.89203 20.523 7.03595 19.4804 7.03595H19.2072V6.12193H18.3176V4.34937C18.3176 3.0572 17.2704 2 15.9904 2H8.00948C6.72952 2 5.68231 3.0572 5.68231 4.34937V19.0251H3.48747C2.66596 19.0251 2 19.691 2 20.5125C2 21.334 2.66596 22 3.48746 22H20.5125C21.334 22 22 21.334 22 20.5125C22 19.691 21.334 19.0251 20.5125 19.0251H18.3176ZM7.93744 4.75852H16.0627V10.602H7.93744V4.75852Z"
      fill="#FB8500"
    />
  </svg>
)

const GasStationIcon = (props: any) => (
  <Icon component={GasStationSvg} {...props} />
)
export default GasStationIcon
