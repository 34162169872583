import { notification } from 'antd'
import R from 'assets'
import {
  GoodPriceIcon,
  InsurranceIcon,
  OilIcon,
  RescueIcon,
} from 'common/components/Icons'
import { ROUTER_PATH } from 'common/config'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { formatPrice } from 'utils/ruleForm'
import styles from '../Home.module.css'
import {
  BigOption,
  CategoryBlock,
  SmallOption,
  SpanHot,
} from './CategoryHome.Mobile.Styled'
import ModalRequest from './ModalRequest'

const CategoryHomeMobile: React.FC<any> = ({ data }) => {
  const history = useHistory()
  const [visibleChangeOil, setVisibleChangeOil] = useState<boolean>(false)
  const [visibleBatter, setVisibleBatter] = useState<boolean>(false)
  return (
    <>
      <CategoryBlock>
        <BigOption
          onClick={() => {
            history.push({
              pathname: `${ROUTER_PATH.PRODUCT}?type=gas`,
            })
            window.location.reload()
          }}
        >
          <GoodPriceIcon className={styles.icon_option} />
          <div style={{ marginLeft: '15px' }}>
            {R.strings().title_good_price_petrol}
            <div
              style={{ display: 'flex', padding: '5px', alignItems: 'center' }}
            >
              <SpanHot>HOT</SpanHot>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                {+data?.price !== data?.price + data?.price_sale && (
                  <>
                    <div
                      style={{
                        color: '#868E96',
                        fontWeight: 'normal',
                        textDecoration: 'line-through',
                        fontSize: '12px',
                      }}
                    >
                      {formatPrice(data?.price + data?.price_sale)}{' '}
                      {R.strings().title_unit}
                    </div>
                    &nbsp;
                  </>
                )}
                <div
                  style={{
                    color: 'red',
                    fontWeight: 'normal',
                    fontSize: '14px',
                  }}
                >
                  {formatPrice(data?.price)} {R.strings().title_unit}
                </div>
              </div>
            </div>
            {data?.price_sale ? (
              <div style={{ color: 'red' }}>
                {R.strings().shock_absorber} {formatPrice(data?.price_sale)}{' '}
                {R.strings().title_unit}
              </div>
            ) : (
              <></>
            )}
          </div>
        </BigOption>
        {/* <SmallOption
          onClick={() => {
            if (hasCookie(SESSION_KEY.SESSION)) {
              setVisibleChangeOil(true)
            } else {
              notificationError(R.strings().err_login)
            }
          }}
        >
          <ChangeOilIcon className={styles.icon_option} />
          {R.strings().title_oil_change_at_home}
        </SmallOption> */}
        <SmallOption
          onClick={() => {
            // if (hasCookie(SESSION_KEY.SESSION)) {
            //   setVisibleBatter(true)
            // } else {
            notification.warning({
              message: 'YOYO',
              description: 'Tính năng đang phát triền',
            })
            // }
          }}
        >
          <RescueIcon className={styles.icon_option} />
          {R.strings().title_car_charging_station}
        </SmallOption>
        {/* Trạm sạc ô tô */}
        <SmallOption
          onClick={() => {
            const nameCategory =
              localStorage.getItem('i18nextLng') === 'vi'
                ? 'Bảo hiểm'
                : 'Insurance'
            history.push({
              pathname: `${ROUTER_PATH.PRODUCT}`,
              search: `type=insurrance&category=${nameCategory}&idCategory=3`,
            })
          }}
        >
          <InsurranceIcon className={styles.icon_option} />
          {R.strings().title_insurrance}
        </SmallOption>
        <SmallOption
          onClick={() => {
            const nameCategory =
              localStorage.getItem('i18nextLng') === 'vi'
                ? 'Dầu nhớt'
                : 'Lubricating oil'
            history.push({
              pathname: `${ROUTER_PATH.PRODUCT}`,
              search: `type=oil&category=${nameCategory}&idCategory=2`,
            })
          }}
        >
          <OilIcon className={styles.icon_option} />
          {R.strings().title_lubricating_oil}
        </SmallOption>
      </CategoryBlock>
      <ModalRequest
        visibleChangeOil={visibleChangeOil}
        visibleBatter={visibleBatter}
        setVisibleChangeOil={setVisibleChangeOil}
        setVisibleBatter={setVisibleBatter}
      />
    </>
  )
}
export default CategoryHomeMobile
