import { Modal, Row } from 'antd'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { formatPrice } from 'utils/ruleForm'

const ContainerQRCode = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 20px 60px;

  & .title_bank {
    /* width: 200px; */
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 15px;
  }

  & .text_total_payment {
    text-align: center;
    font-size: 15px;
    padding: 10px 0 4px 0;
  }

  & .total_payment {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
  }

  & .border_dot {
    border-bottom: 1px dashed;
    margin: 10px 0;
  }
`

const BoxWrap = styled.div`
  width: fit-content;

  background: linear-gradient(to right, black 4px, transparent 4px) 0 0,
    linear-gradient(to right, black 4px, transparent 4px) 0 100%,
    linear-gradient(to left, black 4px, transparent 4px) 100% 0,
    linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
    linear-gradient(to top, black 4px, transparent 4px) 0 100%,
    linear-gradient(to top, black 4px, transparent 4px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding: 2px;
`

const OrderVTPAY = ({
  isModalVisibleScan,
  onCancel,
  dataSource,
  qrGen,
}: any) => {
  return (
    <Modal
      destroyOnClose
      centered
      visible={isModalVisibleScan}
      // title="Thanh toán ViettinBank"
      closable={false}
      footer={null}
      onCancel={onCancel}
      bodyStyle={{
        background: 'linear-gradient(to right, #2980B9, #6DD5FA, #2980B9)',
        width: 'fit-content',
        padding: '40px',
      }}
      width="fit-content"
    >
      <ContainerQRCode>
        <div>
          <div className="title_bank">
            Thanh toán qua ứng dụng Mobile VietinBank
          </div>

          <Row justify="center">
            <BoxWrap>
              <img width="240px" height="240px" src={qrGen} alt="Qr" />
            </BoxWrap>
          </Row>
          <div className="text_total_payment">Số tiền cần thanh toán</div>
          <div className="total_payment">
            {formatPrice(+dataSource?.order_payment?.total_price)}
          </div>
          <div className="border_dot" />
          <Row align="middle" justify="space-between">
            <div>Thanh toán</div>
            <div>VietinPay</div>
          </Row>
          <Row align="middle" justify="space-between">
            <div>Đơn hàng</div>
            <div>{dataSource?.code}</div>
          </Row>
          <Row align="middle" justify="space-between">
            <div>Giờ in</div>
            <div>{moment().format('HH:mm DD/MM/YYYY')}</div>
          </Row>
        </div>
      </ContainerQRCode>
    </Modal>
  )
}

export default React.memo(OrderVTPAY)
