import R from 'assets'
import {
  DebtIcon,
  TranferIcon,
  VNPayIcon,
  WalletIcon,
} from 'common/components/Icons'
import VTPayIcon from 'common/components/Icons/VTPayIcon'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { PAYMENTS } from 'utils/constants'
import { formatPrice } from 'utils/ruleForm'
import { getListPayment, getPercentage } from '../ApiPayment'
import {
  ButtonOrder,
  ContentMethod,
  PaymentMethodBlock,
  PaymentMethodItem,
  RadioPayment,
  RadioStyled,
  TitleBlock,
  ValueBlock,
  WrapButton,
} from '../styled'

const renderIconPayment = (value: number) => {
  switch (value) {
    case PAYMENTS[0].VALUE:
      return <WalletIcon />
    case PAYMENTS[1].VALUE:
      return <DebtIcon />
    case PAYMENTS[2].VALUE:
      return <TranferIcon />
    case PAYMENTS[3].VALUE:
      return <VNPayIcon />
    case PAYMENTS[4].VALUE:
      return <VTPayIcon />
  }
}

type TProp = {
  totalOrderPrice: number
  onPayment: () => void
  typePaymentID: number | null
  onSetTypePaymentID: (value: number | null) => void
  data: any
}

const Payments: React.FC<TProp> = ({
  totalOrderPrice,
  onPayment,
  typePaymentID,
  onSetTypePaymentID,
  data,
}) => {
  const location: any = useLocation()
  const userState: any = useSelector((state: any) => state.authReducer)
    ?.userInfo
  const [listPayment, setListPayment] = useState([])
  const [configPointReceived, setConfigPointReceived] = useState<number>(0)

  const isKeepStock = data?.isKeepStock

  useEffect(() => {
    getConfigPercentage()
  }, [])

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (
      (location?.state?.type === 'oil' &&
        data?.reduce(function (total: number, value: any) {
          return total + value.total_price
        }, 0) -
          data?.reduce(function (total: number, value: any) {
            return total + value?.point_used
          }, 0) ===
          0) ||
      totalOrderPrice - data?.point === 0
    ) {
      let list = [...listPayment]
      setListPayment(list.filter((item: any) => item.id === PAYMENTS[0].VALUE))
      onSetTypePaymentID(4)
    } else {
      getData()
      //   onSetTypePaymentID(null)
    }
  }, [totalOrderPrice, data])

  React.useEffect(() => {
    if (isKeepStock) {
      onSetTypePaymentID(2)
    }
  }, [isKeepStock])

  const getData = async () => {
    try {
      const res = await getListPayment()
      let dataConvert = []
      if (
        !userState?.enterprise ||
        !userState?.enterprise?.status ||
        userState?.enterprise?.debt_enterprises?.length < 1
      ) {
        dataConvert = res.data?.filter(
          (item: any) => item.id !== PAYMENTS[1].VALUE
        )
        if (location?.state?.is_full_fuel) {
          // Chọn hình thức thanh toán khi full fuel
          dataConvert = res.data?.filter(
            (item: any) => item.id === PAYMENTS[0].VALUE
          )
          onSetTypePaymentID(4)
        }
        setListPayment(dataConvert)
      } else {
        if (location?.state?.is_full_fuel) {
          // Chọn hình thức thanh toán khi full fuel
          dataConvert = res.data?.filter(
            (item: any) => item.id === PAYMENTS[0].VALUE
          )
          onSetTypePaymentID(4)
          setListPayment(dataConvert)
        } else {
          setListPayment(res.data)
        }
      }
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const getConfigPercentage = async () => {
    try {
      const res = await getPercentage()
      setConfigPointReceived(res.data.value)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }
  return (
    <PaymentMethodBlock>
      <TitleBlock>{R.strings().title_payments}</TitleBlock>
      <RadioPayment value={typePaymentID}>
        {listPayment?.map((item: any, index: number) => (
          // 2 là chuyển khoản
          <PaymentMethodItem
            key={index}
            className={typePaymentID === item.id ? 'selected' : ''}
            onClick={() => {
              if (isKeepStock && !data?.point && item?.id !== 2) return
              onSetTypePaymentID(item.id)
            }}
          >
            <RadioStyled
              disabled={isKeepStock && !data?.point && item?.id !== 2}
              value={item.id}
            >
              <ContentMethod>
                <div style={{ display: 'flex' }}>
                  {renderIconPayment(item.id)}
                  <div
                    style={{
                      marginLeft: '10px',
                      fontWeight: 500,
                      width: 'auto',
                    }}
                  >
                    {localStorage.getItem('i18nextLng') === 'vi'
                      ? item.name
                      : item.name_en}
                  </div>
                </div>

                <div style={{ color: '#595959' }}>
                  {' '}
                  {localStorage.getItem('i18nextLng') === 'vi'
                    ? item.description
                    : item.description_en}
                </div>
              </ContentMethod>
            </RadioStyled>
          </PaymentMethodItem>
        ))}
      </RadioPayment>

      <div style={{ borderBottom: '1px solid  #CED4DA' }}>
        <TitleBlock>{R.strings().billing_information}</TitleBlock>
        <ValueBlock>
          {R.strings().title_total_price_of_goods} :
          <span>
            {data?.is_full_fuel
              ? R.strings().unknown
              : formatPrice(totalOrderPrice)}
          </span>
        </ValueBlock>
        {data?.is_full_fuel ? (
          <></>
        ) : (
          <ValueBlock>
            {R.strings().title_points_used} :
            <span>
              -{' '}
              {location?.state?.type === 'oil'
                ? formatPrice(
                    data?.reduce(function (total: number, value: any) {
                      return total + value?.point_used
                    }, 0)
                  )
                : formatPrice(data?.point)}
            </span>
          </ValueBlock>
        )}
      </div>
      {data?.is_full_fuel ? (
        <></>
      ) : (
        <ValueBlock color="#262626" colorSpan="#F26522">
          {R.strings().total_payment}:
          <span>
            {location?.state?.type === 'oil'
              ? formatPrice(
                  totalOrderPrice -
                    data?.reduce(function (total: number, value: any) {
                      return total + value?.point_used
                    }, 0)
                )
              : formatPrice(totalOrderPrice - data?.point)}
          </span>
        </ValueBlock>
      )}

      <ValueBlock>
        {R.strings().points_received}:
        <span>
          {data?.is_full_fuel
            ? R.strings().unknown
            : formatPrice(
                ((configPointReceived * totalOrderPrice) / 100).toFixed(0)
              )}
        </span>
      </ValueBlock>

      <WrapButton style={data?.is_full_fuel ? { marginTop: '12px' } : {}}>
        <ButtonOrder onClick={onPayment}>
          {R.strings().title_place_order}
        </ButtonOrder>
      </WrapButton>
    </PaymentMethodBlock>
  )
}
export default Payments
