import { Row } from 'antd'
import React from 'react'
import { ProductCard } from 'common/components/CardProduct'
import { StyledCol } from 'common/components/CardProduct/styled'
import { HeaderBox, HeaderBoxProduct, WrapBox, WrapBoxProduct } from '../styled'
import { useHistory } from 'react-router-dom'
import R from 'assets'
import SkeletonProduct from 'common/components/CardProduct/SkeletonProduct'
import { ROUTER_PATH } from 'common/config'

const SellingProduct: React.FC<TProps> = ({ data, loading }) => {
  const history = useHistory()
  return (
    <WrapBoxProduct>
      <HeaderBoxProduct>{R.strings().title_selling_products}</HeaderBoxProduct>
      {loading ? (
        <Row justify="start">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((item: any, index: number) => (
            <StyledCol xs={12} sm={8} xl={6} key={index}>
              <SkeletonProduct />
            </StyledCol>
          ))}
        </Row>
      ) : (
        <Row justify="start">
          {data?.map((item: any) => (
            <StyledCol xs={12} sm={8} xl={6} key={item.id}>
              <ProductCard
                dataProduct={item}
                onClickProduct={() => {
                  const petrol_id = item?.percent_discount
                    ? item?.petrol_store_id
                    : item?.product_stocks[0]?.petrol_store_id
                  history.push({
                    pathname: `${ROUTER_PATH.DETAIL_PRODUCT}/${item?.id}`,
                    search: `type='oil'&category=${item?.product_category_name}&product_id=${item.id}&petrol_store_id=${petrol_id}&nameProduct=${item.name}`,
                  })
                }}
              />
            </StyledCol>
          ))}
        </Row>
      )}
    </WrapBoxProduct>
  )
}
export default SellingProduct
