import { useState } from 'react'
import BeginForgotPassword from './Begin'
import { Step1ForgotPassword } from './Step1'
import { Step2ForgotPassword } from './Step2'
import { Step3ForgotPassword } from './Step3'

function ForgotPassword(props: any) {
  const [isLoading, setLoading] = useState(false)
  const [userId, setUserId] = useState()
  const [step, setStep] = useState<number>(0)
  const [data, setData] = useState()
  const [phoneSignup, setPhoneSignup] = useState()
  const [time, setTime] = useState<any>(Date.now() + 1000 * 120 + 1000 * 1)
  const [tokenForgot, setTokenForgot] = useState<any>('')

  const convertScreen = (values: any) => {
    switch (values) {
      case 0:
        return (
          <BeginForgotPassword
            setPhoneSignup={setPhoneSignup}
            setStep={setStep}
            setUserId={setUserId}
            setData={setData}
            setTime={setTime}
          />
        )
      case 1:
        return (
          <Step1ForgotPassword
            data={data}
            setStep={setStep}
            phoneSignup={phoneSignup}
            time={time}
            setTime={setTime}
            setTokenForgot={setTokenForgot}
          />
        )
      case 2:
        return (
          <Step2ForgotPassword
            tokenForgot={tokenForgot}
            setTokenForgot={setTokenForgot}
            setStep={setStep}
          />
        )
      case 3:
        return <Step3ForgotPassword setStep={setStep} />
    }
  }
  return <div>{convertScreen(step)}</div>
}

export default ForgotPassword
