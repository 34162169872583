import styled from 'styled-components'
import { Badge, Col } from 'antd'

const ProductBlock = styled.div`
  width: 96%;
  aspect-ratio: 1/1.35;
  background-color: #ffffff;
  border-radius: 15px;
  margin-bottom: 15px;
  /* padding: 2%; */
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  box-shadow: 0px 0px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  cursor: pointer;
  padding: 10px;
`

const BadgeSale = styled(Badge.Ribbon)``

const ImageProduct = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  /* position: relative; */
  /* object-fit: cover; */
  /* margin: 2%; */
`

const NameProduct = styled.div`
  padding: 0em 1em;
  font-size: 16px;
  display: -webkit-box;
  max-height: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`

const PriceProduct = styled.div`
  line-height: 2;
  padding: 0em 1em;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-weight: 500;
  font-size: 16px;
  color: #f26522;
  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`

const StyledCol = styled(Col).attrs(
  (props: { paddingleft: number | undefined }) => ({
    paddingleft: props.paddingleft || 0,
  })
)`
  display: flex;
  justify-content: center;
  margin-left: ${props => props.paddingleft + 'px'};
`
const OriginPrice = styled.span`
  text-decoration-line: line-through;
  padding: 0em 1em;
`
// const OverviewProduct = styled.div`
//   padding: 0 1em;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// `
export {
  ProductBlock,
  ImageProduct,
  NameProduct,
  PriceProduct,
  StyledCol,
  BadgeSale,
  OriginPrice,
  // OverviewProduct,
}
