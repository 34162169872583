import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDataCard, setDataCard } from '../CartSlice'
import { IProductItem, IStoreItem } from '../interface'
import { CheckBoxStyled, NameStore, StoreBlock } from '../styled'
import ProductInCart from './ProductInCart'

type TProps = {
  data: IStoreItem
  indexStore: number
}
const StoreInCart: React.FC<TProps> = ({ data, indexStore }) => {
  const dataCart = useSelector(getDataCard)
  const dispatch = useDispatch()

  const selectAllProductInStore = (value: boolean) => {
    let new_item = {
      ...dataCart[indexStore],
      checkedStore: value,
      product_stocks: dataCart[indexStore].product_stocks?.map((item: any) => ({
        ...item,
        checkedProduct: value,
      })),
    }
    let new_array = [...dataCart]
    new_array[indexStore] = new_item
    dispatch(setDataCard(new_array))
  }

  return (
    <StoreBlock>
      <NameStore>
        <CheckBoxStyled
          checked={data.checkedStore}
          onChange={(e: any) => {
            selectAllProductInStore(e.target.checked)
          }}
        />
        {data.name}
      </NameStore>
      {data.product_stocks?.map((item: IProductItem, index: number) => (
        <ProductInCart
          key={index}
          data={item}
          indexStore={indexStore}
          indexProduct={index}
        />
      ))}
    </StoreBlock>
  )
}

export default StoreInCart
