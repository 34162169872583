export const ROUTER_PATH = {
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/fogot-password',
  NEWS: '/news',
  DETAIL_NEWS: '/detail-news',
  PRODUCT: '/products',
  BUY_GAS: '/buy-gas',
  DETAIL_PRODUCT: '/detail-product',
  CART: '/cart',
  PAYMENT: '/payment',
  ORDER: '/order',
  ACCOUNT: '/account',
  DETAIL_ORDER: '/detail-order',
  STORES: '/stores',
  DETAIL_STORES: '/detail-stores',
  RATE_STORES: '/rate-store',
  INTRODUCE_YOYO: '/introduction-yoyo',
  PAYMENT_PRIVACY_POLICY: '/payment-privacy-policy',
  POLICY: '/policy',
  NOT_FOUND: '/not-found',
  TERM_OF_USE: '/term-of-use',
  SHOPPING_GUIDE: '/shopping-guide',
  REFUND_POLICY: '/refund-policy',

  // đánh giá cho sản phẩm là xăng dầu
  RATE_PRODUCT: '/rate-product',
}
