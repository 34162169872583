import { Row } from 'antd'
import React from 'react'
import { ContentItem, SmallItem } from '../styled'

const DeliveryPolicy = () => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '20px',
        textAlign: 'justify',
        listStyleType: ' upper-roman',
        margin: '20px 0',
      }}
    >
      <Row justify="center" style={{ marginBottom: '10px', width: '100%' }}>
        <b style={{ fontSize: '20px' }}>CHÍNH SÁCH GIAO, NHẬN HÀNG</b>
      </Row>
      <SmallItem>
        <b>1. Phạm vi áp dụng:</b>
        <ContentItem>
          <p>Phạm vi áp dụng: tất cả mọi tỉnh thành trên cả nước.</p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>2. Thời gian giao - nhận hàng</b>
        <ContentItem>
          <p>
            - Đơn hàng sau khi được tiếp nhận, khách hàng trực tiếp tới các cửa
            hàng bán lẻ trên đơn hàng để nhận hàng.
          </p>
          <p>
            - Thời gian lưu giữ đơn hàng thông thường là tối thiểu 30 phút và
            tối đa là 24 tiếng. Tuy nhiên tùy từng thời điểm mà công ty đưa
            thông báo điều chỉnh cho từng đơn hàng và tại mỗi thời điểm theo quy
            định của Bộ Công thương và Bộ tài chính về giá xăng dầu.
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>3. Hình thức giao hàng:</b>
        <ContentItem>
          <p>
            - Hình thức mua lẻ (Thông thường dưới 4000 lít/lần nhưng tùy thời
            điểm Công ty ban hành chính sách quy định về số lượng mua lẻ tại các
            địa điểm xuất hàng): Giao hàng tại chính cửa hàng xăng dầu mà khách
            hàng đặt hàng.
          </p>
          <p>
            - Hình thức mua số lượng lớn (Thông thường quy định trên 4000
            lít/lần, Tuy nhiên tùy thời điểm Công ty ban hành chính sách quy
            định về số lượng mua lẻ tại các địa điểm xuất hàng): giao hàng bằng
            phương tiện đảm bảo phòng cháy chữa cháy tới nơi cho khách hàng
          </p>
          <b>
            * Phân định trách nhiệm của thương nhân, tổ chức cung ứng dịch vụ
            logistics về cung cấp chứng từ hàng hóa trong quá trình giao nhận.
          </b>
          <p style={{ marginTop: '15px' }}>
            * Đơn vị cung cấp dịch vụ logistic có trách nhiệm cung cấp bằng
            chứng an toàn về PCCC, giao nhận hàng hóa (ảnh chụp và chữ ký người
            nhận hàng) tới người mua và người bán khi có yêu cầu.
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>4. Chính sách kiểm hàng:</b>
        <ContentItem>
          <p>
            · Khi nhận hàng quý khách có quyền yêu cầu nhân viên giao hàng cho
            kiểm tra số lượng hàng bằng các hình thức như ca đong hoặc theo bảng
            dung tích xe còn thời hạn kiểm định của Trung tâm kiểm định đo
            lường.
          </p>
          <p>
            · Trường hợp đơn hàng đặt mà bên bán giao không đúng loại sản phẩm
            quý khách có quyền trả hàng và không thanh toán tiền.
          </p>
          <p>
            · Trường hợp quý khách đã thanh toán trước nhưng đơn hàng không đúng
            quý khách yêu cầu hoàn toàn hoặc giao lại đơn mới như đã đặt.
          </p>
          <p>
            · Trong trường hợp yêu cầu hoàn tiền hoặc đổi đơn quý khách liên hệ
            qua Email: sales@yoyojsc.com.vn hoặc số điện thoại 0899.743.888
            chúng tôi cam kết sẽ giải quyết mọi yêu cầu của quý khách.
          </p>
        </ContentItem>
      </SmallItem>
    </div>
  )
}

export default DeliveryPolicy
