import { Steps } from 'antd'
import { useState } from 'react'
import {
  ContentBlock,
  CodeBlock,
  UnderBlock,
  ButtonComplete,
  StepCode,
  FooterCode,
  TextCode,
  SubStep,
} from '../authStyle'
import R from 'assets'
import { ROUTER_PATH } from 'common/config'
import history from 'utils/history'

type Props = {
  setStep: any
}
export const Step3ForgotPassword: React.FC<Props> = ({ setStep }: Props) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  return (
    <ContentBlock>
      <div>
        <CodeBlock>
          <div
            style={{
              margin: '12vh 13vw 0px 13vw',
            }}
          >
            <UnderBlock>
              <img
                src={R.images.image_complete}
                height={'163px'}
                width={'163px'}
                alt="Image Banner "
                loading="eager"
              />
              <TextCode>{R.strings().text_form_success_password}</TextCode>

              <ButtonComplete
                onClick={() => {
                  history.push(ROUTER_PATH.LOGIN)
                }}
              >
                {R.strings().title_login}
              </ButtonComplete>
            </UnderBlock>
          </div>
        </CodeBlock>
        <FooterCode>
          <StepCode direction="horizontal" responsive={false} current={2}>
            <SubStep></SubStep>
            <SubStep></SubStep>
            <SubStep></SubStep>
          </StepCode>
        </FooterCode>
      </div>
    </ContentBlock>
  )
}
