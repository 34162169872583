import { Typography } from 'antd'
import R from 'assets'
import { ROUTER_PATH } from 'common/config'
import React from 'react'
import { Link } from 'react-router-dom'
import { formatPrice } from 'utils/ruleForm'
import {
  ProductBlock,
  ImageProduct,
  NameProduct,
  PriceProduct,
  BadgeSale,
  OriginPrice,
  // OverviewProduct,
} from './styled'
type Props = {
  dataProduct: any
  onClickProduct?: () => void
}
export const InsuranceCard: React.FC<Props> = ({
  dataProduct,
  onClickProduct,
}: Props) => {
  const { Paragraph } = Typography
  return (
    <ProductBlock onClick={onClickProduct}>
      <Link
        to={`${ROUTER_PATH.DETAIL_PRODUCT}/${dataProduct.id}?type=insurrance&product_id=${dataProduct.id}&petrol_store_id=${dataProduct.petrol_store_id}`}
        style={{ color: 'black' }}
      >
        <ImageProduct>
          <img
            style={{
              width: '100%',
              aspectRatio: '1/0.4',
              objectFit: 'cover',
              borderTopLeftRadius: '12px',
              borderTopRightRadius: '12px',
            }}
            src={
              dataProduct.product_media_url
                ? dataProduct.product_media_url
                : R.images.img_product
            }
            alt="Ảnh sản phẩm"
          />
        </ImageProduct>
        <div>
          <NameProduct>
            <Paragraph style={{ margin: '10px 0 0 0' }} ellipsis={{ rows: 2 }}>
              {dataProduct.name}
            </Paragraph>
          </NameProduct>
        </div>
      </Link>
    </ProductBlock>
  )
}
