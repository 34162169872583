import {
  ArrowRightOutlined,
  EnvironmentFilled,
  SearchOutlined,
  StarFilled,
} from '@ant-design/icons'
import { Col, Divider, Row, Spin, Typography } from 'antd'
import R from 'assets'
import { ROUTER_PATH } from 'common/config'
import { useRef } from 'react'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'
import { EmptyComponent } from 'common/components/Empty'
import {
  GasContainer,
  GasLeft,
  GasRight,
  RecordGas,
  RecordStore,
  SearchStore,
} from '../styles'
import { TsgStyle } from 'features/stores/styled'

type Props = {
  active: any
  data: any
  dataStation: any
  loading: boolean
  setChooseGas: any
  setStatus: any
  setIdStation: any
  setActive: any
  onSearchSubmit: (searchKey: string) => void
  geoLocation: boolean
  loadingMore: any
  getMoreData: any
  loadStore: any
}
const ChooseGasoline: React.FC<Props> = ({
  active,
  setActive,
  data,
  loading,
  dataStation,
  setChooseGas,
  onSearchSubmit,
  setStatus,
  setIdStation,
  geoLocation,
  getMoreData,
  loadingMore,
  loadStore,
}) => {
  const scrollRef: any = useRef(null)
  const { Text } = Typography

  const returnStar = (item: any) => {
    return (
      item?.product_reviews?.reduce((a: any, b: any) => a + b?.star || 0, 0) /
      item?.product_reviews?.length
    )
  }

  return (
    <>
      <GasContainer>
        <GasLeft>
          <Row
            style={{
              width: '100%',
              padding: '10px 15px 10px 15px',
            }}
            justify="space-between"
          >
            <div style={{ fontSize: 16, fontWeight: 500 }}>
              {R.strings().title_product}
            </div>
            <div style={{ fontSize: 16, fontWeight: 500 }}>
              {R.strings().market_price}
            </div>
          </Row>
          <div
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              height: '50vh',
              maxHeight: 550,
              padding: '10px 5px 0 0',
            }}
          >
            {data?.map((item: any, index: number) => (
              <RecordGas
                style={
                  active === item?.id ? { backgroundColor: '#feecd9' } : {}
                }
                justify="space-between"
                onClick={() => {
                  setActive(item?.id)
                  setChooseGas(item.id)
                }}
              >
                <Row align="middle">
                  <img height={25} src={R.images.icon_gas} alt="" />
                  <div style={{ padding: '0 0 0 10px' }}>{item.name}</div>
                </Row>
                <div>
                  <Row justify="end" style={{ marginBottom: '6px' }}>
                    <TsgStyle
                      color={'volcano'}
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        history.push({
                          pathname: `${ROUTER_PATH.RATE_PRODUCT}`,
                          state: {
                            product_reviews: item.product_reviews,
                          },
                        })
                      }}
                    >
                      <StarFilled /> &nbsp;{' '}
                      {isNaN(returnStar(item))
                        ? 0
                        : returnStar(item).toFixed(1)}
                      <ArrowRightOutlined />
                    </TsgStyle>
                  </Row>
                  <span>
                    {formatPrice(item.price)} {R.strings().title_unit}
                  </span>
                </div>
              </RecordGas>
            ))}
          </div>
        </GasLeft>
        <GasRight>
          <Spin spinning={loadStore.loadStore}>
            <div style={{ textAlign: 'center' }}>
              <SearchStore
                prefix={<SearchOutlined />}
                placeholder={R.strings().search_store}
                // addonAfter={<Icon type="close-circle-o" />}
                value={dataStation.search}
                onChange={(e: any) => {
                  onSearchSubmit(e.target.value)
                }}
              />
            </div>

            <Divider style={{ margin: '15px 0 5px 0' }} />
            <div
              style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                height: '50vh',
                maxHeight: 550,
                borderRadius: 12,
                marginTop: 5,
                padding: '5px 0px 0 0px',
              }}
              ref={scrollRef}
              onScroll={() => {
                if (
                  scrollRef.current!.scrollTop +
                    scrollRef.current!.clientHeight >=
                  scrollRef.current!.scrollHeight
                ) {
                  let old = scrollRef.current!.scrollTop
                  if (loadingMore) {
                    scrollRef.current!.scrollTop = old - 50
                    getMoreData()
                  }
                }
              }}
            >
              {dataStation.length < 1 ? (
                <div>
                  <EmptyComponent />
                </div>
              ) : (
                dataStation?.map((item: any, index: number) => (
                  <>
                    <RecordStore
                      onClick={() => {
                        setStatus('buy')
                        setIdStation(item.id)
                        history.push({
                          pathname: `${ROUTER_PATH.BUY_GAS}/${item?.id}`,
                        })
                      }}
                    >
                      <div style={{ display: 'flex', flexFlow: 'nowrap' }}>
                        <div>
                          <img src={R.images.icon_store_gas} alt="" />
                        </div>

                        <div
                          style={{
                            width: '100%',
                            padding: '0px 0px 0px 10px',
                            display: 'flex',
                            flexFlow: 'column',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div style={{ fontSize: 15, fontWeight: 500 }}>
                            {item?.petrol_store?.name}
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexFlow: 'nowrap',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Row>
                              {!geoLocation || !item?.distance ? (
                                <></>
                              ) : (
                                <>
                                  <Col>
                                    <div style={{ fontSize: 14 }}>
                                      <EnvironmentFilled
                                        style={{
                                          color: '#F26522',
                                          paddingRight: 5,
                                        }}
                                      />
                                      {item?.distance} km
                                    </div>
                                  </Col>
                                  &nbsp; &nbsp;
                                </>
                              )}

                              <Col>
                                <div style={{ fontSize: 14 }}>
                                  <StarFilled
                                    style={{
                                      color: '#FFC53D',
                                      paddingRight: 5,
                                    }}
                                  />
                                  {
                                    +(item?.petrol_store?.petrol_store_star).toFixed(
                                      1
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                            <Col>
                              <div
                                style={{
                                  fontSize: 15,
                                  fontWeight: 500,
                                  color: '#F26522',
                                }}
                              >
                                {item?.min_price ? (
                                  <>
                                    <Text
                                      delete
                                      style={{ fontSize: 14, color: 'gray' }}
                                    >
                                      {formatPrice(item?.price)}
                                      {R.strings().title_unit}
                                    </Text>
                                    &nbsp;
                                    {/* <br /> */}
                                  </>
                                ) : (
                                  <></>
                                )}
                                {item?.min_price
                                  ? formatPrice(item?.min_price)
                                  : formatPrice(item?.price)}{' '}
                                {R.strings().title_unit}
                              </div>
                            </Col>
                          </div>
                        </div>
                      </div>
                    </RecordStore>
                    <Divider style={{ margin: '10px 0 10px 0' }} />
                  </>
                ))
              )}
            </div>
          </Spin>
          {/* <div
            style={{
              fontSize: '25px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {loadMoreNotification ? <LoadingOutlined /> : <></>}
          </div>

          <div
            style={{
              marginTop: '5px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              setLoadMoreNotification(true)
              let numPage = params.page + 1
              setParams({ ...params, page: numPage })
            }}
          >
            {!loadMoreNotification ? '[ Xem thêm ]' : ''}
          </div> */}
        </GasRight>
      </GasContainer>
    </>
  )
}

export default ChooseGasoline
