import { useState } from 'react'
import BeginSignupScreen from './Begin'
import { Step1SignupScreen } from './Step1'
import { Step2SignupScreen } from './Step2'
import { Step3SignupScreen } from './Step3'

function SignupScreen(props: any) {
  const [userId, setUserId] = useState()
  const [step, setStep] = useState<number>(0)
  const [phoneSignup, setPhoneSignup] = useState()
  const [time, setTime] = useState<any>(Date.now() + 1000 * 120 + 1000 * 1)

  const convertScreen = (values: any) => {
    switch (values) {
      case 0:
        return (
          <BeginSignupScreen
            setStep={setStep}
            setUserId={setUserId}
            setPhoneSignup={setPhoneSignup}
            setTime={setTime}
          />
        )
      case 1:
        return (
          <Step1SignupScreen
            setStep={setStep}
            userId={userId}
            phoneSignup={phoneSignup}
            time={time}
            setTime={setTime}
          />
        )
      case 2:
        return <Step2SignupScreen setStep={setStep} />
      case 3:
        return <Step3SignupScreen setStep={setStep} />
    }
  }
  return <div>{convertScreen(step)}</div>
}

export default SignupScreen
