import { PlusCircleOutlined } from '@ant-design/icons'
import { Popconfirm, Tag } from 'antd'
import R from 'assets'
import {
  GasStationIcon,
  LocationIcon,
  YoYoCoinIcon,
} from 'common/components/Icons'
import { ImageStyled } from 'global-styles'
import React, { useEffect, useState } from 'react'
import { notificationSuccess } from 'utils/notification'
import { formatPrice } from 'utils/ruleForm'
import { deleteAddress, getDefaultAddress, getListAddress } from '../ApiPayment'
import { IAddress, TDataOilItem } from '../interface'
import * as Styled from '../styled'
import ModalUpdateAddress from './ModalUpdateAddress'

type Props = {
  data: TDataOilItem
  dataOil: Array<TDataOilItem>
  setDataOil: React.Dispatch<React.SetStateAction<TDataOilItem[]>>
  indexStore: number
  userState: any
}

const OilItem: React.FC<Props> = ({
  data,
  dataOil,
  setDataOil,
  indexStore,
  userState,
}) => {
  const [isChangeAddress, setIsChangeAddress] = useState<boolean>(false)
  const [selectedAddress, setSelectedAddress] = useState<IAddress>({
    id: 0,
    name: '',
    phone: '',
    address: '',
    is_default: 0,
  })
  const [listAddress, setListAddress] = useState<Array<IAddress>>([])
  const [visibleModal, setVisibleModal] = useState<boolean>(false)
  const [dataUpdate, setDataUpdate] = useState<number | null>(null)

  useEffect(() => {
    requestDefaultAddress()
    requestListAddress()
  }, [])

  //Lấy địa chỉ mặc định
  const requestDefaultAddress = async () => {
    try {
      const res = await getDefaultAddress()
      setSelectedAddress({
        id: res.data?.id,
        name: res.data?.name,
        phone: res.data?.phone,
        address: res.data?.address
          ? res.data?.address +
            ' ' +
            res.data?.ward_name +
            ', ' +
            res.data?.district_name +
            ', ' +
            res.data?.province_name
          : '',
        is_default: res.data?.is_default,
      })
    } catch (error) {
      console.error('Exception ' + error)
    }
  }
  //Lấy danh sách địa chỉ nhà
  const requestListAddress = async () => {
    try {
      const res = await getListAddress({
        search: undefined,
        page: 1,
      })

      const convertData = res.data?.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          phone: item.phone,
          address: item?.address,
          is_default: item.is_default,
        }
      })
      setListAddress(convertData)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const onClickSwitch = (value: boolean) => {
    let new_array = [...dataOil] //clone array data

    if (value) {
      let pointCanUse =
        userState?.point -
        dataOil?.reduce(function (total: number, value: any) {
          return total + value.point_used
        }, 0)
      let record = {
        ...data,
        isCheckedPoint: value,
        point_used:
          parseInt(data.total_price.toFixed()) >= pointCanUse
            ? pointCanUse
            : parseInt(data.total_price.toFixed()),
      }

      new_array[indexStore] = record
    } else {
      new_array[indexStore].isCheckedPoint = value
      let pointCanUse = userState?.point

      new_array = new_array?.map((item: TDataOilItem) => {
        // console.log('pointCanUse: ', pointCanUse)
        if (item.isCheckedPoint) {
          item.point_used =
            parseInt(item.total_price.toFixed()) >= pointCanUse
              ? pointCanUse
              : parseInt(item.total_price.toFixed())
          pointCanUse = pointCanUse - item.point_used
          return {
            ...item,
            // canUsePoint: pointCanUse ? true : false,
          }
        } else {
          return {
            ...item,
            point_used: 0,
            // canUsePoint: pointCanUse ? true : false,
          }
        }
      })
    }
    // console.log('new_array: ', new_array)
    setDataOil(new_array)
  }

  return (
    <Styled.WhiteBlock>
      <Styled.TitleBlock>
        {R.strings().title_delivery_information}
      </Styled.TitleBlock>

      {data.status_home && data.maxAmount >= data.quantity_home ? (
        <Styled.RadioGroup value={data.isGetAtHome}>
          <Styled.SelectionBlock
            className={data.isGetAtHome === 1 ? 'selected' : ''}
            onClick={() => {
              let record = {
                ...data,
                isGetAtHome: 1,
                user_address_id: selectedAddress.id,
              }
              let new_array = [...dataOil]
              new_array[indexStore] = record
              setDataOil(new_array)
            }}
          >
            <Styled.RadioStyled value={1}>
              {R.strings().take_it_at_home}
            </Styled.RadioStyled>
          </Styled.SelectionBlock>
          <Styled.SelectionBlock
            className={data.isGetAtHome === 2 ? 'selected' : ''}
            onClick={() => {
              let record = { ...data, isGetAtHome: 2, user_address_id: 0 }
              let new_array = [...dataOil]
              new_array[indexStore] = record
              setDataOil(new_array)
              setIsChangeAddress(false)
            }}
          >
            <Styled.RadioStyled value={2}>
              {R.strings().pick_up_at_the_store}
            </Styled.RadioStyled>
          </Styled.SelectionBlock>
        </Styled.RadioGroup>
      ) : (
        <></>
      )}

      {isChangeAddress ? (
        <Styled.ChangeAddressBlock>
          <Styled.AddAddressBlock>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setVisibleModal(true)
              }}
            >
              <PlusCircleOutlined style={{ marginRight: '10px' }} />
              {R.strings().add_address}
            </div>
          </Styled.AddAddressBlock>
          {listAddress?.map((item: any, index: number) => (
            <Styled.AddressItem key={index}>
              <Styled.CheckBoxCircle
                style={{ marginRight: '12px' }}
                checked={item.id === selectedAddress.id ? true : false}
                onClick={() => {
                  let record = { ...data, user_address_id: item.id }
                  let new_array = [...dataOil]
                  new_array[indexStore] = record
                  setDataOil(new_array)
                  setSelectedAddress({
                    id: item.id,
                    name: item.name,
                    phone: item.phone,
                    address: item.address,
                    is_default: item.is_default,
                  })
                  setIsChangeAddress(false)
                }}
              />
              <Styled.SelectAddressBlock>
                <div>
                  <div>{item.address}</div>
                  <div style={{ fontWeight: 600 }}>
                    {item.name} | {item.phone}
                    {item.is_default ? (
                      <Styled.DefaultTag>
                        {R.strings().title_default}
                      </Styled.DefaultTag>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <Styled.ButtonUpdateAddress
                    onClick={() => {
                      setDataUpdate(item.id)
                      setVisibleModal(true)
                    }}
                  >
                    {R.strings().title_edit}
                  </Styled.ButtonUpdateAddress>

                  <div
                    style={{
                      border: '1px solid #F26522',
                      backgroundColor: '#F26522',
                      margin: '5px',
                    }}
                  ></div>
                  <Popconfirm
                    title={R.strings().delete_address_question}
                    onConfirm={async () => {
                      try {
                        await deleteAddress(item.id)
                        notificationSuccess(R.strings().delete_successful)
                        requestListAddress()
                      } catch (error) {
                        console.error('Exception ' + error)
                      }
                    }}
                  >
                    <Styled.ButtonUpdateAddress>
                      {R.strings().title_delete}
                    </Styled.ButtonUpdateAddress>
                  </Popconfirm>
                  {/* </>
                  )} */}
                </div>
              </Styled.SelectAddressBlock>
            </Styled.AddressItem>
          ))}
        </Styled.ChangeAddressBlock>
      ) : (
        <Styled.AddressBlock>
          {data.isGetAtHome === 1 ? (
            <>
              <Styled.AddressDetail>
                <LocationIcon style={{ marginRight: '12px' }} />
                {selectedAddress.address ? (
                  <div>
                    <div>{selectedAddress.address}</div>
                    <div style={{ fontWeight: 600 }}>
                      {selectedAddress.name} | {selectedAddress.phone}
                      {selectedAddress.is_default ? (
                        <Tag
                          style={{
                            marginLeft: '5px',
                            borderColor: '#F26522',
                            color: '#F26522',
                          }}
                        >
                          {R.strings().title_default}
                        </Tag>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  R.strings().not_update
                )}
              </Styled.AddressDetail>
              <Styled.ButtonAddress
                onClick={() => {
                  setIsChangeAddress(true)
                }}
              >
                {R.strings().title_change}
              </Styled.ButtonAddress>
            </>
          ) : (
            <Styled.AddressDetail>
              <LocationIcon style={{ marginRight: '12px' }} />
              <div>
                <div>{data.address_store}</div>
                <div style={{ fontWeight: 600 }}>
                  {data.name_store} | {data.phone_store}
                </div>
              </div>
            </Styled.AddressDetail>
          )}
        </Styled.AddressBlock>
      )}

      <Styled.InsuranceInfor tabIndex={-1} id={`add_address_${indexStore}`}>
        <Styled.TitleBlock>
          <GasStationIcon style={{ marginRight: '8px' }} />
          {data.name}
        </Styled.TitleBlock>
        {data.items?.map((item: any, index: number) => (
          <Styled.ProductInfor key={index}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Styled.ImageSymbol>
                <ImageStyled src={item.img_product || R.images.img_product} />
              </Styled.ImageSymbol>
              <div>
                <div>{item.name_product}</div>
                <div id="price-product-small">
                  {item.sale_price
                    ? formatPrice(item.sale_price)
                    : formatPrice(item.price)}{' '}
                  đ
                </div>
              </div>
            </div>

            <div id="price-product">
              {item.sale_price
                ? formatPrice(item.sale_price)
                : formatPrice(item.price)}{' '}
              đ
            </div>
            <div>x{item.amount}</div>
          </Styled.ProductInfor>
        ))}
      </Styled.InsuranceInfor>

      {userState?.point && data.canUsePoint ? (
        <Styled.UsePoint>
          <Styled.WrapPoint>
            <YoYoCoinIcon style={{ marginRight: '10px' }} />
            {formatPrice(
              data?.total_price > userState?.point
                ? userState?.point
                : data?.total_price
            )}{' '}
            {R.strings().points}
            {/* {R.strings().use} {formatPrice(userState?.point)}{' '}
            {R.strings().points} */}
          </Styled.WrapPoint>
          <Styled.SwitchStyled
            checked={data.isCheckedPoint}
            onChange={(value: boolean) => onClickSwitch(value)}
          />
        </Styled.UsePoint>
      ) : (
        <></>
      )}

      <Styled.TotalPriceBlock>
        <Styled.WrapPrice>
          <Styled.ValueBlock>
            {R.strings().title_total_price_of_goods} :
            <span>{formatPrice(data.total_price)} đ</span>
          </Styled.ValueBlock>
          <Styled.ValueBlock>
            {R.strings().title_points_used} :
            <span>{formatPrice(data.point_used)} đ</span>
          </Styled.ValueBlock>
        </Styled.WrapPrice>
      </Styled.TotalPriceBlock>

      <ModalUpdateAddress
        visibleModal={visibleModal}
        dataUpdate={dataUpdate}
        requestListAddress={requestListAddress}
        onCancel={() => {
          setVisibleModal(false)
          setDataUpdate(null)
        }}
      />
    </Styled.WhiteBlock>
  )
}
export default OilItem
