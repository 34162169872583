import Icon from '@ant-design/icons'

const LocationSvg = () => (
  <svg
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.166016 7.9229C0.166016 3.61052 3.76965 0.125 8.12862 0.125C12.4999 0.125 16.1035 3.61052 16.1035 7.9229C16.1035 10.096 15.3132 12.1134 14.0124 13.8234C12.5774 15.7095 10.8087 17.3529 8.81777 18.6429C8.36212 18.941 7.9509 18.9635 7.45081 18.6429C5.44858 17.3529 3.67985 15.7095 2.25711 13.8234C0.955375 12.1134 0.166016 10.096 0.166016 7.9229ZM5.50434 8.16573C5.50434 9.61037 6.68318 10.7466 8.1286 10.7466C9.57498 10.7466 10.7652 9.61037 10.7652 8.16573C10.7652 6.73233 9.57498 5.5408 8.1286 5.5408C6.68318 5.5408 5.50434 6.73233 5.50434 8.16573Z"
      fill="#F26522"
    />
  </svg>
)

const LocationIcon = (props: any) => <Icon component={LocationSvg} {...props} />
export default LocationIcon
