import { ROUTER_PATH } from 'common/config'
import { AccountScreen } from 'features/account'
import { CartScreen } from 'features/cart'
import { HomeScreen } from 'features/home'
import { DetailNewsScreen, NewsScreen } from 'features/news'
import { DetailOrderScreen } from 'features/order'
import { PaymentScreen } from 'features/payment'
import BuyGasoline from 'features/product/components/BuyGas'
import DetailProductScreen from 'features/product/DetailProduct'
import ProductScreen from 'features/product/Product'
import RatePetroleumScreen from 'features/product/Rate.Petroleum'
import {
  DetailStoreScreen,
  RateStoreScreen,
  StoreScreen,
} from 'features/stores'
import {
  IntroduceYoyo,
  PaymentPrivacyPolicy,
  RefundPolicy,
  ShoppingGuide,
  TermsOfUse,
} from 'features/web-view'
import Policy from 'features/web-view/Policy'
import React from 'react'

interface RouterProps {
  path: string
  component: React.FC | React.Component | any
  param?: any
  exact: boolean
}

const routerPage: Array<RouterProps> = [
  {
    path: ROUTER_PATH.HOME,
    component: HomeScreen,
    exact: true,
  },
  {
    path: ROUTER_PATH.NEWS,
    component: NewsScreen,
    exact: true,
  },
  {
    path: ROUTER_PATH.DETAIL_NEWS + '/:id',
    component: DetailNewsScreen,
    exact: true,
  },
  {
    path: ROUTER_PATH.PRODUCT,
    component: ProductScreen,
    exact: true,
  },
  {
    path: ROUTER_PATH.DETAIL_PRODUCT + '/:id',
    component: DetailProductScreen,
    exact: true,
  },
  {
    path: ROUTER_PATH.STORES,
    component: StoreScreen,
    exact: true,
  },
  {
    path: ROUTER_PATH.DETAIL_STORES + '/:id',
    component: DetailStoreScreen,
    exact: true,
  },
  {
    path: ROUTER_PATH.RATE_STORES + '/:id',
    component: RateStoreScreen,
    exact: true,
  },
  {
    path: ROUTER_PATH.INTRODUCE_YOYO,
    component: IntroduceYoyo,
    exact: true,
  },
  {
    path: ROUTER_PATH.PAYMENT_PRIVACY_POLICY,
    component: PaymentPrivacyPolicy,
    exact: true,
  },
  {
    path: ROUTER_PATH.POLICY,
    component: Policy,
    exact: true,
  },
  {
    path: ROUTER_PATH.SHOPPING_GUIDE,
    component: ShoppingGuide,
    exact: true,
  },
  {
    path: ROUTER_PATH.TERM_OF_USE,
    component: TermsOfUse,
    exact: true,
  },
  {
    path: ROUTER_PATH.REFUND_POLICY,
    component: RefundPolicy,
    exact: true,
  },

  {
    path: ROUTER_PATH.BUY_GAS + '/:id',
    component: BuyGasoline,
    exact: true,
  },
  {
    path: ROUTER_PATH.RATE_PRODUCT,
    component: RatePetroleumScreen,
    exact: true,
  },
]
const privateRouter: Array<RouterProps> = [
  {
    path: ROUTER_PATH.CART,
    component: CartScreen,
    exact: true,
  },
  {
    path: ROUTER_PATH.PAYMENT,
    component: PaymentScreen,
    exact: true,
  },
  {
    path: ROUTER_PATH.ACCOUNT,
    component: AccountScreen,
    exact: false,
  },
  {
    path: ROUTER_PATH.DETAIL_ORDER + '/:id',
    component: DetailOrderScreen,
    exact: true,
  },
]

export { routerPage, privateRouter }
