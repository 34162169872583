import Icon from '@ant-design/icons'
import images from 'assets/images'
// <svg
//   width="50"
//   height="50"
//   viewBox="0 0 50 50"
//   fill="none"
//   xmlns="http://www.w3.org/2000/svg"
// >
//   <path
//     d="M32.7877 9.48108L32.1375 7.36696C31.7818 6.23713 31.0283 6 30.458 6H18.5419C17.9716 6 17.2181 6.23713 16.8624 7.36599L16.1762 9.48099L15.7397 10.8282H17.1498L17.589 9.48099L18.1467 7.77187C18.2806 7.3471 18.4018 7.3471 18.5419 7.3471H30.4581C30.5982 7.3471 30.7195 7.3471 30.8532 7.77284L31.375 9.48099L31.7864 10.8282H33.2027L32.7877 9.48108Z"
//     fill="#375E7D"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M6.75854 42.504H41.9079L44.608 38.0692V17.9646L24.5 15.8779L4.39209 17.9646V38.0692L6.75854 42.504Z"
//     fill="#F9F7F8"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M7.72119 10.1541L9.81299 11.286L12.1717 10.1541V8.30103C12.1717 7.27948 11.3375 6.44531 10.3161 6.44531H9.57691C8.55545 6.44531 7.72128 7.27948 7.72128 8.30103V10.1541H7.72119Z"
//     fill="#FBC58B"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M36.8286 8.30103V10.1541L39.2669 11.3887L41.279 10.1541V8.30103C41.279 7.27948 40.4423 6.44531 39.4234 6.44531H38.6817C37.6628 6.44531 36.8286 7.27948 36.8286 8.30103Z"
//     fill="#FFA39E"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M3.25773 44.4141H45.4088C45.7476 44.4141 46.0274 44.1369 46.0274 43.7955V41.4162V39.4887C46.0274 38.7084 45.3883 38.0693 44.608 38.0693H43.3273C42.5471 38.0693 41.9079 38.7084 41.9079 39.4887V40.7976H6.75859V39.4887C6.75859 38.7084 6.11954 38.0693 5.3367 38.0693H4.39214H4.0585C3.2783 38.0693 2.63916 38.7084 2.63916 39.4887V41.4162V43.7955C2.63916 44.1369 2.91637 44.4141 3.25773 44.4141Z"
//     fill="#FA8C16"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M23.541 30.5952C24.1364 30.5952 24.2982 30.7902 24.1621 31.3498L23.0995 35.7208C22.8942 36.5755 23.3562 36.3316 23.8258 35.7208L28.8025 29.2169C29.2696 28.6061 29.362 28.3237 28.5406 28.3237H25.3042C24.7318 28.3237 24.6625 28.2287 24.7909 27.7025L25.8893 23.1955C26.187 21.9712 25.6301 22.5847 25.163 23.1981L20.1838 29.6994C19.7167 30.3103 19.6551 30.5952 20.4482 30.5952H23.541Z"
//     fill="#FFA940"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M4.39209 20.8444H41.1661V40.7976H41.9078V39.4886C41.9078 38.7083 42.5469 38.0693 43.3272 38.0693H44.6079V17.9646H40.8838H4.39209V20.8444Z"
//     fill="#E5E4EC"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M44.0252 41.4162V43.7955C44.0252 44.1369 43.748 44.4141 43.4066 44.4141H45.4086C45.7474 44.4141 46.0272 44.1369 46.0272 43.7955V41.4162V39.4887C46.0272 38.7084 45.3881 38.0693 44.6078 38.0693H43.3271C43.2038 38.0693 43.0833 38.0847 42.9678 38.1156C43.5761 38.2747 44.0252 38.8317 44.0252 39.4888V40.7977V41.4162Z"
//     fill="#E37A04"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M5.23642 14.3483L25.6022 15.878L43.7634 14.3483V12.2513C43.7634 11.0989 42.8189 10.1543 41.6665 10.1543H41.279C31.5748 10.1543 21.873 10.1543 12.1713 10.1543H7.72082H7.33322C6.1808 10.1543 5.23633 11.0989 5.23633 12.2513V14.3483H5.23642Z"
//     fill="#FFA940"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M40.8839 14.3483H43.7636V12.2513C43.7636 11.0989 42.8191 10.1543 41.6667 10.1543H41.2792H37.3574C38.5098 10.1543 39.4543 11.0989 39.4543 12.2513V14.3483H40.8839Z"
//     fill="#FA8C16"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M4.39203 17.9646H44.6078H45.1905C45.9168 17.9646 46.5457 17.5334 46.8331 16.9148C46.9408 16.6838 46.9999 16.4271 46.9999 16.1576C46.9999 15.8882 46.9408 15.6314 46.8331 15.4005C46.5456 14.7819 45.9168 14.3481 45.1905 14.3481H43.7635H5.23648H3.80685C2.8136 14.3482 2 15.1618 2 16.1577C2 17.1511 2.8136 17.9647 3.80685 17.9647H4.39203V17.9646Z"
//     fill="#FA8C16"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M40.8838 17.9646H44.6079H45.1905C45.9168 17.9646 46.5457 17.5334 46.8331 16.9148C46.9409 16.6838 46.9999 16.4271 46.9999 16.1576C46.9999 15.8882 46.9409 15.6314 46.8331 15.4005C46.5456 14.7819 45.9168 14.3481 45.1905 14.3481H43.7635H40.8838C41.6076 14.3481 42.2363 14.7819 42.5238 15.4005C42.6317 15.6314 42.6906 15.8882 42.6906 16.1576C42.6906 16.4271 42.6316 16.6838 42.5238 16.9148C42.2364 17.5334 41.6076 17.9646 40.8838 17.9646Z"
//     fill="#E37A04"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M10.2101 10.1541H12.171V8.30103C12.171 7.27948 11.3368 6.44531 10.3153 6.44531H9.57617C9.36313 6.44531 9.15781 6.48126 8.96533 6.54797C9.68911 6.80206 10.2101 7.49253 10.2101 8.30103V10.1541Z"
//     fill="#FB8500"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M39.318 10.1541H41.2789V8.30103C41.2789 7.27948 40.4422 6.44531 39.4232 6.44531H38.6815C38.4685 6.44531 38.2632 6.48126 38.0732 6.54797C38.7945 6.80206 39.318 7.49253 39.318 8.30103V10.1541Z"
//     fill="#FF4D4F"
//   />
//   <path
//     d="M39.0486 24.9499H38.2337V24.1348C38.2337 23.7627 37.9322 23.4612 37.5601 23.4612C37.1881 23.4612 36.8865 23.7627 36.8865 24.1348V24.9499H36.074C35.7019 24.9499 35.4004 25.2514 35.4004 25.6235C35.4004 25.9955 35.7019 26.2971 36.074 26.2971H36.8865V27.1122C36.8865 27.4842 37.1881 27.7858 37.5601 27.7858C37.9322 27.7858 38.2337 27.4842 38.2337 27.1122V26.2971H39.0486C39.4207 26.2971 39.7222 25.9955 39.7222 25.6235C39.7221 25.2514 39.4207 24.9499 39.0486 24.9499Z"
//     fill="#375E7D"
//   />
//   <path
//     d="M12.0072 26.2971H9.44312C9.07108 26.2971 8.76953 25.9956 8.76953 25.6235C8.76953 25.2515 9.07108 24.95 9.44312 24.95H12.0072C12.3793 24.95 12.6808 25.2515 12.6808 25.6235C12.6807 25.9955 12.3792 26.2971 12.0072 26.2971Z"
//     fill="#375E7D"
//   />
// </svg>
const RescueSvg = () => <img height={60} src={images.xang} alt="xang" />

const RescueIcon = (props: any) => <Icon component={RescueSvg} {...props} />
export default RescueIcon
