import Icon from '@ant-design/icons'

const PasswordColor = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0108 12.601C15.4248 12.601 15.7608 12.265 15.7608 11.851V9.99902C15.7608 9.58502 15.4248 9.24902 15.0108 9.24902H9.31876C8.99476 8.18202 8.01276 7.39802 6.84076 7.39802C5.40676 7.39802 4.23976 8.56502 4.23976 9.99902C4.23976 11.434 5.40676 12.601 6.84076 12.601C8.01276 12.601 8.99476 11.817 9.31876 10.749H11.4308V11.851C11.4308 12.265 11.7668 12.601 12.1808 12.601C12.5948 12.601 12.9308 12.265 12.9308 11.851V10.749H14.2608V11.851C14.2608 12.265 14.5968 12.601 15.0108 12.601ZM5.66576 -0.000976562H14.3348C17.7228 -0.000976562 19.9998 2.37702 19.9998 5.91602V14.083C19.9998 17.622 17.7228 19.999 14.3338 19.999H5.66576C2.27676 19.999 -0.000244141 17.622 -0.000244141 14.083V5.91602C-0.000244141 2.37702 2.27676 -0.000976562 5.66576 -0.000976562ZM5.73886 9.99992C5.73886 9.39192 6.23386 8.89792 6.84086 8.89792C7.44786 8.89792 7.94286 9.39192 7.94286 9.99992C7.94286 10.6069 7.44786 11.1009 6.84086 11.1009C6.23386 11.1009 5.73886 10.6069 5.73886 9.99992Z"
      fill="#F26522"
    />
  </svg>
)

const PasswordColorIcon = (props: any) => (
  <Icon component={PasswordColor} {...props} />
)
export default PasswordColorIcon
