import Icon from '@ant-design/icons'

const MenuSvg = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1H10"
      stroke="#F26522"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M1 13H7"
      stroke="#F26522"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M1 7H15"
      stroke="#F26522"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

const MenuIcon = (props: any) => <Icon component={MenuSvg} {...props} />
export default MenuIcon
