import Icon from '@ant-design/icons'

const CoinColorSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.8"
      d="M15.8335 30.333C23.8416 30.333 30.3335 23.8411 30.3335 15.833C30.3335 7.82488 23.8416 1.33301 15.8335 1.33301C7.82537 1.33301 1.3335 7.82488 1.3335 15.833C1.3335 23.8411 7.82537 30.333 15.8335 30.333Z"
      fill="#F26522"
    />
    <path
      d="M15.8335 28.333C22.7371 28.333 28.3335 22.7366 28.3335 15.833C28.3335 8.92945 22.7371 3.33301 15.8335 3.33301C8.92994 3.33301 3.3335 8.92945 3.3335 15.833C3.3335 22.7366 8.92994 28.333 15.8335 28.333Z"
      fill="#FE941E"
    />
    <path
      d="M15.9062 1.33301C16.0682 1.9936 16.1834 2.66475 16.2512 3.34151C18.5597 3.41845 20.8017 4.1335 22.7285 5.40731C24.6553 6.68113 26.1914 8.46389 27.1665 10.5578C28.1415 12.6516 28.5174 14.9747 28.2523 17.2692C27.9872 19.5637 27.0915 21.7399 25.6647 23.5563C24.2379 25.3726 22.3357 26.7582 20.1692 27.5591C18.0027 28.36 15.6567 28.5449 13.3915 28.0934C11.1263 27.642 9.03048 26.5717 7.33663 25.0014C5.64277 23.431 4.41714 21.4221 3.79573 19.1975C3.1455 19.2865 2.49002 19.3318 1.83373 19.333C1.80923 19.333 1.78373 19.333 1.76123 19.333C2.41645 21.9671 3.79785 24.3643 5.74827 26.252C7.69869 28.1397 10.1398 29.4421 12.7939 30.0109C15.4479 30.5797 18.2083 30.3921 20.7611 29.4695C23.3138 28.5469 25.5563 26.9263 27.2334 24.7921C28.9105 22.6579 29.9549 20.0958 30.2478 17.3973C30.5406 14.6988 30.0702 11.9723 28.89 9.52801C27.7097 7.08371 25.8671 5.01976 23.5718 3.57099C21.2764 2.12223 18.6205 1.34682 15.9062 1.33301Z"
      fill="#F26522"
    />
    <path
      d="M15.8334 28.3333C19.0973 28.3105 22.2228 27.012 24.5419 24.7153C26.8611 22.4186 28.1899 19.3058 28.2444 16.0424C28.2989 12.7789 27.0748 9.62352 24.8337 7.25062C22.5926 4.87773 19.5122 3.47555 16.2509 3.34375C16.3039 3.83851 16.3315 4.33566 16.3334 4.83325C16.3329 8.33865 15.0625 11.7252 12.7574 14.3661C10.4523 17.007 7.26857 18.7234 3.79541 19.1978C4.52985 21.8257 6.10399 24.1411 8.27758 25.7906C10.4512 27.4401 13.1048 28.3331 15.8334 28.3333Z"
      fill="#FB8500"
    />
    <path
      d="M19.2232 22.6662H13.6455V21.8749L15.1427 21.5956C15.2772 21.5749 15.3806 21.5258 15.453 21.4482C15.5254 21.3706 15.5616 21.2646 15.5616 21.1302V18.0194L12.0785 11.9529C12.0216 11.8547 11.9544 11.7771 11.8768 11.7202C11.7992 11.6633 11.7035 11.622 11.5898 11.5961L10.6123 11.3556V10.5488H15.3522V11.3556L13.9248 11.6426L16.7641 16.7704L19.3861 11.6349L17.9355 11.3556V10.5488H21.9306V11.3556L20.9764 11.5728C20.8626 11.5987 20.7644 11.6401 20.6816 11.6969C20.5989 11.7487 20.5317 11.8262 20.4799 11.9297L17.3071 18.0271V21.1302C17.3071 21.2646 17.3407 21.3706 17.4079 21.4482C17.4803 21.5258 17.5864 21.5749 17.726 21.5956L19.2232 21.8749V22.6662Z"
      fill="white"
    />
  </svg>
)

const CoinColorIcon = (props: any) => (
  <Icon component={CoinColorSvg} {...props} />
)
export default CoinColorIcon
