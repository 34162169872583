import R from 'assets'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { WebSocket } from 'services/WebSocket'
import history from 'utils/history'
import { notificationSuccess } from 'utils/notification'
import { getAllListOrder } from './ApiOrder'
import OrderList from './components/OrderList'
import { TabPaneStyled, TabsStyled } from './styled'
type Params = {
  order_status?: string
  page: number
  limit: number
}

const OrderScreen = () => {
  const [statusOrder, setStatusOrder] = useState<string>('1')

  const location: any = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<any>()
  const [distance, setDistance] = useState<any>()
  const deadline = Date.now() + distance
  const [param, setParam] = useState<Params>({
    page: 1,
    limit: 12,
    order_status: '1',
  })
  const [pages, setPages] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  })

  useEffect(() => {
    if (location?.state?.type) {
      setStatusOrder(location?.state?.type)
      setParam({
        ...param,
        order_status: location?.state?.type,
      })
    }
  }, [])

  useEffect(() => {
    ListOrder()
  }, [param, location?.state?.type])

  const ListOrder = async () => {
    setLoading(true)
    try {
      const res = await getAllListOrder(param)
      setDataSource(res.data.listOrderAll)

      setPages({
        ...pages,
        current: res.meta?.pagination?.page,
        total: res.meta?.pagination?.totalItems,
        pageSize: res.meta?.pagination?.limit,
      })
    } catch (error) {
      console.error('Exception ' + error)
    }
    setLoading(false)
  }

  React.useEffect(() => {
    WebSocket.socketClient?.on(`customer_notification`, async (datas: any) => {
      if (datas?.data?.type_notification?.id === 55) {
        notificationSuccess('Thanh toán thành công!')
        ListOrder()
      }
    })
  }, [])

  return (
    <div style={{ padding: '0' }}>
      <TabsStyled
        type="card"
        activeKey={statusOrder}
        onChange={(key: string) => {
          setStatusOrder(key)
          setParam({
            ...param,
            order_status: key,
          })
        }}
        onTabClick={() => {
          history.push({
            pathname: window.location.pathname,
          })
        }}
      >
        <TabPaneStyled
          tab={
            <div style={{ fontWeight: 500 }}>{R.strings().pending_order}</div>
          }
          key={1}
        >
          <OrderList
            statusOrder={statusOrder}
            loading={loading}
            dataSource={dataSource}
          />
        </TabPaneStyled>
        <TabPaneStyled
          tab={
            <div style={{ fontWeight: 500 }}>{R.strings().confirm_order}</div>
          }
          key={2}
        >
          <OrderList
            statusOrder={statusOrder}
            loading={loading}
            dataSource={dataSource}
          />
        </TabPaneStyled>
        <TabPaneStyled
          tab={
            <div style={{ fontWeight: 500 }}>{R.strings().complete_order}</div>
          }
          key={4}
        >
          <OrderList
            statusOrder={statusOrder}
            loading={loading}
            dataSource={dataSource}
          />
        </TabPaneStyled>
        <TabPaneStyled
          tab={
            <div style={{ fontWeight: 500 }}>{R.strings().cancel_order}</div>
          }
          key={5}
        >
          <OrderList
            statusOrder={statusOrder}
            loading={loading}
            dataSource={dataSource}
          />
        </TabPaneStyled>
        <TabPaneStyled
          tab={
            <div style={{ fontWeight: 500 }}>{R.strings().reject_order}</div>
          }
          key={6}
        >
          <OrderList
            statusOrder={statusOrder}
            loading={loading}
            dataSource={dataSource}
          />
        </TabPaneStyled>
      </TabsStyled>
      {/* </Spin> */}
    </div>
  )
}

export default OrderScreen
