import { ApiClient } from 'services/ApiService'

type TUpdate = {
  petrol_store_id: number
  product_stock_id: number
  amount: number
}

export const getCart = (payload: any) =>
  ApiClient.get('/customer/cart/list-cart-full', payload)

export const updateProduct = (id: number, data: TUpdate) =>
  ApiClient.put(`/customer/cart/${id}`, data)

export const deleteAllProduct = () =>
  ApiClient.delete('/customer/cart/all-cart', {})

export const deleteProduct = (payload: any) =>
  ApiClient.delete('/customer/cart', payload)
