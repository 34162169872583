export const isStrongPassword = (pass: string) => {
  // if (!pass || typeof pass != 'string') return false;
  return /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W])(?!.*['"]).{8,}$/.test(
    pass
  )
}
export function formatPrice(num: any) {
  if (num === null || num === undefined || Number.isNaN(parseFloat(num)))
    return ''
  var result = num.toString().replace(/,/g, '')

  return result.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function enterNumbersOnly(value: any, minValue: any) {
  if (!value) return minValue ? minValue : 0
  var result = value?.replace(/[^\w\s]/gi, '')
  return result
}

export function onlyNumbers(value: any) {
  if (value === null || value === undefined || value == 0) return ''
  var result = value?.replace(/[^\w\s]/gi, '')
  return result
}

export function checkNumbersOnly(num: any) {
  if (num === null || num === undefined || Number.isNaN(parseFloat(num)))
    return ''
  const REGEX = /^\d+$/
  return REGEX.test(num)
}

export function checkDecimal(num: any) {
  if (num === null || num === undefined || Number.isNaN(parseFloat(num)))
    return ''
  const REGEX = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/
  return REGEX.test(num)
}

export const parseFloatComas = (value: number) => {
  if (!value) return 0
  return parseFloat(value.toString().replace(/,/g, ''))
}

export const parseIntComas = (value: number) => {
  if (!value) return 0
  return parseInt(value.toString().replace(/,/g, ''))
}
export const maxLength = (maxLength: number, msg: string) => (
  value: string | undefined | null
) => (value && value.length > maxLength ? msg : undefined)

export const minLength4 = (value: string | undefined | null) =>
  value && value.length < 4 ? 'Must be' : undefined

export const minValue = (min: number) => (value: number) =>
  value && value < min ? `Must be at least ${min}` : undefined

export const number = (msg: string) => (value: string | undefined | null) =>
  value && isNaN(Number(value)) ? msg : undefined

export const isEmail = (value: string | undefined | null) => {
  if (!value) return false
  return !/^[a-z][a-z0-9%_/.]{3,32}@[a-z0-9]{3,}(\.[a-z]{3,4}){1,2}$/i.test(
    value
  )
}

export const strongPassword = (value: string | undefined | null) =>
  value &&
  !/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W])(?!.*['"]).{8,}$/.test(value)
    ? 'Must like Abc@1234'
    : undefined

export const normalizePhone = (value: string | undefined | null) => {
  if (!value) {
    return value
  }

  const onlyNumber = value.replace(/[^\d]/g, '')
  if (onlyNumber.length <= 3) {
    return onlyNumber
  }
  if (onlyNumber.length <= 7) {
    return `${onlyNumber.slice(0, 4)}-${onlyNumber.slice(4)}`
  }
  return `${onlyNumber.slice(0, 4)}-${onlyNumber.slice(
    4,
    7
  )}-${onlyNumber.slice(7, 11)}`
}

// export function checkValidateEmail(value: any) {
//   return /^[w-.]+@([w-]+.)+[w-]{2,4}$/.test(value)
// }

export function removeAccents(str: string) {
  var AccentsMap = [
    'aàảãáạăằẳẵắặâầẩẫấậ',
    'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
    'dđ',
    'DĐ',
    'eèẻẽéẹêềểễếệ',
    'EÈẺẼÉẸÊỀỂỄẾỆ',
    'iìỉĩíị',
    'IÌỈĨÍỊ',
    'oòỏõóọôồổỗốộơờởỡớợ',
    'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
    'uùủũúụưừửữứự',
    'UÙỦŨÚỤƯỪỬỮỨỰ',
    'yỳỷỹýỵ',
    'YỲỶỸÝỴ',
  ]
  for (var i = 0; i < AccentsMap.length; i++) {
    var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g')
    var char = AccentsMap[i][0]
    str = str.replace(re, char).replace(/\s/g, '')
  }
  return str
}

export function injectObject2String(str: string, obj: any) {
  if (obj || obj === null) {
    var keys = Object.keys(obj)
    keys.forEach((key: string) => {
      str = str?.replace(`{{${key}}}`, obj[key])
    })
    return str
  }
  return ''
}

export const EMAIL_REGEX = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
