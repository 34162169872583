import { create } from 'apisauce'
import R from 'assets'
import { ROUTER_PATH, SESSION_KEY } from 'common/config'
import Cookie from 'js-cookie'
import queryString from 'query-string'
import swal from 'sweetalert'
import { API_STATUS } from 'utils/constants'
import { notificationError } from './../utils/notification'

const getLanguage = async () => {
  const lang = await localStorage.getItem('i18nextLng')
  return lang
}
const createAPI = () => {
  const APIInstant = create({
    baseURL: process.env.REACT_APP_ECOMMERCE_URL
      ? process.env.REACT_APP_ECOMMERCE_URL
      : process.env.REACT_APP_REQUEST_URL,

    // timeout: 20000,
    headers: { 'Content-Type': 'application/json' },
  })

  APIInstant.setHeader('token', Cookie.get(SESSION_KEY.SESSION) || '')
  APIInstant.setHeader(
    'token-reset-password',
    Cookie.get(SESSION_KEY.SESSION_PASSWORD) || ''
  )

  APIInstant.axiosInstance.interceptors.request.use(
    async config => {
      config.headers.token = Cookie.get(SESSION_KEY.SESSION)
      config.headers.language = await getLanguage()
      return config
    },
    error => Promise.reject(error)
  )
  APIInstant.axiosInstance.interceptors.response.use(
    response => {
      // console.log('response: ', response)
      return response
    },
    async (error: any) => {
      const err = await error?.response
      // console.log('err: ', err)
      // debugger
      if (!err || err.status === API_STATUS.SERVER) {
        if (window.location.pathname !== ROUTER_PATH.NOT_FOUND) {
          // window.location.pathname = ROUTER_PATH.NOT_FOUND
          return
        }
      }
      if (err.status === API_STATUS.UNAUTHORIZED) {
        // Cookie.remove(SESSION_KEY.SESSION)
        if (window.location.pathname !== ROUTER_PATH.LOGIN) {
          swal({
            title: R.strings().notification,
            text: R.strings().err_login,
            icon: 'error',
          }).then(function (isConfirm) {
            if (isConfirm) {
              const link_old = window.location.pathname

              window.location.hash = link_old
              window.location.search = link_old
              window.location.pathname = ROUTER_PATH.LOGIN
            }
          })
        }
      } else {
        notificationError(err?.data?.message)
      }
      return Promise.reject(err)
    }
  )
  return APIInstant
}
const axiosInstance = createAPI()

/* Support function */
function handleResult(api: any) {
  return api.then((res: any) => {
    if (!res?.data?.status) {
      notificationError(res?.data?.message)
      return Promise.reject(res?.data)
    }
    return Promise.resolve(res?.data)
  })
}

function parseUrl(url: string, query: any) {
  return queryString.stringifyUrl({ url: url, query })
}

export const ApiClient = {
  get: (url: string, payload?: any) =>
    handleResult(axiosInstance.get(parseUrl(url, payload))),
  post: (url: string, payload: any, config?: any) =>
    handleResult(axiosInstance.post(url, payload, config)),
  put: (url: string, payload?: any) =>
    handleResult(axiosInstance.put(url, payload)),
  patch: (url: string, payload?: any) =>
    handleResult(axiosInstance.patch(url, payload)),
  delete: (url: string, payload: any) =>
    handleResult(axiosInstance.delete(url, {}, { data: payload })),
}

export default axiosInstance
