import { Avatar, Image, List, Rate, Row } from 'antd'
import R from 'assets'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import {
  AboveBlock,
  ContainerRate,
  MainBlock,
  ProgressStyle,
} from '../stores/styled'

const RatePetroleumScreen = () => {
  const location: any = useLocation()
  const product_reviews = location.state?.product_reviews
  const caculateStar = location.state?.product_reviews?.reduce(
    (a: any, b: any) => a + b?.star || 0,
    0
  )

  return (
    <ContainerRate>
      <AboveBlock>
        <div style={{ width: 260, textAlign: 'center', margin: '0 25px 0 0' }}>
          <h4 style={{ margin: 0, fontWeight: 600 }}>
            {isNaN(caculateStar / product_reviews?.length)
              ? 0
              : (caculateStar / product_reviews?.length)?.toFixed(1)}
            /5
          </h4>
          <div>
            ({product_reviews?.length} {R.strings().evaluate})
          </div>
          <Rate
            allowHalf
            disabled
            value={+(caculateStar / product_reviews?.length)?.toFixed(1)}
          ></Rate>
        </div>
        <div style={{ width: '100%', display: 'flex', flexFlow: 'nowrap' }}>
          <div style={{ width: 55 }}>
            <div>{'5 ' + R.strings().star}</div>
            <div>{'4 ' + R.strings().star}</div>
            <div>{'3 ' + R.strings().star}</div>
            <div>{'2 ' + R.strings().star}</div>
            <div>{'1 ' + R.strings().star}</div>
          </div>
          <div style={{ width: '100%' }}>
            <ProgressStyle
              percent={
                (product_reviews?.filter((item: any) => item.star == 5)
                  ?.length *
                  100) /
                product_reviews?.length
              }
            />
            <ProgressStyle
              percent={
                (product_reviews?.filter((item: any) => item.star == 4)
                  ?.length *
                  100) /
                product_reviews?.length
              }
            />
            <ProgressStyle
              percent={
                (product_reviews?.filter((item: any) => item.star == 3)
                  ?.length *
                  100) /
                product_reviews?.length
              }
            />
            <ProgressStyle
              percent={
                (product_reviews?.filter((item: any) => item.star == 2)
                  ?.length *
                  100) /
                product_reviews?.length
              }
            />
            <ProgressStyle
              percent={
                (product_reviews?.filter((item: any) => item.star == 1)
                  ?.length *
                  100) /
                product_reviews?.length
              }
            />
          </div>
        </div>
      </AboveBlock>
      <MainBlock>
        <List
          itemLayout="horizontal"
          dataSource={product_reviews}
          renderItem={(item: any) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  item?.avatar ? (
                    <Avatar src={item?.avatar} />
                  ) : (
                    <Avatar size={32} src={R.images.avatar_default} />
                  )
                }
                title={
                  <Row justify="space-between">
                    <div>
                      <div>
                        <b>{item?.reviewed_name}</b>
                      </div>

                      <Rate style={{}} allowHalf disabled value={item?.star} />
                    </div>
                    {moment(item?.create_at).format('HH:mm, DD/MM/YYYY')}
                  </Row>
                }
                description={
                  <>
                    <div style={{ fontWeight: 500, color: 'black' }}>
                      {item?.description}
                    </div>
                    {item?.reviews?.review_media?.map((subItem: any) => (
                      <Image.PreviewGroup>
                        <Image
                          style={{
                            width: 70,
                            height: 70,
                            objectFit: 'cover',
                            margin: '0 10px 0 0',
                          }}
                          src={subItem?.media_url}
                        />
                      </Image.PreviewGroup>
                    ))}
                  </>
                }
              />
            </List.Item>
          )}
        />
      </MainBlock>
    </ContainerRate>
  )
}

export default RatePetroleumScreen
