import { Button, Input, Modal } from 'antd'
import Slider from 'react-slick'
import styled from 'styled-components'

const LayoutHome = styled.div`
  width: 100%;
  max-width: 1920px;
`
const ContainerHome = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const WrapBody = styled.div`
  width: 100%;
`
const BrandHome = styled.div`
  display: flex;
  width: 100%;
  border: 2px dashed #ced4da;
  border-radius: 10px;
  justify-content: space-evenly;
  align-items: center;
  margin: 24px 0;
  padding: 10px 0;
`

const ImageBrand = styled.div`
  height: 50px;
  width: 50px;
  position: relative;
  border-radius: 6px;
`

const WrapBox = styled.div`
  width: 100%;
  padding: 1em 1em;
  background-color: #fff;
  border-radius: 12px;
  margin-top: 15px;
`

const WrapBoxProduct = styled.div`
  width: 100%;
  border-radius: 12px;
  margin-top: 15px;
`

const HeaderBox = styled.div`
  width: 100%;
  font-weight: bold;
  line-height: 30px;
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  margin-bottom: 15px;

  span {
    color: #f26522;
    font-weight: normal;
    font-size: 16px;
    cursor: pointer;
  }
`

const HeaderBoxProduct = styled.div`
  width: 100%;
  font-weight: bold;
  line-height: 30px;
  display: flex;
  font-size: 22px;
  justify-content: space-between;
  margin-bottom: 15px;

  background-color: #fff;
  padding: 20px;
  border-radius: 10px;

  span {
    color: #f26522;
    font-weight: normal;
    font-size: 16px;
    cursor: pointer;
  }
`

const CategoryBlock = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  margin-top: 18px;

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: transparent;
  }
  @media only screen and (min-width: 769px) {
    width: 100%;
    height: 130px;
    justify-content: space-between;
    align-items: center;
  }
`

const BigOption = styled.div`
  height: 100%;
  width: 32%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  :hover {
    cursor: pointer;
    background-color: #f0f0f0;
    opacity: 0.7;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    box-shadow: 0px 0px 12px -6px rgba(24, 39, 75, 0.12),
      0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 12px;
    border: 1px solid #f26522;
    background-color: white;
  }
  @media only screen and (min-width: 769px) {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
`

const SmallOption = styled.div`
  height: 100%;
  width: 17%;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
    background-color: #f0f0f0;
    opacity: 0.7;
  }

  @media only screen and (max-width: 768px) {
    width: 48%;
    box-shadow: 0px 0px 12px -6px rgba(24, 39, 75, 0.12),
      0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 12px;
    border: 1px solid #f26522;
    margin-top: 12px;
    background-color: white;
  }
  @media only screen and (min-width: 1024px) {
    :nth-child(5):hover {
      border-bottom-right-radius: 12px;
      border-top-right-radius: 12px;
    }
  }
`

const SpanHot = styled.div`
  height: 20px;
  border-radius: 15px;
  background-color: #fedab3;
  color: #fb8500;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;
  font-weight: normal;
  font-size: 13px;
`
const SlideDealsHot = styled.div`
  width: 100%;
  position: relative;
`

const SliderStyled = styled(Slider)`
  .slick-prev:before {
    content: '❮';
    color: white;
    font-size: 28px;
    display: none;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.18);
    height: auto;
    width: auto;
    padding: 5px 5px 8px 5px;
  }
  .slick-next:before {
    content: '❯';
    color: white;
    font-size: 28px;
    display: none;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.18);
    height: auto;
    width: auto;
    padding: 5px 5px 8px 5px;
  }
  :hover {
    .slick-prev:before {
      display: block;
    }
    .slick-next:before {
      display: block;
    }
  }

  .slick-prev {
    left: 0px;
    z-index: 10;
    height: auto;
    width: auto;
    padding: 5px 5px 8px 5px;
  }
  .slick-next {
    right: 0px;
    z-index: 10;
    width: auto;
    height: auto;
    padding: 5px 5px 8px 5px;
  }

  .slick-prev:before :hover {
    background-color: rgba(0, 0, 0, 0.32);
  }
  .slick-next:before :hover {
    background-color: rgba(0, 0, 0, 0.32);
  }

  @media only screen and (max-width: 768px) {
    .slick-prev:before {
      display: none;
    }
    .slick-next:before {
      display: none;
    }
  }
`

const SliderSmallView = styled.div`
  display: flex;
  overflow-x: hidden;
`

const StyledArrow = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 45%;
  #next {
    float: right;
  }
  display: flex;
  justify-content: space-between;
`

const ButtonArrow = styled.div.attrs(
  (props: { visibility: boolean | undefined }) => ({
    visibility: props.visibility,
  })
)`
  cursor: pointer;
  box-shadow: 0px 4px 14px 1px rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  width: 34px;
  font-size: 22px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  visibility: ${(props: any) => (props.visibility ? 'hidden' : 'visible')};
  :hover {
    background-color: #e3e3e3;
  }

  @media only screen and (max-width: 480px) {
    visibility: hidden;
  }
`

const SliderWrap = styled.div`
  width: 100vw;
  background-color: white;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
`

const BannerBlock = styled.div`
  width: 100%;
  aspect-ratio: 2.15/1;
  display: flex;
  cursor: pointer;
  z-index: 99;
`
const StyledImage = styled.img<any>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  /* border: 1px solid #f26522; */
  border-radius: 10px;
`
const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-header {
    border-radius: 12px 12px 0 0;
  }
`
const InputStyled = styled(Input)`
  border-radius: 12px;
`
const ButtonStyled = styled(Button)`
  width: 100%;
  background-color: #f26522;
  color: white;
  border-radius: 12px;
  height: 40px;
  font-size: 16px;
  :hover {
    color: white;
    background-color: #f26522;
    border: none;
  }
  :focus {
    color: white;
    background-color: #f26522;
    border: none;
  }
`
export {
  InputStyled,
  ButtonStyled,
  ModalStyled,
  LayoutHome,
  BrandHome,
  ContainerHome,
  ImageBrand,
  WrapBox,
  HeaderBox,
  WrapBody,
  CategoryBlock,
  BigOption,
  SmallOption,
  SpanHot,
  SlideDealsHot,
  StyledArrow,
  ButtonArrow,
  BannerBlock,
  SliderWrap,
  SliderStyled,
  StyledImage,
  SliderSmallView,
  WrapBoxProduct,
  HeaderBoxProduct,
}
