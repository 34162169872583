import Icon from '@ant-design/icons'

const DebtInforSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2727 7.32812H8C5.23858 7.32812 3 9.56671 3 12.3281V16.8736C3 19.635 5.23857 21.8736 8 21.8736H16.1818C18.9432 21.8736 21.1818 19.635 21.1818 16.8736V12.3281C21.1818 9.5667 18.9432 7.32812 16.1818 7.32812H12.5455"
      stroke="#ADB5BD"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3301 2.44942C11.3687 1.43293 8.95463 2.19893 7.93815 4.16032L5.66479 8.54694C6.20569 8.34662 6.79069 8.23718 7.40125 8.23718H10.4788L13.4469 2.50995L13.3301 2.44942Z"
      fill="#ADB5BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5504 4.0519L14.302 2.88671L11.5293 8.23694H16.4921C17.2303 8.23694 17.9312 8.39693 18.5619 8.68412C19.0656 6.89596 18.2645 4.94024 16.5504 4.0519Z"
      fill="#ADB5BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 15.0557C3 12.2942 5.23858 10.0557 8 10.0557H16.1818C18.9432 10.0557 21.1818 12.2942 21.1818 15.0557V16.8738C21.1818 19.6353 18.9432 21.8738 16.1818 21.8738H8C5.23857 21.8738 3 19.6353 3 16.8738V15.0557ZM9.52273 15.9106C9.52273 15.4964 9.85851 15.1606 10.2727 15.1606H11.7955V13.692C11.7955 13.2778 12.1312 12.942 12.5455 12.942C12.9597 12.942 13.2955 13.2778 13.2955 13.692V15.1606H14.8182C15.2324 15.1606 15.5682 15.4964 15.5682 15.9106C15.5682 16.3248 15.2324 16.6606 14.8182 16.6606H13.2955V18.2375C13.2955 18.6517 12.9597 18.9875 12.5455 18.9875C12.1312 18.9875 11.7955 18.6517 11.7955 18.2375V16.6606H10.2727C9.85851 16.6606 9.52273 16.3248 9.52273 15.9106Z"
      fill="#ADB5BD"
    />
  </svg>
)

const DebtInforIcon = (props: any) => (
  <Icon component={DebtInforSvg} {...props} />
)
export default DebtInforIcon
