import styled, { createGlobalStyle } from 'styled-components'

const Styles = createGlobalStyle`
    .ant-descriptions-item-content,
    .ant-menu-submenu,
    .ant-menu-item,
    .ant-collapse-header,
    .ant-table-thead { 
       font-size: 15px; 
       font-weight:700
       }

    body,
    html,
    a {
        overflow-y: overlay;
        font-family:'Quicksand', sans-serif;
        font-weight: 500;
        /* font-family: Arial, Helvetica, sans-serif; */
    }

    .ant-table-expanded-row  .ant-table-cell{
        padding: 0px ;
    }
    head{
        background-color: red;
    }
    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #eeeeeeee;
        overflow-x: hidden;
        font-size: 16px;
    }

    a:hover {
        color: #000;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family:'Quicksand','Ubuntu', sans-serif;
        color: #0a1f44;
        font-size: 2.575rem;
        line-height: 3.0625rem;
        @media only screen and (max-width: 414px) {
          font-size: 1.625rem;
        }
    }



    .description_editor * {
        font-family: Arial, Helvetica, sans-serif !important;
        font-size: 16px;
        span {
            background-color: transparent !important;
        }
    } 

    .style_scroll {
        background-color: #fff;
    }

    .ant-tabs-ink-bar {
        background-color: #f26522;
    }

   .style_scroll::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.005);
    }

    .style_scroll::-webkit-scrollbar {
        width: 8px;
        background-color: rgba(0, 0, 0, 0.005);
    }

    .style_scroll::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .container {
        width: 100%;
    }
    @media (min-width: 640px) {
        .container {
            max-width: 640px;
        }
    }
    @media (min-width: 768px) {
        .container {
            max-width: 768px;
        }
    }
    @media (min-width: 1024px) {
        .container {
            max-width: 1024px;
        }
    }
    @media (min-width: 1280px) {
        .container {
            max-width: 1280px;
        }
    }
    @media (min-width: 1536px) {
        .container {
            max-width: 1536px;
        }
    }



`

export default Styles

export const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
