import io, { Socket } from 'socket.io-client'
import Cookie from 'js-cookie'
import { SESSION_KEY } from 'common/config'

export class WebSocket {
  public static socketClient: Socket
  private static currentToken: string | undefined

  public static async init() {
    const token = Cookie.get(SESSION_KEY.SESSION)

    if (!this.socketClient && this.currentToken !== token) {
      this.currentToken = token
      this.socketClient = io(process.env.REACT_APP_WS_URL as string, {
        auth: { token: token },
      })
    }
  }
  public static async disconnect() {
    if (this.socketClient) {
      this.socketClient.disconnect()
    }
  }
}
