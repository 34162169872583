import { Breadcrumb, Empty, Pagination, Row, Select, Spin } from 'antd'
import R from 'assets'
import { InsuranceCard } from 'common/components/CardInsurance'
import { ProductCard } from 'common/components/CardProduct'
import { StyledCol } from 'common/components/CardProduct/styled'
import { ROUTER_PATH } from 'common/config'
import { WrapBoxProduct } from 'features/home/styled'
import {
  getDataCoordinates,
  setDataCoordinates,
} from 'features/stores/StoreSlice'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { notificationError } from 'utils/notification'
import {
  getHightFilter,
  getListDeal,
  getListGas,
  getListInsurance,
  getListOil,
  getListOther,
  getListStation,
} from './ApiProducts'
import GasolineProduct from './components/GasolineProduct'
import {
  AboveBlock,
  BreadcrumbStyle,
  ButtonFilter,
  FilterBlock,
  SelectFilter,
  TextFilterOil,
  TextSort,
} from './styles'
type Params = {
  page: number
  limit: number
  price_status?: any
  age_status?: any
  sell_status?: any
}
type FParams = {
  search?: string
  page: number
  limit: number
  price_status?: any
  age_status?: any
  sell_status?: any
}
type SParams = {
  lat?: number
  lng?: number
  search?: string
  page: number
  limit: number
}
const ProductScreen: React.FC<TProps> = ({}) => {
  const { Option } = Select
  const history = useHistory()
  const location: any = useLocation()
  const dispatch = useDispatch()
  const coordinates = useSelector(getDataCoordinates)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState([])
  const [dataGas, setDataGas] = useState<any>()
  const [dataInsurance, setDataInsurance] = useState<any>()
  const [dataOther, setDataOther] = useState<any>()
  const [active, setActive] = useState<any>()
  const [listStation, setListStation] = useState([])
  const [dataFilter, setDataFilter] = useState<boolean>(false)
  const [chooseGas, setChooseGas] = useState<any>()
  const [onActive, setOnActive] = useState<boolean>(false)
  const [onNew, setOnNew] = useState<boolean>()
  const [loadStore, setLoadStore] = useState<boolean>(false)

  const [pages, setPages] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  })
  const [params, setParams] = useState<Params>({
    page: 1,
    limit: 12,
  })
  const [paramStation, setParamStation] = useState<SParams>({
    lat: undefined,
    lng: undefined,
    search: undefined,
    page: 1,
    limit: 12,
  })
  const [paramFilter, setParamFilter] = useState<FParams>({
    search: undefined,
    age_status: undefined,
    sell_status: undefined,
    price_status: undefined,
    page: 1,
    limit: 12,
  })

  const [geoLocation, setGeoLocation] = useState<boolean>(false)
  const searchParams = new URLSearchParams(window.location.search)
  const typeProduct: string = searchParams.get('type') as string
  const searchProduct: string = searchParams.get('search') as string
  const categoryProduct: string = searchParams.get('category') as string
  const idCategoryProduct: string = searchParams.get('idCategory') as string
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  useEffect(() => {
    getLocation()
  }, [])

  useEffect(() => {
    if (coordinates.location_lat && coordinates.location_long) {
      setParamStation({
        ...paramStation,
        lat: coordinates.location_lat,
        lng: coordinates.location_long,
      })
    }
  }, [coordinates])

  useEffect(() => {
    setParamFilter({
      ...paramFilter,
      search: searchProduct,
      age_status: undefined,
      sell_status: undefined,
      price_status: undefined,
      page: 1,
    })
  }, [searchProduct])

  useEffect(() => {
    getDataSource()
  }, [params, idCategoryProduct])

  useEffect(() => {
    if (dataGas?.[0]?.id) {
      ListStation()
    }
  }, [paramStation, dataGas, chooseGas, idCategoryProduct])

  useEffect(() => {
    if (
      paramFilter.age_status ||
      paramFilter.price_status ||
      paramFilter.sell_status ||
      paramFilter.search
    ) {
      getDataFilter()
    }
  }, [paramFilter, location])

  const getDataFilter = async () => {
    setIsLoading(true)
    setDataFilter(true)
    try {
      const res = await getHightFilter(paramFilter)
      setDataSource(res?.data)
      setPages({
        ...pages,
        current: res.meta?.pagination?.page,
        total: res.meta?.pagination?.totalItems,
        pageSize: res.meta?.pagination?.limit,
      })
    } catch (error) {
      console.error('Exception ' + error)
    }
    setIsLoading(false)
  }

  const getDataSource = async () => {
    setIsLoading(true)
    try {
      if (typeProduct === 'gas') {
        const res = await getListGas({ ...params, limit: 999 })
        setDataGas(res?.data)
        setActive(res?.data[0]?.id)
        setPages({
          ...pages,
          current: res.meta?.pagination?.page,
          total: res.meta?.pagination?.totalItems,
          pageSize: res.meta?.pagination?.limit,
        })
      }

      if (typeProduct === 'oil') {
        const res = await getListOil(params)
        setDataOther(res?.data)
        setPages({
          ...pages,
          current: res.meta?.pagination?.page,
          total: res.meta?.pagination?.totalItems,
          pageSize: res.meta?.pagination?.limit,
        })
      }

      if (typeProduct === 'insurrance') {
        const res = await getListInsurance(params)
        setDataInsurance(res?.data)
        setPages({
          ...pages,
          current: res.meta?.pagination?.page,
          total: res.meta?.pagination?.totalItems,
          pageSize: res.meta?.pagination?.limit,
        })
      }

      if (
        typeProduct != 'gas' &&
        typeProduct != 'oil' &&
        typeProduct != 'insurrance'
      ) {
        const res = await getListOther(Number(idCategoryProduct))
        setDataOther(res?.data)
        setPages({
          ...pages,
          current: res.meta?.pagination?.page,
          total: res.meta?.pagination?.totalItems,
          pageSize: res.meta?.pagination?.limit,
        })
      }
      if (typeProduct === 'deal') {
        const res = await getListDeal(params)
        setDataOther(res?.data)
        setPages({
          ...pages,
          current: res.meta?.pagination?.page,
          total: res.meta?.pagination?.totalItems,
          pageSize: res.meta?.pagination?.limit,
        })
      }
    } catch (error) {
      console.error('Exception ' + error)
    }
    setIsLoading(false)
  }

  const ListStation = async () => {
    setLoadStore(true)
    try {
      if (chooseGas) {
        const res = await getListStation(chooseGas, paramStation)
        setListStation(res.data)
        if (res.data?.length < paramStation.limit) {
          setLoadingMore(false)
        } else {
          setLoadingMore(true)
        }
      } else {
        const res = await getListStation(dataGas?.[0]?.id, paramStation)
        setListStation(res.data)
        if (res.data?.length < paramStation.limit) {
          setLoadingMore(false)
        } else {
          setLoadingMore(true)
        }
      }
    } catch (error) {
      console.error('Exception ' + error)
    }
    setLoadStore(false)
  }

  const getMoreData = async () => {
    setLoadStore(true)
    try {
      if (chooseGas) {
        const res = await getListStation(chooseGas, {
          ...paramStation,
          page: paramStation.page + 1,
        })
        setListStation(listStation.concat(res.data))
        if (res.data?.length < paramStation.limit) {
          setLoadingMore(false)
        } else {
          setLoadingMore(true)
        }
      } else {
        const res = await getListStation(dataGas?.[0]?.id, {
          ...paramStation,
          page: paramStation.page + 1,
        })
        setListStation(listStation.concat(res.data))
        if (res.data?.length < paramStation.limit) {
          setLoadingMore(false)
        } else {
          setLoadingMore(true)
        }
      }
    } catch (error) {
      console.error('Exception ' + error)
    }
    setLoadStore(false)
  }

  const getLocation = () => {
    if (!navigator.geolocation) {
      notificationError('Geolocation is not supported by this browser.')
      setGeoLocation(false)
    } else {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          setGeoLocation(true)
          dispatch(
            setDataCoordinates({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
          )
        },
        (error: any) => {
          // notificationError(R.strings().warning_geolocation)
        }
      )
    }
  }

  React.useEffect(() => {
    if (searchProduct) {
      return setParamFilter({ ...paramFilter, search: searchProduct })
    }
    setDataFilter(false)
  }, [typeProduct, searchProduct])

  return (
    <>
      <Spin spinning={isLoading} size="large">
        <BreadcrumbStyle>
          <Breadcrumb.Item>
            <a href={ROUTER_PATH.HOME}>{R.strings().title_home}</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {categoryProduct
              ? categoryProduct
              : typeProduct === 'search'
              ? searchProduct
              : R.strings().title_great_deal}
          </Breadcrumb.Item>
        </BreadcrumbStyle>
        {typeProduct === 'search' || typeProduct === 'oil' ? (
          <AboveBlock justify="space-between">
            {typeProduct === 'oil' ? (
              <TextFilterOil>{R.strings().title_oil}</TextFilterOil>
            ) : (
              <TextFilterOil />
            )}
            <FilterBlock justify="end" align="middle">
              <TextSort>{R.strings().sort}</TextSort>
              <ButtonFilter
                style={
                  !onNew
                    ? {
                        backgroundColor: 'white',
                        height: 44,
                        width: '90px',
                        border: '1px solid #f26522',
                        color: '#f26522',
                        borderRadius: 12,
                        margin: '0px 10px 0px 10px',
                        fontSize: 16,
                        cursor: 'pointer',
                      }
                    : {
                        backgroundColor: '#f26522',
                        color: 'white',
                        height: 44,
                        width: '90px',
                        border: 'none',
                        borderRadius: 12,
                        margin: '0px 10px 0px 10px',
                        fontSize: 16,
                        cursor: 'pointer',
                      }
                }
                onClick={value => {
                  setOnNew(!onNew)
                  setDataFilter(!dataFilter)

                  if (!dataFilter) {
                    setParamFilter({
                      ...paramFilter,
                      age_status: 1,
                      sell_status: undefined,
                    })
                  } else {
                    setParamFilter({
                      ...paramFilter,
                      age_status: undefined,
                      sell_status: undefined,
                    })
                  }
                }}
              >
                {R.strings().newest}
              </ButtonFilter>
              <ButtonFilter
                style={
                  !onActive
                    ? {
                        backgroundColor: 'white',
                        height: 44,
                        width: '90px',
                        border: '1px solid #f26522',
                        color: '#f26522',
                        borderRadius: 12,
                        margin: '0px 10px 0px 0px',
                        fontSize: 16,
                        cursor: 'pointer',
                      }
                    : {
                        backgroundColor: '#f26522',
                        color: 'white',
                        height: 44,
                        width: '90px',
                        border: 'none',
                        borderRadius: 12,
                        margin: '0px 10px 0px 0px',
                        fontSize: 16,
                        cursor: 'pointer',
                      }
                }
                onClick={value => {
                  setOnActive(!onActive)
                  if (!onActive) {
                    setParamFilter({
                      ...paramFilter,
                      age_status: undefined,
                      price_status: undefined,
                      sell_status: 1,
                    })
                  } else {
                    setParamFilter({
                      ...paramFilter,
                      sell_status: 3,
                    })
                  }
                }}
              >
                {R.strings().sell}
              </ButtonFilter>
              <SelectFilter
                allowClear
                placeholder={
                  <div style={{ color: '#ff520091' }}>{R.strings().price}</div>
                }
                onClear={() => {
                  setDataFilter(false)
                  window.location.reload()
                }}
                onChange={value => {
                  setParamFilter({
                    ...paramFilter,
                    // age_status: undefined,
                    sell_status: undefined,
                    price_status: value,
                  })
                  setDataFilter(true)
                }}
              >
                <Option value={2}>{R.strings().price_low}</Option>
                <Option value={1}>{R.strings().price_hight}</Option>
              </SelectFilter>
            </FilterBlock>
          </AboveBlock>
        ) : (
          <></>
        )}
        <WrapBoxProduct
          style={
            typeProduct === 'gas'
              ? { backgroundColor: 'unset', padding: 0 }
              : { margin: '0 0 30px 0' }
          }
        >
          <Row justify="start">
            {dataFilter ? (
              dataSource?.length ? (
                dataSource?.map((item: any) => (
                  <>
                    <StyledCol xs={12} sm={8} xl={6} key={item.id}>
                      <ProductCard
                        dataProduct={item}
                        onClickProduct={() => {
                          const petrol_id = item?.percent_discount
                            ? item?.petrol_store_id
                            : item?.product_stocks[0]?.petrol_store_id
                          history.push({
                            pathname: `${ROUTER_PATH.DETAIL_PRODUCT}/${item?.id}?type=${typeProduct}&product_id=${item.id}&petrol_store_id=${petrol_id}&nameProduct=${item.name}&idCategoryProduct=${idCategoryProduct}&search=${searchProduct}`,
                            state: { page: pages.current },
                          })
                        }}
                      />
                    </StyledCol>
                  </>
                ))
              ) : (
                <div style={{ width: '100%' }}>
                  <Empty description={R.strings().empty} />
                </div>
              )
            ) : typeProduct === 'oil' ? (
              dataOther?.map((item: any) => (
                <>
                  <StyledCol
                    xs={12}
                    sm={8}
                    xl={6}
                    key={item.id}
                    style={{
                      margin: '0 0 15px 0',
                    }}
                  >
                    <ProductCard
                      dataProduct={item}
                      onClickProduct={() => {
                        const petrol_id = item?.percent_discount
                          ? item?.petrol_store_id
                          : item?.product_stocks[0]?.petrol_store_id
                        history.push({
                          pathname: `${ROUTER_PATH.DETAIL_PRODUCT}/${item?.id}?type=${typeProduct}&category=${categoryProduct}&product_id=${item.id}&petrol_store_id=${petrol_id}&nameProduct=${item.name}&idCategoryProduct=${idCategoryProduct}`,
                          state: { page: pages.current },
                        })
                      }}
                    />
                  </StyledCol>
                </>
              ))
            ) : typeProduct === 'insurrance' ? (
              dataInsurance?.map((item: any) => (
                <>
                  <StyledCol xs={24} sm={12} xl={12} key={item.id}>
                    <InsuranceCard
                      dataProduct={item}
                      onClickProduct={() => {
                        const petrol_id = item?.percent_discount
                          ? item?.petrol_store_id
                          : item?.product_stocks[0]?.petrol_store_id
                        history.push({
                          pathname: `${ROUTER_PATH.DETAIL_PRODUCT}/${item?.id}?type=${typeProduct}&category=${categoryProduct}&product_id=${item.id}&petrol_store_id=${petrol_id}&nameProduct=${item.name}&idCategoryProduct=${idCategoryProduct}`,
                        })
                      }}
                    />
                  </StyledCol>
                </>
              ))
            ) : (
              dataOther?.map((item: any) => (
                <>
                  <StyledCol
                    xs={12}
                    sm={8}
                    xl={6}
                    key={item.id}
                    style={
                      typeProduct === 'gas'
                        ? { display: 'none' }
                        : {
                            margin: '0 0 15px 0',
                          }
                    }
                  >
                    <ProductCard
                      dataProduct={item}
                      onClickProduct={() => {
                        const petrol_id = item?.percent_discount
                          ? item?.petrol_store_id
                          : item?.product_stocks[0]?.petrol_store_id
                        history.push({
                          pathname: `${ROUTER_PATH.DETAIL_PRODUCT}/${item?.id}?type=${typeProduct}&product_id=${item.id}&petrol_store_id=${petrol_id}&nameProduct=${item.name}&idCategoryProduct=${idCategoryProduct}`,
                        })
                      }}
                    />
                  </StyledCol>
                </>
              ))
            )}

            <Row
              justify="end"
              style={
                typeProduct === 'gas'
                  ? { display: 'none' }
                  : { marginTop: '12px', width: '100%' }
              }
            >
              <Pagination
                onChange={async (page, pageSize) => {
                  setParams({ ...params, page })
                  setParamFilter({ ...paramFilter, page })
                }}
                showSizeChanger={false}
                pageSize={pages.pageSize}
                current={pages.current}
                total={pages.total}
              />
            </Row>
          </Row>
          <Row style={typeProduct !== 'gas' ? { display: 'none' } : {}}>
            <GasolineProduct
              onSearchSubmit={(searchKey: string) => {
                setParamStation({
                  ...paramStation,
                  search:
                    searchKey.trim() === '' ? undefined : searchKey.trimStart(),
                  page: 1,
                })
              }}
              loadStore={loadStore}
              getMoreData={getMoreData}
              loadingMore={loadingMore}
              active={active}
              setActive={setActive}
              data={dataGas}
              loading={isLoading}
              setLoading={setIsLoading}
              dataStation={listStation}
              setChooseGas={setChooseGas}
              geoLocation={geoLocation}
            />
          </Row>
        </WrapBoxProduct>
      </Spin>
    </>
  )
}

export default ProductScreen
