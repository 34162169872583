import { ApiClient } from 'services/ApiService'

export const getListStore = (payload: any) =>
  ApiClient.get('/customer/petrol-store', payload)
export const getDetailStore = (petrol_store_id: number) =>
  ApiClient.get(`/customer/petrol-store/${petrol_store_id}`)
export const listCategory = (petrol_store_id: number) =>
  ApiClient.get(`/customer/petrol-store/${petrol_store_id}/category`)
export const getListGas = (petrol_store_id: number) =>
  ApiClient.get(`/customer/petrol-store/${petrol_store_id}/list-petrol`)
export const getListOil = (petrol_store_id: number) =>
  ApiClient.get(`/customer/petrol-store/${petrol_store_id}/list-oil`)
export const getListInsurance = (petrol_store_id: number) =>
  ApiClient.get(`/customer/petrol-store/${petrol_store_id}/insurance`)
export const getListOther = (
  petrol_store_id: number,
  category_id: number,
  payload: any
) =>
  ApiClient.get(
    `/customer/petrol-store/${petrol_store_id}/${category_id}/product`,
    payload
  )
export const getListRate = (petrol_store_id: number, payload: any) =>
  ApiClient.get(`/customer/review/${petrol_store_id}`, payload)
