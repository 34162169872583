import Icon from '@ant-design/icons'

const UserSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11.5788"
      cy="7.27803"
      r="4.77803"
      fill="#ADB5BD"
      stroke="#ADB5BD"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00002 18.7016C3.99873 18.3657 4.07385 18.0339 4.2197 17.7313C4.67736 16.816 5.96798 16.3308 7.03892 16.1112C7.81128 15.9463 8.59431 15.8362 9.38217 15.7816C10.8408 15.6535 12.3079 15.6535 13.7666 15.7816C14.5544 15.8369 15.3374 15.947 16.1099 16.1112C17.1808 16.3308 18.4714 16.7702 18.9291 17.7313C19.2224 18.3481 19.2224 19.0642 18.9291 19.681C18.4714 20.6421 17.1808 21.0814 16.1099 21.2919C15.3384 21.4636 14.5551 21.5768 13.7666 21.6306C12.5794 21.7313 11.3866 21.7496 10.1968 21.6855C9.92221 21.6855 9.65677 21.6855 9.38217 21.6306C8.59663 21.5775 7.81632 21.4642 7.04807 21.2919C5.96798 21.0814 4.68652 20.6421 4.2197 19.681C4.0746 19.3749 3.99955 19.0403 4.00002 18.7016Z"
      fill="#ADB5BD"
      stroke="#ADB5BD"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const UserIcon = (props: any) => <Icon component={UserSvg} {...props} />
export default UserIcon
