import { CheckCircleOutlined, CopyOutlined } from '@ant-design/icons'
import { Avatar, Row } from 'antd'
import R from 'assets'
import * as ICON from 'common/components/Icons'
import { ROUTER_PATH } from 'common/config'
import { OrderScreen } from 'features/order'
import { ParagraphStyle } from 'features/order/styled'
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { ENTERPRISE_REQUEST_STATUS } from 'utils/constants'
import BecomeAgentTab from './components/BecomeAgentTab'
import ChangePasswordTab from './components/ChangePasswordTab'
import DebtTab from './components/DebtTab'
import LocationTab from './components/LocationTab'
import RankTab from './components/RankTab'
import UserInforTab from './components/UserInforTab'
import WalletPointTab from './components/WalletPointTab'
import * as Styled from './styled'
const searchParams = new URLSearchParams(window.location.search)
const keyTab: string = searchParams.get('key') as string
const AccountScreen: React.FC = () => {
  const subMenu = [
    {
      pathname: `${ROUTER_PATH.ACCOUNT}/userinfor`,
      name: R.strings().title_account,
      icon: ICON.UserIcon,
      icon_hover: ICON.UserIconColorIcon,
      render: UserInforTab,
    },
    {
      pathname: `${ROUTER_PATH.ACCOUNT}/order`,
      name: R.strings().title_order,
      icon: ICON.OrderIcon,
      icon_hover: ICON.OrderColorIcon,
      render: OrderScreen,
    },
    {
      pathname: `${ROUTER_PATH.ACCOUNT}/walletPoint`,
      name: R.strings().wallet,
      icon: ICON.CoinIcon,
      icon_hover: ICON.CoinColorIcon,
      render: WalletPointTab,
    },
    // {
    //   pathname: `${ROUTER_PATH.ACCOUNT}/rank`,
    //   name: R.strings().rank,
    //   icon: ICON.MedalIcon,
    //   icon_hover: ICON.MedalIconColorIcon,
    //   render: RankTab,
    // },
    {
      pathname: `${ROUTER_PATH.ACCOUNT}/location`,
      name: R.strings().address,
      icon: ICON.LocationIconv2,
      icon_hover: ICON.LocationIcon,
      render: LocationTab,
    },
    {
      pathname: `${ROUTER_PATH.ACCOUNT}/agent`,
      name: R.strings().become_an_agent,
      icon: ICON.StoreIcon,
      icon_hover: ICON.StoreColorIcon,
      render: BecomeAgentTab,
    },
    {
      pathname: `${ROUTER_PATH.ACCOUNT}/debt`,
      name: R.strings().debt_information,
      icon: ICON.DebtInforIcon,
      icon_hover: ICON.DebtInforColorIcon,
      render: DebtTab,
    },
    {
      pathname: `${ROUTER_PATH.ACCOUNT}/changePassword`,
      name: R.strings().change_password,
      icon: ICON.PasswordIcon,
      icon_hover: ICON.PasswordColorIcon,
      render: ChangePasswordTab,
    },
  ]
  const history = useHistory()
  const location: any = useLocation()
  const userState: any = useSelector((state: any) => state.authReducer)
    ?.userInfo

  return (
    <Styled.RowAccount>
      <Styled.ColAccount xs={24} md={6} className="menu">
        <Styled.AvatarBlock>
          <Avatar
            src={userState?.avatar || R.images.avatar_default}
            size={{ xs: 24, sm: 32, md: 42, lg: 64, xl: 80, xxl: 100 }}
          />
          <div id="name-user">{userState?.name}</div>
          <div id="phone-user">
            {R.strings().referral_code}:
            <ParagraphStyle
              copyable={{
                icon: [
                  <CopyOutlined key="copy-icon" style={{ color: '#f26522' }} />,
                  <CheckCircleOutlined
                    key="copied-icon"
                    style={{ color: '#f26522' }}
                  />,
                ],
              }}
            >
              {userState?.code}
            </ParagraphStyle>
          </div>
          <Row
            style={{ fontSize: '14px', marginTop: '10px', fontWeight: 600 }}
            align="middle"
          >
            <ICON.MedalIcon />{' '}
            <span style={{ marginLeft: '4px' }}>
              {R.strings().rank}: {userState?.rank || 'Vô hạng'}
            </span>
          </Row>
        </Styled.AvatarBlock>
        {subMenu?.map((item: any, index: number) => {
          if (item.pathname === `${ROUTER_PATH.ACCOUNT}/debt`) {
            if (
              userState?.enterprise &&
              userState?.enterprise?.approve_status ===
                ENTERPRISE_REQUEST_STATUS.ACCEPT &&
              userState?.enterprise?.debt_enterprises?.length > 0
            ) {
              return (
                <Styled.MenuItem
                  key={index}
                  onClick={() => {
                    history.push(item.pathname)
                  }}
                  className={
                    location?.pathname == item.pathname ? 'active' : ''
                  }
                >
                  <item.icon style={{ marginRight: '10px' }} id="icon" />
                  <item.icon_hover
                    style={{ marginRight: '10px' }}
                    id="icon_hover"
                  />
                  {item.name}
                </Styled.MenuItem>
              )
            } else {
              return <></>
            }
          } else {
            return (
              <Styled.MenuItem
                key={index}
                onClick={() => {
                  history.push(item.pathname)
                }}
                className={location?.pathname == item.pathname ? 'active' : ''}
              >
                <item.icon style={{ marginRight: '10px' }} id="icon" />
                <item.icon_hover
                  style={{ marginRight: '10px' }}
                  id="icon_hover"
                />
                {item.name}
              </Styled.MenuItem>
            )
          }
        })}
      </Styled.ColAccount>

      <Styled.ColAccount
        xs={24}
        md={17}
        backgroundColor={
          [
            `${ROUTER_PATH.ACCOUNT}/walletPoint`,
            `${ROUTER_PATH.ACCOUNT}/debt`,
            `${ROUTER_PATH.ACCOUNT}/order`,
          ].includes(location?.pathname)
            ? 'transperent'
            : 'white'
        }
      >
        <Switch>
          {subMenu?.map((item: any, index: number) => (
            <Route
              key={item.pathname}
              path={item.pathname}
              render={() => <item.render key={index} />}
            />
          ))}
        </Switch>
      </Styled.ColAccount>
    </Styled.RowAccount>
  )
}
export default AccountScreen
