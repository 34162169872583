import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Badge, Button, Divider, Empty, Row, Spin, Tooltip } from 'antd'
import R from 'assets'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { injectObject2String } from 'utils/ruleForm'
import { listNotify, readAllNotify, readNotify } from './ApiContainer'
import { ContainerNotify, ModalNotify, RecordNotify } from './styles'
import { ROUTER_PATH } from 'common/config'
import { useHistory } from 'react-router-dom'
const ListNotification: React.FC<any> = ({
  getDataNotify,
  dataNotify,
  loadMoreNotification,
  loading,
  setLoading,
  showDrawer,
  visibleNotify,
  setVisibleNotify,
  setUpdateNotify,
  updateNotify,
  getMoreDataNotify,
  visible,
  setVisible,
}) => {
  const userState: any = useSelector((state: any) => state.authReducer)
    ?.userInfo

  const scrollRef: any = useRef(null)
  const history = useHistory()
  return (
    <>
      {!showDrawer ? (
        <ContainerNotify>
          <Row
            justify="space-between"
            style={{
              backgroundColor: '#f26522',
              borderRadius: '12px 12px 0 0',
              padding: 5,
            }}
          >
            <div
              style={{
                fontWeight: 600,
                padding: '3px 0 0 5px',
                color: 'white',
              }}
            >
              {R.strings().notification}
            </div>
            <Row>
              {visible ? (
                <Tooltip title="Đánh dấu đã đọc">
                  <Button
                    type="text"
                    style={{ color: 'white' }}
                    onClick={async () => {
                      setLoading(true)
                      try {
                        const res = await readAllNotify()
                        getDataNotify()
                      } catch (error) {
                        console.log(error)
                      }
                      setUpdateNotify(!updateNotify)
                      setLoading(false)
                      setVisible(false)
                    }}
                  >
                    <CheckOutlined />
                  </Button>
                </Tooltip>
              ) : (
                <></>
              )}
              {/* <Tooltip title="Đóng">
            <Button
              type="text"
              style={{ color: 'white' }}
              // onClick={() => setVisible(false)}
            >
              <CloseOutlined />
            </Button>
          </Tooltip> */}
            </Row>
          </Row>

          <div
            style={{
              padding: '5px 0 5px 0',
              overflowX: 'auto',
              maxHeight: '400px',
            }}
            ref={scrollRef}
            onScroll={() => {
              if (
                scrollRef.current!.scrollTop +
                  scrollRef.current!.clientHeight >=
                scrollRef.current!.scrollHeight
              ) {
                let old = scrollRef.current!.scrollTop
                if (loadMoreNotification) {
                  console.log('loadMoreNotification: ', loadMoreNotification)
                  scrollRef.current!.scrollTop = old - 50
                  getMoreDataNotify()
                }
              }
            }}
          >
            <Spin spinning={loading}>
              {dataNotify?.length > 0 ? (
                dataNotify?.map((item: any) => (
                  <>
                    <div
                      style={
                        item?.is_read === 0
                          ? { backgroundColor: '#feecd9' }
                          : {}
                      }
                    >
                      <RecordNotify
                        onClick={async () => {
                          setLoading(true)
                          try {
                            const res = await readNotify(item?.id)
                            getDataNotify()
                          } catch (error) {
                            console.log(error)
                          }
                          setLoading(false)
                          if (
                            item?.type_notification?.scope === 1 ||
                            item?.type_notification?.scope === 9 ||
                            item?.type_notification?.scope === 14 ||
                            item?.type_notification?.scope === 15 ||
                            item?.type_notification?.scope === 34 ||
                            item?.type_notification?.scope === 35 ||
                            item?.type_notification?.scope === 36
                          ) {
                            history.push({
                              pathname: `${ROUTER_PATH.DETAIL_ORDER}/${item?.data?.order_id}`,
                            })
                            window.location.reload()
                          }
                          if (item?.type_notification?.scope === 2) {
                            history.push({
                              pathname: `${ROUTER_PATH.DETAIL_NEWS}/${item?.data?.config_banner_id}`,
                            })
                          }
                          if (
                            item?.type_notification?.scope === 4 ||
                            item?.type_notification?.scope === 11 ||
                            item?.type_notification?.scope === 16
                          ) {
                            history.push({
                              pathname: `${ROUTER_PATH.ACCOUNT}/walletPoint`,
                            })
                          }
                          if (item?.type_notification?.scope === 5) {
                            history.push({
                              pathname: `${ROUTER_PATH.ACCOUNT}/rank`,
                            })
                          }
                          if (
                            item?.type_notification?.scope === 8 ||
                            item?.type_notification?.scope === 12 ||
                            item?.type_notification?.scope === 13 ||
                            item?.type_notification?.scope === 14 ||
                            item?.type_notification?.scope === 15
                          ) {
                            history.push({
                              pathname: `${ROUTER_PATH.ACCOUNT}/debt`,
                            })
                          }
                        }}
                      >
                        <img
                          src={
                            item?.type_notification?.scope === 2 ||
                            item?.type_notification?.scope === 3
                              ? R.images.icon_notify_new
                              : item?.type_notification?.scope === 5 ||
                                item?.type_notification?.scope === 10
                              ? R.images.icon_notify_rank
                              : item?.type_notification?.scope === 4 ||
                                item?.type_notification?.scope === 11
                              ? R.images.icon_notify_points
                              : R.images.icon_notify_order
                          }
                          alt=""
                          style={{ height: 35 }}
                        />
                        <div style={{ margin: '0 0 0 5px' }}>
                          <div>
                            {injectObject2String(
                              localStorage.getItem('i18nextLng') === 'vi'
                                ? item?.type_notification?.title
                                : item?.type_notification?.title_en,
                              item.data
                            )}
                          </div>
                          <div>
                            {moment(item?.create_at).format('HH:mm DD/MM/YYYY')}
                          </div>
                        </div>
                      </RecordNotify>
                      <Divider style={{ padding: '5px 0 5px 0', margin: 0 }} />
                    </div>
                  </>
                ))
              ) : (
                <>
                  <Empty description={R.strings().empty} />
                </>
              )}
            </Spin>
          </div>
        </ContainerNotify>
      ) : (
        <>
          <ModalNotify
            title={
              <Row justify="space-between">
                <div>{R.strings().notification}</div>
                <Button
                  type="text"
                  style={{ color: 'white' }}
                  onClick={async () => {
                    setLoading(true)
                    try {
                      setUpdateNotify(!updateNotify)
                      const res = await readAllNotify()
                      getDataNotify()
                    } catch (error) {
                      console.log(error)
                    }
                    setLoading(false)
                    setVisible(false)
                  }}
                >
                  <CheckOutlined />
                </Button>
              </Row>
            }
            visible={visibleNotify}
            footer={null}
            onCancel={() => {
              setVisibleNotify(false)
            }}
          >
            <div
              style={{
                padding: '5px 0 5px 0',
                overflowX: 'auto',
                maxHeight: '400px',
              }}
              ref={scrollRef}
              onScroll={() => {
                if (
                  scrollRef.current!.scrollTop +
                    scrollRef.current!.clientHeight >=
                  scrollRef.current!.scrollHeight
                ) {
                  let old = scrollRef.current!.scrollTop
                  if (loadMoreNotification) {
                    console.log('loadMoreNotification: ', loadMoreNotification)
                    scrollRef.current!.scrollTop = old - 50
                    getMoreDataNotify()
                  }
                }
              }}
            >
              <Spin spinning={loading}>
                {dataNotify?.length > 0 ? (
                  dataNotify?.map((item: any) => (
                    <div
                      style={
                        item?.is_read === 0
                          ? { backgroundColor: '#feecd9' }
                          : {}
                      }
                    >
                      <RecordNotify
                        onClick={async () => {
                          setLoading(true)
                          try {
                            const res = await readNotify(item?.id)
                            getDataNotify()
                          } catch (error) {
                            console.log(error)
                          }
                          setLoading(false)
                          if (
                            item?.type_notification?.scope === 1 ||
                            item?.type_notification?.scope === 9 ||
                            item?.type_notification?.scope === 14 ||
                            item?.type_notification?.scope === 15 ||
                            item?.type_notification?.scope === 34 ||
                            item?.type_notification?.scope === 35 ||
                            item?.type_notification?.scope === 36
                          ) {
                            console.log(1111)

                            history.push({
                              pathname: `${ROUTER_PATH.DETAIL_ORDER}/${item?.data?.order_id}`,
                            })
                            window.location.reload()
                          }
                          if (item?.type_notification?.scope === 2) {
                            history.push({
                              pathname: `${ROUTER_PATH.DETAIL_NEWS}/${item?.data?.config_banner_id}`,
                            })
                          }
                          if (
                            item?.type_notification?.scope === 4 ||
                            item?.type_notification?.scope === 11
                          ) {
                            history.push({
                              pathname: `${ROUTER_PATH.ACCOUNT}/walletPoint`,
                            })
                          }
                          if (item?.type_notification?.scope === 5) {
                            history.push({
                              pathname: `${ROUTER_PATH.ACCOUNT}/rank`,
                            })
                          }
                          if (
                            item?.type_notification?.scope === 8 ||
                            item?.type_notification?.scope === 13 ||
                            item?.type_notification?.scope === 14 ||
                            item?.type_notification?.scope === 15
                          ) {
                            history.push({
                              pathname: `${ROUTER_PATH.ACCOUNT}/debt`,
                            })
                          }
                        }}
                      >
                        <img
                          src={
                            item?.type_notification?.scope === 2 ||
                            item?.type_notification?.scope === 3
                              ? R.images.icon_notify_new
                              : item?.type_notification?.scope === 5 ||
                                item?.type_notification?.scope === 10
                              ? R.images.icon_notify_rank
                              : item?.type_notification?.scope === 4 ||
                                item?.type_notification?.scope === 11
                              ? R.images.icon_notify_points
                              : R.images.icon_notify_order
                          }
                          alt=""
                          style={{ height: 35 }}
                        />
                        <div style={{ margin: '0 0 0 5px' }}>
                          <div>
                            {injectObject2String(
                              localStorage.getItem('i18nextLng') === 'vi'
                                ? item?.type_notification?.title
                                : item?.type_notification?.title_en,
                              item.data
                            )}
                          </div>
                          <div>
                            {moment(item?.create_at).format('HH:mm DD/MM/YYYY')}
                          </div>
                        </div>
                      </RecordNotify>
                      <Divider style={{ padding: '5px 0 5px 0', margin: 0 }} />
                    </div>
                  ))
                ) : (
                  <>
                    <Empty description={R.strings().empty} />
                  </>
                )}
              </Spin>
            </div>
          </ModalNotify>
        </>
      )}
    </>
  )
}

export default ListNotification
