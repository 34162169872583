import { AutoComplete, Button, Form, Input, Modal, Select } from 'antd'
import R from 'assets'
import React, { useEffect, useState } from 'react'
import { REG_PHONE } from 'utils/constants'
import { notificationError } from 'utils/notification'
import {
  createAddress,
  getAddress,
  getDistrict,
  getPlace,
  getPlaceAuto,
  getProvince,
  getWard,
  updateAddress,
} from '../ApiPayment'
import { TDataAddress } from '../interface'
import { BlockInputUpdate, SwitchStyled } from '../styled'

const { Option } = Select

type Props = {
  visibleModal: boolean
  dataUpdate: any
  requestListAddress: () => void
  onCancel: () => void
}

type TAddress = {
  location_address?: string
  location_lat: number
  location_long: number
}

const ModalUpdateAddress: React.FC<Props> = ({
  visibleModal,
  dataUpdate,
  requestListAddress,
  onCancel,
}) => {
  const [form] = Form.useForm()
  const [dataSource, setDataSource] = useState<TDataAddress>({
    name: '',
    phone: undefined,
    address: '',
    location_address: '',
    location_lat: 10,
    location_long: 10,
    province_id: 0,
    district_id: 0,
    ward_id: 0,
    is_default: 0,
  })
  const [listProvince, setListProvince] = useState<Array<any>>([])
  const [listDistrict, setListDistrict] = useState<Array<any>>([])
  const [listWard, setListWard] = useState<Array<any>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<Array<any>>([])
  const [paramsAddress, setParamsAddress] = useState({ address: '' })
  const [placeID, setPlaceID] = useState(null)
  const [address, setAddress] = useState<TAddress>({
    location_lat: 10,
    location_long: 10,
  })

  useEffect(() => {
    getListProvince()
  }, [])

  useEffect(() => {
    if (dataUpdate) {
      getDetail()
    }
  }, [dataUpdate])

  useEffect(() => {
    getListDistrict()
  }, [dataSource.province_id])

  useEffect(() => {
    getListWard()
  }, [dataSource.district_id])

  useEffect(() => {
    const run = setTimeout(() => {
      getAutoCompletePlace()
    }, 450)
    return () => clearTimeout(run)
  }, [paramsAddress])

  const getDetail = async () => {
    try {
      setIsLoading(true)
      const res = await getAddress(dataUpdate)

      form.setFieldsValue({
        name: res.data.name,
        phone: res.data.phone,
        address: res.data.address,
        province_id: res.data.province_id,
        district_id: res.data.district_id,
        ward_id: res.data.ward_id,
        location_address: res.data.location_address,
      })
      setAddress({
        location_lat: res.data.location_lat,
        location_long: res.data.location_long,
      })
      setParamsAddress({ address: res.data.address })

      setDataSource({
        ...dataSource,
        province_id: res.data.province_id,
        district_id: res.data.district_id,
        ward_id: res.data.ward_id,
        is_default: res.data.is_default,
      })
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }
  const getListProvince = async () => {
    try {
      const res = await getProvince()

      const convert = res.data?.map((item: any) => ({
        label: item.name,
        value: item.id,
      }))
      setListProvince(convert)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const getListDistrict = async () => {
    try {
      const res = await getDistrict({ province_id: dataSource.province_id })
      const convert = res.data?.map((item: any) => ({
        label: item.name,
        value: item.id,
      }))
      setListDistrict(convert)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const getListWard = async () => {
    try {
      const res = await getWard({ district_id: dataSource.district_id })
      const convert = res.data?.map((item: any) => ({
        label: item.name,
        value: item.id,
      }))
      setListWard(convert)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const getAutoCompletePlace = async () => {
    try {
      const res = await getPlaceAuto(paramsAddress)
      const convertData = res.data.predictions?.map((item: any) => ({
        value: item.description,
        placeID: item.place_id,
      }))
      setOptions(convertData)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true)
      if (placeID) {
        const res = await getPlace({ placeId: placeID })
        const dataPush = {
          ...values,
          location_lat: res.data.lat,
          location_long: res.data.long,
          is_default: dataSource.is_default,
        }
        if (dataUpdate) {
          await updateAddress(dataUpdate, dataPush)
        } else {
          await createAddress(dataPush)
        }
        requestListAddress()
        onCancel()
        form.resetFields()
      } else {
        if (dataUpdate) {
          const dataPush = {
            ...values,
            location_lat: address.location_lat,
            location_long: address.location_long,
            is_default: dataSource.is_default,
          }
          await updateAddress(dataUpdate, dataPush)

          requestListAddress()
          onCancel()
          form.resetFields()
        } else {
          notificationError(R.strings().please_enter_google_maps_address)
        }
      }
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Modal
      title={R.strings().info_order}
      visible={visibleModal}
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        labelAlign="left"
        form={form}
        onFinish={(values: any) => onFinish(values)}
        scrollToFirstError
      >
        <Form.Item
          labelAlign="right"
          label={R.strings().text_form_name}
          name="name"
          rules={[
            {
              type: 'string',
              message: R.strings().placeholder_enter_name,
            },
            {
              required: true,
              message: R.strings().enter_name,
            },
            {
              min: 1,
              max: 56,
              message: R.strings().err_length_56,
            },
          ]}
        >
          <Input placeholder={R.strings().placeholder_enter_name} />
        </Form.Item>
        <Form.Item
          labelAlign="right"
          name="phone"
          label={R.strings().text_form_phone_signup}
          rules={[
            {
              required: true,
              message: R.strings().text_form_phone_required,
            },
            {
              pattern: REG_PHONE,
              min: 10,
              max: 10,
              message: R.strings().text_form_phone_error,
            },
          ]}
        >
          <Input placeholder={R.strings().text_form_phone_signup} />
        </Form.Item>
        <Form.Item
          labelAlign="right"
          label={R.strings().title_province}
          name="province_id"
          rules={[
            {
              required: true,
              message: R.strings().please_select_province,
            },
          ]}
        >
          <Select
            placeholder={R.strings().title_province}
            style={{ width: '100%' }}
            showSearch
            optionFilterProp="children"
            allowClear
            onChange={(value: number) => {
              setDataSource({
                ...dataSource,
                province_id: value,
                district_id: 0,
                ward_id: 0,
              })
              form.setFieldsValue({ district_id: null, ward_id: null })
            }}
          >
            {listProvince?.map((item: any) => {
              return <Option value={item?.value}>{item?.label}</Option>
            })}
          </Select>
        </Form.Item>
        <Form.Item
          labelAlign="right"
          label={R.strings().title_district}
          name="district_id"
          rules={[
            {
              required: true,
              message: R.strings().please_select_district,
            },
          ]}
        >
          <Select
            placeholder={R.strings().title_district}
            style={{ width: '100%' }}
            showSearch
            optionFilterProp="children"
            allowClear
            onChange={(value: number) => {
              setDataSource({ ...dataSource, district_id: value, ward_id: 0 })
              form.setFieldsValue({ ward_id: null })
            }}
          >
            {listDistrict?.map((item: any) => {
              return <Option value={item?.value}>{item?.label}</Option>
            })}
          </Select>
        </Form.Item>
        <Form.Item
          labelAlign="right"
          label={R.strings().title_wards}
          name="ward_id"
          rules={[
            {
              required: true,
              message: R.strings().please_select_ward,
            },
          ]}
        >
          <Select
            placeholder={R.strings().title_wards}
            style={{ width: '100%' }}
            showSearch
            optionFilterProp="children"
            allowClear
            onChange={(value: number) => {
              setDataSource({ ...dataSource, ward_id: value })
            }}
          >
            {listWard?.map((item: any) => {
              return <Option value={item?.value}>{item?.label}</Option>
            })}
          </Select>
        </Form.Item>
        <Form.Item
          labelAlign="right"
          label={R.strings().address_detail}
          name="address"
          rules={[
            {
              required: true,
              message: R.strings().please_enter_specific_address,
            },
            { min: 1, max: 255, message: R.strings().err_length_255 },
          ]}
        >
          <Input
            style={{ width: '100%' }}
            placeholder={R.strings().address_detail}
          />
        </Form.Item>
        <Form.Item
          labelAlign="right"
          label={R.strings().google_map_address}
          name="location_address"
          rules={[
            {
              required: true,
              message: R.strings().please_enter_google_maps_address,
            },
            { min: 1, max: 255, message: R.strings().err_length_255 },
          ]}
        >
          <AutoComplete
            options={options}
            style={{ width: '100%' }}
            onSelect={(data: string, option: any) => {
              setPlaceID(option.placeID)
            }}
            onChange={(value: any) => {
              if (value) setParamsAddress({ address: value })
            }}
            placeholder={R.strings().google_map_address}
          />
        </Form.Item>

        <BlockInputUpdate>
          {R.strings().set_as_default_address}
          <SwitchStyled
            style={{ marginLeft: '8px' }}
            checked={dataSource.is_default ? true : false}
            onChange={value => {
              setDataSource({ ...dataSource, is_default: value ? 1 : 0 })
            }}
          />
        </BlockInputUpdate>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{
              width: '70%',
              color: 'white',
              backgroundColor: '#F26522',
              borderRadius: '16px',
            }}
            htmlType="submit"
            loading={isLoading}
            onClick={() => {}}
          >
            {R.strings().save}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
export default ModalUpdateAddress
