import React, { useEffect, useState } from 'react'
import { ButtonStyled, InputStyled, ModalStyled } from '../styled'
import R from 'assets'
import { AutoComplete, Form } from 'antd'
import { REG_PHONE } from 'utils/constants'
import { notificationSuccess } from 'utils/notification'
import { requestBattery, requestChangeOli } from '../ApiHome'
import { getPlace, getPlaceAuto } from 'features/payment/ApiPayment'
const ModalRequest: React.FC<any> = ({
  visibleChangeOil,
  setVisibleChangeOil,
  visibleBatter,
  setVisibleBatter,
}) => {
  const [form] = Form.useForm()
  const [isLoading, setLoading] = useState(false)
  const [options, setOptions] = useState<Array<any>>([])
  const [placeID, setPlaceID] = useState(null)
  const [paramsAddress, setParamsAddress] = useState({ address: '' })

  useEffect(() => {
    const run = setTimeout(() => {
      getAutoCompletePlace()
    }, 450)
    return () => clearTimeout(run)
  }, [paramsAddress])

  const getAutoCompletePlace = async () => {
    try {
      const res = await getPlaceAuto(paramsAddress)
      const convertData = res.data.predictions?.map((item: any) => ({
        value: item.description,
        placeID: item.place_id,
      }))
      setOptions(convertData)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }
  const onFinish = async (values: any) => {
    try {
      setLoading(true)
      let resLocation
      if (placeID) {
        resLocation = await getPlace({ placeId: placeID })
      }
      if (visibleChangeOil) {
        const dataPush = {
          name: values.name,
          address_customer: values.address_customer,
          address_google_map: values.address_google_map,
          location_lat: values.location_lat,
          location_long: values.address_customer,
          phone: values.phone,
          car_company: values.address_customer,
          note_content: values.note_content,
        }
      } else {
        const dataPush = {
          name: values.name,
          address_customer: values.address_customer,
          address_google_map: paramsAddress.address,
          location_lat: resLocation.data.lat || 10,
          location_long: resLocation.data.long || 10,
          phone: values.phone,
          car_company: values.car_company,
          manufacture_year: values.manufacture_year,
          type_battery: values.type_battery,
        }
        await requestBattery(dataPush)
        notificationSuccess(R.strings().text_form_success)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      visibleChangeOil ? setVisibleChangeOil(false) : setVisibleBatter(false)
      // form.resetFields()
    }
  }
  return (
    <ModalStyled
      visible={visibleChangeOil ? visibleChangeOil : visibleBatter}
      onCancel={() => {
        visibleChangeOil ? setVisibleChangeOil(false) : setVisibleBatter(false)
        form.resetFields()
      }}
      title={
        visibleChangeOil
          ? R.strings().title_oil_change_at_home
          : R.strings().title_battery_rescue
      }
      footer={null}
      destroyOnClose
    >
      <Form
        layout="vertical"
        form={form}
        name="normal_login"
        style={{ padding: 0, fontSize: 16 }}
        initialValues={{
          remember: true,
          username: null,
          password: null,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label={<span>{R.strings().contact}</span>}
          name="name"
          rules={[
            {
              max: 55,
              message: R.strings().err_length_55,
            },
          ]}
        >
          <InputStyled size="large" placeholder={R.strings().contact} />
        </Form.Item>
        <Form.Item
          label={<span>{R.strings().address_customer}</span>}
          name="address_customer"
          rules={[
            {
              required: true,
              message: R.strings().address_customer,
            },
            {
              max: 255,
              message: R.strings().err_length_255,
            },
          ]}
        >
          <InputStyled
            size="large"
            placeholder={R.strings().address_customer}
          />
        </Form.Item>
        <Form.Item
          label={<span>{R.strings().text_form_phone_login}</span>}
          name="phone"
          rules={[
            {
              required: true,
              message: R.strings().notify_enter_phone,
            },
            {
              pattern: REG_PHONE,
              max: 10,
              message: R.strings().err_phone,
            },
          ]}
        >
          <InputStyled
            size="large"
            placeholder={R.strings().text_form_placeholder_phone}
          />
        </Form.Item>
        <Form.Item
          label={<span>{R.strings().vehicle_type}</span>}
          name="car_company"
          rules={[
            {
              required: true,
              message: R.strings().vehicle_type,
            },
            {
              max: 255,
              message: R.strings().err_length_255,
            },
          ]}
        >
          <InputStyled
            size="large"
            placeholder={`${R.strings().vehicle_type} (honda-wave)`}
          />
        </Form.Item>

        {visibleChangeOil ? (
          <Form.Item
            label={<span>{R.strings().note_more}</span>}
            name="note_content"
          >
            <InputStyled size="large" placeholder={R.strings().note_more} />
          </Form.Item>
        ) : (
          <>
            <Form.Item
              label={<span>{R.strings().manufacturing}</span>}
              name="manufacture_year"
              rules={[
                {
                  max: 4,
                  message: R.strings().err_length_4,
                },
              ]}
            >
              <InputStyled
                size="large"
                placeholder={R.strings().manufacturing}
              />
            </Form.Item>
            <Form.Item
              label={<span>{R.strings().battery_type}</span>}
              name="type_battery"
              rules={[
                {
                  required: true,
                  message: R.strings().battery_type,
                },
                {
                  max: 255,
                  message: R.strings().err_length_255,
                },
              ]}
            >
              <InputStyled
                size="large"
                placeholder={R.strings().battery_type}
              />
            </Form.Item>
          </>
        )}
        <Form.Item
          label={<span>{R.strings().google_map_address}</span>}
          name="address_google"
          rules={[
            {
              required: true,
              message: R.strings().google_map_address,
            },
          ]}
        >
          <AutoComplete
            options={options}
            style={{ width: '100%' }}
            onSelect={(data: string, option: any) => {
              setPlaceID(option.placeID)
            }}
            onChange={(value: any) => {
              if (value) setParamsAddress({ address: value })
            }}
            placeholder={R.strings().google_map_address}
          />
        </Form.Item>
        <Form.Item>
          <ButtonStyled htmlType="submit">
            {R.strings().title_signup}
          </ButtonStyled>
        </Form.Item>
      </Form>
    </ModalStyled>
  )
}
export default ModalRequest
