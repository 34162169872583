import {
  ArrowRightOutlined,
  EnvironmentFilled,
  PhoneFilled,
  StarFilled,
} from '@ant-design/icons'
import { Empty, Pagination, Row, Spin, Tabs, Typography } from 'antd'
import R from 'assets'
import { InsuranceCard } from 'common/components/CardInsurance'
import { ProductCard } from 'common/components/CardProduct'
import { StyledCol } from 'common/components/CardProduct/styled'
import { GoogleMapIcon } from 'common/components/Icons'
import { ROUTER_PATH } from 'common/config'
import { WrapBox } from 'features/home/styled'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'
import { RecordGas } from '../product/styles'
import { getDetailStore, getListOther, listCategory } from './ApiStore'
import { ImgStyle, TabStyle, TextGoToMap, TsgStyle } from './styled'

type Params = {
  page: number
  limit: number
  price_status?: any
  age_status?: any
  sell_status?: any
}

const DetailStoreScreen: React.FC = () => {
  const { TabPane } = Tabs
  const { Text } = Typography
  const param: any = useParams()
  const [detailStore, setDetailStore] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataOther, setDataOther] = useState<any>()
  const [dataCategory, setDataCategory] = useState<any>()
  const [pages, setPages] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  })
  const [params, setParams] = useState<Params>({
    page: 1,
    limit: 12,
  })
  const [typeProduct, setTypeProduct] = useState<number>(1)

  useEffect(() => {
    getDataSource()
  }, [])
  useEffect(() => {
    getListCategory()
  }, [])
  useEffect(() => {
    getDataProduct()
  }, [typeProduct, params])

  const getDataSource = async () => {
    try {
      setIsLoading(true)
      const res = await getDetailStore(param.id)
      setDetailStore(res.data)
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }
  const getListCategory = async () => {
    try {
      setIsLoading(true)
      const res = await listCategory(param.id)
      setDataCategory(res.data)
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }
  const getDataProduct = async () => {
    setIsLoading(true)
    try {
      const res = await getListOther(param?.id, typeProduct, params)
      setDataOther(res?.data)
      setPages({
        ...pages,
        current: res.meta?.pagination?.page,
        total: res.meta?.pagination?.totalItems,
        pageSize: res.meta?.pagination?.limit,
      })
      // }
    } catch (error) {
      console.error('Exception ' + error)
    }
    setIsLoading(false)
  }
  const searchParams = new URLSearchParams(window.location.search)
  const PetrolStoreId: string = searchParams.get('petrol_store_id') as string

  return (
    <div>
      <Spin spinning={isLoading}>
        <Row
          justify="space-between"
          style={{
            backgroundColor: 'white',
            margin: '30px 0 0 0',
            padding: 20,
            borderRadius: '12px 12px 0 0',
          }}
        >
          <Row>
            <ImgStyle src={R.images.img_petrol} alt="" />
            <div>
              <div>
                <b style={{ fontSize: 18 }}>{detailStore?.name}</b>
              </div>
              <div>
                <PhoneFilled style={{ color: '#539643' }} />
                &nbsp; {detailStore?.phone}
              </div>
              <div>
                <EnvironmentFilled style={{ color: '#f26522' }} /> &nbsp;
                {detailStore?.location_address}, {detailStore?.ward?.name},
                {detailStore?.district?.name}, {detailStore?.province?.name}
                &nbsp;&nbsp;
              </div>
            </div>
          </Row>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <TsgStyle
              color={'volcano'}
              onClick={() => {
                history.push({
                  pathname: `${ROUTER_PATH.RATE_STORES}/${param?.id}`,
                })
              }}
            >
              <StarFilled /> &nbsp; {+detailStore?.petrol_store_star.toFixed(1)}
              <ArrowRightOutlined />
            </TsgStyle>
            <TextGoToMap
              style={{ fontSize: 14 }}
              onClick={() => {
                window.open(
                  `https://www.google.com/maps/place/?q=${detailStore.name}/@${detailStore?.location_lat},${detailStore?.location_long}`
                )
              }}
            >
              Chỉ đường
            </TextGoToMap>
          </div>
        </Row>
        <TabStyle
          type="card"
          onChange={(key: any) => {
            setTypeProduct(key)
          }}
        >
          {dataCategory?.map((items: any) => (
            <TabPane
              tab={
                <div>
                  {localStorage.getItem('i18nextLng') === 'vi'
                    ? items.name
                    : items.name_en
                    ? items.name_en
                    : items.name}
                </div>
              }
              key={items?.id}
            >
              <WrapBox style={{ margin: '0 0 30px 0' }}>
                <Row justify="start">
                  {typeProduct == 3 ? (
                    dataOther?.length > 0 ? (
                      dataOther?.map((item: any) => (
                        <>
                          <StyledCol
                            xs={24}
                            sm={12}
                            xl={12}
                            key={item.id}
                            style={{
                              margin: '0 0 15px 0',
                            }}
                          >
                            <InsuranceCard
                              dataProduct={item}
                              onClickProduct={() => {
                                const nameCategory =
                                  localStorage.getItem('i18nextLng') === 'vi'
                                    ? items.name
                                    : items.name_en
                                    ? items.name_en
                                    : items.name
                                history.push({
                                  pathname: `${ROUTER_PATH.DETAIL_PRODUCT}/${item?.id}?type=${typeProduct}&category=${nameCategory}&product_id=${item.id}&nameProduct=${item.name}&petrol_store_id=${item?.product_stocks[0]?.petrol_store_id}`,
                                })
                              }}
                            />
                          </StyledCol>
                        </>
                      ))
                    ) : (
                      <>
                        <div style={{ width: '100%' }}>
                          <Empty description={R.strings().empty} />
                        </div>
                      </>
                    )
                  ) : typeProduct != 1 ? (
                    dataOther?.length > 0 ? (
                      dataOther?.map((item: any) => (
                        <>
                          <StyledCol
                            xs={12}
                            sm={8}
                            xl={6}
                            key={item.id}
                            style={{
                              margin: '0 0 15px 0',
                            }}
                          >
                            <ProductCard
                              dataProduct={item}
                              onClickProduct={() => {
                                const nameCategory =
                                  localStorage.getItem('i18nextLng') === 'vi'
                                    ? items.name
                                    : items.name_en
                                    ? items.name_en
                                    : items.name
                                history.push({
                                  pathname: `${ROUTER_PATH.DETAIL_PRODUCT}/${item?.id}?type=${typeProduct}&category=${nameCategory}&product_id=${item.id}&nameProduct=${item.name}&petrol_store_id=${item?.product_stocks[0]?.petrol_store_id}`,
                                })
                              }}
                            />
                          </StyledCol>
                        </>
                      ))
                    ) : (
                      <>
                        <div style={{ width: '100%' }}>
                          <Empty description={R.strings().empty} />
                        </div>
                      </>
                    )
                  ) : (
                    <></>
                  )}
                  {dataOther?.length > 0 ? (
                    <Row
                      justify="end"
                      style={
                        typeProduct == 1
                          ? { display: 'none' }
                          : { marginTop: '12px', width: '100%' }
                      }
                    >
                      <Pagination
                        onChange={async (page, pageSize) => {
                          setParams({ ...params, page })
                        }}
                        showSizeChanger={false}
                        pageSize={pages.pageSize}
                        current={pages.current}
                        total={pages.total}
                      />
                    </Row>
                  ) : (
                    <></>
                  )}
                </Row>
                {typeProduct == 1 ? (
                  dataOther?.length > 0 ? (
                    <>
                      <Row
                        style={{
                          width: '100%',
                          padding: '10px 15px 10px 15px',
                        }}
                        justify="space-between"
                      >
                        <div style={{ fontSize: 16, fontWeight: 500 }}>
                          {R.strings().title_product}
                        </div>
                        <div style={{ fontSize: 16, fontWeight: 500 }}>
                          {R.strings().price}
                        </div>
                      </Row>
                      <div
                        style={{
                          overflowY: 'auto',
                          overflowX: 'hidden',
                          height: '50vh',
                          maxHeight: 550,
                          padding: '10px 5px 0 0',
                        }}
                      >
                        {dataOther?.map((item: any, index: number) => (
                          <RecordGas
                            justify="space-between"
                            onClick={() => {
                              // setChooseGas(item.id)
                              history.push({
                                pathname: `${ROUTER_PATH.BUY_GAS}/${item?.product_stocks[0]?.id}`,
                              })
                            }}
                          >
                            <Row>
                              <img src={R.images.icon_gas} alt="" />
                              <div style={{ padding: '0 0 0 10px' }}>
                                {item.name}
                              </div>
                            </Row>
                            <div style={{ color: '#f26522' }}>
                              {item.min_price ? (
                                <>
                                  <Text
                                    delete
                                    style={{ fontSize: 14, color: 'gray' }}
                                  >
                                    {formatPrice(item.product_stocks[0]?.price)}
                                  </Text>
                                  &nbsp;
                                  {formatPrice(item.min_price)}
                                </>
                              ) : (
                                formatPrice(item.product_stocks[0]?.price)
                              )}
                              &nbsp;{R.strings().title_unit}
                            </div>
                          </RecordGas>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ width: '100%' }}>
                        <Empty description={R.strings().empty} />
                      </div>
                    </>
                  )
                ) : (
                  <></>
                )}
              </WrapBox>
            </TabPane>
          ))}
        </TabStyle>
      </Spin>
    </div>
  )
}
export default DetailStoreScreen
