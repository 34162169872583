import { RightCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import R from 'assets'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './styles/WebViewVNP.module.css'
export default function WebViewVNP() {
  const [statusVNP, setStatusVNP] = useState<number>(0)
  const [orderType, setOrderType] = useState<number>(0)
  const location = useLocation()
  useEffect(() => {
    const urlParams: any = new URLSearchParams(location.search).get('status')
    const type: any = new URLSearchParams(location.search).get('order_type')
    if (urlParams) {
      const value = parseInt(urlParams)
      setStatusVNP(value)
      setOrderType(type)
    }
  }, [])

  const renderScreen = () => {
    switch (statusVNP) {
      case 1:
        return (
          <div>
            <div
              style={{
                color: 'green',
              }}
              className={styles.contend_view}
            >
              Thanh toán thành công!!
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  window.location.href = `yoyo://success?order_type=${orderType}`
                }}
              >
                Quay lại ứng dụng <RightCircleOutlined />
              </Button>
            </div>
          </div>
        )
      case 0:
        return (
          <div>
            <div
              style={{
                color: 'red',
              }}
              className={styles.contend_view}
            >
              Thanh toán thất bại!!
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              <Button
                onClick={() => {
                  window.location.href = 'yoyo://failed/'
                }}
                type="primary"
              >
                Quay lại ứng dụng
                <RightCircleOutlined />
              </Button>
            </div>
          </div>
        )
      default:
        return <div className={styles.contend_view}>Loading...</div>
    }
  }

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.logo_VNP}>
          <img src={R.images.logo_vnpay} alt="" style={{ maxWidth: '30vw' }} />
        </div>
        {renderScreen()}
      </div>
    </div>
  )
}
