import { createSlice } from '@reduxjs/toolkit'

let initialState: any = {
  coordinates: {
    location_lat: undefined,
    location_long: undefined,
  },
}

export const getDataCoordinates = (state: any) => state.storeReducer.coordinates

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setDataCoordinates: (state, action) => {
      state.coordinates.location_lat = action.payload.lat
      state.coordinates.location_long = action.payload.lng
    },
  },
})

export const { setDataCoordinates } = storeSlice.actions

export default storeSlice.reducer
