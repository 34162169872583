import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Form, Row, Spin } from 'antd'
import { ROUTER_PATH, SESSION_KEY } from 'common/config'
import Cookie from 'js-cookie'
import { useState } from 'react'
import { REG_PHONE } from 'utils/constants'
import history from 'utils/history'
import R from 'assets'
import './authStyle.css'
import {
  ContentBlock,
  LeftBlock,
  LogoForm,
  RightBlock,
  TitleForm,
  TextForget,
  ButtonForm,
  HaveCount,
  ImageAuth,
  InputForm,
  InputFormPassword,
  ImageLogo,
} from './authStyle'
import { requestLogin } from './AuthApi'
import { notificationSuccess } from 'utils/notification'
import { useDispatch } from 'react-redux'
import { getUserInfoAction } from './AuthSlice'
import { Link, useLocation } from 'react-router-dom'

function LoginScreen(props: any) {
  const [isLoading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const location = useLocation()

  const onFinish = async (values: any) => {
    try {
      setLoading(true)
      const resLogin = await requestLogin({
        username: values.username.trim(),
        password: values.password,
      })
      Cookie.set(SESSION_KEY.SESSION, resLogin.data.token, {
        expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
      })
      notificationSuccess(R.strings().login_success)
      dispatch(getUserInfoAction())
      if (location?.hash) {
        history.push(location?.hash.replace('#', '') + location.search)
      } else {
        history.push(ROUTER_PATH.HOME)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <ContentBlock>
      <LeftBlock>
        <ImageAuth
          src={R.images.image_login}
          alt="Image Banner "
          loading="eager"
        />
      </LeftBlock>
      <RightBlock>
        <LogoForm>
          <Link to="/">
            <ImageLogo
              src={R.images.logo_yoyo}
              alt="App icon"
              loading="eager"
            />
          </Link>
        </LogoForm>
        <TitleForm>{R.strings().title_login}</TitleForm>
        <Form
          layout="vertical"
          form={form}
          name="normal_login"
          style={{ padding: 0 }}
          initialValues={{
            remember: true,
            username: null,
            password: null,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={<span>{R.strings().text_form_phone_login}</span>}
            name="username"
            rules={[
              {
                required: true,
                message: R.strings().notify_enter_phone,
              },
              {
                pattern: REG_PHONE,
                max: 10,
                message: R.strings().err_phone,
              },
            ]}
          >
            <InputForm
              size="large"
              prefix={<UserOutlined />}
              placeholder={R.strings().text_form_placeholder_phone}
            />
          </Form.Item>
          <Form.Item
            label={<span>{R.strings().text_form_password}</span>}
            name="password"
            rules={[
              { required: true, message: R.strings().notify_enter_password },
              {
                max: 55,
                min: 6,
                message: R.strings().err_password_lenght,
              },
              {
                whitespace: true,
                message: R.strings().err_password_space,
              },
            ]}
          >
            <InputFormPassword
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder={R.strings().text_form_placeholder_password}
            />
          </Form.Item>
          <Row
            justify="space-between"
            style={{
              fontWeight: 400,
              width: '100%',
              fontSize: '2vh',
            }}
          >
            <>&nbsp;</>
            <TextForget
              onClick={() => {
                history.push(ROUTER_PATH.FORGOT_PASSWORD)
              }}
            >
              {R.strings().text_form_forgot}?
            </TextForget>
          </Row>
          <Form.Item style={{ margin: 0 }}>
            <Row justify="center">
              <Spin spinning={isLoading}>
                <ButtonForm style={{ cursor: 'pointer' }}>
                  {R.strings().title_login}
                </ButtonForm>
              </Spin>
            </Row>
          </Form.Item>
          <HaveCount>
            {R.strings().text_form_havent_acc}?&nbsp;
            <p
              onClick={() => {
                history.push(ROUTER_PATH.SIGNUP)
              }}
            >
              {R.strings().title_signup}
            </p>
          </HaveCount>
        </Form>
      </RightBlock>
    </ContentBlock>
  )
}

export default LoginScreen
