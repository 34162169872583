import { ArrowLeftOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { Divider, Form, Input, Row, Spin, Statistic } from 'antd'
import { requestSignup, verifySignup } from '../AuthApi'
import {
  ContentBlock,
  CodeBlock,
  AboveBlock,
  TextCode,
  UnderBlock,
  ButtonCode,
  StepCode,
  FooterCode,
  SubStep,
  InputCode,
} from '../authStyle'
import Cookies from 'js-cookie'
import { SESSION_KEY } from 'common/config'
import { notificationSuccess } from 'utils/notification'
import R from 'assets'
import OtpInput from 'react-otp-input'
import { caculateCountDown } from 'utils/functionHelper'

const now = Date.now()

type Props = {
  setStep: any
  userId: any
  phoneSignup: any
  time: any
  setTime: any
}
const { Countdown } = Statistic
export const Step1SignupScreen: React.FC<Props> = ({
  userId,
  setStep,
  phoneSignup,
  time,
  setTime,
}: Props) => {
  const [outTime, setOutTiem] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [otp, setOtp] = useState<any>('')

  const onFinish = async (values: any) => {
    try {
      let newData

      newData = {
        code: otp,
      }
      setLoading(true)
      const res = await verifySignup(newData, userId)
      Cookies.set(SESSION_KEY.SESSION, res.data.token, {
        expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
      })
      notificationSuccess(res?.message)
      setStep(2)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }
  return (
    <ContentBlock>
      <div>
        <CodeBlock>
          <AboveBlock justify="space-between">
            <ArrowLeftOutlined onClick={() => setStep(0)} />
            <span>{R.strings().text_form_otp}</span>
            <ArrowLeftOutlined style={{ color: 'white' }} />
          </AboveBlock>
          <Divider style={{ margin: 0 }} />
          <UnderBlock>
            <TextCode>{R.strings().text_form_enter_phone}</TextCode>
            <Form
              // layout="inline"
              form={form}
              name="normal_login"
              className="login-form"
              initialValues={{
                code: null,
              }}
              onFinish={onFinish}
              style={{ padding: '0 30px 0 30px' }}
            >
              <InputCode
                maxLength={6}
                onChange={(e: any) => {
                  setOtp(e.target.value)
                }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    maxWidth: 280,
                    display: 'flex',
                    flexFlow: 'nowrap',
                  }}
                >
                  <div
                    style={{
                      height: 2,
                      width: 40,
                      margin: '0 2px 0 2px',
                      backgroundColor: 'black',
                    }}
                  ></div>
                  <div
                    style={{
                      height: 2,
                      width: 40,
                      margin: '0 2px 0 2px',
                      backgroundColor: 'black',
                    }}
                  ></div>
                  <div
                    style={{
                      height: 2,
                      width: 40,
                      margin: '0 2px 0 2px',
                      backgroundColor: 'black',
                    }}
                  ></div>
                  <div
                    style={{
                      height: 2,
                      width: 40,
                      margin: '0 2px 0 2px',
                      backgroundColor: 'black',
                    }}
                  ></div>
                  <div
                    style={{
                      height: 2,
                      width: 40,
                      margin: '0 2px 0 2px',
                      backgroundColor: 'black',
                    }}
                  ></div>
                  <div
                    style={{
                      height: 2,
                      width: 40,
                      margin: '0 2px 0 2px',
                      backgroundColor: 'black',
                    }}
                  ></div>
                </div>
              </div>
              {outTime ? (
                <></>
              ) : (
                <>
                  <span
                    style={{
                      fontSize: 16,
                      padding: '5vh 0px 8px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {R.strings().text_form_expired}:
                  </span>
                  <Countdown
                    valueStyle={{
                      color: '#F26522',
                      fontSize: '16px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    value={now + time}
                    format="mm:ss"
                    onFinish={() => setOutTiem(true)}
                  />
                </>
              )}
              <Form.Item
                style={
                  outTime == false
                    ? {
                        padding: '20px 0px 20px 0px',
                      }
                    : { display: 'none' }
                }
              >
                <Spin spinning={isLoading}>
                  <ButtonCode>{R.strings().text_form_confirm}</ButtonCode>
                </Spin>
              </Form.Item>
            </Form>
            <Form
              style={{
                margin: '60px 0 0 0',
              }}
            >
              {outTime ? R.strings().text_form_havent_send : ''}
              <Form.Item
                style={
                  outTime
                    ? {
                        padding: '20px 0px 20px 0px',
                        textAlign: 'center',
                      }
                    : { display: 'none' }
                }
              >
                <Spin spinning={isLoading}>
                  <button
                    style={{
                      color: '#F26522',
                      border: 'none',
                      backgroundColor: 'unset',
                      cursor: 'pointer',
                    }}
                    onClick={async () => {
                      const res = await requestSignup({
                        phone: phoneSignup,
                      })
                      // Cookies.set(SESSION_KEY.SESSION, res.data.token, {
                      //   expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
                      // })
                      setTime(caculateCountDown(res.data.expired_at))
                      notificationSuccess(R.strings().notify_otp_again)
                      setOutTiem(false)
                      setLoading(false)
                    }}
                  >
                    {R.strings().text_form_send}
                  </button>
                </Spin>
              </Form.Item>
            </Form>
          </UnderBlock>
        </CodeBlock>
        <FooterCode>
          <StepCode direction="horizontal" responsive={false}>
            <SubStep></SubStep>
            <SubStep></SubStep>
            <SubStep></SubStep>
          </StepCode>
        </FooterCode>
      </div>
    </ContentBlock>
  )
}
