import { Popconfirm } from 'antd'
import R from 'assets'
import { deleteAddress, getListAddress } from 'features/payment/ApiPayment'
import { IAddress } from 'features/payment/interface'
import {
  AddressItem,
  ButtonUpdateAddress,
  DefaultTag,
  SelectAddressBlock,
} from 'features/payment/styled'
import React, { useEffect, useState } from 'react'
import { notificationSuccess } from 'utils/notification'
import * as Styled from '../styled'
import ModalUpdateAddress from 'features/payment/components/ModalUpdateAddress'
import { PlusCircleOutlined } from '@ant-design/icons'
import { LocationIcon } from 'common/components/Icons'

const LocationTab: React.FC = () => {
  const [listAddress, setListAddress] = useState<Array<IAddress>>([])
  const [visibleModal, setVisibleModal] = useState<boolean>(false)
  const [dataUpdate, setDataUpdate] = useState<number | null>(null)

  useEffect(() => {
    requestListAddress()
  }, [])

  const requestListAddress = async () => {
    try {
      const res = await getListAddress({
        search: undefined,
        page: 1,
      })
      const convertData = res.data?.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          phone: item.phone,
          address:
            item?.address +
            ', ' +
            item?.ward_name +
            ', ' +
            item?.district_name +
            ', ' +
            item?.province_name,
          is_default: item.is_default,
          location_lat: item.location_lat,
          location_long: item.location_long,
        }
      })
      setListAddress(convertData)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }
  return (
    <>
      <Styled.HeaderLocation>
        <span id="title">{R.strings().my_address}</span>
        <Styled.ButtonAdd
          onClick={() => {
            setVisibleModal(true)
          }}
          icon={<PlusCircleOutlined />}
        >
          {R.strings().add_address}
        </Styled.ButtonAdd>
      </Styled.HeaderLocation>
      <Styled.BodyAddress>
        {listAddress?.map((item: any, index: number) => (
          <AddressItem key={index}>
            <SelectAddressBlock>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  window.open(
                    `https://www.google.com/maps/place/?q=${item.name}/@${item?.location_lat},${item?.location_long}`
                  )
                }}
              >
                <LocationIcon style={{ marginRight: '10px' }} />
                <div style={{ lineHeight: '32px' }}>
                  <div id="address">{item.address}</div>
                  <div style={{ fontWeight: 600 }}>
                    {item.name} | {item.phone}
                    {item.is_default ? (
                      <DefaultTag>{R.strings().title_default}</DefaultTag>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                <ButtonUpdateAddress
                  onClick={() => {
                    setDataUpdate(item.id)
                    setVisibleModal(true)
                  }}
                >
                  {R.strings().title_edit}
                </ButtonUpdateAddress>
                <div
                  style={{
                    border: '1px solid #F26522',
                    backgroundColor: '#F26522',
                    margin: '5px',
                  }}
                ></div>
                <Popconfirm
                  title={R.strings().delete_address_question}
                  onConfirm={async () => {
                    try {
                      await deleteAddress(item.id)
                      notificationSuccess('Xóa thành công')
                      requestListAddress()
                    } catch (error) {
                      console.error('Exception ' + error)
                    }
                  }}
                  cancelText={R.strings().cancel_order}
                >
                  <ButtonUpdateAddress>
                    {R.strings().title_delete}
                  </ButtonUpdateAddress>
                </Popconfirm>
              </div>
            </SelectAddressBlock>
          </AddressItem>
        ))}
      </Styled.BodyAddress>

      <ModalUpdateAddress
        visibleModal={visibleModal}
        dataUpdate={dataUpdate}
        requestListAddress={requestListAddress}
        onCancel={() => {
          setVisibleModal(false)
          setDataUpdate(null)
        }}
      />
    </>
  )
}
export default LocationTab
