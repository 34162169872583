import { Breadcrumb, Col, Row } from 'antd'
import R from 'assets'
import { ROUTER_PATH } from 'common/config'
import { IProductItem, IStoreItem } from 'features/cart/interface'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { TYPE_ORDER } from 'utils/constants'
import { notificationError, notificationSuccess } from 'utils/notification'
import { EMAIL_REGEX } from 'utils/ruleForm'
import { createPayment, createPaymentUrl } from './ApiPayment'
import ExportBill from './components/ExportBill'
import InsuranceItem from './components/InsuranceItem'
import OilItem from './components/OilItem'
import Payments from './components/Payments'
import { PAYMENTS } from 'utils/constants'
import PetrolItem from './components/PetrolItem'
import Transport from './components/Transport'
import {
  TDataInsuranceItem,
  TDataOilItem,
  TDataPetrolItem,
  TExportData,
  TVehicleData,
} from './interface'
import { WarpPayment } from './styled'
import { useDispatch, useSelector } from 'react-redux'
import { getUserInfoAction, setAmountCart } from 'features/auth/AuthSlice'
import { paymentVT } from 'features/order/ApiOrder'

const PaymentScreen: React.FC = () => {
  const location: any = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const userState = useSelector((state: any) => state.authReducer)?.userInfo
  const [dataOil, setDataOil] = useState<Array<TDataOilItem>>([
    {
      address_store: 0,
      petrol_store_id: 0,
      name: '',
      name_store: '',
      phone_store: '',
      note: '',
      user_address_id: 0,
      total_price: 0,
      items: [
        {
          cart_id: 0,
          amount: 0,
          product_stock_id: 0,
          name_product: '',
          price: 0,
          sale_price: 0,
          img_product: '',
        },
      ],
      isGetAtHome: 2,
      isCheckedPoint: false,
      canUsePoint: true,
      point_used: 0,
      status_home: 0,
      quantity_home: 0,
      maxAmount: 0,
    },
  ])

  const [dataInsurance, setDataInsurance] = useState<TDataInsuranceItem>({
    product: {
      name: '',
      price: '',
      media: '',
    },
    amount: 0,
    petrol_store_id: 0,
    product_stock_id: 0,
    total_price: 0,
    isCheckedPoint: false,
    point: 0,
    isGetAtHome: 2,
    user_address_id: 0,
    name: '',
    number_plate: '',
    vehicle_brand: '',
  })

  const [dataPetrol, setDataPetrol] = useState<TDataPetrolItem>({
    amount: 0,
    is_full_fuel: 0,
    isKeepStock: 0,
    liters: 0,
    name: '',
    petrol_store: {
      name: '',
      petrol_store_star: 0,
      review_count: 0,
      address: '',
    },
    petrol_store_id: 0,
    price_per_liter: 0,
    product_stock_id: 0,
    total_price: 0,
    user_address_id: 0,
    isCheckedPoint: false,
    point: 0,

    // lấy xăng tại nhà, cửa hàng
    // isGetAtHome: 2,
    // status_home: 0,
    // maxAmount: 0,
    // quantity_home: 0,
  })
  const [vehicleData, setVehicleData] = useState<TVehicleData>({
    number_plate: '',
    type_vehicle_id: 0,
    is_vehicle: 1,
  })
  const [totalOrderPrice, setTotalOrderPrice] = useState<number>(0)
  const [exportData, setExportData] = useState<TExportData>({
    is_receipt: 0,
    tax: undefined,
    name: '',
    address: '',
    email: '',
  })
  const [typePaymentID, setTypePaymentID] = useState<number | null>(null)

  useEffect(() => {
    dispatch(getUserInfoAction())
    // console.log('location: ', location?.state)
    if (location?.state && location?.state?.type === 'oil') {
      const convertData: Array<TDataOilItem> = location?.state?.data?.map(
        (item: IStoreItem, index: number) => {
          let status = 0
          let quantity = 1000
          let maxAmount = 0
          return {
            address_store: item.address_store,
            petrol_store_id: item.id,
            name: item.name,
            name_store: item.name_store,
            phone_store: item.phone_store,
            note: '',
            user_address_id: 0,
            total_price: item.product_stocks?.reduce(function (
              total: number,
              value: any
            ) {
              if (value.sale_price) {
                return total + value.amount * value.sale_price
              } else {
                return total + value.amount * value.price
              }
            },
            0),
            items: item.product_stocks?.map((itemA: IProductItem) => {
              if (itemA.status_home) {
                status = 1
                if (itemA.quantity_home < quantity) {
                  quantity = itemA.quantity_home
                }
                if (itemA.amount > maxAmount) {
                  maxAmount = itemA.amount
                }
              }
              return {
                cart_id: itemA.cart_id,
                amount: itemA.amount,
                product_stock_id: itemA.product_stock_id,
                name_product: itemA.name_product,
                price: itemA.price,
                sale_price: itemA.sale_price,
                img_product: itemA.img_product,
              }
            }),
            status_home: status,
            quantity_home: quantity,
            maxAmount: maxAmount,
            isGetAtHome: 2,
            isCheckedPoint: false,
            canUsePoint: true,
            point_used: 0,
          }
        }
      )
      setDataOil(convertData)
    }

    if (location?.state?.type === 'insurance') {
      setDataInsurance({
        product: {
          name: location?.state?.product?.name,
          price: location?.state?.product.price,
          media: location?.state?.product?.media,
        },
        amount: location?.state?.amount,
        petrol_store_id: location?.state?.petrol_store_id,
        product_stock_id: location?.state?.product_stock_id,
        total_price: location?.state?.total_price,
        isCheckedPoint: false,
        point: 0,
        isGetAtHome: 2,
        user_address_id: 0,
        name: '',
        number_plate: '',
        vehicle_brand: '',
      })
    }
    if (location?.state?.type === 'petrol') {
      if (location?.state?.is_full_fuel) {
        setTypePaymentID(4)
      }
      setDataPetrol({
        amount: location?.state?.amount,
        is_full_fuel: location?.state?.is_full_fuel,
        isKeepStock: location?.state?.isKeepStock,
        liters: location?.state?.liters,
        name: location?.state?.name,
        petrol_store: {
          name: location?.state?.petrol_store.name,
          petrol_store_star: location?.state?.petrol_store.petrol_store_star,
          review_count: location?.state?.petrol_store.review_count,
          address: location?.state?.petrol_store.address,
          phone_store: location?.state?.petrol_store.phone_store,
        },
        petrol_store_id: location?.state?.petrol_store_id,
        price_per_liter: location?.state?.price_per_liter,
        product_stock_id: location?.state?.product_stock_id,
        total_price: parseInt(location?.state?.total_price),
        user_address_id: 0,
        isCheckedPoint: false,
        point: 0,
        product: location?.state?.product,
        // cấu hình lấy xăng tại nhà, cửa hàng
        // isGetAtHome: 2,
        // status_home: 0,
        // maxAmount: 0,
        // quantity_home: 0,
      })
    }
    if (
      location?.state?.type === 'petrol' ||
      location?.state?.type === 'insurance'
    ) {
      setTotalOrderPrice(location?.state?.total_price)
    }
  }, [location])

  useEffect(() => {
    if (
      !dataOil?.find((itemFind: TDataOilItem) => itemFind.isGetAtHome === 2)
    ) {
      setVehicleData({ ...vehicleData, is_vehicle: 0 })
    } else {
      setVehicleData({ ...vehicleData, is_vehicle: 1 })
    }

    if (location?.state?.type === 'oil') {
      setTotalOrderPrice(
        dataOil?.reduce(function (total: number, value: any) {
          return total + value.total_price
        }, 0)
      )
    }
  }, [dataOil])

  const renderProduct = (type: string) => {
    switch (type) {
      case 'oil':
        return (
          <>
            {dataOil?.map((item: TDataOilItem, index: number) => (
              <OilItem
                key={index}
                indexStore={index}
                userState={userState}
                data={item}
                dataOil={dataOil}
                setDataOil={setDataOil}
              />
            ))}
          </>
        )
      case 'petrol':
        return <PetrolItem data={dataPetrol} setDataPetrol={setDataPetrol} />
      case 'insurance':
        return (
          <InsuranceItem
            data={dataInsurance}
            setDataInsurance={setDataInsurance}
          />
        )
    }
  }

  const onValidateBeforeConfirm = () => {
    if (vehicleData.is_vehicle && !vehicleData.number_plate) {
      notificationError(R.strings().please_enter_the_license_plate)
      document.getElementById('number_plate')?.focus()
      return false
    }
    if (exportData.is_receipt && !exportData.tax) {
      notificationError(R.strings().enter_tax_code)
      document.getElementById('exportData_tax')?.focus()
      return false
    }
    if (exportData.is_receipt && !exportData.name) {
      notificationError(R.strings().enter_company)
      document.getElementById('exportData_name')?.focus()
      return false
    }
    if (exportData.is_receipt && !exportData.address) {
      notificationError(R.strings().please_enter_your_address)
      document.getElementById('exportData_address')?.focus()
      return false
    }
    if (exportData.is_receipt && !exportData.email) {
      notificationError(R.strings().enter_email)
      document.getElementById('exportData_email')?.focus()
      return false
    }
    if (exportData.is_receipt && !EMAIL_REGEX.test(exportData.email)) {
      notificationError(R.strings().err_email)
      document.getElementById('exportData_email')?.focus()
      return false
    }
    if (!typePaymentID) {
      notificationError(R.strings().please_choose_a_form_of_payment)
      return false
    }
    if (typePaymentID === PAYMENTS[3].VALUE && totalOrderPrice < 10000) {
      notificationError(R.strings().err_limit_vnpay)
      return false
    }
    if (!vehicleData.type_vehicle_id) {
      notificationError(R.strings().please_choose_vehicle)
      return false
    }

    if (location?.state?.type === 'insurance' && !dataInsurance.name) {
      notificationError(R.strings().please_enter_owner_name)
      document.getElementById('dataInsurance_name')?.focus()
      return false
    }
    if (location?.state?.type === 'insurance' && !dataInsurance.number_plate) {
      notificationError(R.strings().please_enter_your_address)
      document.getElementById('dataInsurance_number_plate')?.focus()
      return false
    }
    if (location?.state?.type === 'insurance' && !dataInsurance.vehicle_brand) {
      notificationError(R.strings().please_enter_the_car_brand)
      document.getElementById('dataInsurance_vehicle_brand')?.focus()
      return false
    }
    if (location.state?.type === 'oil') {
      for (let i = 0; i < dataOil.length; i++) {
        if (dataOil[i]?.user_address_id === undefined) {
          notificationError(R.strings().please_update_the_address_of_each_order)
          document.getElementById(`add_address_${i}`)?.focus()
          return false
        }
      }
    }

    return true
  }

  const onPayment = async () => {
    if (onValidateBeforeConfirm()) {
      try {
        let dataPush: any = {
          is_receipt: exportData.is_receipt,
          tax: exportData.tax,
          name: exportData.name,
          address: exportData.address,
          email: exportData.email,
          type_order: confirmTypeOrder(location?.state?.type),
          number_plate: vehicleData.number_plate,
          type_vehicle_id: vehicleData.type_vehicle_id,
          is_vehicle: vehicleData.is_vehicle,
          type_payment_id: typePaymentID,
          save_status:
            location.state?.type === 'petrol' ? dataPetrol?.isKeepStock : 0,
        }
        if (location.state?.type === 'petrol') {
          //thanh toán xăng
          dataPush.order_gasoline = {
            note: '',
            user_address_id: dataPetrol?.user_address_id,
            total_price: dataPetrol?.is_full_fuel ? 0 : dataPetrol?.total_price,
            point_used: dataPetrol.point,
            liter: dataPetrol?.liters,
            price_per_liter: dataPetrol?.price_per_liter,
            is_full_fuel: dataPetrol?.is_full_fuel,
            petrol_store_id: dataPetrol?.petrol_store_id,
            amount: dataPetrol?.is_full_fuel ? 0 : dataPetrol?.amount,
            product_stock_id: dataPetrol?.product_stock_id,
          }
        }
        if (location.state?.type === 'insurance') {
          //thanh toán bảo hiểm
          dataPush.order_insurance = {
            note: '',
            user_address_id: dataInsurance.user_address_id,
            total_price: dataInsurance.total_price,
            point_used: dataInsurance.point,
            name: dataInsurance.name,
            number_plate: dataInsurance.number_plate,
            vehicle_brand: dataInsurance.vehicle_brand,
            petrol_store_id: dataInsurance.petrol_store_id,
            amount: dataInsurance.amount,
            product_stock_id: dataInsurance.product_stock_id,
          }
        }
        if (location.state?.type === 'oil') {
          //thanh toán mua dầu
          dataPush.carts = dataOil?.map((item: TDataOilItem) => ({
            petrol_store_id: item.petrol_store_id,
            note: item.note,
            user_address_id: item.user_address_id,
            total_price: item.total_price,
            point_used: item.point_used,
            items: item?.items?.map((itemA: any) => ({
              cart_id: [itemA.cart_id],
              amount: itemA.amount,
              product_stock_id: itemA.product_stock_id,
              price: itemA.sale_price || itemA.price,
            })),
          }))
        }
        // console.log('dataPush: ', dataPush)
        // debugger
        if (typePaymentID === PAYMENTS[3].VALUE) {
          const res = await createPayment(dataPush)
          const resPayment = await createPaymentUrl({
            order_ids: res.data,
            order_description: 'Thanh toan don hang',
            order_type: '250000',
            locale: 'vn',
          })
          window.open(resPayment.data.vnpayBuilderUrl)
          history.replace({
            pathname: `${ROUTER_PATH.ACCOUNT}/order`,
            state: {
              type:
                dataPush.order_gasoline &&
                (dataPush.type_payment_id === 3 ||
                  dataPush.type_payment_id === 4)
                  ? '2'
                  : '1',
            },
          })
        }
        if (typePaymentID === PAYMENTS[4].VALUE) {
          const res = await createPayment(dataPush)
          await paymentVT({
            order_id: res.data,
          })
          notificationSuccess(res.message)
          history.replace({
            pathname: `${ROUTER_PATH.ACCOUNT}/order`,
            state: {
              type:
                dataPush.order_gasoline &&
                (dataPush.type_payment_id === 3 ||
                  dataPush.type_payment_id === 4)
                  ? '2'
                  : '1',
            },
          })
        } else {
          const res = await createPayment(dataPush)
          notificationSuccess(res.message)
          history.replace({
            pathname: `${ROUTER_PATH.ACCOUNT}/order`,
            state: {
              type:
                dataPush.order_gasoline &&
                (dataPush.type_payment_id === 3 ||
                  dataPush.type_payment_id === 4)
                  ? '2'
                  : '1',
            },
          })
        }
      } catch (error) {
        console.error('Exception ' + error)
      } finally {
        dispatch(setAmountCart())
      }
    }
  }

  const confirmTypeOrder = (type: string) => {
    switch (type) {
      case 'oil':
        return TYPE_ORDER.PRODUCT
      case 'petrol':
        return TYPE_ORDER.GASOLINE
      case 'insurance':
        return TYPE_ORDER.INSURANCE
    }
  }

  const dataToPayment = (type: string) => {
    switch (type) {
      case 'oil':
        return dataOil
      case 'petrol':
        return dataPetrol
      case 'insurance':
        return dataInsurance
    }
  }

  return (
    <WarpPayment>
      <Breadcrumb style={{ marginBottom: '10px' }}>
        <Breadcrumb.Item href={ROUTER_PATH.HOME}>
          {R.strings().title_home}
        </Breadcrumb.Item>
        <Breadcrumb.Item>{R.strings().title_payment}</Breadcrumb.Item>
      </Breadcrumb>

      <Row justify="space-between">
        <Col xs={24} md={17}>
          {renderProduct(location?.state?.type)}

          {vehicleData.is_vehicle ? (
            <Transport data={vehicleData} setVehicleData={setVehicleData} />
          ) : (
            <></>
          )}
          <ExportBill exportData={exportData} setExportData={setExportData} />
        </Col>
        <Col
          xs={24}
          md={7}
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Payments
            totalOrderPrice={totalOrderPrice}
            onPayment={onPayment}
            typePaymentID={typePaymentID}
            onSetTypePaymentID={(value: number | null) => {
              setTypePaymentID(value)
            }}
            data={dataToPayment(location?.state?.type)}
          />
        </Col>
      </Row>
    </WarpPayment>
  )
}
export default PaymentScreen
