import { Select } from 'antd'
import R from 'assets'
import { ReloadIcon, Wallet2Icon } from 'common/components/Icons'
import React, { useEffect, useRef, useState } from 'react'
import { formatPrice } from 'utils/ruleForm'
import moment from 'moment'
import * as Styled from '../styled'
import { getHistoryDebt } from '../ApiAccount'
import { STATUS_ORDER } from 'utils/constants'
import { LoadingOutlined } from '@ant-design/icons'

type TParams = {
  page: number
  is_paid?: number
}
const Option = Select
const renderDay = (time: string) => {
  let res = 0
  let resOneDay = 0
  let timeOrder = Date.parse(time)
  let getToday: any = new Date()
  let today = Date.parse(getToday)
  res = (timeOrder - today) / 86400000
  resOneDay = (timeOrder - today) / 3600000
  if (res > 1) {
    return `${R.strings().left} ${res.toFixed(0)} ${R.strings().day}`
  } else if (res >= 0 && res <= 1) {
    return `${R.strings().left} ${resOneDay.toFixed(0)}h`
  } else if (res < 0 && res > -1) {
    return `${R.strings().out_of_date} ${(resOneDay * -1).toFixed(0)}h`
  } else if (res < -1) {
    return `${R.strings().out_of_date} ${(res * -1).toFixed(0)} ${
      R.strings().day
    }`
  }
}
const DebtTab: React.FC = () => {
  const scrollRef: any = useRef(null)
  const [params, setparams] = useState<TParams>({ page: 1, is_paid: undefined })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataDebt, setDataDebt] = useState({
    availability: 0,
    debtEnterprise: 0,
    list: [],
  })
  const [pagination, setPagination] = useState({ page: 1, limit: 12, total: 0 })

  useEffect(() => {
    getDataSource()
  }, [params])

  const getDataSource = async () => {
    try {
      setIsLoading(true)
      const res = await getHistoryDebt(params)

      setDataDebt({
        availability: res.data[0].availability,
        debtEnterprise: res.data[0].debtEnterprise,
        list: res.data[0].rows,
      })
      setPagination({
        page: res.meta.pagination?.page,
        limit: 12,
        total: res.meta.pagination?.totalItems,
      })
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  const renderOrderStatus = (value: number) => {
    switch (value) {
      case STATUS_ORDER.WAIT_CONFIRM:
        return R.strings().pending_order
      case STATUS_ORDER.CONFIRMED:
        return R.strings().confirm_order
      case STATUS_ORDER.COMPLETE:
        return R.strings().complete_order
      case STATUS_ORDER.CANCEL:
        return R.strings().cancel_order
      case STATUS_ORDER.DENY:
        return R.strings().reject_order
    }
  }

  const getMoreData = async () => {
    try {
      setIsLoading(true)
      const res = await getHistoryDebt({ ...params, page: params.page + 1 })
      setDataDebt({
        availability: res.data[0].availability,
        debtEnterprise: res.data[0].debtEnterprise,
        list: dataDebt.list.concat(res.data[0].rows),
      })
      setPagination({
        page: res.meta.pagination?.page,
        limit: 12,
        total: res.meta.pagination?.totalItems,
      })
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Styled.RowDebt>
        <Styled.ColItemBlock>
          <Styled.ColItemDebt>
            <div>
              <b>{formatPrice(dataDebt.debtEnterprise)} đ</b>
              <p style={{ fontSize: '14px' }}>{R.strings().limit}</p>
            </div>
            <Wallet2Icon />
          </Styled.ColItemDebt>
        </Styled.ColItemBlock>
        <Styled.ColItemBlock>
          <Styled.ColItemDebt>
            <div>
              <b>{formatPrice(dataDebt.availability)} đ</b>
              <p style={{ fontSize: '14px' }}>{R.strings().availability}</p>
            </div>
            <ReloadIcon />
          </Styled.ColItemDebt>
        </Styled.ColItemBlock>
      </Styled.RowDebt>

      <Styled.BodyWallet>
        <Styled.WrapFilter>
          <span
            style={{ fontSize: '18px', fontWeight: 600, marginRight: '10px' }}
          >
            {R.strings().debt_history}
          </span>
          <Select
            placeholder={R.strings().debt_status}
            onChange={(value: number) => {
              setparams({ page: 1, is_paid: value })
            }}
            allowClear
          >
            <Option value={1}>{R.strings().paid}</Option>
            <Option value={0}>{R.strings().unPaid}</Option>
          </Select>
        </Styled.WrapFilter>

        <Styled.ResponsiveForMobile
          ref={scrollRef}
          onScroll={() => {
            if (
              scrollRef.current!.scrollTop + scrollRef.current!.clientHeight >=
              scrollRef.current!.scrollHeight
            ) {
              let old = scrollRef.current!.scrollTop
              if (!isLoading && pagination.total > dataDebt.list.length) {
                scrollRef.current!.scrollTop = old - 50
                getMoreData()
              }
            }
          }}
        >
          {dataDebt.list.map((item: any, index: number) => (
            <Styled.DebtItem>
              <Styled.TopDebtItem>
                <span>{moment(item.create_at).format('DD/MM/YYYY')}</span>
                <span>{renderDay(item.payment_time)} </span>
              </Styled.TopDebtItem>
              <Styled.MiddleDebtItem>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <img
                      alt=""
                      src={item.data_product.media_url || R.images.img_product}
                      style={{ width: '50px' }}
                    />
                    <div style={{ padding: '0 5px' }}>
                      <div>{item.code}</div>
                      <b>{formatPrice(item.total_price)} đ</b>
                    </div>
                  </div>
                  <span style={{ color: 'color: #495057;' }}>
                    {renderOrderStatus(item.order_status)}
                  </span>
                </div>
              </Styled.MiddleDebtItem>
              <Styled.BottomDebtItem>
                <b>Tổng nợ: {formatPrice(item.total_debt)}</b>
                <span>
                  {item.is_paid ? R.strings().paid : R.strings().unPaid}
                </span>
              </Styled.BottomDebtItem>
            </Styled.DebtItem>
          ))}
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            {isLoading ? <LoadingOutlined /> : ''}
          </span>
        </Styled.ResponsiveForMobile>

        <Styled.HeaderTable justify="space-between">
          <Styled.ColTable md={8}>{R.strings().title_order}</Styled.ColTable>
          <Styled.ColTable md={4}>{R.strings().date_created}</Styled.ColTable>
          <Styled.ColTable md={4}>{R.strings().debt_term}</Styled.ColTable>
          <Styled.ColTable md={4}>
            {R.strings().accumulated_debt}
          </Styled.ColTable>
          <Styled.ColTable md={4}>{R.strings().figure}</Styled.ColTable>
        </Styled.HeaderTable>
        <Styled.BodyTable
          ref={scrollRef}
          onScroll={() => {
            if (
              scrollRef.current!.scrollTop + scrollRef.current!.clientHeight >=
              scrollRef.current!.scrollHeight
            ) {
              let old = scrollRef.current!.scrollTop
              if (!isLoading && pagination.total > dataDebt.list.length) {
                scrollRef.current!.scrollTop = old - 50
                getMoreData()
              }
            }
          }}
        >
          {dataDebt.list?.map((item: any, index: number) => (
            <Styled.RowTable key={index}>
              <Styled.ColTable md={8}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    padding: '0 6px',
                  }}
                >
                  <img
                    alt=""
                    src={item.data_product.media_url || R.images.img_product}
                    style={{ width: '50px' }}
                  />
                  <div>
                    <p>{item.code}</p>
                    <p>{renderOrderStatus(item.order_status)}</p>
                  </div>
                </div>
              </Styled.ColTable>
              <Styled.ColTable md={4}>
                {moment(item.create_at).format('DD/MM/YYYY')}
              </Styled.ColTable>
              <Styled.ColTable md={4}>
                {renderDay(item.payment_time)}
              </Styled.ColTable>
              <Styled.ColTable md={4}>
                {formatPrice(item.total_debt)} đ
              </Styled.ColTable>
              <Styled.ColTable md={4}>
                <p>{formatPrice(item.total_price)} đ</p>
                <p>{item.is_paid ? R.strings().paid : R.strings().unPaid}</p>
              </Styled.ColTable>
            </Styled.RowTable>
          ))}
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            {isLoading ? <LoadingOutlined /> : ''}
          </span>
        </Styled.BodyTable>
      </Styled.BodyWallet>
    </>
  )
}
export default DebtTab
