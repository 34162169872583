import { Row } from 'antd'
import React from 'react'
import { ContentItem, SmallItem } from '../styled'

const ReturnAndExchangePolicy = () => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '20px',
        textAlign: 'justify',
        listStyleType: ' upper-roman',
        margin: '20px 0',
      }}
    >
      <Row justify="center" style={{ marginBottom: '10px', width: '100%' }}>
        <b style={{ fontSize: '20px' }}>CHÍNH SÁCH BẢO HÀNH, ĐỔI TRẢ</b>
      </Row>
      <SmallItem>
        <b>1. Chính sách bảo hành</b>
        <ContentItem>
          <p>
            Thời gian bảo hành và chuyển giao trách nhiệm về chất lượng, số
            lượng hàng hóa.
          </p>
          <p>
            <b>Đối với xăng dầu sáng:</b> Tính từ thời điểm hàng hóa được bơm
            rót xong vào phương tiện chứa đựng của khách hàng.
          </p>
          <p>
            <b>Đối với dầu mỡ nhờn</b> Tính từ thời điểm khách hàng ký trên biên
            bản giao hàng hóa, bảo hành trong suốt thời hạn của sản phẩm với
            điều kiện hàng hóa chưa được bóc tem, không có dấu hiệu bị bào mòn
            bao bì bởi yếu tố môi trường.
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>2. Điều kiện bảo hành</b>
        <ContentItem>
          <p>
            - Hàng hóa bảo hành phải còn nguyên tem bảo hành, tem sản phẩm và
            giấy biên nhận chứng minh là quý khách đã mua hàng từ pyoyo.vn
          </p>
          <p>
            - Đối với những hỏng hóc như: Làm vỡ, làm hỏng, gây biến dạng, để
            lửa gây hư hại và các trường hợp tương tự khác không thuộc phạm vị
            bảo hành
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>3. Hướng dẫn yêu cầu bảo hành</b>
        <ContentItem>
          <p>
            Quý khách có thể gọi điện tới bộ phận chăm sóc khách hàng của công
            ty để yêu cầu bảo hành:
          </p>
          <p>Mọi chi tiết xin quý khách vui lòng liên hệ với chúng tôi:</p>
          <p>Hotline: 0899.743.888</p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>4. Chính sách đổi trả</b>
        <b>- Phạm vi áp dụng</b>
        <ContentItem>
          <p>
            Chỉ áp dụng với sản phẩm dầu mỡ nhờn. Áp dụng đổi trả hàng trong
            vòng 07 ngày đối với các sản phẩm bị hư hỏng do lỗi vận chuyển hoặc
            lỗi do nhà sản xuấ
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>5. Điều kiện đổi trả</b>
        <b>
          - Thời gian đổi trả: trong vòng 7 ngày kể từ ngày yêu cầu đổi trả hàng
        </b>
        <b>- Yêu cầu giữ nguyên bao bì, tem mác của sản phẩm khi đổi trả</b>
        <b>- Số lần đổi trả cho 1 sản phẩm là 1 lần</b>
        <ContentItem>
          <p>
            <i>
              - Các sản phẩm không được đổi trả: đã hết thời gian đổi trả và các
              sản phẩm không do lỗi của nhà vận chuyển và không do lỗi của nhà
              sản xuấ
            </i>
          </p>
          <p>
            Chúng tôi cũng rất sẵn lòng lắng nghe các góp ý và yêu cầu của quý
            khách hàng.
          </p>
          <p>
            Quý khách có thể liên hệ số: 0899.743.888 (giờ hành chính) để đóng
            góp ý kiến của quý khách về sản phẩm và dịch vụ của chúng tôi
          </p>
        </ContentItem>
      </SmallItem>
    </div>
  )
}

export default ReturnAndExchangePolicy
