import { DeleteOutlined } from '@ant-design/icons'
import R from 'assets'
import { Popconfirm, Row, Typography } from 'antd'
import { MinusIcon, PlusIcon } from 'common/components/Icons'
import React from 'react'
import { enterNumbersOnly, formatPrice } from 'utils/ruleForm'
import {
  ButtonChangeQuantity,
  CheckBoxStyled,
  ColCart,
  ImageProduct,
  InforProduct,
  InputAmount,
  ProductBlock,
} from '../styled'
import { IProductItem } from '../interface'
import { useDispatch, useSelector } from 'react-redux'
import { getDataCard, setDataCard } from '../CartSlice'
import { deleteProduct, updateProduct } from '../ApiCart'
import { useLocation } from 'react-router-dom'
import { API_STATUS } from 'utils/constants'
import { setAmountCart } from 'features/auth/AuthSlice'

const { Paragraph } = Typography
type TProps = {
  data: IProductItem
  indexStore: number
  indexProduct: number
}
const ProductInCart: React.FC<TProps> = ({
  data,
  indexStore,
  indexProduct,
}) => {
  const dataCart = useSelector(getDataCard)
  const dispatch = useDispatch()

  const selectProduct = (value: boolean) => {
    let new_item = {
      ...dataCart[indexStore].product_stocks[indexProduct],
      checkedProduct: value,
    }

    let newArrayProduct = [...dataCart[indexStore].product_stocks]
    newArrayProduct[indexProduct] = new_item

    let newStore = {
      ...dataCart[indexStore],
      product_stocks: newArrayProduct,
    }

    let newArrayCart = [...dataCart]
    newArrayCart[indexStore] = newStore
    // Tích all product => checked store
    let amountProductSelect = 0
    newArrayCart[indexStore]?.product_stocks?.map((item: IProductItem) => {
      if (item.checkedProduct) {
        amountProductSelect++
      }
    })
    if (
      amountProductSelect === newArrayCart[indexStore].product_stocks?.length
    ) {
      newArrayCart[indexStore].checkedStore = true
    } else {
      newArrayCart[indexStore].checkedStore = false
    }

    dispatch(setDataCard(newArrayCart))
  }

  const increaseAmount = async () => {
    let new_item = {
      ...dataCart[indexStore].product_stocks[indexProduct],
      amount: data.amount + 1,
    }

    let newArrayProduct = [...dataCart[indexStore].product_stocks]
    newArrayProduct[indexProduct] = new_item

    let newArrayStore = {
      ...dataCart[indexStore],
      product_stocks: newArrayProduct,
    }

    let newArrayCart = [...dataCart]
    newArrayCart[indexStore] = newArrayStore

    try {
      const payload = {
        petrol_store_id: newArrayCart[indexStore].id,
        product_stock_id:
          newArrayCart[indexStore].product_stocks[indexProduct]
            .product_stock_id,
        amount: data.amount + 1,
      }
      await updateProduct(
        newArrayCart[indexStore].product_stocks[indexProduct].cart_id,
        payload
      )
    } catch (error) {
      console.error('Exception ' + error)
    }

    dispatch(setDataCard(newArrayCart))
  }

  const decreaseAmount = async () => {
    let new_item = {
      ...dataCart[indexStore].product_stocks[indexProduct],
      amount: data.amount - 1,
    }

    let newArrayProduct = [...dataCart[indexStore].product_stocks]
    newArrayProduct[indexProduct] = new_item

    let newArrayStore = {
      ...dataCart[indexStore],
      product_stocks: newArrayProduct,
    }

    let newArrayCart = [...dataCart]
    newArrayCart[indexStore] = newArrayStore

    dispatch(setDataCard(newArrayCart))

    try {
      const payload = {
        petrol_store_id: newArrayCart[indexStore].id,
        product_stock_id:
          newArrayCart[indexStore].product_stocks[indexProduct]
            .product_stock_id,
        amount: data.amount - 1,
      }
      await updateProduct(
        newArrayCart[indexStore].product_stocks[indexProduct].cart_id,
        payload
      )
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const changeAmount = async (value: number) => {
    let new_item = {
      ...dataCart[indexStore].product_stocks[indexProduct],
      amount: value,
    }

    let newArrayProduct = [...dataCart[indexStore].product_stocks]
    newArrayProduct[indexProduct] = new_item

    let newArrayStore = {
      ...dataCart[indexStore],
      product_stocks: newArrayProduct,
    }

    let newArrayCart = [...dataCart]
    newArrayCart[indexStore] = newArrayStore

    try {
      const payload = {
        petrol_store_id: newArrayCart[indexStore].id,
        product_stock_id:
          newArrayCart[indexStore].product_stocks[indexProduct]
            .product_stock_id,
        amount: value,
      }
      await updateProduct(
        newArrayCart[indexStore].product_stocks[indexProduct].cart_id,
        payload
      )
    } catch (error) {
      console.error('Exception ' + error)
    }

    dispatch(setDataCard(newArrayCart))
  }

  const removeProduct = async () => {
    let newArrayProduct = [...dataCart[indexStore].product_stocks]
    newArrayProduct.splice(indexProduct, 1)

    let newArrayStore = {
      ...dataCart[indexStore],
      product_stocks: newArrayProduct,
    }

    let newArrayCart = [...dataCart]
    newArrayCart[indexStore] = newArrayStore

    try {
      const payload = {
        id: [dataCart[indexStore].product_stocks[indexProduct].cart_id],
      }
      await deleteProduct(payload)
      dispatch(setAmountCart())
    } catch (error) {
      console.error('Exception ' + error)
    }
    dispatch(setDataCard(newArrayCart))
  }

  return (
    <ProductBlock>
      <ColCart xs={24} md={10} justify="start">
        <CheckBoxStyled
          checked={data?.checkedProduct}
          onChange={(e: any) => {
            selectProduct(e.target.checked)
          }}
        />
        <ImageProduct
          preview={false}
          src={data.img_product || R.images.img_product}
        />
        <InforProduct>
          <Paragraph style={{ margin: 0 }} ellipsis={{ rows: 2 }}>
            {data.name_product}
          </Paragraph>
          <Row justify="space-around">
            <ColCart xs={12} id="price-col">
              {data.sale_price ? (
                <span
                  style={{
                    textDecoration: 'line-through',
                    color: '#BFBFBF',
                    marginRight: '5px',
                  }}
                >
                  {formatPrice(data.price)}
                </span>
              ) : (
                ''
              )}
              <p style={{ color: '#F26522' }}>
                {data.sale_price
                  ? formatPrice(data.sale_price)
                  : formatPrice(data.price)}
                đ
              </p>
            </ColCart>
            <ColCart xs={12} id="amount-col">
              <ButtonChangeQuantity
                disabled={data.amount < 2 ? true : false}
                onClick={() => {
                  if (data.amount > 1) {
                    decreaseAmount()
                  }
                }}
              >
                <MinusIcon />
              </ButtonChangeQuantity>

              <InputAmount
                value={formatPrice(data.amount)}
                onChange={(e: any) => {
                  let value = enterNumbersOnly(e.target.value, 1)
                  if (value && value < 1000) {
                    changeAmount(parseInt(value))
                  } else {
                    if (value > 999) {
                      changeAmount(999)
                    }
                  }
                }}
              />

              <ButtonChangeQuantity
                onClick={() => {
                  increaseAmount()
                }}
              >
                <PlusIcon />
              </ButtonChangeQuantity>
            </ColCart>
          </Row>
        </InforProduct>
      </ColCart>
      <ColCart xs={12} md={5}>
        {data.sale_price ? (
          <span
            style={{
              textDecoration: 'line-through',
              color: '#BFBFBF',
              marginRight: '5px',
            }}
          >
            {formatPrice(data.price)}
          </span>
        ) : (
          ''
        )}
        {data.sale_price
          ? formatPrice(data.sale_price)
          : formatPrice(data.price)}
        đ
      </ColCart>
      <ColCart xs={12} md={3}>
        <ButtonChangeQuantity
          disabled={data.amount < 2 ? true : false}
          onClick={() => {
            if (data.amount > 1) {
              decreaseAmount()
            }
          }}
        >
          <MinusIcon />
        </ButtonChangeQuantity>

        <InputAmount
          value={formatPrice(data.amount)}
          onChange={(e: any) => {
            let value = enterNumbersOnly(e.target.value, 1)
            if (value && value < 1000) {
              changeAmount(parseInt(value))
            } else {
              if (value > 999) {
                changeAmount(999)
              }
            }
          }}
        />

        <ButtonChangeQuantity
          onClick={() => {
            increaseAmount()
          }}
        >
          <PlusIcon />
        </ButtonChangeQuantity>
      </ColCart>
      <ColCart md={4}>
        {data.sale_price
          ? formatPrice(data.sale_price * data.amount)
          : formatPrice(data.price * data.amount)}
        đ
      </ColCart>
      <ColCart md={2}>
        <Popconfirm
          title={R.strings().delete_question}
          onConfirm={() => {
            removeProduct()
          }}
          okText={R.strings().confirm}
          cancelText={R.strings().cancel_order}
        >
          <DeleteOutlined
            style={{ color: '#f5222d', cursor: 'pointer', fontSize: '20px' }}
          />
        </Popconfirm>
      </ColCart>
    </ProductBlock>
  )
}

export default ProductInCart
