import React, { useState } from 'react'
import ChooseGasoline from './ChooseGasoline'

type Props = {
  active: any
  setActive: any
  data: any
  dataStation?: any
  loading: boolean
  setLoading: any
  setChooseGas?: any
  onSearchSubmit: (searchKey: string) => void
  geoLocation: boolean
  getMoreData: any
  loadingMore: any
  loadStore: any
}
const GasolineProduct: React.FC<Props> = ({
  active,
  setActive,
  data,
  loading,
  setLoading,
  dataStation,
  setChooseGas,
  onSearchSubmit,
  geoLocation,
  getMoreData,
  loadingMore,
  loadStore,
}) => {
  const [status, setStatus] = useState<string>('choose')
  const [idStation, setIdStation] = useState<number>()
  return (
    <>
      <ChooseGasoline
        loadStore={{ loadStore }}
        getMoreData={getMoreData}
        loadingMore={loadingMore}
        active={active}
        setActive={setActive}
        data={data}
        loading={loading}
        dataStation={dataStation}
        setChooseGas={setChooseGas}
        onSearchSubmit={onSearchSubmit}
        setStatus={setStatus}
        setIdStation={setIdStation}
        geoLocation={geoLocation}
      />
    </>
  )
}

export default GasolineProduct
