import i18n from 'assets/i18n/translation'
import { ContainerPage, Page404 } from 'common/components/ContainerPage'
import { ROUTER_PATH, SESSION_KEY } from 'common/config'
import { getUserInfoAction } from 'features/auth/AuthSlice'
import ForgotPassword from 'features/auth/forgot/ForgotPassword'
import LoginScreen from 'features/auth/Login'
import SignupScreen from 'features/auth/signup/Signup'
import { WebViewVNP } from 'features/web-view'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'
import { WebSocket } from 'services/WebSocket'
import { hasCookie } from 'utils/functionHelper'
import history from 'utils/history'
import PrivateRoute from './PrivateRoute'
import { privateRouter, routerPage } from './routerType'
import ScrollToTop from './ScrollToTop'
import Cookie from 'js-cookie'
export default function AppNavigator() {
  const dispatch = useDispatch()
  const [language, setLanguage] = useState<string>('vi')

  const userState: any = useSelector((state: any) => state.authReducer)
    ?.userInfo

  useEffect(() => {
    if (hasCookie(SESSION_KEY.SESSION)) {
      dispatch(getUserInfoAction())
      // WebSocket.init()
    }
  }, [])

  useEffect(() => {
    if (userState) {
      setLanguage(userState?.language)
      i18n.changeLanguage(userState?.language)
    }
  }, [userState])

  const MainNavigator = () => (
    <ScrollToTop>
      <ContainerPage language={language} setLanguage={setLanguage}>
        {routerPage.map((item: any, index: number) => (
          <Route
            key={index}
            path={item.path}
            component={item.component}
            exact={item.exact}
          />
        ))}
        {privateRouter.map((item: any, index: number) => (
          <PrivateRoute
            key={index}
            path={item.path}
            component={item.component}
            exact={item.exact}
          />
        ))}
      </ContainerPage>
    </ScrollToTop>
  )

  return (
    <Router history={history}>
      <Switch>
        <Route path={ROUTER_PATH.LOGIN} component={LoginScreen} exact />
        <Route path={ROUTER_PATH.SIGNUP} component={SignupScreen} exact />
        <Route path={ROUTER_PATH.NOT_FOUND} component={Page404} exact />
        <Route path={'/webViewVNP'} exact component={WebViewVNP} />
        <Route
          path={ROUTER_PATH.FORGOT_PASSWORD}
          component={ForgotPassword}
          exact
        />
        {MainNavigator()}
      </Switch>
    </Router>
  )
}
