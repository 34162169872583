import { Divider, Table, Tag } from 'antd'
import React from 'react'
import { BottomBlock } from '../styled'
import R from 'assets'
import { ApiClient } from 'services/ApiService'
import moment from 'moment'
const columns = [
  {
    title: 'STT',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Tên sản phẩm',
    dataIndex: 'product_name',
    key: 'product_name',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (value: string) =>
      value == '2' ? (
        <Tag color="red">Quá hạn</Tag>
      ) : (
        <Tag color="green">Đổ xăng</Tag>
      ),
  },
  {
    title: 'Số lượng',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Số dư',
    dataIndex: 'remain',
    key: 'remain',
  },
  {
    title: 'Thời gian',
    dataIndex: 'create_at',
    key: 'create_at',
    render: (date: string) => moment(date).format('HH:mm DD/MM/YYYY'),
  },
]

const HistoryRefueling = ({
  order_id,
  user_id,
  petrol_store_id,
}: {
  order_id: string
  user_id: string
  petrol_store_id: string
}) => {
  const [dataSource, setDataSource] = React.useState<any>([])

  React.useEffect(() => {
    ;(async () => {
      const res = await ApiClient.get(
        `/client/order/${order_id}/${user_id}/${petrol_store_id}/history`
      )
      if (res.status) {
        setDataSource(res.data)
      }
    })()
  }, [order_id, petrol_store_id, user_id])

  return (
    <div style={{ marginTop: '16px' }}>
      <BottomBlock>
        <div
          style={{
            padding: '0 16px 0 16px',
            fontWeight: 600,
            fontSize: 18,
          }}
        >
          {R.strings().title_refueling_history}
        </div>
        <Divider
          style={{
            margin: '10px 0 10px 0',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexFlow: 'nowrap',
            padding: '0 16px 0 16px',
          }}
        >
          <Table
            bordered
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            style={{ width: '100%' }}
          />
        </div>
      </BottomBlock>
    </div>
  )
}

export default HistoryRefueling
