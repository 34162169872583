import { EnvironmentOutlined, UploadOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Form, Input, Row } from 'antd'
import R from 'assets'
import { TitleStyles } from 'common/components/ContainerPage/styles'
import { RadiusSelection } from 'common/components/Inputs'
import UploadComponent from 'common/components/Upload'
import { CheckboxSquare } from 'features/payment/styled'
import React, { useState } from 'react'
import { REG_PHONE } from 'utils/constants'
import { notificationSuccess } from 'utils/notification'
import {
  getDistrict,
  getPlace,
  getPlaceAuto,
  getProvince,
  getWard,
  requestPartner,
} from '../ApiAccount'
import { ButtonStyled, ContentRules } from '../styled'

const BecomeAgentTab: React.FC = () => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isRead, setIsRead] = useState<boolean>(false)
  const [urls, setUrls] = useState<any>([])
  const [options, setOptions] = useState<Array<any>>([])
  const [placeId, setPlaceId] = useState<string>('')

  const [province, setProvince] = useState<Array<any>>([])
  const [district, setDistrict] = useState<Array<any>>([])
  const [ward, setWard] = useState<Array<any>>([])

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true)
      let res_place_id
      if (placeId !== '') {
        res_place_id = await getPlace({ placeId: placeId })
      }

      values.address = res_place_id?.data?.address
      values.location_lat = res_place_id?.data?.lat
      values.location_long = res_place_id?.data?.long
      values.representative = values?.representative
        ? values.representative
        : ''

      delete values.confirm
      delete values.address_google_map

      const res = await requestPartner({
        ...values,
        media_url: urls.join(),
      })
      notificationSuccess(res.message)
      form.resetFields()
      setIsRead(false)
      window.scrollTo(0, 0)
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  const getProvinces = async () => {
    try {
      const res = await getProvince()
      const data = res.data.map((item: any, index: number) => {
        return {
          text: item.name,
          value: item.id,
        }
      })
      setProvince(data)
    } catch (error) {
    } finally {
    }
  }

  React.useEffect(() => {
    getProvinces()
  }, [])

  const onSelect = (data: string, option: any) => {
    setPlaceId(option.place_id)
  }

  const onChange = async (data: string) => {
    try {
      const res = await getPlaceAuto({ address: data })
      const list_option = res.data?.predictions?.map((item: any) => ({
        value: item.description,
        place_id: item.place_id,
      }))
      setOptions(list_option)
    } catch (error) {
      console.log(error)
    }
  }

  const getDistricts = async (payload: any) => {
    try {
      const res = await getDistrict(payload)
      const data = res.data.map((item: any, index: number) => {
        return {
          text: item.name,
          value: item.id,
        }
      })
      setDistrict(data)
    } catch (error) {
    } finally {
    }
  }

  const getWards = async (payload: any) => {
    try {
      const res = await getWard(payload)
      const data = res.data.map((item: any, index: number) => {
        return {
          text: item.name,
          value: item.id,
        }
      })
      setWard(data)
    } catch (error) {
    } finally {
    }
  }

  return (
    <Form
      form={form}
      layout="vertical"
      labelAlign="left"
      scrollToFirstError
      onFinish={values => onFinish(values)}
      style={{ padding: '25px' }}
    >
      <TitleStyles>{R.strings().register_to_become_a_partner}</TitleStyles>

      <Form.Item
        name="name"
        label={R.strings().title_customer_name}
        rules={[
          {
            required: true,
            message: R.strings().title_please_customer_name,
          },
          {
            min: 6,
            max: 55,
            message: R.strings().between6and55characters,
          },
        ]}
      >
        <Input placeholder={R.strings().title_customer_name} />
      </Form.Item>
      <Form.Item
        name="company_name"
        label={R.strings().text_form_company}
        rules={[
          {
            required: true,
            message: R.strings().enter_company,
          },
        ]}
      >
        <Input placeholder={R.strings().text_form_company} />
      </Form.Item>
      <Form.Item
        name="contact_phone"
        label={R.strings().title_phone_contact}
        rules={[
          {
            required: true,
            message: R.strings().notify_enter_phone,
          },
          {
            pattern: REG_PHONE,
            max: 10,
            message: R.strings().err_phone,
          },
        ]}
      >
        <Input placeholder={R.strings().title_enter_phone_contact} />
      </Form.Item>
      <Form.Item
        name="tax"
        label={R.strings().text_form_tax}
        rules={[
          {
            required: true,
            message: R.strings().enter_tax_code,
          },
          {
            min: 10,
            max: 13,
            message: R.strings().between10and13characters,
          },
        ]}
      >
        <Input placeholder={R.strings().text_form_tax} />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            message: R.strings().enter_email,
          },
          {
            min: 6,
            max: 55,
            message: R.strings().between6and55characters,
          },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="phone"
        label={R.strings().text_form_phone_signup}
        rules={[
          {
            required: true,
            message: R.strings().notify_enter_phone,
          },
          {
            pattern: REG_PHONE,
            max: 10,
            message: R.strings().err_phone,
          },
        ]}
      >
        <Input placeholder={R.strings().text_form_phone_signup} />
      </Form.Item>
      <Form.Item
        name="representative"
        label={R.strings().contact}
        rules={[
          {
            required: true,
            message: R.strings().enter_contact,
          },
          {
            min: 6,
            max: 55,
            message: R.strings().between6and55characters,
          },
        ]}
      >
        <Input placeholder={R.strings().contact} />
      </Form.Item>
      <Form.Item
        // className={styles.item_form}
        labelAlign="right"
        label={
          <span>
            {R.strings().address} Google Maps&nbsp;
            <EnvironmentOutlined />
          </span>
        }
        name="address_google_map"
        rules={[
          {
            required: true,
            message: R.strings().please_enter_google_maps_address,
          },
          {
            max: 255,
            message: R.strings().err_length_255,
          },
        ]}
      >
        <AutoComplete
          options={options}
          placeholder={R.strings().google_map_address}
          onSelect={onSelect}
          onChange={(value: string) => {
            setTimeout(() => {
              onChange(value)
            }, 300)
          }}
        />
      </Form.Item>
      <Row>
        <Form.Item
          style={{ width: 1000, marginBottom: 0, padding: 0 }}
          labelAlign="right"
          // label="Chi tiết địa chỉ"
          labelCol={{ flex: '20px' }}
          label={R.strings().address_detail}
          required
        >
          <Input.Group compact style={{ width: 1000, paddingTop: 10 }}>
            <Form.Item
              style={{ width: 165, paddingRight: 5 }}
              name="province_id"
              rules={[
                {
                  required: true,
                  message: R.strings().please_select_province,
                },
              ]}
            >
              <RadiusSelection
                showArrow={false}
                placeholder={R.strings().title_province}
                options={province}
                onChange={(value: number, text: any) => {
                  if (value) {
                    getDistricts({ province_id: value })
                  }
                  form.setFieldsValue({ district_id: 'Quận /huyện' })
                  form.setFieldsValue({ ward_id: 'Phường /xã' })
                  form.setFieldsValue({ location_address: '' })
                }}
              />
            </Form.Item>
            <Form.Item
              style={{ width: 165, paddingRight: 5 }}
              name="district_id"
              rules={[
                {
                  required: true,
                  message: R.strings().please_select_district,
                },
              ]}
            >
              <RadiusSelection
                showArrow={false}
                placeholder={R.strings().district}
                options={district}
                onChange={(value: number, text: any) => {
                  if (value) {
                    getWards({ district_id: value })
                  }
                  // setNameDistrict(text?.children)
                  // form.setFieldsValue({
                  //   location_address: `${nameProvince}, ${text.children}`,
                  // })
                  form.setFieldsValue({ ward_id: 'Phường /xã' })
                  form.setFieldsValue({ location_address: '' })
                }}
              />
            </Form.Item>
            <Form.Item
              style={{ width: 165, paddingRight: 5 }}
              name="ward_id"
              rules={[
                {
                  required: true,
                  message: R.strings().please_select_ward,
                },
              ]}
            >
              <RadiusSelection
                showArrow={false}
                placeholder={R.strings().title_wards}
                options={ward}
                onChange={(value: number, text: any) => {
                  // setNameWard(text?.children)
                  form.setFieldsValue({ location_address: '' })
                }}
                // value={data?.status}
              />
            </Form.Item>
            <Form.Item
              style={{ width: 165, paddingRight: 5 }}
              name="location_address"
              rules={[
                {
                  required: true,
                  message: R.strings().title_please_apartment_number,
                },
              ]}
            >
              <Input
                style={{ borderRadius: 5 }}
                allowClear
                placeholder={R.strings().title_apartment_number}
                // onChange={(e: any) => {
                //   setNameStreet(e.target.value)
                // }}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Row>

      <Form.Item name="avatar" label={R.strings().text_form_images}>
        <UploadComponent
          isUploadServerWhenUploading
          uploadType="list"
          listType="picture-card"
          onSuccessUpload={url => {
            setUrls([...urls, url])
          }}
        >
          +
        </UploadComponent>
      </Form.Item>

      <Form.Item name="avatar" label={R.strings().text_form_files}>
        <UploadComponent
          isUploadServerWhenUploading
          accept=".pdf,.doc,.docx,.txt,.xls,.xlsx,.zip"
          uploadType="list"
          listType="text"
          maxLength={2}
          onSuccessUpload={url => {
            setUrls([...urls, url])
          }}
        >
          <Button icon={<UploadOutlined />}>{R.strings().title_upload}</Button>
        </UploadComponent>
      </Form.Item>

      <ContentRules>
        <b>{R.strings().terms_of_becoming_a_partner}</b>
        <p>{R.strings().confirm_text_become_agent}</p>
        <CheckboxSquare
          checked={isRead}
          onChange={(e: any) => {
            setIsRead(e.target.checked)
          }}
        >
          {R.strings().i_have_read_and_agree_to}
          <a
            href="https://pyoyo.vn/term-of-use"
            target="_blank"
            style={{ color: '#F26522' }}
            rel="noreferrer"
          >
            {' '}
            {R.strings().rules}
          </a>
        </CheckboxSquare>
      </ContentRules>

      <Row justify="center">
        <ButtonStyled
          htmlType="submit"
          style={{ width: '30%' }}
          loading={isLoading}
          disabled={isRead ? false : true}
        >
          {R.strings().title_signup}
        </ButtonStyled>
      </Row>
    </Form>
  )
}
export default BecomeAgentTab
