import { BellOutlined, CloseOutlined, DownOutlined } from '@ant-design/icons'
import { Avatar, Badge, Dropdown, Input, Menu, Row, Space, Spin } from 'antd'
import R from 'assets'
import i18n from 'assets/i18n/translation'
import {
  BagIcon,
  FlagESIcon,
  FlagVNIcon,
  MenuIcon,
  SearchIcon,
} from 'common/components/Icons'
import { ROUTER_PATH, SESSION_KEY } from 'common/config'
import {
  getCountCart,
  getUserInfoAction,
  setAmountCart,
} from 'features/auth/AuthSlice'
import Cookie from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { WebSocket } from 'services/WebSocket'
import { API_STATUS } from 'utils/constants'
import { hasCookie } from 'utils/functionHelper'
import { notificationNote } from 'utils/notification'
import { injectObject2String } from 'utils/ruleForm'
import {
  getListCategory,
  listNotify,
  unrReadNotify,
  updateLanguage,
} from './ApiContainer'
import ListNotification from './ListNotification'
import {
  HeaderBlock,
  HeaderItem,
  LoginButton,
  LogoHeader,
  MenuButton,
  SearchArea,
  StyledDrawer,
  WrapAction,
} from './styles'

const Header: React.FC<any> = ({ language, setLanguage }) => {
  const history = useHistory()
  const location = useLocation()
  const userState: any = useSelector((state: any) => state.authReducer)
    ?.userInfo
  const dispatch = useDispatch()

  const amountProductInCart = useSelector(getCountCart)
  const [showDrawer, setShowDrawer] = useState<boolean>(false)
  const [dataCategory, setDataCategory] = useState<any>()
  const [onCLickNotify, setOnCLickNotify] = useState<boolean>(false)
  const [searchProduct, setSearchProduct] = useState<string>('')
  const [dataUnread, setDataUnread] = useState<any>()
  const [dataNotify, setDataNotify] = useState<any>()
  const [visibleNotify, setVisibleNotify] = useState<boolean>(false)
  const [updateNotify, setUpdateNotify] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(true)
  const [loadMoreNotification, setLoadMoreNotification] = useState<boolean>(
    false
  )

  // loading when change language
  const [loadingLanguage, setLoadingLanguage] = useState<boolean>(false)

  const [param, setParam] = useState({
    page: 1,
    limit: 12,
  })

  useEffect(() => {
    WebSocket.init()
    if (hasCookie(SESSION_KEY.SESSION)) {
      WebSocket.socketClient?.on(
        `customer_notification`,
        async (datas: any) => {
          console.log('🚀 ~ file: Header.tsx:81 ~ datas', datas)
          if (!datas.data.count_online) {
            if (userState?.id === datas.data.user_id) {
              setUpdateNotify(!updateNotify)
              setVisible(true)
              notificationNote(
                injectObject2String(
                  localStorage.getItem('i18nextLng') === 'vi'
                    ? datas.data?.type_notification?.content
                    : datas.data?.type_notification?.content_en,
                  datas.data?.data
                )
              )
            }
          }
        }
      )
    }
  }, [userState])

  const MENU_HEADER = [
    {
      title: R.strings().title_home,
      main_path: ROUTER_PATH.HOME,
      relationship: [ROUTER_PATH.HOME],
    },
    {
      title: R.strings().title_product,
      main_path: ROUTER_PATH.PRODUCT,
      relationship: [ROUTER_PATH.PRODUCT, ROUTER_PATH.DETAIL_PRODUCT],
    },
    {
      title: R.strings().title_news,
      main_path: ROUTER_PATH.NEWS,
      relationship: [ROUTER_PATH.NEWS, ROUTER_PATH.DETAIL_NEWS],
    },
    {
      title: R.strings().title_list_store,
      main_path: ROUTER_PATH.STORES,
      relationship: [ROUTER_PATH.STORES],
    },
  ]

  useEffect(() => {
    listCategory()

    if (hasCookie(SESSION_KEY.SESSION)) {
      dispatch(setAmountCart())
      getUnread()
    }
  }, [])

  useEffect(() => {
    if (hasCookie(SESSION_KEY.SESSION)) {
      getDataNotify()
      getUnread()
      setUpdateNotify(false)
    }
  }, [updateNotify])

  useEffect(() => {
    if (hasCookie(SESSION_KEY.SESSION)) {
      getDataNotify()
    }
  }, [onCLickNotify])

  const getDataNotify = async () => {
    try {
      const res = await listNotify(param)
      setDataNotify(res.data)
      if (res.data?.length < param.limit) {
        setLoadMoreNotification(false)
      } else {
        setLoadMoreNotification(true)
      }
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const getMoreDataNotify = async () => {
    try {
      const res = await listNotify({
        ...param,
        page: param.page + 1,
      })
      setDataNotify(dataNotify.concat(res.data))
      setParam({ ...param, page: param.page + 1 })
      if (res.data?.length < param.limit) {
        setLoadMoreNotification(false)
      } else {
        setLoadMoreNotification(true)
      }
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const getUnread = async () => {
    try {
      const res = await unrReadNotify()
      setDataUnread(res.data)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const listCategory = async () => {
    try {
      const res = await getListCategory()
      setDataCategory(res.data)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const renderActive = (item: any) => {
    for (let j = 0; j <= item.relationship.length; j++) {
      if (location.pathname.includes(item.relationship[j])) {
        if (
          item.main_path === ROUTER_PATH.HOME &&
          location.pathname !== item.main_path
        ) {
          return false
        }

        return true
      }
    }
    return false
  }

  const onChangeLanguage = async (value: string) => {
    setLoadingLanguage(true)
    try {
      if (hasCookie(SESSION_KEY.SESSION)) {
        const res: any = await updateLanguage({ language: value })
        if (res?.code === API_STATUS.SUCCESS) {
          dispatch(getUserInfoAction())
        }
      }
      i18n.changeLanguage(value)
      setLanguage(value)
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setLoadingLanguage(false)
    }
  }

  const menu = (
    <Menu>
      {dataCategory?.map((item: any) => (
        <Menu.Item key={item.id}>
          <div
            onClick={() => {
              const typeProduct =
                item.id === 1
                  ? 'gas'
                  : item.id === 2
                  ? 'oil'
                  : item.id === 3
                  ? 'insurrance'
                  : ''
              const nameCategory =
                localStorage.getItem('i18nextLng') === 'vi'
                  ? item.name
                  : item.name_en
                  ? item.name_en
                  : item.name
              setShowDrawer(false)
              history.replace({
                pathname: `${ROUTER_PATH.PRODUCT}`,
                // search: `type=${typeProduct}&category=${nameCategory}&idCategory=${item.id}`,
                search: `type=${typeProduct}&category=${nameCategory}&idCategory=${item.id}`,
              })
              // window.location.reload()
            }}
          >
            {localStorage.getItem('i18nextLng') === 'vi'
              ? item.name
              : item.name_en
              ? item.name_en
              : item.name}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  )

  const option = (
    <Menu>
      <Menu.Item
        onClick={() => {
          if (location.pathname !== ROUTER_PATH.ACCOUNT)
            history.push(ROUTER_PATH.ACCOUNT + '/userinfor')
        }}
      >
        {R.strings().title_account}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          Cookie.remove(SESSION_KEY.SESSION)
          window.location.reload()
        }}
      >
        {R.strings().title_log_out}
      </Menu.Item>
    </Menu>
  )

  const optionLanguage = (
    <Menu>
      <Menu.Item
        onClick={() => {
          onChangeLanguage('vi')
        }}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <FlagVNIcon />
        {R.strings().vietnamese}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          onChangeLanguage('en')
        }}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <FlagESIcon />
        {R.strings().english}
      </Menu.Item>
    </Menu>
  )

  return (
    <HeaderBlock>
      <Row align="middle">
        <MenuButton>
          {showDrawer ? (
            <CloseOutlined
              style={{ color: '#f26522' }}
              onClick={() => {
                setShowDrawer(!showDrawer)
              }}
            />
          ) : (
            <MenuIcon
              style={{ marginRight: '20px' }}
              onClick={() => {
                setShowDrawer(!showDrawer)
              }}
            />
          )}
        </MenuButton>
        <LogoHeader
          onClick={() => {
            history.push(ROUTER_PATH.HOME)
          }}
        >
          <img src={R.images.img_logo} alt="Ảnh logo" width={110} height={45} />
        </LogoHeader>
        <ul>
          {MENU_HEADER?.map((item: any, index: number) => {
            if (index === 1) {
              return (
                <li key={item.id}>
                  <Dropdown overlay={menu} trigger={['click']}>
                    <div
                      className={
                        renderActive(item) ? 'active' : 'category-item'
                      }
                    >
                      {R.strings().title_product}&nbsp;
                      <DownOutlined />
                    </div>
                  </Dropdown>
                </li>
              )
            }
            return (
              <li key={item.id}>
                <div
                  className={renderActive(item) ? 'active' : 'category-item'}
                  onClick={() => {
                    if (location.pathname !== item.main_path)
                      history.push(item.main_path)
                  }}
                >
                  {item.title}
                </div>
              </li>
            )
          })}
        </ul>
      </Row>
      <Space size="large">
        {/* chưa dăng nhập */}
        <SearchArea id="big-width">
          <SearchIcon />
          <Input
            bordered={false}
            placeholder={R.strings().title_search_product}
            onPressEnter={e => {
              history.push({
                pathname: `${ROUTER_PATH.PRODUCT}`,
                search: `type=search&search=${searchProduct}&idCategory=2`,
              })
              // window.location.reload()
            }}
            onChange={(e: any) => {
              setSearchProduct(e.target.value)
            }}
          />
        </SearchArea>
        {hasCookie(SESSION_KEY.SESSION) ? (
          <div style={{ display: 'flex', flexFlow: 'nowrap' }}>
            <WrapAction style={{ margin: '0 15px 0 0' }}>
              <Dropdown
                overlay={
                  <ListNotification
                    visible={visible}
                    setVisible={setVisible}
                    getMoreDataNotify={getMoreDataNotify}
                    setParam={setParam}
                    dataNotify={dataNotify}
                    loadMoreNotification={loadMoreNotification}
                    getDataNotify={getDataNotify}
                    setLoadMoreNotification={setLoadMoreNotification}
                    param={param}
                    loading={loading}
                    setLoading={setLoading}
                    showDrawer={showDrawer}
                    setUpdateNotify={setUpdateNotify}
                    updateNotify={updateNotify}
                  />
                }
                trigger={['click']}
                placement="bottomRight"
              >
                <Badge
                  count={dataUnread?.count}
                  overflowCount={999}
                  style={{ backgroundColor: '#f26522' }}
                >
                  <BellOutlined
                    style={{
                      color: '#f26522',
                      cursor: 'pointer',
                      fontSize: 24,
                    }}
                    onClick={() => {
                      setOnCLickNotify(!onCLickNotify)
                    }}
                  />
                </Badge>
              </Dropdown>
            </WrapAction>
            <WrapAction>
              <Badge
                style={{ backgroundColor: '#F26522', color: 'white' }}
                count={amountProductInCart}
              >
                <BagIcon
                  style={{ cursor: 'point' }}
                  onClick={() => {
                    if (location.pathname !== ROUTER_PATH.CART)
                      history.push(ROUTER_PATH.CART)
                  }}
                />
              </Badge>
              <Dropdown overlay={option} trigger={['click']}>
                <div>
                  <Avatar
                    src={userState?.avatar || R.images.avatar_default}
                    style={{ marginLeft: '20px' }}
                  />
                </div>
              </Dropdown>
            </WrapAction>
          </div>
        ) : (
          <WrapAction>
            <LoginButton
              onClick={() => {
                history.push(ROUTER_PATH.LOGIN)
              }}
            >
              {R.strings().title_login}
            </LoginButton>
          </WrapAction>
        )}
        {/** Start Drawer */}
        <StyledDrawer
          placement="left"
          onClose={() => {
            setShowDrawer(false)
          }}
          visible={showDrawer}
        >
          <ul>
            <li>
              <SearchArea id="small-width">
                <SearchIcon />
                <Input
                  bordered={false}
                  placeholder={R.strings().title_search_product}
                  onPressEnter={e => {
                    history.push({
                      pathname: `${ROUTER_PATH.PRODUCT}`,
                      search: `type=search&search=${searchProduct}&idCategory=2`,
                    })
                    // window.location.reload()
                  }}
                  onChange={(e: any) => {
                    setSearchProduct(e.target.value)
                  }}
                />
              </SearchArea>
            </li>

            {MENU_HEADER?.map((item: any, index: number) => {
              if (index === 1) {
                return (
                  <li key={item.id}>
                    <Dropdown overlay={menu} trigger={['click']}>
                      <div
                        className={
                          renderActive(item) ? 'active' : 'category-item'
                        }
                      >
                        {R.strings().title_product}&nbsp;
                        <DownOutlined />
                      </div>
                    </Dropdown>
                  </li>
                )
              }
              return (
                <li key={item.id}>
                  <div
                    className={renderActive(item) ? 'active' : 'category-item'}
                    onClick={() => {
                      if (location.pathname !== item.main_path)
                        history.push(item.main_path)
                      setShowDrawer(false)
                    }}
                  >
                    {item.title}
                  </div>
                </li>
              )
            })}

            {hasCookie(SESSION_KEY.SESSION) ? (
              <>
                <HeaderItem
                  onClick={() => {
                    history.push(ROUTER_PATH.CART)
                    setShowDrawer(false)
                  }}
                >
                  <div>{R.strings().title_cart}</div>
                </HeaderItem>
                <HeaderItem
                  onClick={() => {
                    history.push(ROUTER_PATH.ACCOUNT + '/userinfor')
                    setShowDrawer(false)
                  }}
                >
                  <div>{R.strings().title_account}</div>
                </HeaderItem>
                <div style={{ textAlign: 'center' }}>
                  <Badge
                    count={dataUnread?.count}
                    overflowCount={999}
                    size="small"
                    offset={[25, 10]}
                  >
                    <HeaderItem
                      onClick={() => {
                        setVisibleNotify(!visibleNotify)
                      }}
                    >
                      <div style={{ height: 30 }}>
                        {R.strings().notification}
                      </div>
                    </HeaderItem>
                  </Badge>
                </div>
                <HeaderItem>
                  <div
                    onClick={() => {
                      Cookie.remove(SESSION_KEY.SESSION)
                      window.location.reload()
                    }}
                  >
                    {R.strings().title_log_out}
                  </div>
                </HeaderItem>
              </>
            ) : (
              <HeaderItem>
                <div
                  onClick={() => {
                    history.push(ROUTER_PATH.LOGIN)
                  }}
                >
                  {R.strings().title_login}
                </div>
              </HeaderItem>
            )}
          </ul>
          <ListNotification
            visible={visible}
            setVisible={setVisible}
            setParam={setParam}
            dataNotify={dataNotify}
            loadMoreNotification={loadMoreNotification}
            getDataNotify={getDataNotify}
            setLoadMoreNotification={setLoadMoreNotification}
            param={param}
            loading={loading}
            setLoading={setLoading}
            showDrawer={showDrawer}
            visibleNotify={visibleNotify}
            setVisibleNotify={setVisibleNotify}
            setUpdateNotify={setUpdateNotify}
            updateNotify={updateNotify}
          />
          {/* đã đăng nhập */}

          <div style={{ display: 'flex', flexFlow: 'nowrap' }}>
            <WrapAction style={{ margin: '0 10px 0 0' }}>
              <Dropdown
                overlay={<ListNotification />}
                trigger={['click']}
                placement="bottomRight"
              >
                <Badge count={10} overflowCount={999}>
                  <BellOutlined
                    style={{
                      color: '#f26522',
                      cursor: 'pointer',
                      fontSize: 24,
                    }}
                  />
                </Badge>
              </Dropdown>
            </WrapAction>

            {hasCookie(SESSION_KEY.SESSION) ? (
              <WrapAction>
                <BagIcon style={{ marginRight: '10px' }} />
                <Avatar src={userState?.avatar || R.images.avatar_default} />
              </WrapAction>
            ) : (
              <WrapAction>
                <LoginButton>{R.strings().title_login}</LoginButton>
              </WrapAction>
            )}
          </div>
        </StyledDrawer>
        <Dropdown overlay={optionLanguage} trigger={['click']}>
          <div>
            {language === 'vi' ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <FlagVNIcon style={{ marginRight: '8px' }} />
                {/* {R.strings().vietnamese}
                &nbsp;
                <DownOutlined /> */}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <FlagESIcon style={{ marginRight: '8px' }} />
                {/* {R.strings().english}
                &nbsp;
                <DownOutlined /> */}
              </div>
            )}
          </div>
        </Dropdown>
      </Space>
      {/** End Drawer */}

      {/* loading when change language */}
      {loadingLanguage && (
        <div
          style={{
            position: 'fixed',
            inset: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </HeaderBlock>
  )
}

export default Header
