import R from 'assets'
import {
  GasStationIcon,
  LocationIcon,
  YoYoCoinIcon,
} from 'common/components/Icons'
import { ImageStyled } from 'global-styles'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatPrice } from 'utils/ruleForm'
import { getAddressStore } from '../ApiPayment'
import { TDataInsuranceItem } from '../interface'
import * as Styled from '../styled'
type Props = { data: TDataInsuranceItem; setDataInsurance: any }

const InsuranceItem: React.FC<Props> = ({ data, setDataInsurance }) => {
  const userState = useSelector((state: any) => state.authReducer)?.userInfo
  const [addressPetrolStore, setAddressPetrolStore] = useState({
    address: '',
    name: '',
    phone: '',
  })
  useEffect(() => {
    if (data.petrol_store_id) {
      getAddressPetrolStore()
    }
  }, [data])

  const getAddressPetrolStore = async () => {
    try {
      const res = await getAddressStore(data.petrol_store_id)

      setAddressPetrolStore({
        address: `${res.data.location_address}, ${res.data.ward.name}, ${res.data.district.name}, ${res.data.province.name} `,
        name: res.data.name,
        phone: res.data.phone,
      })
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  return (
    <>
      <Styled.WhiteBlock>
        <Styled.TitleBlock>
          {R.strings().title_delivery_information}
        </Styled.TitleBlock>

        <Styled.AddressBlock>
          <Styled.AddressDetail>
            <LocationIcon style={{ marginRight: '10px' }} />
            <div>
              <div>{addressPetrolStore.address}</div>
              <div style={{ fontWeight: 600 }}>
                {addressPetrolStore.name} | {addressPetrolStore.phone}
              </div>
            </div>
          </Styled.AddressDetail>
        </Styled.AddressBlock>

        {/** End delivery address */}

        <Styled.InsuranceInfor>
          <Styled.TitleBlock>
            <div style={{ display: 'flex' }}>
              <GasStationIcon style={{ marginRight: '8px' }} />
              {addressPetrolStore.name}
            </div>
          </Styled.TitleBlock>

          <Styled.ProductInfor>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Styled.ImageSymbol>
                <ImageStyled src={data?.product?.media || R.images.img_news1} />
              </Styled.ImageSymbol>
              <div>
                <div>{data?.product?.name}</div>
                <div id="price-product-small">
                  {formatPrice(data.product?.price)} đ
                </div>
              </div>
            </div>
            <div id="price-product">{formatPrice(data.product?.price)} đ</div>
            <div>x{data?.amount}</div>
          </Styled.ProductInfor>
        </Styled.InsuranceInfor>

        {userState?.point ? (
          <Styled.UsePoint>
            <Styled.WrapPoint>
              <YoYoCoinIcon style={{ marginRight: '10px' }} />
              {formatPrice(
                data?.total_price > userState?.point
                  ? userState?.point
                  : data?.total_price
              )}{' '}
              {R.strings().points}
              {/* {R.strings().use} {formatPrice(userState?.point)}{' '}
              {R.strings().points} */}
            </Styled.WrapPoint>
            <Styled.SwitchStyled
              checked={data.isCheckedPoint}
              onChange={(value: boolean) => {
                if (value) {
                  if (data.total_price.toFixed() > userState?.point) {
                    setDataInsurance({
                      ...data,
                      isCheckedPoint: value,
                      point: userState?.point,
                    })
                  } else {
                    setDataInsurance({
                      ...data,
                      isCheckedPoint: value,
                      point: parseInt(data.total_price.toFixed()),
                    })
                  }
                } else {
                  setDataInsurance({ ...data, isCheckedPoint: value, point: 0 })
                }
              }}
            />
          </Styled.UsePoint>
        ) : (
          <></>
        )}

        <Styled.TotalPriceBlock>
          <Styled.WrapPrice>
            <Styled.ValueBlock>
              {R.strings().title_total_price_of_goods} :
              <span>{formatPrice(data.total_price)} đ</span>
            </Styled.ValueBlock>
            <Styled.ValueBlock>
              {R.strings().title_points_used} :
              <span>{formatPrice(data.point)} đ</span>
            </Styled.ValueBlock>
          </Styled.WrapPrice>
        </Styled.TotalPriceBlock>
      </Styled.WhiteBlock>

      <Styled.WhiteBlock>
        <Styled.TitleBlock>
          {R.strings().title_insurance_information}{' '}
          <b style={{ color: 'red' }}>&#42;</b>
        </Styled.TitleBlock>
        <div style={{ borderTop: '1px solid #CED4DA', marginTop: '12px' }}>
          <Styled.WrapInputPayment>
            <Styled.InputPayment
              id="dataInsurance_name"
              placeholder={R.strings().placeholder_owner_name}
              value={data.name}
              onChange={(e: any) => {
                setDataInsurance({ ...data, name: e.target.value })
              }}
              addonBefore={<b style={{ color: 'red' }}>&#42;</b>}
              bordered={false}
            />
          </Styled.WrapInputPayment>
          <Styled.WrapInputPayment>
            <Styled.InputPayment
              id="dataInsurance_number_plate"
              placeholder={R.strings().number_plate}
              value={data.number_plate}
              onChange={(e: any) => {
                setDataInsurance({ ...data, number_plate: e.target.value })
              }}
              addonBefore={<b style={{ color: 'red' }}>&#42;</b>}
              bordered={false}
            />
          </Styled.WrapInputPayment>
          <Styled.WrapInputPayment>
            <Styled.InputPayment
              id="dataInsurance_vehicle_brand"
              placeholder={R.strings().placeholder_car_company}
              value={data.vehicle_brand}
              onChange={(e: any) => {
                setDataInsurance({ ...data, vehicle_brand: e.target.value })
              }}
              addonBefore={<b style={{ color: 'red' }}>&#42;</b>}
              bordered={false}
            />
          </Styled.WrapInputPayment>
        </div>
      </Styled.WhiteBlock>
    </>
  )
}
export default InsuranceItem
