import { ApiClient } from 'services/ApiService'

export const getAmountProductInCart = () =>
  ApiClient.get('/customer/cart/count-cart')

export const updateLanguage = (payload: any) =>
  ApiClient.put('/customer/auth/language', payload)

export const getListCategory = () => ApiClient.get(`/customer/category`)

export const listNotify = (payload: any) =>
  ApiClient.get(`/notification`, payload)

export const unrReadNotify = () => ApiClient.get(`/notification/not-read`)

export const readNotify = (id: number) => ApiClient.patch(`/notification/${id}`)

export const readAllNotify = () => ApiClient.put(`/notification`)

// API lấy lượt truy cập
export const getHits = () => ApiClient.get('/home/access')
