import { ApiClient } from 'services/ApiService'

export const getListGas = (payload: any) =>
  ApiClient.get('/customer/products/petrol', payload)
export const getListOil = (payload: any) =>
  ApiClient.get('/customer/products/2/category', payload)
export const getListInsurance = (payload: any) =>
  ApiClient.get('/customer/products/insurance', payload)
export const getHightFilter = (payload: any) =>
  ApiClient.get('/customer/products/hight-filter', payload)
export const getListDeal = (payload: any) =>
  ApiClient.get('/customer/products/deal', payload)
export const getDetail = (
  product_id: number,
  petrol_store_id: number,
  payload: any
) =>
  ApiClient.get(
    `/customer/products/${product_id}/${petrol_store_id}/product`,
    payload
  )
export const getProvince = () => ApiClient.get('/google-address/province')
export const getDistrict = (payload: any) =>
  ApiClient.get('/google-address/district', payload)
export const getListStore = (
  product_id: number,
  petrol_store_id: number,
  payload: any,
  dataLocation?: any
) =>
  ApiClient.get(
    `/customer/products/${product_id}/${petrol_store_id}/petrol-store`,
    payload
  )
export const getListStation = (product_id: number, payload: any) =>
  ApiClient.get(`/customer/products/${product_id}/list-petrol`, payload)
export const getDetailStation = (petrol_stock_id: number) =>
  ApiClient.get(`/customer/products/${petrol_stock_id}/petrol`)
export const addCart = (payload: any) =>
  ApiClient.post(`/customer/cart`, payload)
export const getListOther = (category_id: number) =>
  ApiClient.get(`/customer/products/${category_id}/category`)
