import Icon from '@ant-design/icons'

const DebtInforColor = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.27273 5.32812H6C3.23858 5.32812 1 7.56671 1 10.3281V14.8736C1 17.635 3.23857 19.8736 6 19.8736H14.1818C16.9432 19.8736 19.1818 17.635 19.1818 14.8736V10.3281C19.1818 7.5667 16.9432 5.32812 14.1818 5.32812H10.5455"
      stroke="#F26522"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3301 0.449417C9.36868 -0.567067 6.95463 0.19893 5.93815 2.16032L3.66479 6.54694C4.20569 6.34662 4.79069 6.23718 5.40125 6.23718H8.47876L11.4469 0.509952L11.3301 0.449417Z"
      fill="#F26522"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5504 2.0519L12.302 0.886713L9.5293 6.23694H14.4921C15.2303 6.23694 15.9312 6.39693 16.5619 6.68412C17.0656 4.89596 16.2645 2.94024 14.5504 2.0519Z"
      fill="#F26522"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 13.0557C1 10.2942 3.23858 8.05566 6 8.05566H14.1818C16.9432 8.05566 19.1818 10.2942 19.1818 13.0557V14.8738C19.1818 17.6353 16.9432 19.8738 14.1818 19.8738H6C3.23857 19.8738 1 17.6353 1 14.8738V13.0557ZM7.52273 13.9106C7.52273 13.4964 7.85851 13.1606 8.27273 13.1606H9.79545V11.692C9.79545 11.2778 10.1312 10.942 10.5455 10.942C10.9597 10.942 11.2955 11.2778 11.2955 11.692V13.1606H12.8182C13.2324 13.1606 13.5682 13.4964 13.5682 13.9106C13.5682 14.3248 13.2324 14.6606 12.8182 14.6606H11.2955V16.2375C11.2955 16.6517 10.9597 16.9875 10.5455 16.9875C10.1312 16.9875 9.79545 16.6517 9.79545 16.2375V14.6606H8.27273C7.85851 14.6606 7.52273 14.3248 7.52273 13.9106Z"
      fill="#F26522"
    />
  </svg>
)

const DebtInforColorIcon = (props: any) => (
  <Icon component={DebtInforColor} {...props} />
)
export default DebtInforColorIcon
