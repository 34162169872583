import R from 'assets'

export const RESTART_ON_REMOUNT = '@saga-injector/restart-on-remount'

export const API_STATUS = {
  SUCCESS: 200,
  CREATED: 201,
  NOTFOUND: 404,
  BADREQUEST: 400,
  SERVER: 500,
  UNAUTHORIZED: 401,
  CONFLICT: 409,
  FORBIDDEN: 403,
}
export const ENTERPRISE_REQUEST_STATUS = {
  PENDING: 2, // Chờ phê duyệt
  ACCEPT: 1, // Chấp nhận
  REJECT: 0, // Từ chối
}
export const MEDIA_NAME = {
  IMAGE: 'image',
  VIDEO: 'video',
}
export const PAYMENTS = [
  {
    NAME: R.strings().title_cash,
    DESCRIPTION: R.strings().description_cash,
    VALUE: 4,
  },
  {
    NAME: R.strings().title_debt,
    DESCRIPTION: R.strings().description_debt,
    VALUE: 3,
  },
  {
    NAME: R.strings().title_transfer,
    DESCRIPTION: R.strings().description_transfer,
    VALUE: 2,
  },
  {
    NAME: R.strings().title_VN_Pay,
    DESCRIPTION: R.strings().description_VN_Pay,
    VALUE: 1,
  },
  {
    NAME: 'VT',
    DESCRIPTION: 'VT',
    VALUE: 5,
  },
]
export const TYPE_POINT = {
  ORDER: 1,
  REFUND: 2,
  REFERRAL: 3,
  USE_POINT: 4,
  USES_CANCEL_POINT: 5,
}
export const CONFIG_TYPE = {
  ATTENDANCE: 1,
  ORDER_PROMOTION: 2,
  DAILY_TURN: 3,
  REFERRAL_APP: 4,
  REFERRAL_CODE: 5,
  REFERRAL_MEMBER: 6,
}
export const ACCOUNT_R0LE = {
  ALL: 0,
  ADMIN: 1,
  STAFF: 2,
  AGENT: 3,
}
export const REQUEST_STATUS = {
  PENDING: 0,
  SUCCESS: 1,
  REFUSE: 2,
}
export const GENDER = {
  MALE: 1,
  FEMALE: 2,
}
export const REG_PHONE = /^(0|\+84)(\s|\.)?((3[2-9])|(5[689])|(7[06-9])|(8[1-689])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/

export const IS_ACTIVE = {
  ACTIVE: 1,
  INACTIVE: 0,
}

export const TYPE_ORDER = {
  GASOLINE: 1, // ORDER XĂNG
  PRODUCT: 2, // ORDER product bình thường
  INSURANCE: 3, // Order bảo hiểm
}
export const TYPE_VEHICLE = {
  MOTORCYCLE: 1,
  CAR: 2,
}

export const STATUS_ORDER = {
  WAIT_CONFIRM: 1, //chờ xác nhận
  CONFIRMED: 2, //đã xác nhận
  SHIP: 3, //đang thực hiện
  COMPLETE: 4, //Hoàn thành
  CANCEL: 5, //Huy
  DENY: 6, //Từ chối
}
