import { BackTop, Col, Divider, Row, Spin } from 'antd'
import Countdown from 'antd/lib/statistic/Countdown'
import R from 'assets'
import images from 'assets/images'
import { EmptyComponent } from 'common/components/Empty'
import { ROUTER_PATH } from 'common/config'
import { createPaymentUrl } from 'features/payment/ApiPayment'
import { Cancellation, CancellationMobile } from 'features/product/styles'
import moment from 'moment'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { WebSocket } from 'services/WebSocket'
import { formatPrice } from 'utils/ruleForm'
import { paymentVT } from '../ApiOrder'
import {
  ColStyle,
  InfoProduct,
  ListProductBlock,
  PaymentButton,
  TagStyle,
} from '../styled'
import OrderVTPAY from './Order.VTPAY'
type Props = {
  loading: boolean
  dataSource: any
  statusOrder: any
}

const OrderList: React.FC<Props> = ({ loading, dataSource, statusOrder }) => {
  const history = useHistory()

  // state xử lý open modal scan qr viettin
  const [isModalVisibleScan, setIsModalVisibleScan] = useState<boolean>(false)
  const [waitPayment, setWaitPayment] = useState(false)
  const [qrGen, setQrGen] = useState('')
  const [detailOrder, setDetailOrder] = useState(null)

  const handlePaymentVT = (orderId: any) => {
    setWaitPayment(true)
    paymentVT({
      order_id: orderId,
    })
      .then((result: any) => {
        setQrGen(result?.data?.qrCode)
      })
      .finally(() => {
        setIsModalVisibleScan(true)
      })
    // .catch(err => {})
  }
  React.useEffect(() => {
    WebSocket.socketClient?.on(`customer_notification`, async (datas: any) => {
      if (datas?.data?.type_notification?.id === 55) {
        setIsModalVisibleScan(false)
        setWaitPayment(false)
      }
    })
  }, [])

  return (
    <>
      <Spin spinning={loading} size="large">
        <div
          style={{
            height: '65vh',
            overflowX: 'auto',
            backgroundColor: 'unset',
          }}
        >
          <div style={{ margin: '0 0 15px 15px', color: '#f26522' }}>
            <b>
              {R.strings().amount}: {dataSource?.length}
            </b>
          </div>
          {dataSource?.length ? (
            dataSource?.map((item: any) => (
              <>
                <ListProductBlock>
                  <div
                    style={{
                      padding: 15,
                      display: 'flex',
                      flexFlow: 'nowrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexFlow: 'nowrap',
                      }}
                    >
                      <img
                        alt=""
                        src={R.images.symbol_gas}
                        style={{ width: 20, height: 20, objectFit: 'cover' }}
                      />
                      <div style={{ fontWeight: 500, padding: '0 0 0 10px' }}>
                        {item?.petrol_store?.name}
                      </div>
                    </div>
                    <TagStyle
                      color={
                        item.status === 1
                          ? '#feecd9'
                          : item.status === 2
                          ? 'blue'
                          : item.status === 4
                          ? 'green'
                          : '#E9ECEF'
                      }
                      style={
                        item.status === 1
                          ? {
                              color: '#f26522',
                            }
                          : item.status === 5 || 6
                          ? {
                              color: 'black',
                            }
                          : {}
                      }
                    >
                      {item.status === 1
                        ? R.strings().pending_order
                        : item.status === 2
                        ? R.strings().confirm_order
                        : item.status === 4
                        ? R.strings().complete_order
                        : item.status === 5
                        ? R.strings().cancel_order
                        : item.status === 6
                        ? R.strings().reject_order
                        : ''}
                    </TagStyle>
                  </div>
                  <Divider style={{ margin: 0 }} />
                  {item?.order_items?.map((subItem: any) => (
                    <div
                      style={{ padding: '0 15px 15px 15px' }}
                      onClick={() => {
                        history.push({
                          pathname: `${ROUTER_PATH.DETAIL_ORDER}/${item?.id}`,
                        })
                      }}
                    >
                      <Row
                        justify="space-between"
                        style={{ padding: '14px 0', alignItems: 'center' }}
                      >
                        <InfoProduct>
                          {/* check ảnh sản phẩm là xăng thì default */}
                          <img
                            alt=""
                            src={
                              item?.order_gasoline
                                ? images.default_fill
                                : subItem?.media_url
                            }
                            style={{
                              width: 70,
                              height: 70,
                              objectFit: 'cover',
                            }}
                          />{' '}
                          <div style={{ paddingLeft: 10 }}>
                            {subItem?.product_name}
                          </div>
                        </InfoProduct>
                        <ColStyle>
                          <div>
                            {item.order_gasoline && item.status < 4
                              ? formatPrice(subItem?.price_reality)
                              : formatPrice(subItem?.product_price)}
                            đ
                          </div>
                        </ColStyle>
                        <Col>
                          <div>
                            {subItem?.total_quantity === 0
                              ? R.strings().bottle_full
                              : 'x' +
                                formatPrice(
                                  (+subItem?.total_quantity).toFixed(3)
                                )}
                          </div>
                        </Col>
                        <Col>
                          <div>
                            {item.order_gasoline && item.status < 4
                              ? item.order_gasoline?.is_full_fuel === 0
                                ? formatPrice(subItem?.price_reality)
                                : formatPrice(subItem?.product_price)
                              : formatPrice(subItem?.product_price)}
                            đ
                          </div>
                        </Col>
                      </Row>
                      <Divider style={{ margin: 0 }} />
                    </div>
                  ))}
                  <Row
                    justify="space-between"
                    style={{ padding: '0px 15px 15px 15px', fontWeight: 500 }}
                  >
                    <div>
                      {item?.total_amount} {R.strings().title_product}
                    </div>
                    {item?.cancellation_time &&
                    item?.status !== 4 &&
                    item?.status !== 2 &&
                    item?.status !== 5 &&
                    item?.status !== 6 ? (
                      <Cancellation>
                        {R.strings().cacel_after}:&nbsp;
                        <Countdown
                          valueStyle={{
                            color: '#F26522',
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                          value={
                            Date.now() +
                            Number(moment(item?.cancellation_time)) -
                            Number(moment())
                          }
                          format="HH:mm:ss"
                          // onFinish={() => setOutTiem(true)}
                        />
                      </Cancellation>
                    ) : (
                      <></>
                    )}
                    {(item?.order_payment?.type_payment?.id === 1 ||
                      item?.order_payment?.type_payment?.id === 5) &&
                    item?.order_payment?.is_paid === 0 &&
                    item?.status === 1 ? (
                      <Cancellation>
                        {R.strings().wait_payment}:&nbsp;
                        <Countdown
                          valueStyle={{
                            color: '#F26522',
                            fontSize: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                          value={
                            Date.now() +
                            Number(
                              moment(
                                item?.order_vnpays[0]?.vnpay_transaction
                                  ?.update_at ||
                                  item?.order_vtpays[0]?.vtpay_transaction
                                    ?.update_at
                              )
                            ) +
                            900000 -
                            Number(moment())
                          }
                          format="mm:ss"
                        />
                        <PaymentButton
                          onClick={async () => {
                            if (
                              item?.order_vtpays[0]?.vtpay_transaction
                                ?.update_at
                            ) {
                              setDetailOrder(item)
                              handlePaymentVT(item?.id)
                              return
                            }

                            const resPayment = await createPaymentUrl({
                              order_ids: item?.id,
                              order_description: 'Thanh toan don hang',
                              order_type: '250000',
                              locale: 'vn',
                            })
                            window.open(resPayment.data.vnpayBuilderUrl)
                          }}
                        >
                          {R.strings().title_payment}
                        </PaymentButton>
                      </Cancellation>
                    ) : (
                      <></>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexFlow: 'nowrap',
                        // width: '33%',
                      }}
                    >
                      {item?.total_price === 0 &&
                      item?.order_gasoline?.is_full_fuel === 1
                        ? R.strings().unknown
                        : R.strings().title_total_price + ':'}
                      &nbsp;
                      <div style={{ color: '#f26522' }}>
                        {item?.total_price === 0
                          ? item?.order_gasoline?.is_full_fuel === 1
                            ? ''
                            : 0 + ' đ'
                          : formatPrice(+(item?.total_price).toFixed(3)) + ' đ'}
                      </div>
                    </div>
                  </Row>
                  {item?.order_gasoline &&
                  item?.status !== 4 &&
                  item?.status !== 2 &&
                  item?.status !== 5 &&
                  item?.status !== 6 ? (
                    <CancellationMobile>
                      {R.strings().cacel_after}:&nbsp;
                      <Countdown
                        valueStyle={{
                          color: '#F26522',
                          fontSize: '14px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        value={
                          Date.now() +
                          Number(moment(item?.cancellation_time)) -
                          Number(moment())
                        }
                        format="HH:mm:ss"
                        // onFinish={() => setOutTiem(true)}
                      />
                    </CancellationMobile>
                  ) : (
                    <></>
                  )}
                  {item?.order_payment?.type_payment?.id === 1 &&
                  item?.order_payment?.is_paid === 0 &&
                  item?.status === 1 ? (
                    <CancellationMobile>
                      <Row>
                        {R.strings().wait_payment}:&nbsp;
                        <Countdown
                          valueStyle={{
                            color: '#F26522',
                            fontSize: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                          value={
                            Date.now() +
                            Number(
                              moment(
                                item?.order_vnpays[0]?.vnpay_transaction
                                  ?.update_at
                              )
                            ) +
                            900000 -
                            Number(moment())
                          }
                          format="mm:ss"
                        />
                      </Row>
                      <PaymentButton
                        onClick={async () => {
                          const resPayment = await createPaymentUrl({
                            order_ids: item?.id,
                            order_description: 'Thanh toan don hang',
                            order_type: '250000',
                            locale: 'vn',
                          })
                          window.open(resPayment.data.vnpayBuilderUrl)
                        }}
                      >
                        {R.strings().title_payment}
                      </PaymentButton>
                    </CancellationMobile>
                  ) : (
                    <></>
                  )}
                </ListProductBlock>
                <BackTop> </BackTop>
              </>
            ))
          ) : (
            <>
              <EmptyComponent />
            </>
          )}
        </div>
      </Spin>
      <OrderVTPAY
        isModalVisibleScan={isModalVisibleScan}
        onCancel={() => {
          setIsModalVisibleScan(false)
          setWaitPayment(false)
        }}
        qrGen={qrGen}
        dataSource={detailOrder}
      />
    </>
  )
}

export default OrderList
