import { Button, Checkbox, Col, Image, Input, Row } from 'antd'
import styled from 'styled-components'

const WrapCart = styled.div`
  padding: 20px 0;
`

const HeaderCart = styled(Row)`
  line-height: 50px;
  background-color: #ffffff;
  border-radius: 8px 8px 0px 0px;
  margin-top: 10px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const ColCart = styled(Col).attrs((props: { justify: string | undefined }) => ({
  justify: props.justify || 'center',
}))`
  justify-content: ${props => props.justify};
  display: flex;
  align-items: center;

  #price-col {
    display: none;
  }
  #amount-col {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    #price-col {
      display: block;
    }
    #amount-col {
      display: block;
    }
    :nth-child(2) {
      display: none;
    }
    :nth-child(3) {
      display: none;
    }
    :nth-child(4) {
      display: none;
    }
    :nth-child(5) {
      display: none;
    }
  }
`

const InforProduct = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: space-between;
`

const StoreBlock = styled.div`
  margin-top: 10px;
  background-color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  padding: 6px;
`

const NameStore = styled.div`
  line-height: 40px;
`

const ProductBlock = styled(Row)`
  height: 110px;
  border-top: 1.5px solid #e8e8e8;
  @media only screen and (max-width: 768px) {
    height: 140px;
  }
`

const ButtonChangeQuantity = styled(Button)`
  &&.ant-btn {
    padding: 2px;
    height: 24px;
  }
  @media only screen and (max-width: 768px) {
    &&.ant-btn {
      padding: 0px;
      height: 24px;
    }
  }
`

const ImageProduct = styled(Image)`
  width: 90px;
  aspect-ratio: 1/1;
  margin: 0 10px;

  @media only screen and (max-width: 768px) {
    width: 50px;
  }
`

const AffixBottomBlock = styled.div`
  width: 100%;
  background-color: #ffff;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 20px;
  box-shadow: 0px 0px 12px -6px rgba(24, 39, 75, 0.12),
    0px -8px 24px -4px rgba(24, 39, 75, 0.08);
`

const ButtonPurchase = styled(Button)`
  background-color: #f26522;
  border-radius: 12px;
  color: white;
`

const TotalPriceBlock = styled.div`
  display: flex;
  align-items: center;
  span {
    color: #f26522;
    font-weight: 600;
    font-size: 16px;
    margin-left: 5px;
  }
`

const InputAmount = styled(Input)`
  width: 58px;
  height: 24px;
  border-radius: 0px;
  @media only screen and (max-width: 768px) {
    width: 40px;
  }
`

const CheckAllBlock = styled.div`
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const CheckBoxStyled = styled(Checkbox)`
  margin: 10px;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f26522;
    border-color: #f26522;
  }
`

export {
  WrapCart,
  HeaderCart,
  ColCart,
  StoreBlock,
  NameStore,
  ProductBlock,
  ButtonChangeQuantity,
  ImageProduct,
  AffixBottomBlock,
  ButtonPurchase,
  TotalPriceBlock,
  InputAmount,
  CheckBoxStyled,
  InforProduct,
  CheckAllBlock,
}
