import { Avatar, Image, List, Rate, Row, Spin } from 'antd'
import R from 'assets'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getListRate } from './ApiStore'
import { AboveBlock, ContainerRate, MainBlock, ProgressStyle } from './styled'

type Params = {
  // page: number
  limit: number
  price_status?: any
  age_status?: any
  sell_status?: any
}

const RateStoreScreen: React.FC = () => {
  const param: any = useParams()
  const [rateStore, setRateStore] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [starValue, setStarValue] = useState<number>(0)
  const [pages, setPages] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  })
  const [params, setParams] = useState<Params>({
    // page: 1,
    limit: 999,
  })

  useEffect(() => {
    getDataSource()
  }, [])

  const getDataSource = async () => {
    try {
      setIsLoading(true)
      const res = await getListRate(param.id, params)
      setRateStore(res.data)
      if (res.data.petrol_store?.avg_star) {
        setStarValue(res.data?.petrol_store?.avg_star)
      }
      setPages({
        ...pages,
        current: res.meta?.pagination?.page,
        total: res.meta?.pagination?.totalItems,
        pageSize: res.meta?.pagination?.limit,
      })
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  // const searchParams = new URLSearchParams(window.location.search)
  // const PetrolStoreId: string = searchParams.get('petrol_store_id') as string
  const TotalStar =
    rateStore?.petrol_store?.five_star +
    rateStore?.petrol_store?.four_star +
    rateStore?.petrol_store?.three_star +
    rateStore?.petrol_store?.two_star +
    rateStore?.petrol_store?.one_star
  return (
    <Spin spinning={isLoading}>
      <ContainerRate>
        <AboveBlock>
          <div
            style={{ width: 260, textAlign: 'center', margin: '0 25px 0 0' }}
          >
            <h4 style={{ margin: 0, fontWeight: 600 }}>
              {+rateStore?.petrol_store?.avg_star.toFixed(1)}/5
            </h4>
            <div>
              ({rateStore?.petrol_store_review.length} {R.strings().evaluate})
            </div>
            <Rate allowHalf disabled value={starValue}></Rate>
          </div>
          <div style={{ width: '100%', display: 'flex', flexFlow: 'nowrap' }}>
            <div style={{ width: 55 }}>
              <div>{'5 ' + R.strings().star}</div>
              <div>{'4 ' + R.strings().star}</div>
              <div>{'3 ' + R.strings().star}</div>
              <div>{'2 ' + R.strings().star}</div>
              <div>{'1 ' + R.strings().star}</div>
            </div>
            <div style={{ width: '100%' }}>
              <ProgressStyle
                percent={(rateStore?.petrol_store?.five_star * 100) / TotalStar}
              />
              <ProgressStyle
                percent={(rateStore?.petrol_store?.four_star * 100) / TotalStar}
              />
              <ProgressStyle
                percent={
                  (rateStore?.petrol_store?.three_star * 100) / TotalStar
                }
              />
              <ProgressStyle
                percent={(rateStore?.petrol_store?.two_star * 100) / TotalStar}
              />
              <ProgressStyle
                percent={(rateStore?.petrol_store?.one_star * 100) / TotalStar}
              />
            </div>
          </div>
        </AboveBlock>
        <MainBlock>
          <List
            itemLayout="horizontal"
            dataSource={rateStore?.petrol_store_review}
            renderItem={(item: any) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    item?.users?.avatar ? (
                      <Avatar src={item?.users?.avatar} />
                    ) : (
                      <Avatar size={32} src={R.images.avatar_default} />
                    )
                  }
                  title={
                    <Row justify="space-between">
                      <div>
                        <div>
                          <b>{item?.users?.name}</b>
                        </div>

                        <Rate
                          style={{}}
                          allowHalf
                          disabled
                          value={item?.star}
                        ></Rate>
                      </div>
                      &nbsp; &nbsp; &nbsp;
                      {moment(item?.create_at).format('HH:mm, DD/MM/YYYY')}
                    </Row>
                  }
                  description={
                    <>
                      <div style={{ fontWeight: 500, color: 'black' }}>
                        {item?.description}
                      </div>
                      {item?.review_media?.map((subItem: any) => (
                        <Image.PreviewGroup>
                          <Image
                            style={{
                              width: 70,
                              height: 70,
                              objectFit: 'cover',
                              margin: '0 10px 0 0',
                            }}
                            src={subItem?.media_url}
                          />
                        </Image.PreviewGroup>
                      ))}
                    </>
                  }
                />
              </List.Item>
            )}
          />
          {/* <Row justify="end" style={{}}>
            <Pagination
              onChange={async (page, pageSize) => {
                setParams({ ...params, page })
              }}
              showSizeChanger={false}
              pageSize={pages.pageSize}
              current={pages.current}
              total={pages.total}
            />
          </Row> */}
        </MainBlock>
      </ContainerRate>
    </Spin>
  )
}
export default RateStoreScreen
