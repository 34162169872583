import R from 'assets'
import React from 'react'
import { ContentItem, SmallItem } from './styled'

const TermsOfUse: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '20px',
        listStyleType: ' upper-roman',
        textAlign: 'justify',
      }}
    >
      ĐIỀU KHOẢN SỬ DỤNG ỨNG DỤNG PYOYO
      <SmallItem>
        <b>1. Sự tuân thủ của Người dùng</b>
        <ContentItem>
          <p>
            Người dùng đồng ý tuân thủ với tất cả hướng dẫn, thông báo, quy tắc
            hoạt động, chính sách và chỉ dẫn liên quan đến việc mua hàng
            hóa/dịch vụ thông qua nền tảng, cũng như bất kỳ sửa đổi, điều chỉnh
            nào đối với những quy định nói trên do PYOYO ban hành vào từng thời
            điểm. PYOYO có quyền sửa đổi các hướng dẫn, thông báo, quy tắc hoạt
            động, chính sách và chỉ dẫn vào bất kỳ thời điểm nào bằng cách thông
            báo trước cho Người dùng và Người dùng được xem là đã biết và chịu
            sự ràng buộc với những thay đổi, điều chỉnh sau khi những thông báo,
            điều chỉnh này được công bố trên nền tảng phù hợp với chính sách của
            PYOYO và quy định pháp luật áp dụng.
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>2. Nhà cung cấp </b>
        <ContentItem>
          <p>
            Nhà cung cấp cung cấp các hàng hóa/dịch vụ trên nền tảng của PYOYO.
            PYOYO có thể là “Nhà cung cấp” đối với một số hàng hóa/dịch vụ nhất
            định. Thông tin về một hàng hóa/dịch vụ cụ thể được Nhà cung cấp bán
            trên nền tảng được nêu rõ trong trang mô tả sản phẩm trên nền tảng.
            Các hàng hóa/dịch vụ do Nhà cung cấp bán cho Khách hàng sẽ chịu sự
            điều chỉnh của hợp đồng với Khách hàng tương ứng:{' '}
          </p>
          <p>
            (a) đối với sản phẩm do Nhà cung cấp (không phải PYOYO), sẽ được
            thỏa thuận trực tiếp giữa Nhà cung cấp hàng đó và Khách hàng trên
            ứng dụng PYOYO; và
          </p>
          <p>
            (b) đối với sản phẩm do PYOYO bán, sẽ được thỏa thuận trực tiếp giữa
            PYOYO và Khách hàng.
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>3. Giá sản phẩm</b>
        <ContentItem>
          <p>
            3.1 Giá của hàng hóa/dịch vụ đã bao gồm các loại thuế theo quy định
            của pháp luật. Trong mọi trường hợp, giá của hàng hóa/dịch vụ không
            bao gồm phí giao hàng hóa/dịch vụ, phí giao dịch qua các phương thức
            thanh toán và bất kỳ chi phí nào khác. Chi phí giao hàng hóa/dịch
            vụ, phí giao dịch và các chi phí phát sinh (nếu có) sẽ được quy định
            cụ thể cho từng Nhà cung cấp và/hoặc hàng hóa/dịch vụ và được áp
            dụng riêng cho từng phương thức thanh toán. Trừ khi được quy định
            khác đi cho từng Nhà cung cấp và/hoặc hàng hóa/dịch vụ, Khách hàng
            có trách nhiệm tự thanh toán các chi phí này.
          </p>
          <p>
            3.2 Giá của các sản phẩm hiển thị trên ứng dụng được xác định và
            chịu trách nhiệm duy nhất bởi Nhà cung cấp và chỉ được liệt kê để
            Người dùng biết thông tin. Người dùng đồng ý và hiểu rằng giá của
            các hàng hóa/dịch vụ được hiển thị trên ứng dụng Người dùng chỉ là
            ước tính và có thể thay đổi vào từng thời điểm. Người dùng đồng ý
            thanh toán giá các hàng hóa/dịch vụ như được thể hiện trong biên
            nhận hoặc hóa đơn mua hàng do Nhà cung cấp phát hành. Nếu có sự khác
            biệt đáng kể với giá các sản phẩm được liệt kê trong ứng dụng Người
            dùng, vui lòng cho chúng tôi biết bằng cách gửi khiếu nại qua ứng
            dụng Người dùng.
          </p>
          <p>
            3.3 Vì xăng dầu là mặt hàng đặc biệt, giá bán xăng dầu có thể tăng
            giảm theo từng thời điểm. Bởi vậy, giá sản phẩm và số lượng sản phẩm
            Khách hàng nhận được sẽ được tính và quy đổi tại thời điểm Khách
            hàng <b>NHẬN</b> sản phẩm tại Cửa hàng chứ không tính theo giá, số
            lượng tại thời điểm đặt mua.
          </p>
          <p>
            3.4 Giá của các hàng hóa/dịch vụ trên ứng dụng có thể bị hiển thị
            không chính xác vì các lý do như sự cố kỹ thuật, lỗi đánh máy hoặc
            thông tin sản phẩm lỗi thời được cung cấp bởi Nhà cung cấp, trong
            trường hợp đó, Nhà cung cấp hàng có thể hủy (các) đơn đặt hàng của
            Người dùng. Nhà cung cấp sẽ chịu trách nhiệm duy nhất về bất kỳ sự
            bảo đảm/cam đoan nào đối với các sản phẩm đã bán cho Người dùng và
            không có trường hợp nào là trách nhiệm của PYOYO. Đối với sản phẩm
            của PYOYO cung cấp, PYOYO chịu trách nhiệm về sự đảm bảo hàng hóa và
            dịch vụ cho khách hàng.
          </p>
          <p>
            3.5 Trừ khi Nhà cung cấp hàng có hỗ trợ khác, Khách hàng có nhu cầu
            xuất hóa đơn GTGT cho giá hàng hóa/dịch vụ cần điền thông tin yêu
            cầu xuất hóa đơn trên ứng dụng tại thời điểm xác nhận Đơn Hàng để
            được nhận hóa đơn GTGT hợp lệ từ Nhà cung cấp hàng. Khách hàng hiểu
            rằng PYOYO không chịu bất cứ trách nhiệm nào trong việc Nhà cung cấp
            hàng từ chối xuất hóa đơn phát sinh từ việc Khách hàng không có yêu
            cầu hợp lệ theo quy định trên đây. Giá trị trên hóa đơn GTGT tương
            ứng với số tiền cuối cùng mà Khách hàng phải trả nhưng không bao
            gồm: giá trị điểm thưởng, quà tặng, mã khuyến mại…{' '}
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>4. Đặt hàng và xác Nhận</b>
        <ContentItem>
          <p>
            4.1 Người dùng có thể thực hiện Đặt đơn hàng bằng cách điền và gửi
            thông tin được yêu cầu trên ứng dụng. Nhà cung cấp hàng sẽ không
            chấp nhận các Đơn đặt hàng được thực hiện theo bất kỳ cách nào khác.
            Người dùng phải chịu trách nhiệm đảm bảo tính chính xác của thông
            tin trong Đơn đặt hàng.
          </p>
          <p>
            4.2 Người dùng không thể thay đổi các thông tin đơn hàng và/hoặc địa
            chỉ gửi hóa đơn thanh toán mà Người dùng đã cung cấp trong quá trình
            đặt hàng sau khi PYOYO và/hoặc Nhà cung cấp hàng nhận được Đơn đặt
            hàng. Để thay đổi thông tin đó, Người dùng cần phải hủy đơn đặt hàng
            hiện tại và đặt một Đơn đặt hàng mới với địa chỉ cập nhật chính xác.
          </p>
          <p>
            4.3 Tất cả các đơn hàng đặt trước thực hiện trên ứng dụng (“Đơn
            Hàng”) với Nhà cung cấp được xem là đã được xác nhận. Nhà cung cấp
            có toàn quyền chấp nhận hoặc từ chối, hủy đơn hàng của Người dùng.{' '}
          </p>
          <p>
            4.4 Người dùng đồng ý rằng: Đối với dịch vụ mua hàng tại Cửa hàng
            xăng dầu, kể từ thời điểm Người dùng đặt đơn hàng, sau hai mươi tư
            (24) giờ nếu Người dùng không đến cửa hàng để thực hiện giao dịch,
            PYOYO sẽ tự động hủy đơn đặt hàng đó và tiền (nếu thanh toán trả
            trước) sẽ được hoàn trả cho Người dùng trong thời gian theo quy định
            của PYOYO.
          </p>
          <p>
            4.5 Trong ứng dụng PYOYO sẽ hiển thị và chỉ dẫn đường đi đến cả các
            cửa hàng đã thuộc hệ thống và những cửa hàng có trong khu vực đó
            nhưng chưa tham gia hệ thống PYOYO. Người dùng đồng ý rằng PYOYO sẽ
            không chịu trách nhiệm về đảm bảo chất lượng, sự sẵn có của các sản
            phẩm thuộc Cửa hàng xăng dầu chưa tham gia hệ thống bởi PYOYO không
            đánh giá, kiểm chứng các cửa hàng đó.{' '}
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>5. Thanh toán </b>
        <ContentItem>
          {' '}
          <p>
            5.1 Khi Người dùng đã hoàn thành việc sử dụng dịch vụ, Người dùng
            được yêu cầu thanh toán đầy đủ cho PYOYO hoặc Cửa hàng bằng cách
            chọn một trong các phương thức thanh toán có sẵn trên ứng dụng.
          </p>
          <p>
            5.2 Với thanh toán bằng tiền mặt, Người dùng sẽ thanh toán tất cả
            các khoản phí Người dùng phải trả cho PYOYO và bất kỳ khoản tiền nào
            khác phải trả cho Nhà cung cấp.
          </p>
          <p>
            5.3 Người dùng chỉ có thể thay đổi kênh thanh toán trước khi đơn
            hàng được xác nhận bởi PYOYO hoặc nhà cung cấp.
          </p>
          <p>5.4 Tất cả các khoản thanh toán phải bằng Việt Nam Đồng (Đồng).</p>
          <p>
            5.5 Không ảnh hưởng đến các quyền và biện pháp khắc phục khác, PYOYO
            có thể tạm dừng xử lý bất kỳ giao dịch nào khi tin rằng giao dịch đó
            có thể là gian lận, bất hợp pháp hoặc có thể liên quan đến bất kỳ
            hoạt động tội phạm nào hoặc nơi mà được tin rằng Người dùng vi phạm
            các Điều khoản sử dụng này, chính sách, hoặc Luật áp dụng. Trong
            trường hợp như vậy, Người dùng sẽ không buộc PYOYO chịu trách nhiệm
            cho bất kỳ sự từ chối, trì hoãn, đình chỉ hoặc hủy bỏ bất kỳ khoản
            thanh toán hoặc bất kỳ chương trình khuyến mại nào cho Người dùng.
          </p>
          <p>
            5.6 Nếu Người dùng bị tính phí không chính xác, Người dùng có thể
            liên hệ với chúng tôi qua ứng dụng Người dùng hoặc qua email tới
            sale@yoyojsc.com.vn để được hỗ trợ. PYOYO có toàn quyền quyết định
            trong việc xem xét có nên xử lý bất kỳ khoản hoàn trả hoặc trả lại
            nào cho Người dùng hay không và phương thức hoàn trả hoặc trả lại
            khoản thanh toán đó.
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>6. Phí</b>
        <ContentItem>
          <p>
            6.1 Nếu Điều khoản dịch vụ này không có quy định nào khác hoặc các
            bên không có thỏa thuận nào khác, Khách hàng không phải thanh toán
            bất kỳ khoản phí nào khi sử dụng sàn giao dịch thương mại điện tử
            PYOYO. Nếu PYOYO có cơ chế thu phí với người dùng sẽ thông báo tới
            người dùng thông qua ứng dụng và website chính thức của PYOYO.
          </p>
          <p>
            6.2 Nếu có bất kỳ khoản phí nào phải trả, khoản phí đó cũng là đối
            tượng chịu thuế theo quy định pháp luật có liên quan. Nhà cung cấp
            thừa nhận và đồng ý rằng PYOYO có thể khấu trừ các khoản phí phải
            trả cho PYOYO và các khoản thuế áp dụng từ số tiền bán hàng được
            thanh toán bởi Người mua. PYOYO sẽ xuất biên lai hoặc hóa đơn tài
            chính cho khoản phí và khoản thuế do Nhà cung cấp chi trả nếu có yêu
            cầu.
          </p>
          <p>6.3 Phí Thanh Toán</p>
          <p>
            - Phí thanh toán là phí áp dụng cho mỗi đơn hàng thành công trên sàn
            giao dịch TMĐT PYOYO (không áp dụng cho đơn hàng bị hủy hoặc bị yêu
            cầu trả hàng/hoàn tiền). Trong mọi trường hợp, Nhà cung cấp chịu
            trách nhiệm chi trả phí thanh toán.{' '}
          </p>
          <p>
            - Phí thanh toán được cấn trừ trực tiếp trên từng đơn hàng trước khi
            tiền bán hàng được ghi nhận vào tài khoản của Nhà cung cấp.
          </p>
          <p>
            Phí thanh toán được tính trên tổng giá trị thanh toán của Khách hàng
            cho đơn hàng, bao gồm tổng tiền hàng và phí vận chuyển (nếu có) mà
            Khách hàng thực trả.
          </p>
          <p>
            - Tùy vào phương thức thanh toán mà Khách hàng đã chọn, mức phí
            thanh toán được áp dụng cho mỗi đơn hàng sẽ khác nhau.
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>7. Chương trình khuyến mại</b>
        <ContentItem>
          <p>
            7.1 Tùy vào từng thời điểm, theo toàn quyền quyết định của mình,
            PYOYO, Nhà cung cấp có thể đưa ra các chiến dịch tiếp thị và khuyến
            mại trên ứng dụng bao gồm các mã giảm giá, gói hội viên, voucher
            hoặc các hình thức khác (gọi chung là “mã giảm giá”). Tùy từng loại
            mã giảm giá sẽ có thời hạn áp dụng, điều kiện sử dụng, số lượt sử
            dụng khác nhau. Mã giảm giá có thể sẽ không được áp dụng cùng với
            các chương trình khuyến mại khác, trừ trường hợp được sự đồng thuận
            từ PYOYO. Người dùng đồng ý rằng Người dùng sẽ chỉ sử dụng các ưu
            đãi và khuyến mại đó cho mục đích sử dụng dự kiến của chương trình
            và sẽ không lạm dụng, sao chép, bán hoặc chuyển giao chương trình ưu
            đãi và khuyến mại dưới bất kỳ hình thức nào. Người dùng cũng hiểu
            rằng các chương trình ưu đãi và khuyến mại không thể được đổi thành
            tiền mặt và có thể hết hạn vào ngày nhất định, ngay cả trước khi
            Người dùng sử dụng các chương trình đó.
          </p>
          <p>
            7.2 Người dùng hiểu rằng chính sách này áp dụng khác nhau cho từng
            Nhà cung cấp hàng và không phải hàng hóa/dịch vụ nào cung ứng trên
            ứng dụng PYOYO đều được áp dụng mã giảm giá, thưởng, tích và sử dụng
            điểm thưởng để thanh toán.
          </p>
          <p>
            7.3 PYOYO có quyền thu hồi một phần hoặc toàn bộ các điểm đã tích,
            khuyến mại, ưu đãi tích cho Khách hàng trong các trường hợp hủy đơn
            hàng, đổi, trả hàng hóa, không phân biệt việc đổi, trả hàng hóa xuất
            phát từ lỗi và/hoặc nhu cầu của Khách hàng, Nhà cung cấp hàng hoặc
            bất kỳ bên thứ ba nào khác.
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>8. Hủy đơn hàng, hoàn tiền</b>
        <ContentItem>
          <p>
            8.1 Nếu các đơn hàng đã được đặt, và hình thức thanh toán là chuyển
            khoản, tiền mặt, QR code, ATM thì đơn hàng cần được chờ xác nhận của
            PYOYO. Đơn hàng chưa được xác nhận thì khách hàng hoàn toàn có thể
            hủy đơn bình thường. Đơn hàng đã được xác nhận, trường hợp khách
            hàng muốn hủy, hãy liên hệ hotline của PYOYO để được hỗ trợ hủy đơn
            hàng. Khách hàng có thể hủy đơn hàng bằng cách vào mục “Lịch sử Giao
            dịch” và chọn “Hủy Giao dịch” hoặc sử dụng chức năng chat hoặc gọi
            trực tiếp đến tổng đài của YOYO. Trong trường hợp cần thiết, nhân
            viên tổng đài YOYO sẽ gọi để xác nhận với Người dùng. Trong trường
            hợp Khách hàng hủy nhiều đơn hàng gây ảnh hưởng đến hệ thống, YOYO
            có thể chặn Khách hàng sử dụng mã khuyến mại hoặc tạm khoá dịch vụ
            của Khách hàng trong một thời gian
          </p>
          <p>
            Tại đây, nếu đơn hàng đã được thanh toán thì với đơn hàng đã được hỗ
            trợ hủy, PYOYO sẽ hoàn tiền lại cho khách hàng qua ví điểm trên ứng
            dụng PYOYO trong vòng 3-5 ngày hành chính. Điểm hoàn sẽ được sử dụng
            cho lần mua hàng tiếp theo của khách hàng, với mức quy đổi là 1000 đ
            = 1 điểm.
          </p>
          <p>
            8.2 Khách hàng có thể được hoàn tiền cho các đơn hàng hủy, bị hủy,
            do lỗi của Nhà cung cấp, do lỗi của bên thực hiện dịch vụ giao nhận
            hoặc các trường hợp khác không phải lỗi của Khách hàng. Giá trị tiền
            được hoàn sẽ được xử lý theo thời gian quyết định của PYOYO.
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>9. Chăm sóc Khách hàng và xử lý khiếu nại</b>
        <ContentItem>
          {' '}
          <p>
            9.1 PYOYO chỉ hỗ trợ xử lý các yêu cầu, khiếu nại liên quan đến xử
            lý kỹ thuật khi đặt và thanh toán tiền hàng hóa/dịch vụ trên ứng
            dụng. Khách hàng có thể liên hệ với hotline và chăm sóc Khách hàng
            của PYOYO để được hỗ trợ.
          </p>
          <p>
            9.2 Mọi thắc mắc, khiếu nại khác liên quan đến hàng hóa/dịch vụ,
            Khách hàng trực tiếp liên hệ với Nhà cung cấp hàng theo thông tin
            được đăng tải của từng Nhà cung cấp hàng. Trường hợp Khách hàng đã
            liên hệ với PYOYO, PYOYO sẽ hỗ trợ để chuyển thắc mắc, khiếu nại của
            Khách hàng cho Nhà cung cấp hàng. Nhà cung cấp hàng và Khách hàng
            đồng ý đầu tiên sẽ thỏa thuận với nhau để giải quyết tranh chấp đó
            bằng thảo luận hai bên. Nếu vấn đề không được giải quyết bằng thảo
            luận hai bên, Khách hàng có thể khiếu nại lên cơ quan có thẩm quyền
            của địa phương để giải quyết tranh chấp phát sinh đối với giao dịch.
          </p>
          <p>
            9.3 Khiếu nại xảy ra khi có tranh chấp giữa Khách hàng và Nhà cung
            cấp, khi đó bên PYOYO đóng vai trò trung gian điều phối giải quyết
            khiếu nại:
          </p>
          <p>
            a) PYOYO có quyền đóng băng giao dịch thanh toán hoặc tạm ngưng giao
            dịch trong trường hợp Khách hàng khiếu nại cho đến khi giải quyết
            xong khiếu nại.
          </p>
          <p>
            b) Nhà cung cấp có nghĩa vụ cung cấp tất cả các chứng từ, bằng chứng
            để phục vụ việc giải quyết khiếu nại và hợp tác, phối hợp theo đúng
            quy trình xử lý khiếu nại.
          </p>
          <p>
            c) Nếu lỗi xảy ra được xác định do sai sót của bên Nhà cung cấp, Nhà
            cung cấp có nghĩa vụ phải đổi, trả hoặc bổ sung theo yêu cầu của
            Khách hàng. Mọi chi phí phát sinh của quá trình trên phải do Nhà
            cung cấp chịu trách nhiệm thanh toán.
          </p>
          <p>
            d) Mọi quy trình của PYOYO được thực hiện trên cơ sở PYOYO đóng vai
            trò trung gian hòa giải giữa Nhà cung cấp hàng và Khách hàng. Nhà
            cung cấp có nghĩa vụ thực hiện phán quyết do PYOYO đưa ra nếu sự hòa
            giải thất bại. Trong trường hợp Nhà cung cấp không đồng ý thực thi
            phán quyết của PYOYO, PYOYO có quyền gỡ bỏ gian hàng của Nhà cung
            cấp tạm thời đến vĩnh viễn tùy mức độ và mật độ vi phạm.
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>10. Thuế</b>
        <ContentItem>
          <p>
            10.1 Điều khoản sử dụng này phải tuân theo mọi khoản thuế, thuế
            quan, phí, lệ phí và/hoặc chi phí áp dụng, bất kể bằng loại tiền tệ
            nào, có thể có hiệu lực và liên quan đến mọi khoản thuế áp dụng
            trong tương lai có thể được ban hành bất kỳ thời điểm nào.
          </p>
          <p>
            10.2 Người dùng đồng ý thực hiện mọi công việc, hành động cần thiết
            và theo yêu cầu của Luật áp dụng để cho phép, hỗ trợ và/hoặc giúp
            PYOYO khiếu nại hoặc xác định mọi khoản chi phí đầu vào áp dụng,
            khoản bù trừ, chiết khấu hoặc hoàn lại đối với bất kỳ khoản thuế nào
            đã nộp hoặc phải nộp liên quan đến Hệ Thống và/hoặc Dịch Vụ.
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>11. Điều khoản bổ sung</b>
        <ContentItem>
          <p>
            11.1 Ứng dụng cho phép Người dùng đặt mua xăng dầu và các sản phẩm
            dịch vụ từ Cửa hàng trên ứng dụng PYOYO. PYOYO không sở hữu, bán
            hoặc bán lại bất kỳ mặt hàng và không kiểm soát các Cửa Hàng trên
            ứng dụng PYOYO hoặc bất kỳ dịch vụ nào do Nhà cung cấp cung cấp.
            Người dùng hiểu rằng bất kỳ đơn hàng nào Người dùng đặt sẽ tuỳ thuộc
            vào tính sẵn có của các sản phẩm và địa điểm phục vụ của Cửa hàng và
            PYOYO không đảm bảo rằng Cửa hàng đang sẵn sàng phục vụ.
          </p>
          <p>
            11.2 Người dùng chỉ có thể đặt mua các sản phẩm từ danh sách có sẵn
            của từng Cửa Hàng trên ứng dụng Người dùng. Cả PYOYO và Cửa Hàng đều
            không đảm bảo các sản phẩm cụ thể có sẵn. Danh sách sản phẩm có thể
            thay đổi kèm theo hoặc không kèm theo thông báo.
          </p>
          <p>
            11.3 Người dùng chỉ được mua các sản phẩm cho mục đích tiêu dùng cá
            nhân của riêng Người dùng hoặc theo yêu cầu của người mà Người dùng
            chỉ định là người nhận các sản phẩm đó và Người dùng không được đặt
            mua với mục đích gây hại hoặc tạo phiền phức cho Cửa Hàng hoặc người
            nhận đơn hàng theo chỉ định của Người dùng.
          </p>
          <p>
            11.4 Không ảnh hưởng đến các điều khoản khác trong Điều khoản sử
            dụng, PYOYO không chịu trách nhiệm về:
          </p>
          <p>+ Tình trạng các sản phẩm</p>
          <p>+ Chất lượng các sản phẩm; hoặc</p>
          <p>
            + Sự chênh lệch giá giữa giá hiển thị ứng dụng Người dùng và giá
            được Cửa Hàng yêu cầu thanh toán.
          </p>
          <p>
            11.5 Sau khi Người dùng khai báo phương tiện, PYOYO sẽ hỗ trợ tư vấn
            và đưa ra gợi ý sản phẩm xăng dầu phù hợp với phương tiện của Người
            dùng. Người dùng đồng ý rằng gợi ý PYOYO đưa ra chỉ mang tính chất
            tham khảo, mọi sự quyết định mua sản phẩm phụ thuộc vào Người dùng
            và PYOYO sẽ không chịu trách nhiệm về quyết định đó.
          </p>
          <p>
            11.6 Người dùng có thể đặt mua sản phẩm cho người thân, bạn bè bằng
            cách khai báo phương tiện nhận dịch vụ/Hàng hóa là phương tiện của
            người thân, bạn bè và chia sẽ mã QR code sau khi xác nhận đặt đơn
            tới họ. Người dùng cam kết không tiết lộ mã QR code và chỉ chia sẻ
            mã QR code tới người được chỉ định, được khai báo thông tin trong
            đơn hàng. PYOYO cam kết không tiết lộ mã QR code đơn hàng của Người
            dùng và không chịu trách nhiệm trong trường hợp Người dùng làm tiết
            lộ mã ra ngoài làm ảnh hướng đến quá trình giao nhận sản phẩm. Nếu
            như đơn hàng có sự điều chỉnh về giá trị, PYOYO vẫn sẽ gửi thông báo
            về tài khoản người đặt đơn. Và nếu đơn hàng có sự khiếu nại thì
            PYOYO sẽ tiến hành làm việc với người đặt đơn hàng chứ không phải là
            người nhận hàng.
          </p>
          <p>
            11.7 Sau khi đơn hàng hoàn tất sẽ có thông báo được gửi đến ứng dụng
            Người dùng, Người dùng có trách nhiệm xem và gửi xác nhận tới PYOYO.
            Nếu Người dùng xác nhận đơn hàng, đồng nghĩa Người dùng đồng ý với
            việc đã nhận đầy đủ hàng hóa/dịch vụ từ Nhà cung cấp. Nếu Người dùng
            không xác nhận tại thời điểm nhận hàng, hệ thống sẽ tự động xác nhận
            sau bốn mươi tám (48) giờ kể từ khi thông báo được gửi và tương
            đương với việc Người dùng đã nhận đầy đủ hàng hóa/dịch vụ từ Nhà
            cung cấp. Nếu sau khi hệ thống tự động xác nhận, Người dùng có phản
            hồi, khiếu nại về đơn hàng thì PYOYO sẽ có toàn quyền xử lý khiếu
            nại đó tùy theo quy định của PYOYO.
          </p>
        </ContentItem>
      </SmallItem>
    </div>
  )
}
export default TermsOfUse
