import Icon from '@ant-design/icons'

const InsurranceSvg = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.4972 42.2068V42.485C23.1276 43.7206 21.9808 44.6241 20.6265 44.6241C18.9743 44.6241 17.6303 43.2801 17.6303 41.6279C17.6303 40.9718 17.0984 40.4399 16.4423 40.4399C15.7862 40.4399 15.2544 40.9718 15.2544 41.6279C15.2544 44.59 17.6644 47 20.6265 47C22.7256 47 24.5461 45.7893 25.4296 44.03V43.72L23.4972 42.2068Z"
      fill="#707487"
    />
    <path
      d="M17.0384 41.6279C17.0384 41.3276 17.1506 41.0543 17.3343 40.8451C17.1166 40.5973 16.7982 40.4399 16.4423 40.4399C15.7862 40.4399 15.2544 40.9718 15.2544 41.6279C15.2544 44.59 17.6644 47 20.6265 47C20.931 47 21.2294 46.9737 21.5201 46.9249C18.9803 46.4992 17.0384 44.2866 17.0384 41.6279Z"
      fill="#676A77"
    />
    <path
      d="M24.8033 27.6444C24.4338 27.6444 24.1343 27.3449 24.1343 26.9754V18.9471C24.1343 18.5776 24.4338 18.2781 24.8033 18.2781C25.1728 18.2781 25.4723 18.5776 25.4723 18.9471V26.9754C25.4723 27.345 25.1728 27.6444 24.8033 27.6444Z"
      fill="#E4EAF8"
    />
    <path
      d="M35.7426 26.2131C33.503 26.2108 31.6256 27.422 30.6292 29.1839L30.6289 29.1836C30.6289 29.1836 30.3034 29.5868 29.8699 29.5888C29.4363 29.5867 29.1108 29.1836 29.1108 29.1836L29.1106 29.1839C28.1228 27.437 26.2529 26.2108 23.9972 26.2131C20.655 26.2167 18.1248 28.9228 18.1363 32.0859C18.1439 38.5641 23.6955 43.6742 29.4688 46.4578H29.4689C29.614 46.5057 29.7517 46.5198 29.87 46.5184C29.9882 46.5199 30.1258 46.5057 30.271 46.4578C31.8978 45.6736 38.2234 42.3265 40.6492 36.655C43.2187 30.6485 40.1577 26.2178 35.7426 26.2131Z"
      fill="#F26522"
    />
    <path
      d="M19.921 32.1735C19.8933 29.0817 22.1165 26.7077 24.8651 26.2738L24.9163 26.273C24.757 26.248 24.2779 26.2128 23.9971 26.2131C20.6832 26.2167 18.1362 28.8814 18.1362 32.0859C18.1458 38.5612 23.6834 43.6684 29.4688 46.4578C29.6139 46.5057 29.7515 46.5198 29.8697 46.5184C29.9879 46.5198 30.1256 46.5057 30.2708 46.4578C30.4042 46.3935 30.5698 46.3116 30.7617 46.2129C25.2326 43.3697 19.9709 38.4347 19.921 32.1735Z"
      fill="#ED3A3A"
    />
    <path
      d="M24.8033 5.6262C24.4338 5.6262 24.1343 5.32667 24.1343 4.95717V2.66902C24.1343 2.29953 24.4338 2 24.8033 2C25.1728 2 25.4723 2.29953 25.4723 2.66902V4.95717C25.4723 5.32667 25.1728 5.6262 24.8033 5.6262Z"
      fill="#707487"
    />
    <path
      d="M28.1672 40.3362C28.1663 40.3362 28.1654 40.3362 28.1644 40.3362C27.8836 40.3349 27.6334 40.1586 27.538 39.8944L25.3437 33.819L24.6068 35.3639C24.4958 35.5967 24.2608 35.7449 24.003 35.7449H16.6353C16.2658 35.7449 15.9663 35.4453 15.9663 35.0758C15.9663 34.7063 16.2658 34.4068 16.6353 34.4068H23.5807L24.8253 31.798C24.9415 31.5545 25.1922 31.4038 25.462 31.4179C25.7315 31.4312 25.9666 31.605 26.0583 31.8589L28.1754 37.7206L29.2423 34.8433C29.3396 34.5809 29.5898 34.4069 29.8696 34.4069H33.2923C33.5384 34.4069 33.7646 34.5421 33.8814 34.7587L34.7597 36.3902L35.55 34.781C35.6625 34.5519 35.8954 34.4069 36.1504 34.4069H43.3825C43.752 34.4069 44.0515 34.7064 44.0515 35.0759C44.0515 35.4454 43.752 35.7449 43.3825 35.7449H36.5673L35.3876 38.147C35.2771 38.372 35.0502 38.5163 34.7996 38.521C34.5491 38.5262 34.3169 38.3899 34.1981 38.1692L32.8928 35.7449H30.335L28.7946 39.8999C28.6972 40.1622 28.4469 40.3362 28.1672 40.3362Z"
      fill="#E4EAF8"
    />
    <path
      d="M7.69809 17.6167C9.98756 17.6167 12.0098 18.7556 13.2328 20.4968C13.314 20.6124 13.4855 20.6124 13.5666 20.4968C14.7898 18.7556 16.812 17.6167 19.1015 17.6167C21.391 17.6167 23.4132 18.7556 24.6363 20.4968C24.7175 20.6124 24.889 20.6124 24.9701 20.4968C26.1932 18.7556 28.2155 17.6167 30.505 17.6167C32.7944 17.6167 34.8167 18.7556 36.0398 20.4968C36.121 20.6124 36.2925 20.6124 36.3736 20.4968C37.5967 18.7556 39.619 17.6167 41.9084 17.6167C43.1416 17.6167 44.2971 17.9478 45.2921 18.5248C45.4678 18.6267 45.6717 18.4457 45.5866 18.2613C41.9635 10.4077 34.0201 4.95703 24.8033 4.95703C15.5866 4.95703 7.64316 10.4078 4.01995 18.2613C3.93487 18.4457 4.13869 18.6266 4.31438 18.5248C5.30948 17.9477 6.46498 17.6167 7.69809 17.6167Z"
      fill="#F25B06"
    />
    <path
      d="M21.2192 5.2373C13.5606 6.44255 7.1638 11.4469 4.01995 18.2615C3.93487 18.4459 4.13869 18.6268 4.31438 18.525C4.79374 18.2471 5.31054 18.0269 5.85511 17.8726C8.78627 11.466 14.4325 6.70069 21.2192 5.2373Z"
      fill="#D85003"
    />
    <path
      d="M24.8037 4.95703V20.5835C24.8668 20.5835 24.93 20.5546 24.9705 20.4968C26.1936 18.7556 28.2159 17.6167 30.5054 17.6167C32.7948 17.6167 34.8171 18.7556 36.0402 20.4968C36.1214 20.6124 36.2929 20.6124 36.374 20.4968C37.5971 18.7556 39.6194 17.6167 41.9088 17.6167C43.142 17.6167 44.2974 17.9478 45.2925 18.5248C45.4682 18.6267 45.6721 18.4457 45.587 18.2613C41.9638 10.4078 34.0204 4.95703 24.8037 4.95703Z"
      fill="#FCA829"
    />
    <path
      d="M13.4561 20.5748C13.4989 20.5627 13.5385 20.5373 13.5669 20.4968C14.8346 18.6939 16.9026 17.6167 19.1017 17.6167C19.1018 17.6167 19.102 17.6167 19.102 17.6167C21.3577 17.6167 23.4116 18.7478 24.6366 20.4968C24.7178 20.6123 24.8892 20.6123 24.9704 20.4968C26.2105 18.7341 28.2561 17.6167 30.5052 17.6167C30.5053 17.6167 30.5055 17.6167 30.5056 17.6167C32.3709 17.6167 34.1697 18.3932 35.4546 19.7779C36.0369 20.4045 36.0007 20.5323 36.151 20.5748C33.9582 11.2696 29.6991 4.95703 24.8035 4.95703C19.9079 4.95703 15.6488 11.2696 13.4561 20.5748Z"
      fill="#F25B06"
    />
    <path
      d="M24.8035 4.95703C19.9079 4.95703 15.6488 11.2696 13.4561 20.5748C13.4989 20.5627 13.5385 20.5373 13.5669 20.4968C14.8346 18.6939 16.9026 17.6167 19.1017 17.6167C19.1018 17.6167 19.102 17.6167 19.102 17.6167C21.3577 17.6167 23.4116 18.7478 24.6366 20.4968C24.6772 20.5546 24.7404 20.5835 24.8035 20.5835V4.95703Z"
      fill="#FCA829"
    />
    <path
      d="M15.758 18.5044C17.6897 10.3458 21.0192 4.95703 24.8035 4.95703C19.9079 4.95703 15.6488 11.2696 13.4561 20.5748C13.4988 20.5627 13.5386 20.5373 13.5669 20.4968C14.1633 19.6481 14.9074 18.996 15.758 18.5044Z"
      fill="#FF5C00"
    />
  </svg>
)

const InsurranceIcon = (props: any) => (
  <Icon component={InsurranceSvg} {...props} />
)
export default InsurranceIcon
