import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { ContentNews, ContentNewsWrap, TitlePost } from '../styled'

const SkeletonDetail: React.FC = () => {
  return (
    <ContentNewsWrap xs={24} sm={16} xl={18}>
      <TitlePost size={28}>
        <Skeleton style={{ backgroundColor: '#E6E6E6', width: '90%' }} />
      </TitlePost>
      <div style={{ color: '#8c8c8c', marginTop: '12px' }}>
        <Skeleton style={{ backgroundColor: '#E6E6E6', width: '30%' }} />
      </div>
      <ContentNews>
        <Skeleton
          style={{
            backgroundColor: '#E6E6E6',
            height: '20px',
            margin: '10px 0',
          }}
          count={10}
        />
      </ContentNews>
    </ContentNewsWrap>
  )
}

export default SkeletonDetail
