import io, { Socket } from 'socket.io-client'

export class WebSocketOnline {
  public static socketClient: Socket
  private static currentToken: string | undefined

  public static async init() {
    this.socketClient = io(process.env.REACT_APP_WS_URL as string, {})
  }
  public static async disconnect() {
    if (this.socketClient) {
      this.socketClient.disconnect()
    }
  }
}
