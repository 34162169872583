import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { ImageProduct, NameProduct, PriceProduct, ProductBlock } from './styled'

const SkeletonProduct = () => {
  return (
    <ProductBlock>
      <ImageProduct style={{ display: 'block', width: '90%', margin: 'auto' }}>
        <Skeleton style={{ backgroundColor: '#E6E6E6', height: '100%' }} />
      </ImageProduct>
      <NameProduct>
        <Skeleton style={{ backgroundColor: '#E6E6E6', height: '100%' }} />
      </NameProduct>
      <PriceProduct>
        <Skeleton style={{ backgroundColor: '#E6E6E6', width: '50%' }} />
      </PriceProduct>
    </ProductBlock>
  )
}

export default SkeletonProduct
