const images = {
  AppStoreBadge: require('./images/AppStoreBadge.png').default,
  GooglePlayBadge: require('./images/GooglePlayBadge.png').default,
  ImageProduct: require('./images/ImageProduct.png').default,
  Location: require('./images/Location.png').default,
  Message: require('./images/Message.png').default,
  Mobile: require('./images/Mobile.png').default,
  SampleBanner: require('./images/SampleBanner.png').default,
  app_icon: require('./images/app_icon.png').default,
  avatar_default: require('./images/avatar_default.png').default,
  buy_now_1: require('./images/buy_now_1.jpg').default,
  buy_now_2: require('./images/buy_now_2.jpg').default,
  cart: require('./images/cart.jpg').default,
  completed_signup: require('./images/completed_signup.png').default,
  dribbble_1: require('./images/dribbble_1.gif').default,
  footer1: require('./images/footer1.png').default,
  footer2: require('./images/footer2.png').default,
  footer3: require('./images/footer3.png').default,
  footer4: require('./images/footer4.png').default,
  forgetPassword: require('./images/forgetPassword.jpg').default,
  icon_avatar_default: require('./images/icon_avatar_default.png').default,
  icon_banking: require('./images/icon_banking.png').default,
  icon_cancel: require('./images/icon_cancel.png').default,
  icon_complete: require('./images/icon_complete.png').default,
  icon_confirm: require('./images/icon_confirm.png').default,
  icon_gas: require('./images/icon_gas.png').default,
  icon_notify_new: require('./images/icon_notify_new.png').default,
  icon_notify_order: require('./images/icon_notify_order.png').default,
  icon_notify_point: require('./images/icon_notify_point.png').default,
  icon_notify_rank: require('./images/icon_notify_rank.png').default,
  icon_payment: require('./images/icon_payment.png').default,
  icon_pending: require('./images/icon_pending.png').default,
  icon_store_gas: require('./images/icon_store_gas.png').default,
  icon_vnpay: require('./images/icon_vnpay.png').default,
  image_complete: require('./images/image_complete.png').default,
  image_login: require('./images/image_login.png').default,
  img_banner_auth: require('./images/img_banner_auth.png').default,
  img_logo: require('./images/img_logo.png').default,
  img_petrol: require('./images/img_petrol.png').default,
  index: require('./images/index.ts').default,
  introduction_img: require('./images/introduction_img.jpg').default,
  logo_vnpay: require('./images/logo_vnpay.png').default,
  logo_yoyo: require('./images/logo_yoyo.png').default,
  news1: require('./images/news1.png').default,
  news2: require('./images/news2.png').default,
  payment: require('./images/payment.jpg').default,
  signup1: require('./images/signup1.jpg').default,
  signup2: require('./images/signup2.jpg').default,
  signup3: require('./images/signup3.jpg').default,
  symbol_gas: require('./images/symbol_gas.png').default,
  default_fill: require('./images/default_fill.jpeg').default,
  viettin: require('./images/viettin.png').default,
  bocongthuong: require('./images/bocongthuong.jpeg').default,
}
export default images
