import Icon from '@ant-design/icons'

const StarSvg = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9317 9.93337C13.7159 10.1425 13.6167 10.445 13.6659 10.7417L14.4067 14.8417C14.4692 15.1892 14.3226 15.5409 14.0317 15.7417C13.7467 15.95 13.3676 15.975 13.0567 15.8084L9.36591 13.8834C9.23758 13.815 9.09508 13.7784 8.94925 13.7742H8.72341C8.64508 13.7859 8.56841 13.8109 8.49841 13.8492L4.80675 15.7834C4.62425 15.875 4.41758 15.9075 4.21508 15.875C3.72175 15.7817 3.39258 15.3117 3.47341 14.8159L4.21508 10.7159C4.26425 10.4167 4.16508 10.1125 3.94925 9.90004L0.940079 6.98337C0.688412 6.73921 0.600912 6.37254 0.715912 6.04171C0.827579 5.71171 1.11258 5.47087 1.45675 5.41671L5.59841 4.81587C5.91341 4.78337 6.19008 4.59171 6.33175 4.30837L8.15674 0.566707C8.20008 0.483374 8.25591 0.406707 8.32341 0.341707L8.39841 0.283374C8.43758 0.240041 8.48258 0.204207 8.53258 0.175041L8.62341 0.141707L8.76508 0.083374H9.11591C9.42924 0.115874 9.70508 0.303374 9.84925 0.583374L11.6984 4.30837C11.8317 4.58087 12.0909 4.77004 12.3901 4.81587L16.5317 5.41671C16.8817 5.46671 17.1742 5.70837 17.2901 6.04171C17.3992 6.37587 17.3051 6.74254 17.0484 6.98337L13.9317 9.93337Z"
      fill="#FFC53D"
    />
  </svg>
)

const StarIcon = (props: any) => <Icon component={StarSvg} {...props} />
export default StarIcon
