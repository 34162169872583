import { notification } from 'antd'
import R from 'assets'
import {
  GoodPriceIcon,
  InsurranceIcon,
  OilIcon,
  RescueIcon,
} from 'common/components/Icons'
import { ROUTER_PATH } from 'common/config'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { formatPrice } from 'utils/ruleForm'
import styles from '../Home.module.css'
import { BigOption, CategoryBlock, SmallOption, SpanHot } from '../styled'
import ModalRequest from './ModalRequest'

const CategoryHome: React.FC<any> = ({ data }) => {
  const history = useHistory()
  const [visibleChangeOil, setVisibleChangeOil] = useState<boolean>(false)
  const [visibleBatter, setVisibleBatter] = useState<boolean>(false)
  return (
    <>
      <CategoryBlock>
        <BigOption
          onClick={() => {
            history.push({
              pathname: `${ROUTER_PATH.PRODUCT}?type=gas`,
            })
            window.location.reload()
          }}
        >
          <div style={{ marginLeft: '15px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <span>{R.strings().title_good_price_petrol}</span>
              <SpanHot>HOT</SpanHot>
            </div>
            <div
              style={{ display: 'flex', padding: '5px', alignItems: 'center' }}
            >
              {+data?.price !== data?.price + data?.price_sale && (
                <>
                  <div
                    style={{
                      color: '#868E96',
                      fontWeight: 'normal',
                      textDecoration: 'line-through',
                      fontSize: '14px',
                      marginRight: '4px',
                    }}
                  >
                    {formatPrice(data?.price + data?.price_sale)}{' '}
                    {R.strings().title_unit}
                  </div>
                  &nbsp;
                </>
              )}
              <div
                style={{
                  color: 'red',
                  fontWeight: 'normal',
                }}
              >
                {formatPrice(data?.price)} {R.strings().title_unit}
              </div>
            </div>
            {data?.price_sale ? (
              <div style={{ color: 'red' }}>
                {R.strings().shock_absorber} {formatPrice(data?.price_sale)}{' '}
                {R.strings().title_unit}
              </div>
            ) : (
              <></>
            )}
          </div>
          <GoodPriceIcon className={styles.icon_option} />
        </BigOption>
        {/* <SmallOption
          onClick={() => {
            if (hasCookie(SESSION_KEY.SESSION)) {
              setVisibleChangeOil(true)
            } else {
              notificationError(R.strings().err_login)
            }
          }}
        >
          <ChangeOilIcon className={styles.icon_option} />
          {R.strings().title_oil_change_at_home}
        </SmallOption> */}
        <SmallOption
          onClick={() => {
            // if (hasCookie(SESSION_KEY.SESSION)) {
            //   setVisibleBatter(true)
            // } else {
            notification.warning({
              message: 'YOYO',
              description: 'Tính năng đang phát triền',
            })
            // }
          }}
        >
          <div style={{ marginLeft: '15px' }}>
            {R.strings().title_car_charging_station}
          </div>
          <RescueIcon className={styles.icon_option} />
        </SmallOption>
        {/* Trạm sạc ô tô */}
        <SmallOption
          onClick={() => {
            const nameCategory =
              localStorage.getItem('i18nextLng') === 'vi'
                ? 'Bảo hiểm'
                : 'Insurance'
            history.push({
              pathname: `${ROUTER_PATH.PRODUCT}`,
              search: `type=insurrance&category=${nameCategory}&idCategory=3`,
            })
          }}
        >
          <div style={{ marginLeft: '15px' }}>
            {R.strings().title_insurrance}
          </div>
          <InsurranceIcon className={styles.icon_option} />
        </SmallOption>
        <SmallOption
          onClick={() => {
            const nameCategory =
              localStorage.getItem('i18nextLng') === 'vi'
                ? 'Dầu nhớt'
                : 'Lubricating oil'
            history.push({
              pathname: `${ROUTER_PATH.PRODUCT}`,
              search: `type=oil&category=${nameCategory}&idCategory=2`,
            })
          }}
        >
          <div style={{ marginLeft: '15px' }}>
            {R.strings().title_lubricating_oil}
          </div>
          <OilIcon className={styles.icon_option} />
        </SmallOption>
      </CategoryBlock>
      <ModalRequest
        visibleChangeOil={visibleChangeOil}
        visibleBatter={visibleBatter}
        setVisibleChangeOil={setVisibleChangeOil}
        setVisibleBatter={setVisibleBatter}
      />
    </>
  )
}
export default CategoryHome
