import {
  CheckCircleOutlined,
  CopyOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons'
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Modal,
  notification,
  Row,
  Skeleton,
  Spin,
  Typography,
} from 'antd'
import Countdown from 'antd/lib/statistic/Countdown'
import R from 'assets'
import images from 'assets/imagesAsset'
import { DebtIcon, GoogleMapIcon } from 'common/components/Icons'
import { ROUTER_PATH } from 'common/config'
import { createPaymentUrl } from 'features/payment/ApiPayment'
import { ButtonOrder } from 'features/payment/styled'
import { TextGoToMap } from 'features/stores/styled'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { WebSocket } from 'services/WebSocket'
import styled from 'styled-components'
import { notificationSuccess } from 'utils/notification'
import { formatPrice, number, removeAccents } from 'utils/ruleForm'
import {
  cancelOrder,
  getDetailOrder,
  getListBank,
  paymentVT,
  reasonCancel,
} from './ApiOrder'
import HistoryRefueling from './components/HistoryRefueling'
import OrderVTPAY from './components/Order.VTPAY'
import ReasonCancel from './components/ReasonCancel'
import ReviewModal from './components/ReviewOrder'
import {
  AboveBank,
  AboveBlock,
  BankBlock,
  BottomBlock,
  BreadcrumbStyle,
  CancelButton,
  CodeBlock,
  ContainerOrder,
  InfoBlock,
  InfoProduct,
  InputInfoBank,
  ItemBank,
  LeftBlock,
  PaidBlock,
  ParagraphStyle,
  PriceMobile,
  PriceNotMobile,
  ReviewButton,
  RightBlock,
  StatusBlock,
} from './styled'

const DetailOrderScreen: React.FC = () => {
  const { Paragraph } = Typography
  const param: any = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [showCode, setShowCode] = useState<boolean>(true)
  const [dataSource, setDataSource] = useState<any>()
  const [dataBank, setDataBank] = useState<any>()
  const [choose, setChoose] = useState<any>()
  const [active, setActive] = useState<number>()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [contentBank, setContentBank] = useState<string>('')
  const [dataReasons, setDataReasons] = useState<any>()
  const [distance, setDistance] = useState<any>()
  const [distanceVnpay, setDistanceVnpay] = useState<any>()
  const deadline = Date.now() + distance
  const deadlineVnpay = Date.now() + distanceVnpay
  const [reasons, setReasons] = useState<any>({
    reason_id: '',
    reason: '',
  })

  // state xử lý open modal scan qr viettin
  const [isModalVisibleScan, setIsModalVisibleScan] = useState<boolean>(false)
  const [waitPayment, setWaitPayment] = useState(false)
  const [qrGen, setQrGen] = useState('')

  useEffect(() => {
    DetailOrder()
    ListBank()
  }, [])

  useEffect(() => {
    getReason()
  }, [])
  const getReason = async () => {
    try {
      const res = await reasonCancel()
      setDataReasons(res)
      setReasons({
        ...reasons,
        reason_id: res?.data[0]?.id,
        reason: res?.data[0]?.content,
      })
    } catch (error) {
    } finally {
    }
  }
  const DetailOrder = async () => {
    setLoading(true)
    try {
      const res = await getDetailOrder(param.id)

      setDataSource(res.data?.detailOrder)

      setDistance(
        Number(moment(res.data?.detailOrder?.cancellation_time)) -
          Number(moment())
      )

      setDistanceVnpay(
        Number(
          moment(
            res.data?.detailOrder?.order_vnpays[0]?.vnpay_transaction?.update_at
          )
        ) +
          900000 -
          Number(moment())
      )

      setContentBank(
        removeAccents(res.data?.detailOrder?.user?.name) +
          '_' +
          res.data?.detailOrder?.user?.phone +
          '_' +
          res.data?.detailOrder?.code
      )
    } catch (error) {
      console.error('Exception ' + error)
    }
    setLoading(false)
  }
  const ListBank = async () => {
    setLoading(true)
    try {
      const res = await getListBank()
      setDataBank(res.data)
      if (res.data.length) {
        setActive(res.data[0].id)
      }
    } catch (error) {
      console.error('Exception ' + error)
    }
    setLoading(false)
  }
  const PatchCancelOrder = async () => {
    setLoading(true)
    try {
      const res = await cancelOrder(param.id, reasons)
      notificationSuccess('Hủy đơn hàng thành công')
      if (res?.code === 200) {
        window.location.reload()
      }
    } catch (error) {
      console.error('Exception ' + error)
    }
    setLoading(false)
  }
  const getPaymentVNPay = async () => {
    const resPayment = await createPaymentUrl({
      order_ids: param.id,
      order_description: 'Thanh toan don hang',
      order_type: '250000',
      locale: 'vn',
    })
    window.open(resPayment.data.vnpayBuilderUrl)
  }
  const history = useHistory()
  const location: any = useLocation()
  const onHandleBack = () => {
    history.replace(ROUTER_PATH.ORDER)
  }
  useEffect(() => {
    if (location.pathname.includes(ROUTER_PATH.ORDER))
      window.addEventListener('popstate', () => onHandleBack())
  }, [])

  const handlePaymentVT = () => {
    setWaitPayment(true)
    paymentVT({
      order_id: dataSource?.id,
    })
      .then((result: any) => {
        setQrGen(result?.data?.qrCode)
      })
      .finally(() => {
        setIsModalVisibleScan(true)
      })
    // .catch(err => {})
  }

  React.useEffect(() => {
    WebSocket.socketClient?.on(`customer_notification`, async (datas: any) => {
      if (datas?.data?.type_notification?.id === 55) {
        notificationSuccess('Thanh toán thành công!')
        DetailOrder()
        setIsModalVisibleScan(false)
        setWaitPayment(false)
      }
    })
  }, [param?.id])

  return (
    <Spin spinning={loading}>
      <ContainerOrder>
        <LeftBlock>
          <BreadcrumbStyle>
            <Breadcrumb.Item>
              <a href={ROUTER_PATH.HOME}>{R.strings().title_home}</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a
                href=""
                onClick={() => {
                  history.push({
                    pathname: `${ROUTER_PATH.ACCOUNT}/order`,
                    state: { type: String(dataSource?.status) },
                  })
                }}
              >
                {R.strings().title_order}
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {dataSource?.status === 1 ? (
                R.strings().pending_order
              ) : dataSource?.status === 2 ? (
                R.strings().confirm_order
              ) : dataSource?.status === 4 ? (
                R.strings().complete_order
              ) : dataSource?.status === 5 ? (
                R.strings().cancel_order
              ) : dataSource?.status === 6 ? (
                R.strings().reject_order
              ) : (
                <></>
              )}
            </Breadcrumb.Item>
          </BreadcrumbStyle>
          <AboveBlock>
            <StatusBlock
              style={
                dataSource?.status === 5 || dataSource?.status === 6
                  ? { backgroundColor: '#E9ECEF', color: 'black' }
                  : {}
              }
            >
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'nowrap',
                  alignItems: 'center',
                }}
              >
                <div>
                  {dataSource?.status === 1 ? (
                    <img src={R.images.icon_pending} alt="" />
                  ) : dataSource?.status === 2 ? (
                    <img src={R.images.icon_confirm} alt="" />
                  ) : dataSource?.status === 4 ? (
                    <img src={R.images.icon_complete} alt="" />
                  ) : dataSource?.status === 5 || 6 ? (
                    <img src={R.images.icon_cancel} alt="" />
                  ) : (
                    <></>
                  )}
                </div>
                <div style={{ margin: '0 0px 0 10px' }}>
                  {dataSource?.status === 1 ? (
                    R.strings().pending_order
                  ) : dataSource?.status === 2 ? (
                    R.strings().confirm_order
                  ) : dataSource?.status === 4 ? (
                    R.strings().complete_order
                  ) : dataSource?.status === 5 ? (
                    R.strings().cancel_order
                  ) : dataSource?.status === 6 ? (
                    R.strings().reject_order
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div>
                {dataSource?.order_payment?.is_paid === 0
                  ? R.strings().not_payment_order
                  : R.strings().payment_order}
              </div>
            </StatusBlock>
            {/* {dataSource?.order_payment?.type_payment_id === 5 &&
              dataSource?.status === 1 &&
              !dataSource?.order_payment?.is_paid && (
                <Row justify="end">
                  <ButtonPaymenVT
                    loading={waitPayment}
                    onClick={handlePaymentVT}
                  >
                    Thanh toán
                  </ButtonPaymenVT>
                </Row>
              )} */}
          </AboveBlock>
          {dataSource?.reasonCancel?.content || dataSource?.reason ? (
            <Row
              style={{
                backgroundColor: 'white',
                margin: '0 0 15px 0',
                padding: 15,
                borderRadius: 12,
                alignItems: 'center',
              }}
            >
              <div style={{ fontSize: 18 }}>
                {R.strings().reason_cancel}:&nbsp;
              </div>
              <div>
                {dataSource?.reasonCancel?.content
                  ? localStorage.getItem('i18nextLng') === 'vi'
                    ? dataSource?.reasonCancel?.content
                    : dataSource?.reasonCancel?.content_en
                  : dataSource?.reason}
              </div>
            </Row>
          ) : (
            <></>
          )}
          {dataSource?.order_payment?.type_payment?.name === 'Chuyển khoản' ? (
            <BankBlock>
              <AboveBank>
                <div style={{ fontWeight: 500, padding: '0 10px 0 0' }}>
                  {R.strings().list_banks}
                </div>
                {dataBank?.length ? (
                  dataBank?.map((item: any) => (
                    <ItemBank
                      active={item?.id === active}
                      onClick={() => {
                        setChoose(item?.owner)
                        setActive(item?.id)
                      }}
                    >
                      {item?.name}
                    </ItemBank>
                  ))
                ) : (
                  <></>
                )}
              </AboveBank>
              <Divider style={{ margin: '10px 0 10px 0' }} />
              {dataBank?.length ? (
                choose ? (
                  dataBank?.map((item: any) =>
                    choose === item?.owner ? (
                      <div style={{ padding: '0 16px 0 16px' }}>
                        <Row justify="space-between">
                          <Col style={{ width: '48%' }}>
                            {R.strings().name_bank}
                            <InputInfoBank>
                              <Paragraph style={{ margin: 0 }}>
                                {item?.owner}
                              </Paragraph>
                            </InputInfoBank>
                          </Col>
                          <Col style={{ width: '48%' }}>
                            {R.strings().number_bank}
                            <InputInfoBank>
                              <ParagraphStyle
                                copyable={{
                                  icon: [
                                    <CopyOutlined
                                      key="copy-icon"
                                      style={{ color: '#f26522' }}
                                    />,
                                    <CheckCircleOutlined
                                      key="copied-icon"
                                      style={{ color: '#f26522' }}
                                    />,
                                  ],
                                }}
                              >
                                {item?.account_number}
                              </ParagraphStyle>
                            </InputInfoBank>
                          </Col>
                        </Row>
                        <div style={{ padding: '10px 0 0 0' }}>
                          {R.strings().content_bank}
                        </div>
                        <InputInfoBank>
                          <ParagraphStyle
                            copyable={{
                              icon: [
                                <CopyOutlined
                                  key="copy-icon"
                                  style={{ color: '#f26522' }}
                                />,
                                <CheckCircleOutlined
                                  key="copied-icon"
                                  style={{ color: '#f26522' }}
                                />,
                              ],
                            }}
                          >
                            {contentBank ? contentBank : ''}
                          </ParagraphStyle>
                        </InputInfoBank>
                      </div>
                    ) : (
                      <></>
                    )
                  )
                ) : (
                  <div style={{ padding: '0 16px 0 16px' }}>
                    <Row justify="space-between">
                      <Col style={{ width: '48%' }}>
                        {R.strings().name_bank}
                        <InputInfoBank>
                          <Paragraph style={{ margin: 0 }}>
                            {dataBank[0]?.owner}
                          </Paragraph>
                        </InputInfoBank>
                      </Col>
                      <Col style={{ width: '48%' }}>
                        {R.strings().number_bank}
                        <InputInfoBank>
                          <ParagraphStyle
                            copyable={{
                              icon: [
                                <CopyOutlined
                                  key="copy-icon"
                                  style={{ color: '#f26522' }}
                                />,
                                <CheckCircleOutlined
                                  key="copied-icon"
                                  style={{ color: '#f26522' }}
                                />,
                              ],
                            }}
                          >
                            {dataBank[0]?.account_number}
                          </ParagraphStyle>
                        </InputInfoBank>
                      </Col>
                    </Row>
                    <div style={{ padding: '10px 0 0 0' }}>
                      {R.strings().content_bank}
                    </div>
                    <InputInfoBank>
                      <ParagraphStyle
                        copyable={{
                          icon: [
                            <CopyOutlined
                              key="copy-icon"
                              style={{ color: '#f26522' }}
                            />,
                            <CheckCircleOutlined
                              key="copied-icon"
                              style={{ color: '#f26522' }}
                            />,
                          ],
                        }}
                      >
                        {contentBank ? contentBank : ''}
                      </ParagraphStyle>
                    </InputInfoBank>
                  </div>
                )
              ) : (
                <></>
              )}
            </BankBlock>
          ) : (
            <></>
          )}
          <Skeleton loading={loading}>
            <InfoBlock>
              <div
                style={{
                  padding: '0 16px 0 16px',
                  fontWeight: 600,
                  fontSize: 18,
                }}
              >
                {R.strings().info_order}
              </div>
              <Divider
                style={{
                  margin: '10px 0 10px 0',
                }}
              />
              <div style={{ padding: '0 16px 0 16px' }}>
                <div>
                  <EnvironmentOutlined style={{ color: '#f26522' }} />
                  &nbsp;
                  {dataSource?.address ? (
                    <>
                      {`${
                        dataSource?.address?.location_address
                          ? dataSource?.address?.location_address + ','
                          : ''
                      }
                  ${
                    dataSource?.address?.ward_name
                      ? dataSource?.address?.ward_name + ','
                      : ''
                  }
                  ${
                    dataSource?.address?.district_name
                      ? dataSource?.address?.district_name + ','
                      : ''
                  },
                  ${
                    dataSource?.address?.province_name
                      ? dataSource?.address?.province_name
                      : ''
                  }`}{' '}
                      &nbsp;&nbsp;
                      <TextGoToMap
                        onClick={() => {
                          window.open(
                            `https://www.google.com/maps/place/?q=${dataSource?.f_user_address.name}/@${dataSource?.f_user_address?.location_lat},${dataSource?.f_user_address?.location_long}`
                          )
                        }}
                        style={{ fontSize: '14px' }}
                      >
                        Chỉ đường
                      </TextGoToMap>
                    </>
                  ) : (
                    <>
                      {console.log(
                        'dataSource?.petrol_store?.location_address',
                        dataSource?.petrol_store?.location_address
                      )}
                      {`${
                        dataSource?.petrol_store?.location_address
                          ? dataSource?.petrol_store?.location_address + ','
                          : ''
                      } 
                      ${
                        dataSource?.petrol_store?.ward?.name
                          ? dataSource?.petrol_store?.ward?.name + ','
                          : ''
                      } 
                      ${
                        dataSource?.petrol_store?.district?.name
                          ? dataSource?.petrol_store?.district?.name + ','
                          : ''
                      } 
                      ${
                        dataSource?.petrol_store?.province?.name
                          ? dataSource?.petrol_store?.province?.name
                          : ''
                      }`}{' '}
                      &nbsp;&nbsp;
                      <TextGoToMap
                        onClick={() => {
                          window.open(
                            `https://www.google.com/maps/place/?q=${dataSource?.petrol_store.name}/@${dataSource?.petrol_store?.location_lat},${dataSource?.petrol_store?.location_long}`
                          )
                        }}
                        style={{ fontSize: '14px' }}
                      >
                        Chỉ đường
                      </TextGoToMap>
                    </>
                  )}
                </div>
                <div>
                  <EnvironmentOutlined style={{ color: 'white' }} />
                  &nbsp;
                  {!dataSource?.address ? (
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: 'grey',
                      }}
                    >
                      {dataSource?.petrol_store?.name}
                      &nbsp; | &nbsp;
                      {dataSource?.petrol_store?.phone}
                    </div>
                  ) : (
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: 'grey',
                      }}
                    >
                      {dataSource?.address?.name}
                      &nbsp; | &nbsp;
                      {dataSource?.address?.phone}
                    </div>
                  )}
                </div>
              </div>
              <Divider
                style={{
                  margin: '10px 0 10px 0',
                }}
              />
              <div style={{ padding: '0 16px 0 16px', fontWeight: 600 }}>
                <img src={R.images.symbol_gas} alt="" />
                &nbsp;{dataSource?.petrol_store?.name}
              </div>
              <Divider
                style={{
                  margin: '10px 0 10px 0',
                }}
              />
              <div style={{ padding: '0 16px 0 16px' }}>
                {dataSource?.order_items?.map((item: any) => (
                  <>
                    <Row
                      justify="space-between"
                      style={{ padding: '0 0 16px 0', alignItems: 'center' }}
                    >
                      <InfoProduct>
                        <img
                          alt=""
                          src={item?.media_url}
                          style={{
                            width: 70,
                            aspectRatio: '1/1',
                            objectFit: 'cover',
                          }}
                        />
                        <div style={{ margin: '0 0 0 10px', width: '100%' }}>
                          <div>{item?.product_name}</div>
                          <PriceMobile justify="space-between">
                            <Divider style={{ margin: '0 0 5px 0' }} />
                            <div>
                              {dataSource.order_gasoline &&
                              dataSource.status < 4
                                ? dataSource.order_gasoline?.is_full_fuel === 0
                                  ? formatPrice(item?.price_reality)
                                  : formatPrice(item?.product_price)
                                : formatPrice(item?.product_price)}
                              đ
                            </div>
                            <div>
                              {!dataSource.order_gasoline &&
                              dataSource.status === 4
                                ? 'x ' +
                                  formatPrice(
                                    +(item?.total_quantity).toFixed(3)
                                  )
                                : dataSource.order_receipt_gasoline
                                ? 'x ' +
                                  formatPrice(
                                    (+(
                                      dataSource.order_payment.total_price /
                                      dataSource.order_receipt_gasoline.price
                                    )).toFixed(3)
                                  )
                                : (dataSource.total_price +
                                    dataSource.points_used) /
                                    dataSource.order_items[0].price_reality ===
                                  0
                                ? R.strings().bottle_full
                                : 'x ' +
                                  +(
                                    (dataSource.total_price +
                                      dataSource.points_used) /
                                    dataSource.order_items[0].price_reality
                                  ).toFixed(3)}
                            </div>
                          </PriceMobile>
                        </div>
                      </InfoProduct>
                      <PriceNotMobile>
                        {dataSource.order_gasoline && dataSource.status < 4
                          ? dataSource.order_gasoline?.is_full_fuel === 0
                            ? formatPrice(item?.price_reality)
                            : formatPrice(item?.product_price)
                          : formatPrice(item?.product_price)}
                        đ
                      </PriceNotMobile>
                      <PriceNotMobile>
                        {!dataSource.order_gasoline && dataSource.status === 4
                          ? 'x ' +
                            formatPrice(+(item?.total_quantity).toFixed(3))
                          : dataSource.order_receipt_gasoline
                          ? 'x ' +
                            formatPrice(
                              (+(
                                dataSource.order_payment.total_price /
                                dataSource.order_receipt_gasoline.price
                              )).toFixed(3)
                            )
                          : (dataSource.total_price + dataSource.points_used) /
                              dataSource.order_items[0].price_reality ===
                            0
                          ? R.strings().bottle_full
                          : 'x ' +
                            +(
                              (dataSource.total_price +
                                dataSource.points_used) /
                              dataSource.order_items[0].price_reality
                            ).toFixed(3)}
                      </PriceNotMobile>
                    </Row>
                    <Divider
                      style={{
                        margin: '10px 0 10px 0',
                      }}
                    />
                    {/* dem nguoc mua xang */}
                    {dataSource?.order_gasoline &&
                    dataSource?.status !== 4 &&
                    dataSource?.status !== 2 &&
                    dataSource?.status !== 5 &&
                    dataSource?.status !== 6 ? (
                      <Row>
                        {R.strings().cacel_after}:&nbsp;
                        <Countdown
                          valueStyle={{
                            color: '#F26522',
                            fontSize: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                          value={deadline}
                          format="HH:mm:ss"
                        />
                      </Row>
                    ) : (
                      <></>
                    )}
                    {/* dem nguoc vnpay */}
                    {dataSource?.order_payment?.type_payment?.id === 1 &&
                    dataSource?.order_payment?.is_paid === 0 &&
                    dataSource?.status === 1 ? (
                      <Row>
                        {R.strings().wait_payment}:&nbsp;
                        <Countdown
                          valueStyle={{
                            color: '#F26522',
                            fontSize: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                          value={deadlineVnpay}
                          format="mm:ss"
                        />
                      </Row>
                    ) : (
                      <></>
                    )}
                    {dataSource?.order_insurance ? (
                      <>
                        <div style={{ fontWeight: 600 }}>
                          &nbsp;{R.strings().info_insurance}
                        </div>
                        <Divider
                          style={{
                            margin: '10px 0 10px 0',
                          }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            flexFlow: 'nowrap',
                            padding: '0 16px 0 16px',
                          }}
                        >
                          <div style={{ width: '30%', lineHeight: 2.5 }}>
                            <div>{R.strings().owner_insurance}</div>
                            <div>{R.strings().license_plates}</div>
                            <div>{R.strings().car_company}</div>
                          </div>
                          <div style={{ lineHeight: 2.5, fontWeight: 500 }}>
                            <div>{dataSource?.order_insurance?.name}</div>
                            <div>
                              {dataSource?.order_insurance?.number_plate}
                            </div>
                            <div>
                              {dataSource?.order_insurance?.vehicle_brand}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </div>
            </InfoBlock>
          </Skeleton>
          {dataSource?.order_receipt ? (
            <BottomBlock>
              <div
                style={{
                  padding: '0 16px 0 16px',
                  fontWeight: 600,
                  fontSize: 18,
                }}
              >
                {R.strings().request_order}
              </div>
              <Divider
                style={{
                  margin: '10px 0 10px 0',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'nowrap',
                  padding: '0 16px 0 16px',
                }}
              >
                <div style={{ width: '30%', lineHeight: 2.5 }}>
                  <div>{R.strings().text_form_tax}</div>
                  <div>{R.strings().text_form_company}</div>
                  <div>{R.strings().text_form_tax_address}</div>
                  <div>Email</div>
                </div>
                <div style={{ lineHeight: 2.5, fontWeight: 500 }}>
                  <div>{dataSource?.order_receipt?.tax}</div>
                  <div>{dataSource?.order_receipt?.name}</div>
                  <div>{dataSource?.order_receipt?.address}</div>
                  <div>{dataSource?.order_receipt?.email}</div>
                </div>
              </div>
            </BottomBlock>
          ) : (
            <></>
          )}
          {dataSource?.f_user_address ? (
            <></>
          ) : (
            <BottomBlock>
              <div
                style={{
                  padding: '0 16px 0 16px',
                  fontWeight: 600,
                  fontSize: 18,
                }}
              >
                {R.strings().title_vehicle_information}
              </div>
              <Divider
                style={{
                  margin: '10px 0 10px 0',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'nowrap',
                  padding: '0 16px 0 16px',
                }}
              >
                <div style={{ width: '30%', lineHeight: 2.5 }}>
                  <div>{R.strings().type_vehicle}</div>
                  <div>{R.strings().number_plate}</div>
                </div>
                <div style={{ lineHeight: 2.5, fontWeight: 500 }}>
                  <div>
                    {localStorage.getItem('i18nextLng') === 'vi'
                      ? dataSource?.user_vehicle?.type_vehicle_id === 1
                        ? 'Xe máy'
                        : 'Ô tô'
                      : dataSource?.user_vehicle?.type_vehicle_id === 1
                      ? 'Motorcycle'
                      : 'Car'}
                  </div>
                  <div>{dataSource?.user_vehicle?.number_plate}</div>
                </div>
              </div>
            </BottomBlock>
          )}

          {/* lịch sử đổ xăng cho đơn hàng mua xăng giữ hàng */}
          {dataSource?.save_status === 1 && dataSource?.status !== 1 && (
            <HistoryRefueling
              order_id={dataSource?.id}
              user_id={dataSource?.user_id}
              petrol_store_id={dataSource?.petrol_store_id}
            />
          )}
        </LeftBlock>
        <RightBlock>
          <div
            style={{
              padding: '16px 16px 0 16px',
              lineHeight: 2,
            }}
          >
            {showCode ? (
              <>
                <Row justify="center">
                  <b>QR code</b>
                </Row>
                <div style={{ textAlign: 'center', margin: '15px 0 5px 0' }}>
                  <QRCode
                    value={'/admin-order-detail/' + dataSource?.id}
                    size={128}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
            <CodeBlock>
              <Row
                justify="center"
                onClick={() => {
                  setShowCode(!showCode)
                }}
              >
                {R.strings().code_order} -&nbsp;
                <Paragraph
                  copyable={{
                    icon: [
                      <CopyOutlined
                        key="copy-icon"
                        style={{ color: '#f26522' }}
                      />,
                      <CheckCircleOutlined
                        key="copied-icon"
                        style={{ color: '#f26522' }}
                      />,
                    ],
                  }}
                  style={{ margin: 0 }}
                >
                  {dataSource?.code}
                </Paragraph>
              </Row>
            </CodeBlock>
            <div
              style={{
                fontSize: 18,
                fontWeight: 600,
                padding: '16px 0 16px 0',
              }}
            >
              {R.strings().title_payments}
            </div>
            <PaidBlock>
              <div style={{ fontWeight: 500 }}>
                <Row align="middle">
                  {dataSource?.order_payment?.type_payment?.slug ===
                  'chuyen-khoan-ngan-hang' ? (
                    <img src={R.images.icon_banking} alt="" />
                  ) : dataSource?.order_payment?.type_payment?.slug ===
                    'thanh-toan-bang-tien-mat' ? (
                    <img src={R.images.icon_payment} alt="" />
                  ) : dataSource?.order_payment?.type_payment?.slug ===
                    'cong-thanh-toan-vnpay' ? (
                    <img src={R.images.icon_vnpay} alt="" />
                  ) : dataSource?.order_payment?.type_payment?.slug ===
                    'cong-thanh-toan-vtpay' ? (
                    <img
                      width="26px"
                      height="26px"
                      src={images.viettin}
                      alt=""
                    />
                  ) : (
                    <DebtIcon />
                  )}
                  &nbsp;&nbsp;
                  {localStorage.getItem('i18nextLng') === 'vi'
                    ? dataSource?.order_payment?.type_payment?.name
                    : dataSource?.order_payment?.type_payment?.name_en}
                </Row>
                <div>
                  {localStorage.getItem('i18nextLng') === 'vi'
                    ? dataSource?.order_payment?.type_payment?.description
                    : dataSource?.order_payment?.type_payment?.description_en}
                </div>
              </div>
            </PaidBlock>
            <div
              style={{
                padding: '16px 0px 16px 0px',
                fontWeight: 600,
                fontSize: 18,
              }}
            >
              {R.strings().paid_info}
            </div>
            {dataSource?.order_receipt_gasoline?.total_price_reality ? (
              <Row justify="space-between">
                <div>{R.strings().total_price_reality}</div>
                <div>
                  {formatPrice(
                    dataSource?.order_receipt_gasoline?.total_price_reality
                  )}
                </div>
              </Row>
            ) : (
              <></>
            )}
            <Row justify="space-between">
              <div>{R.strings().total_price_goods_order}</div>
              <div>
                {dataSource?.order_receipt_gasoline == null ||
                dataSource?.order_receipt_gasoline.total_price === 0
                  ? dataSource?.points_used === 0 &&
                    dataSource?.total_price === 0
                    ? R.strings().unknown
                    : dataSource?.total_price + dataSource?.points_used === 0
                    ? '0đ'
                    : formatPrice(
                        dataSource?.total_price + dataSource?.points_used
                      ) + 'đ'
                  : dataSource?.points_used === 0 &&
                    dataSource?.order_receipt_gasoline.total_price === 0
                  ? R.strings().unknown
                  : dataSource?.order_receipt_gasoline.total_price +
                      dataSource?.points_used ===
                    0
                  ? '0đ'
                  : formatPrice(
                      dataSource?.order_receipt_gasoline.total_price +
                        dataSource?.points_used
                    ) + 'đ'}
              </div>
            </Row>
            <Row justify="space-between">
              <div>{R.strings().point_used}</div>
              <div>-{formatPrice(dataSource?.points_used)}</div>
            </Row>
          </div>
          <div
            style={{
              display: 'flex',
              flexFlow: 'nowrap',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                width: 20,
                height: 40,
                backgroundColor: '#efefef',
                borderRadius: '0 20px 20px 0',
              }}
            />
            <div
              style={{
                border: '1px dashed #CED4DA',
                height: 1,
                width: '80%',
              }}
            ></div>
            <div
              style={{
                width: 20,
                height: 40,
                backgroundColor: '#efefef',
                borderRadius: '20px 0px 0px 20px',
              }}
            />
          </div>
          <div style={{ padding: '0 16px 16px 16px', lineHeight: 2 }}>
            <Row justify="space-between">
              <div style={{ fontWeight: 600 }}>{R.strings().total_paid}</div>
              <div style={{ color: '#f26522' }}>
                {dataSource?.total_price === 0
                  ? dataSource?.order_gasoline?.is_full_fuel === 1
                    ? R.strings().unknown
                    : 0 + ' đ'
                  : formatPrice(+dataSource?.total_price.toFixed(3)) + ' đ'}
                {/* {formatPrice(dataSource?.total_price)} */}
              </div>
            </Row>
            <Row justify="space-between">
              <div>{R.strings().point_received}</div>
              <div>
                {dataSource?.order_receipt_gasoline
                  ? dataSource?.order_receipt_gasoline?.total_price_reality <
                    dataSource?.order_receipt_gasoline?.total_price
                    ? '+' +
                      formatPrice(
                        dataSource?.points_received +
                          dataSource?.order_receipt_gasoline?.total_price -
                          dataSource?.order_receipt_gasoline
                            ?.total_price_reality
                      )
                    : '+' + formatPrice(dataSource?.points_received)
                  : dataSource?.points_received === 0
                  ? R.strings().unknown
                  : '+' + formatPrice(dataSource?.points_received)}
              </div>
            </Row>
            {dataSource?.order_payment?.type_payment?.id === 1 &&
            dataSource?.order_payment?.is_paid === 0 &&
            dataSource?.status === 1 ? (
              <ReviewButton
                onClick={() => {
                  getPaymentVNPay()
                }}
              >
                {R.strings().title_payment}
              </ReviewButton>
            ) : (
              <></>
            )}

            {dataSource?.order_payment?.type_payment_id === 5 &&
              dataSource?.status === 1 &&
              !dataSource?.order_payment?.is_paid && (
                <ReviewButton loading={waitPayment} onClick={handlePaymentVT}>
                  {R.strings().title_payment}
                </ReviewButton>
              )}

            <div style={{ width: '100%', textAlign: 'center' }}>
              {dataSource?.status === 1 &&
              dataSource?.order_payment?.type_payment?.id !== 5 &&
              !dataSource?.order_gasoline &&
              dataSource?.order_payment?.type_payment?.id !== 1 ? (
                <CancelButton
                  onClick={() => {
                    setIsModalVisible(!isModalVisible)
                  }}
                >
                  {R.strings().cancel_order}
                </CancelButton>
              ) : (
                <></>
              )}
              {dataSource?.status === 4 ? (
                !dataSource?.check_review ? (
                  <ReviewButton
                    onClick={() => {
                      setVisible(!visible)
                    }}
                  >
                    {R.strings().evaluate}
                  </ReviewButton>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              <ReasonCancel
                dataReasons={dataReasons}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                cancelOrder={PatchCancelOrder}
                id_order={param.id}
                reasons={reasons}
                setReasons={setReasons}
              />
              <ReviewModal
                check_review={dataSource?.check_review}
                isModalVisible={visible}
                setIsModalVisible={setVisible}
                order_id={dataSource?.id}
                petrol_store_id={dataSource?.petrol_store?.id}
              />
            </div>
          </div>
        </RightBlock>
      </ContainerOrder>
      <OrderVTPAY
        isModalVisibleScan={isModalVisibleScan}
        onCancel={() => {
          setIsModalVisibleScan(false)
          setWaitPayment(false)
        }}
        qrGen={qrGen}
        dataSource={dataSource}
      />
    </Spin>
  )
}

const ButtonPaymenVT = styled(Button)`
  background: #f26522;
  border-radius: 12px;
  color: white;
  font-weight: 600;
  margin-top: 12px;

  :active,
  :focus,
  :hover {
    border-color: #f26522;
    color: #f26522;
  }
`

export default DetailOrderScreen
