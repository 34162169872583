import { Row } from 'antd'
import React from 'react'
import { ContentItem, SmallItem } from '../styled'

const InformationPrivacyPolicy = () => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '20px',
        textAlign: 'justify',
        listStyleType: ' upper-roman',
        margin: '20px 0',
      }}
    >
      <Row justify="center" style={{ marginBottom: '10px', width: '100%' }}>
        <b style={{ fontSize: '20px' }}>CHÍNH SÁCH BẢO MẬT THÔNG TIN</b>
      </Row>
      <SmallItem>
        <b>1. Mục đích thu thập thông tin cá nhân</b>
        <ContentItem>
          <p>
            Mục đích của việc thu thập thông tin khách hàng nhằm liên quan đến
            các vấn đề như:
          </p>
          <p>- Hỗ trợ khách hàng: mua hàng, thanh toán, giao hàng.</p>
          <p>
            - Cung cấp thông tin sản phẩm, các dịch vụ và hỗ trợ theo yêu cầu
            của khách hàng
          </p>
          <p>
            - Gửi thông báo các chương trình, sản phẩm mới nhất của chúng tô
          </p>
          <p>- Giải quyết vấn đề phát sinh khi mua hàng.</p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>2. Phạm vi thu thập thông tin</b>
        <ContentItem>
          <p>
            Chúng tôi thu thập thông tin cá nhân của khách hàng khi tiến hàng
            đặt hàng trên website:
          </p>
          <p>Họ tên:</p>
          <p>Địa chỉ email:</p>
          <p>Số điện thoại:</p>
          <p>Địa chỉ:</p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>3. Thời gian lưu trữ thông tin</b>
        <ContentItem>
          <p>
            Dữ liệu cá nhân của Thành viên sẽ được lưu trữ cho đến khi có yêu
            cầu hủy bỏ hoặc tự thành viên đăng nhập và thực hiện hủy bỏ. Còn lại
            trong mọi trường hợp thông tin cá nhân thành viên sẽ được bảo mật
            trên máy chủ của pyoyo.vn
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>4. Những người hoặc tổ chức có thể được tiếp cận với thông tin đó</b>
        <ContentItem>
          <p>
            – Đối với các bên vận chuyển, sẽ cung cấp các thông tin để phục vụ
            cho việc giao nhận hàng hóa như Tên, địa chỉ và số điện thoại.
          </p>
          <p>
            – Đối với nhân viên công ty sẽ có các bộ phận chuyên trách để phục
            vụ việc chăm sóc khách hàng trong quá trình sử dụng sản phẩm.
          </p>
          <p>
            – Các chương trình có tính liên kết, đồng thực hiện, thuê ngoài cho
            các mục đích được nêu tại Mục 1 và luôn áp dụng các yêu cầu bảo mật
            thông tin cá nhân.
          </p>
          <p>
            – Yêu cầu pháp lý: Chúng tôi có thể tiết lộ các thông tin cá nhân
            nếu điều đó do luật pháp yêu cầu và việc tiết lộ như vậy là cần
            thiết một cách hợp lý để tuân thủ các quy trình pháp lý.
          </p>
          <p>
            – Chuyển giao kinh doanh (nếu có): trong trường hợp sáp nhập, hợp
            nhất toàn bộ hoặc một phần với công ty khác, người mua sẽ có quyền
            truy cập thông tin được chúng tôi lưu trữ, duy trì trong đó bao gồm
            cả thông tin cá nhân.
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>5. Địa chỉ của đơn vị thu thập và quản lý thông tin</b>
        <ContentItem>
          <p>- Tên doanh nghiệp: CÔNG TY CỔ PHẦN HỆ SINH THÁI CÔNG NGHỆ YOYO</p>
          <p>
            – Thành lập và hoạt động theo Giấy chứng nhận đăng ký doanh nghiệp
            số: 0109083491 do Sở Kế hoạch và Đầu tư thành phố Hà Nội cấp ngày 10
            tháng 02 năm 2020 thay đổi lần thứ hai ngày 23/02/2023.
          </p>
          <p>
            – Trụ sở chính: Tầng 5 Số 11 Ngõ 84, Ngọc Khánh, Phường Giảng Võ,
            Quận Ba Đình, thành phố Hà Nội
          </p>
        </ContentItem>
      </SmallItem>
      <SmallItem>
        <b>
          6. Phương thức và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu:
        </b>
        <ContentItem>
          <p>
            Nếu quý khách có bất cứ về yêu cầu nào về việc tiếp cận và chỉnh sửa
            thông tin cá nhân đã cung cấp, quý khách có thể:
          </p>
          <p>- Gọi điện trực tiếp về số điện thoại: 0899.743.888</p>
          <p>- Gửi mail: sales@yoyojsc.com.vn</p>
          <b>
            Cơ chế tiếp nhận và giải quyết khiếu nại của người tiêu dùng liên
            quan đến việc thông tin cá nhân bị sử dụng sai mục đích hoặc phạm vi
            đã thông báo
          </b>
          <p>
            Tại pyoyo.vn, việc bảo vệ thông tin cá nhân của bạn là rất quan
            trọng, bạn được đảm bảo rằng thông tin cung cấp cho chúng tôi sẽ
            được mật pyoyo.vn cam kết không chia sẻ, bán hoặc cho thuê thông tin
            cá nhân của bạn cho bất kỳ người nào khác. pyoyo.vn cam kết chỉ sử
            dụng các thông tin của bạn vào các trường hợp sau
          </p>
          <p>– Nâng cao chất lượng dịch vụ dành cho khách hàn</p>
          <p>– Giải quyết các tranh chấp, khiếu nại</p>
          <p>
            – Khi cơ quan pháp luật có yêu cầu. pyoyo.vn hiểu rằng quyền lợi của
            bạn trong việc bảo vệ thông tin cá nhân cũng chính là trách nhiệm
            của chúng tôi nên trong bất kỳ trường hợp có thắc mắc, góp ý nào
            liên quan đến chính sách bảo mật của pyoyo.vn, và liên quan đến việc
            thông tin cá nhân bị sử dụng sai mục đích hoặc phạm vi đã thông báo
            vui lòng liên hệ qua số hotline 0899.743.888 hoặc email:
            sales@yoyojsc.com.vn để xử lý và làm việc trực tiếp với khách hàng.
          </p>
        </ContentItem>
      </SmallItem>
    </div>
  )
}

export default InformationPrivacyPolicy
