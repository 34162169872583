import R from 'assets'

type Props = {
  text?: string
}
export default function EmptyComponent({ text }: Props) {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src={R.images.image_complete} />
      </div>
      <div style={{ fontWeight: 600, textAlign: 'center', color: '#CED4DA' }}>
        {text ? text : R.strings().list_is_empty}
      </div>
    </div>
  )
}
