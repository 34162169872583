import Icon from '@ant-design/icons'

const CoinSvg = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.8"
      d="M11.875 22.75C17.8811 22.75 22.75 17.8811 22.75 11.875C22.75 5.8689 17.8811 1 11.875 1C5.8689 1 1 5.8689 1 11.875C1 17.8811 5.8689 22.75 11.875 22.75Z"
      fill="#868E96"
    />
    <path
      d="M11.875 21.25C17.0527 21.25 21.25 17.0527 21.25 11.875C21.25 6.69733 17.0527 2.5 11.875 2.5C6.69733 2.5 2.5 6.69733 2.5 11.875C2.5 17.0527 6.69733 21.25 11.875 21.25Z"
      fill="#ADB5BD"
    />
    <path
      d="M14.4174 17.0001H10.2341V16.4067L11.357 16.1972C11.4579 16.1817 11.5355 16.1448 11.5898 16.0867C11.6441 16.0285 11.6712 15.949 11.6712 15.8481V13.515L9.05887 8.9652C9.0162 8.8915 8.96578 8.83332 8.9076 8.79066C8.84941 8.74799 8.77766 8.71696 8.69232 8.69756L7.95923 8.5172V7.91211H11.5141V8.5172L10.4436 8.73247L12.573 12.5783L14.5396 8.72666L13.4516 8.5172V7.91211H16.448V8.5172L15.7323 8.68011C15.647 8.6995 15.5733 8.73053 15.5112 8.7732C15.4492 8.81199 15.3987 8.87017 15.36 8.94775L12.9803 13.5208V15.8481C12.9803 15.949 13.0055 16.0285 13.056 16.0867C13.1103 16.1448 13.1898 16.1817 13.2945 16.1972L14.4174 16.4067V17.0001Z"
      fill="white"
    />
  </svg>
)

const CoinIcon = (props: any) => <Icon component={CoinSvg} {...props} />
export default CoinIcon
