import { ArrowLeftOutlined } from '@ant-design/icons'
import { Divider, Form, Spin, Statistic } from 'antd'
import R from 'assets'
import { SESSION_KEY } from 'common/config'
import Cookies from 'js-cookie'
import { useState } from 'react'
import { setTimeout } from 'timers'
import { caculateCountDown } from 'utils/functionHelper'
import { notificationSuccess } from 'utils/notification'
import { requestPassword, verifyPassword } from '../AuthApi'
import {
  AboveBlock,
  ButtonCode,
  CodeBlock,
  ContentBlock,
  FooterCode,
  InputCode,
  StepCode,
  SubStep,
  TextCode,
  UnderBlock,
} from '../authStyle'

type Props = {
  setStep: any
  data: any
  phoneSignup: any
  time: any
  setTime: any
  setTokenForgot?: any
}
const { Countdown } = Statistic
export const Step1ForgotPassword: React.FC<Props> = ({
  setStep,
  data,
  phoneSignup,
  time,
  setTime,
  setTokenForgot,
}: Props) => {
  const [form] = Form.useForm()
  const [outTime, setOutTiem] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  // const [values, setValues] = useState<string>()
  const [otp, setOtp] = useState<any>('')
  const onFinish = async (values: any) => {
    try {
      setLoading(true)
      let newData

      newData = {
        otp: String(otp),
        email: data?.data?.email,
      }

      const res = await verifyPassword(newData)
      setTokenForgot(res?.data?.token_forgot_password)
      setTimeout(() => {
        Cookies.set(
          SESSION_KEY.SESSION_PASSWORD,
          res?.data?.token_forgot_password,
          {
            expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
          }
        )
        notificationSuccess(res?.message)
        setStep(2)
      }, 1000)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <ContentBlock>
      <div>
        <CodeBlock>
          <AboveBlock justify="space-between">
            <ArrowLeftOutlined onClick={() => setStep(0)} />
            <span>{R.strings().text_form_otp}</span>
            <ArrowLeftOutlined style={{ color: 'white' }} />
          </AboveBlock>
          <Divider style={{ margin: 0 }} />
          <UnderBlock>
            <TextCode>
              {R.strings().text_form_enter_otp}
              <div style={{ paddingTop: '10px', color: '#F26522' }}>
                {data?.data?.email}
              </div>
            </TextCode>
            <Form
              // layout="inline"
              form={form}
              name="normal_login"
              className="login-form"
              initialValues={{
                code: null,
              }}
              onFinish={onFinish}
            >
              <InputCode
                maxLength={6}
                onChange={(e: any) => {
                  setOtp(e.target.value)
                }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    maxWidth: 280,
                    display: 'flex',
                    flexFlow: 'nowrap',
                  }}
                >
                  <div
                    style={{
                      height: 2,
                      width: 40,
                      margin: '0 2px 0 2px',
                      backgroundColor: 'black',
                    }}
                  ></div>
                  <div
                    style={{
                      height: 2,
                      width: 40,
                      margin: '0 2px 0 2px',
                      backgroundColor: 'black',
                    }}
                  ></div>
                  <div
                    style={{
                      height: 2,
                      width: 40,
                      margin: '0 2px 0 2px',
                      backgroundColor: 'black',
                    }}
                  ></div>
                  <div
                    style={{
                      height: 2,
                      width: 40,
                      margin: '0 2px 0 2px',
                      backgroundColor: 'black',
                    }}
                  ></div>
                  <div
                    style={{
                      height: 2,
                      width: 40,
                      margin: '0 2px 0 2px',
                      backgroundColor: 'black',
                    }}
                  ></div>
                  <div
                    style={{
                      height: 2,
                      width: 40,
                      margin: '0 2px 0 2px',
                      backgroundColor: 'black',
                    }}
                  ></div>
                </div>
              </div>
              {outTime ? (
                <></>
              ) : (
                <>
                  <span
                    style={{
                      fontSize: 16,
                      padding: '5vh 0px 8px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {R.strings().text_form_expired}:
                  </span>
                  <Countdown
                    valueStyle={{
                      color: '#F26522',
                      fontSize: '16px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    value={Date.now() + time}
                    format="mm:ss"
                    onFinish={() => setOutTiem(true)}
                  />
                </>
              )}
              <Form.Item
                style={
                  outTime == false
                    ? {
                        padding: '20px 0px 20px 0px',
                      }
                    : { display: 'none' }
                }
              >
                <Spin spinning={isLoading}>
                  <ButtonCode>{R.strings().text_form_confirm}</ButtonCode>
                </Spin>
              </Form.Item>
            </Form>
            <Form
              style={{
                margin: '60px 0 0 0',
              }}
            >
              {outTime == true ? R.strings().text_form_havent_send : ''}
              <Form.Item
                style={
                  outTime == true
                    ? {
                        padding: '20px 0px 20px 0px',
                        textAlign: 'center',
                      }
                    : { display: 'none' }
                }
              >
                <Spin spinning={isLoading}>
                  <button
                    style={{
                      color: '#F26522',
                      border: 'none',
                      backgroundColor: 'unset',
                      cursor: 'pointer',
                    }}
                    onClick={async () => {
                      const res = await requestPassword({
                        phone: phoneSignup,
                      })

                      setTime(caculateCountDown(res.data.expired_at))
                      notificationSuccess(R.strings().notify_otp_again)
                      setOutTiem(false)
                      setLoading(false)
                    }}
                  >
                    {R.strings().text_form_send}
                  </button>
                </Spin>
              </Form.Item>
            </Form>
          </UnderBlock>
        </CodeBlock>
        <FooterCode>
          <StepCode direction="horizontal" responsive={false}>
            <SubStep></SubStep>
            <SubStep></SubStep>
            <SubStep></SubStep>
          </StepCode>
        </FooterCode>
      </div>
    </ContentBlock>
  )
}
