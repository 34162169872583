import R from 'assets'
import { ROUTER_PATH } from 'common/config'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { CalenderIcon } from '../Icons'
import {
  FlexSmallNews,
  ImageNews,
  OverviewNews,
  StyledImage,
  TimeNews,
} from './styled'

type Props = {
  data: any
}
const SmallNews: React.FC<Props> = ({ data }) => {
  return (
    <Link
      to={`${ROUTER_PATH.DETAIL_NEWS}/${data.id}`}
      style={{ color: 'black' }}
    >
      <FlexSmallNews>
        <div style={{ width: '95%', height: '100%' }}>
          <ImageNews>
            <StyledImage src={data.image_url || R.images.img_news2} alt=".." />
          </ImageNews>
          <OverviewNews>
            <span color="#8c8c8c">{data.title_banner}</span>
            <TimeNews>
              <CalenderIcon style={{ marginRight: '10px' }} />
              {moment(data.create_at).format('HH:mm DD/MM/YYYY')}
            </TimeNews>
          </OverviewNews>
        </div>
      </FlexSmallNews>
    </Link>
  )
}

export default SmallNews
