import Icon from '@ant-design/icons'

const VNPaySvg = () => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.335 5.59183L10.7022 7.22455L10.6215 7.30531L10.1018 7.82497L9.71559 8.21121L9.19593 8.73087L9.11517 8.81163L8.89045 9.03635L8.80969 9.11711L8.04424 9.87905L7.96348 9.9598C7.73525 10.188 7.48244 10.3776 7.21208 10.5251C7.07514 10.5988 6.9382 10.6656 6.79424 10.7182C6.47472 10.8411 6.14115 10.9113 5.80758 10.9324C5.60042 10.9465 5.39326 10.9394 5.1861 10.9149C4.74368 10.8622 4.30829 10.7217 3.91152 10.49C3.74298 10.3917 3.56742 10.2899 3.42345 10.1459C3.42345 10.1459 3.42345 10.1459 3.41994 10.1459C3.38834 10.1143 3.35674 10.0827 3.32514 10.0511L0.354635 7.08059L0.291433 7.01739C0.238764 6.96121 0.193118 6.89801 0.154494 6.83129C0.0561798 6.66627 0 6.47315 0 6.26598C0 6.23438 -3.27009e-09 6.19927 0.00351123 6.16767C0.00702247 6.11851 0.0140449 6.07287 0.0245786 6.02722C0.0280899 6.00966 0.0316011 5.99211 0.0386236 5.97455C0.0421348 5.96753 0.0421349 5.96051 0.0456461 5.95349C0.0877809 5.81304 0.158006 5.68312 0.252809 5.56374C0.277388 5.53214 0.305477 5.50405 0.333567 5.47245L0.386236 5.41978L3.54986 2.25264L5.46699 0.335507C5.89536 -0.0928635 6.57654 -0.11042 7.02598 0.279327L12.335 5.59183Z"
      fill="#005AA9"
    />
    <path
      d="M6.55651 12.7197C6.30019 12.7197 6.05089 12.6635 5.80862 12.5511L5.20117 11.9437L5.0537 11.7962L5.02912 11.7681L4.74471 11.4837L3.32617 10.0511C3.35777 10.0827 3.38937 10.1143 3.42098 10.146C3.56494 10.2899 3.7405 10.3917 3.91255 10.4901C4.30932 10.7218 4.74471 10.8622 5.18713 10.9149C5.39078 10.9395 5.60145 10.9465 5.80862 10.9325C6.14569 10.9114 6.47926 10.8412 6.79527 10.7183C6.93923 10.6621 7.07617 10.5989 7.21311 10.5252C7.48348 10.3777 7.73628 10.1881 7.96451 9.95985L8.04527 9.8791L8.81072 9.11716L8.89148 9.0364L9.1162 8.81168L9.19696 8.73092L9.71662 8.21126L10.1029 7.82502L10.6225 7.30536L10.7033 7.2246L13.9898 3.93809L15.3451 2.47039C15.3486 2.46688 15.3522 2.46337 15.3522 2.46337L15.4259 2.39314C15.8402 1.97882 16.5109 1.97882 16.9252 2.39314L17.6661 3.13401L17.6906 3.15859C17.3606 3.14104 17.0305 3.26042 16.7812 3.50269L14.2707 5.9746L14.2461 6.0062L9.87814 10.304L9.87112 10.2969L8.25946 11.877L8.25244 11.891C8.24892 11.8981 8.21381 11.9367 8.15412 11.9929C8.02772 12.1158 7.76086 12.351 7.40623 12.516C7.24471 12.5933 7.08319 12.6459 6.92168 12.6811C6.82687 12.6986 6.72856 12.7127 6.63024 12.7162H6.55651V12.7197Z"
      fill="#EC1C24"
    />
    <path
      d="M21.6889 8.65013L21.6081 8.73089L18.0478 12.3475L14.3083 16.1431C14.1889 16.2625 14.066 16.3713 13.9326 16.4661C13.9291 16.4697 13.9221 16.4732 13.9185 16.4802C13.901 16.4942 13.8834 16.5083 13.8624 16.5188C13.8588 16.5223 13.8588 16.5223 13.8553 16.5258C13.29 16.9296 12.5983 17.1649 11.8539 17.1649C10.934 17.1649 10.0983 16.8032 9.47683 16.2168C9.47683 16.2168 9.47683 16.2168 9.47331 16.2133C9.44522 16.1888 9.41714 16.1642 9.38905 16.1396L8.74649 15.497L6.9382 13.6888L6.33427 13.0848L6.25 13.0006C6.34831 13.0181 6.44663 13.0251 6.54846 13.0251H6.55197H6.63272C6.7486 13.0216 6.86447 13.0076 6.97683 12.983C7.16292 12.9444 7.34902 12.8812 7.5316 12.7934C7.92486 12.6073 8.2198 12.3475 8.36025 12.2105C8.41643 12.1579 8.45506 12.1122 8.47261 12.0911L9.86306 10.7253L9.87008 10.7323L14.6489 6.03075L14.6735 5.99915L16.9944 3.71685C17.3069 3.41137 17.7949 3.37274 18.1496 3.61502L18.7008 4.16628L21.6854 7.15083C21.7135 7.17892 21.7381 7.2035 21.7591 7.23159C22.1032 7.64943 22.0787 8.2639 21.6889 8.65013Z"
      fill="#EC1C24"
    />
    <path
      d="M10.1581 4.69643C10.0738 4.7807 9.93689 4.77719 9.84911 4.69292C8.44111 3.27438 6.14125 3.26385 4.72271 4.67186C3.30417 6.07986 3.29363 8.37972 4.70515 9.79826L5.80768 10.9113C5.8147 10.9183 5.82172 10.9289 5.82524 10.9359C5.81821 10.9359 5.8147 10.9359 5.80768 10.9359C5.60754 10.9499 5.40389 10.9429 5.20375 10.9219L4.39265 10.1037C2.81611 8.51666 2.82664 5.93941 4.41372 4.36287C6.00431 2.78632 8.57804 2.79686 10.1546 4.38393L10.1581 4.38744C10.2459 4.47523 10.2424 4.61216 10.1581 4.69643Z"
      fill="#009DDA"
    />
    <path
      d="M11.8434 6.08342L11.5379 6.3889L11.0007 5.84817C10.9164 5.7639 10.9164 5.62696 11.0007 5.54269C11.085 5.45842 11.2219 5.45842 11.3062 5.54269L11.8434 6.08342Z"
      fill="#009DDA"
    />
    <path
      d="M7.22184 10.5216C7.21833 10.5251 7.21482 10.5251 7.2113 10.5286C7.07788 10.6023 6.94445 10.6655 6.804 10.7182L5.30119 9.20484C4.36018 8.25681 4.22675 6.77506 4.98167 5.67605C5.04838 5.57773 5.18532 5.55315 5.28364 5.61987C5.38195 5.68658 5.40653 5.82352 5.33981 5.92183C4.70428 6.8488 4.81664 8.0988 5.61018 8.89936L7.22184 10.5216Z"
      fill="#009DDA"
    />
    <path
      d="M10.9364 6.98925L10.9294 6.99627L10.7046 7.22099L10.6309 7.29122L8.942 5.58827C8.31349 4.95625 7.40057 4.73504 6.55085 5.00891C6.43849 5.04754 6.3156 4.98434 6.27697 4.87198C6.23835 4.75962 6.30155 4.63672 6.41742 4.5981C7.42164 4.27156 8.50661 4.5349 9.24748 5.28279L10.9364 6.98925Z"
      fill="#009DDA"
    />
    <path
      d="M10.0303 7.89518L9.72482 8.20066L8.0324 6.4942C7.61807 6.07636 6.94392 6.07636 6.52608 6.48718C6.11175 6.9015 6.10824 7.57566 6.51906 7.9935L7.61105 9.09251C7.69532 9.17678 7.69532 9.31372 7.61105 9.39799C7.52678 9.48226 7.38984 9.48226 7.30557 9.39799L6.21358 8.29898C5.63423 7.7126 5.63774 6.76457 6.2206 6.1817C6.80698 5.59884 7.75852 5.60586 8.33788 6.18872L10.0303 7.89518Z"
      fill="#009DDA"
    />
    <path
      d="M9.12515 8.80113L9.11813 8.80815L8.89341 9.03287L8.81968 9.10661L7.12375 7.39663C7.03948 7.31236 7.03948 7.17543 7.12375 7.09116C7.20802 7.00689 7.34496 7.00689 7.42923 7.09116L9.12515 8.80113Z"
      fill="#009DDA"
    />
  </svg>
)

const VNPayIcon = (props: any) => <Icon component={VNPaySvg} {...props} />
export default VNPayIcon
