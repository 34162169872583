import { Col, Divider, Row, Select, Spin, Typography } from 'antd'
import R from 'assets'
import { formatPrice } from 'utils/ruleForm'
import { EnvironmentFilled, StarFilled } from '@ant-design/icons'
import {
  ModalStoreStyte,
  SelectStore,
  RecordStore,
  InputStyte,
} from '../styles'
import { useEffect, useState } from 'react'
import { getDistrict, getListStore, getProvince } from '../ApiProducts'
import { EmptyComponent } from 'common/components/Empty'
import { useSelector } from 'react-redux'
import { getDataCoordinates } from 'features/stores/StoreSlice'

type Props = {
  isModalVisible: any
  setIdStore: any
  idStore: any
  product_id: number
  petrol_store_id: number
  setIsModalVisible: any
  geoLocation: boolean
  dataLocation: any
}
type Params = {
  user_id?: number
  lat: any
  lng: any
  petrol_store_id?: any
  province?: number
  district?: number
}
export interface OptionItem {
  value: string | number
  text: string | number
}
const ModalStore: React.FC<Props> = ({
  isModalVisible,
  product_id,
  petrol_store_id,
  setIdStore,
  idStore,
  setIsModalVisible,
  geoLocation,
  dataLocation,
}: Props) => {
  const coordinates = useSelector(getDataCoordinates)
  const { Option } = Select
  const { Text } = Typography
  const [province, setProvince] = useState<any>()
  const [district, setDistrict] = useState<any>()
  const [valueDistrict, setValueDistrict] = useState<any>()
  const [valueProvince, setValueProvince] = useState<any>()
  const [datas, setDatas] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [choose, setChoose] = useState<any>()
  const userState: any = useSelector((state: any) => state.authReducer)
    ?.userInfo
  const [params, setParams] = useState<Params>({
    user_id: userState ? userState.id : undefined,
    lat: undefined,
    lng: undefined,
    petrol_store_id: petrol_store_id,
    province: undefined,
    district: undefined,
  })
  const handleOk = () => {
    setIsModalVisible(false)
    setIdStore(choose)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
    setParams({ ...params, province: undefined, district: undefined })
    setValueProvince(
      <div style={{ color: 'lightgray' }}>{R.strings().province_city}</div>
    )
  }

  useEffect(() => {
    if ((coordinates.location_lat && coordinates.location_long) || userState) {
      setParams({
        user_id: userState?.id,
        petrol_store_id: idStore ? idStore : undefined,
        lat: coordinates.location_lat,
        lng: coordinates.location_long,
      })
    }
  }, [coordinates, userState, idStore])

  useEffect(() => {
    getProvinces()
  }, [])

  useEffect(() => {
    if (province?.id) {
      getDistricts({ province_id: province.id })
    }
  }, [province?.id])

  useEffect(() => {
    listStore()
  }, [params])

  useEffect(() => {
    setParams({ ...params, lat: dataLocation.lat, lng: dataLocation.lng })
  }, [dataLocation, petrol_store_id, idStore])

  const getProvinces = async () => {
    try {
      const res = await getProvince()
      const data = res.data.map((item: any, index: number) => {
        return {
          text: item.name,
          value: item.id,
        }
      })
      setProvince(data)
    } catch (error) {}
  }

  const getDistricts = async (payload: any) => {
    try {
      const res = await getDistrict(payload)
      const data = res.data.map((item: any, index: number) => {
        return {
          text: item.name,
          value: item.id,
        }
      })
      setDistrict(data)
    } catch (error) {}
  }

  const listStore = async () => {
    setIsLoading(true)
    try {
      if (idStore) {
        const res = await getListStore(
          product_id,
          idStore,
          params,
          dataLocation
        )
        const data = res.data.map((item: any, index: number) => {
          return {
            petrol_store: item.petrol_store,
            distance: item.distance,
            price: item.price,
            min_price: item.min_price,
            petrol_store_star: +item.petrol_store_star.toFixed(1),
            petrol_store_id: item.petrol_store_id,
          }
        })
        setDatas(data)
        setIsLoading(false)
      } else {
        const res = await getListStore(
          product_id,
          petrol_store_id,
          params,
          dataLocation
        )
        const data = res.data.map((item: any, index: number) => {
          return {
            petrol_store: item.petrol_store,
            distance: item.distance,
            price: item.price,
            min_price: item.min_price,
            petrol_store_star: +item.petrol_store_star.toFixed(1),
            petrol_store_id: item.petrol_store_id,
          }
        })
        setDatas(data)
        setIsLoading(false)
      }
    } catch (error) {
      console.log('error: ', error)
    }
  }
  console.log('datas: ', datas)
  return (
    <ModalStoreStyte
      title={
        <div style={{ width: '100%', textAlign: 'center' }}>
          {R.strings().list_store}
          <Row justify="space-between" style={{ paddingTop: 10 }}>
            <SelectStore
              allowClear
              showSearch
              value={valueProvince}
              placeholder={R.strings().province_city}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value: any) => {
                if (value) {
                  getDistricts({ province_id: value })
                }
                setParams({
                  ...params,
                  province: value,
                })
                setValueDistrict(
                  <div style={{ color: 'lightgray' }}>
                    {R.strings().district}
                  </div>
                )
              }}
            >
              {province?.length >= 1 ? (
                province?.map((item: OptionItem) => (
                  <Option value={item.value}>{item.text}</Option>
                ))
              ) : (
                <></>
              )}
            </SelectStore>
            {params.province ? (
              <SelectStore
                allowClear
                showSearch
                placeholder={R.strings().district}
                value={valueDistrict}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(value: any) => {
                  setParams({
                    ...params,
                    district: value,
                  })
                  setValueDistrict(value)
                }}
              >
                {district?.map((item: OptionItem) => (
                  <Option value={item.value}>{item.text}</Option>
                ))}
              </SelectStore>
            ) : (
              <InputStyte placeholder={R.strings().district} disabled />
            )}
          </Row>
        </div>
      }
      footer={null}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Spin spinning={isLoading}>
        <div
          style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '50vh',
            borderRadius: 12,
            padding: '0 3px 0 0px',
          }}
        >
          {datas?.length < 1 ? (
            <>
              <EmptyComponent />
            </>
          ) : (
            datas?.map((item: any, index: number) => (
              <>
                <RecordStore
                  onClick={() => {
                    window.scrollTo(0, 0)
                    setIdStore(item.petrol_store_id)
                    setIsModalVisible(false)
                  }}
                >
                  <div style={{ display: 'flex', flexFlow: 'nowrap' }}>
                    <div>
                      <img src={R.images.icon_store_gas} alt="" />
                    </div>

                    <div
                      style={{
                        width: '100%',
                        padding: '0px 0px 0px 10px',
                        display: 'flex',
                        flexFlow: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ fontSize: 15, fontWeight: 500 }}>
                        {item?.petrol_store?.name}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexFlow: 'nowrap',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Row>
                          {!geoLocation || !item?.distance ? (
                            <></>
                          ) : (
                            <>
                              <Col>
                                <div style={{ fontSize: 14 }}>
                                  <EnvironmentFilled
                                    style={{
                                      color: '#F26522',
                                      paddingRight: 5,
                                    }}
                                  />
                                  {item?.distance} km
                                </div>
                              </Col>
                              &nbsp; &nbsp;
                            </>
                          )}

                          <Col>
                            <div style={{ fontSize: 14 }}>
                              <StarFilled
                                style={{ color: '#FFC53D', paddingRight: 5 }}
                              />
                              {item?.petrol_store_star}
                            </div>
                          </Col>
                        </Row>
                        <Col>
                          <div
                            style={{
                              fontSize: 15,
                              fontWeight: 500,
                              color: '#F26522',
                            }}
                          >
                            {item?.min_price ? (
                              <>
                                <Text
                                  style={{ fontSize: 14, color: 'gray' }}
                                  delete
                                >
                                  {formatPrice(item?.price)}đ
                                </Text>
                                &nbsp;
                                {formatPrice(item?.min_price)}đ
                              </>
                            ) : (
                              formatPrice(item?.price) + 'đ'
                            )}
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                  <Divider style={{ margin: '10px 0 10px 0' }} />
                </RecordStore>
              </>
            ))
          )}
        </div>
      </Spin>
    </ModalStoreStyte>
  )
}

export default ModalStore
