import { AutoComplete, Button, Checkbox, Input, Radio, Switch, Tag } from 'antd'
import styled from 'styled-components'

const WarpPayment = styled.div`
  margin: 20px 0;
`

const PaymentMethodBlock = styled.div`
  width: 97%;
  background: #ffffff;
  border-radius: 12px;
  padding: 12px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const TitleBlock = styled.div`
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  padding-bottom: 10px;
`

const RadioPayment = styled(Radio.Group)`
  border-top: 1px solid #f2f2f2;
  padding-top: 10px;
  width: 100%;
`
const PaymentMethodItem = styled.div`
  margin: 8px 0 15px 0;
  padding: 8px 8px;
  height: 90px;
  border: 1px solid #ced4da;
  border-radius: 12px;
  display: flex;
  align-items: center;
  &&.selected {
    border: 1px solid #f26522;
  }
`
const WrapCheckbox = styled.div`
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContentMethod = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
`

const RadioStyled = styled(Radio)`
  .ant-radio-checked .ant-radio-inner {
    border-color: #f26522;
    ::after {
      background-color: #f26522;
    }
  }
  .ant-radio-wrapper {
    width: 100%;
  }
`
const ValueBlock = styled.div.attrs(
  (props: { color: string | undefined; colorSpan: string | undefined }) => ({
    color: props.color || '#595959',
    colorSpan: props.colorSpan || '#262626',
  })
)`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  color: ${props => props.color};
  span {
    color: ${props => props.colorSpan};
  }
`

const WrapButton = styled.div`
  display: flex;
  justify-content: center;
`

const ButtonOrder = styled(Button)`
  background: #f26522;
  border-radius: 12px;
  color: white;
  width: 90%;
  font-weight: 600;

  :active,
  :focus,
  :hover {
    border-color: #f26522;
    color: #f26522;
  }
`

const CheckBoxCircle = styled(Checkbox)`
  .ant-checkbox-inner {
    border-radius: 50%;
    border-color: #ced4da;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f26522;
    border-color: #f26522;
    padding: 1px 1px;
  }
`

const CheckboxSquare = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f26522;
    border-color: #f26522;
    padding: 1px 1px;
  }
`

const WhiteBlock = styled.div`
  padding: 12px;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 12px;
`
const RadioGroup = styled(Radio.Group)`
  display: flex;
  justify-content: space-evenly;
  border-top: 1px solid #f2f2f2;
  padding-top: 10px;
  @media only screen and (max-width: 480px) {
    display: block;
  }
`

const SelectionBlock = styled.div`
  width: 48%;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 10px;
  display: flex;
  align-items: center;
  &&.selected {
    border: 1px solid #f26522;
    background-color: #feecd9;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    margin-top: 10px;
  }
`
const WrapInputPayment = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`
const InputPayment = styled(Input)`
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 10px;
  width: 97%;
  @media only screen and (max-width: 480px) {
    width: 100%;
    line-height: 32px;
  }
  .ant-input-group-addon {
    border: none;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
`

const AutoCompleteStyled = styled(AutoComplete)`
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 10px;
  width: 97%;
  @media only screen and (max-width: 480px) {
    width: 100%;
    line-height: 32px;
  }
`

const InforStore = styled.div`
  border-top: 1px solid #f2f2f2;
  padding: 12px;
  display: flex;
`

const ImageSymbol = styled.div`
  aspect-ratio: 1/1;
  width: 80px;
  margin-right: 12px;
  @media only screen and (max-width: 480px) {
    display: none;
  }
`
const ProductInfor = styled.div`
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-weight: 600;
  #price-product-small {
    display: none;
  }
  @media only screen and (max-width: 480px) {
    #price-product-small {
      display: block;
      color: #f26522;
    }
    #price-product {
      display: none;
    }
  }
`
const WrapPoint = styled.div`
  display: flex;
  align-items: center;
`

const UsePoint = styled.div`
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
`

const TotalPriceBlock = styled.div`
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: flex-end;
`
const WrapPrice = styled.div`
  width: 50%;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`
const SwitchStyled = styled(Switch)`
  &&.ant-switch-checked {
    background-color: #f26522;
  }
`

const InsuranceInfor = styled.div`
  padding: 12px;
  border-top: 1px solid #f2f2f2;
`

const AddressBlock = styled.div`
  border-top: 1px solid #f2f2f2;
  padding: 12px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const AddressDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const ButtonAddress = styled(Button)`
  color: #f26522;
  border: 1px solid #f26522;
  border-radius: 12px;
`

const AddAddressBlock = styled.div`
  color: #f26522;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`
const RadioGroupAddress = styled(Radio.Group)`
  border-top: 1px solid #f2f2f2;
  width: 100%;
`

const AddressItem = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  border-top: 1px solid #f2f2f2;
`

const ChangeAddressBlock = styled.div`
  padding: 10px;
  border-top: 1px solid #f2f2f2;
  margin-top: 10px;
`
const DefaultTag = styled(Tag)`
  margin-left: 5px;
  border-color: #f26522;
  color: #f26522;
`

const ButtonUpdateAddress = styled.div`
  color: #f26522;
  font-weight: 500;
  cursor: pointer;

  :hover {
    font-weight: bold;
  }
`

const SelectAddressBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  :hover {
    #address {
      color: #f26522;
    }
  }
`

const BlockInputUpdate = styled.div`
  padding: 7px 0;
  font-weight: 500;
`

export {
  WarpPayment,
  PaymentMethodBlock,
  TitleBlock,
  PaymentMethodItem,
  ValueBlock,
  WrapButton,
  WrapCheckbox,
  ContentMethod,
  CheckBoxCircle,
  WhiteBlock,
  RadioGroup,
  SelectionBlock,
  CheckboxSquare,
  InputPayment,
  WrapInputPayment,
  RadioStyled,
  InforStore,
  ImageSymbol,
  ProductInfor,
  WrapPoint,
  UsePoint,
  TotalPriceBlock,
  SwitchStyled,
  InsuranceInfor,
  AddressBlock,
  AddressDetail,
  ButtonAddress,
  ButtonOrder,
  RadioPayment,
  WrapPrice,
  AddAddressBlock,
  RadioGroupAddress,
  AddressItem,
  ChangeAddressBlock,
  DefaultTag,
  ButtonUpdateAddress,
  SelectAddressBlock,
  BlockInputUpdate,
  AutoCompleteStyled,
}
