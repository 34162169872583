import Icon from '@ant-design/icons'

const TranferSvg = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 1.31579V3.27211C20 3.93579 19.4621 4.47368 18.7984 4.47368H15.2632V2.36842H8.68421H7.10526H4.73684V4.47368H1.20158C0.537895 4.47368 0 3.93579 0 3.27211V1.31579V1.20158C0 0.537895 0.537895 0 1.20158 0H18.7984C19.4621 0 20 0.537895 20 1.20158V1.31579Z"
      fill="#414440"
    />
    <path
      d="M8.68564 2.36841H7.1067H4.73828V4.47367V15.7721C4.73828 16.5084 5.33512 17.1053 6.07144 17.1053H7.1067H8.68564H13.9314C14.6677 17.1053 15.2646 16.5084 15.2646 15.7721V4.47367V2.36841H8.68564Z"
      fill="#D7DEED"
    />
    <path
      d="M7.10547 2.36841H8.68441V17.1053H7.10547V2.36841Z"
      fill="#313A52"
    />
    <path
      d="M16.054 2.63153H3.94871C3.80339 2.63153 3.68555 2.51369 3.68555 2.36838C3.68555 2.22306 3.80339 2.10522 3.94871 2.10522H16.054C16.1994 2.10522 16.3171 2.22306 16.3171 2.36838C16.3171 2.51369 16.1994 2.63153 16.054 2.63153Z"
      fill="#474D68"
    />
    <path
      d="M20.0002 1.20156C20.0002 1.15108 19.9967 1.10145 19.9906 1.05261H17.3686C17.2233 1.05261 17.1055 1.17045 17.1055 1.31577C17.1055 1.46108 17.2233 1.57892 17.3686 1.57892H20.0002V1.31577V1.20156Z"
      fill="#2D302C"
    />
    <path
      d="M2.89474 1.31577C2.89474 1.17045 2.7769 1.05261 2.63158 1.05261H0.0095263C0.00347367 1.10145 0 1.15108 0 1.20156V1.31577V1.57892H2.63158C2.7769 1.57892 2.89474 1.46108 2.89474 1.31577Z"
      fill="#2D302C"
    />
  </svg>
)

const TranferIcon = (props: any) => <Icon component={TranferSvg} {...props} />
export default TranferIcon
