import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import StoreList from './components/StoreList'
import { getListStore } from './ApiStore'
import MapStore from './components/MapStore'
import { notificationError } from 'utils/notification'
import R from 'assets'
import { ButtonGoogleMap } from './styled'
import { getDataCoordinates, setDataCoordinates } from './StoreSlice'
import { useDispatch, useSelector } from 'react-redux'

type TParams = {
  page: number
  limit: number
  search?: string
  distance?: number
  lat?: number
  lng?: number
  user_id?: number
}

const StoreScreen: React.FC = () => {
  const dispatch = useDispatch()
  const coordinates = useSelector(getDataCoordinates)
  const userState: any = useSelector((state: any) => state.authReducer)
    ?.userInfo
  const [listStore, setListStore] = useState<Array<any>>([])
  const [active, setActive] = useState<any>()
  const [params, setParams] = useState<TParams>({
    page: 1,
    limit: 20,
    search: undefined,
    distance: 5,
    lat: undefined,
    lng: undefined,
    user_id: userState?.id ? userState?.id : undefined,
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [defaultProps, setdefaultops] = useState({
    center: {
      lat: 21.0031,
      lng: 105.853,
    },
    zoom: 18,
  })
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [dataMarker, setDataMarker] = useState<any>({})
  const [geoLocation, setGeoLocation] = useState<boolean>(false)

  useEffect(() => {
    getLocation()
  }, [])

  useEffect(() => {
    if (coordinates.location_lat && coordinates.location_long) {
      setParams({
        ...params,
        lat: coordinates.location_lat,
        lng: coordinates.location_long,
      })
    }
  }, [coordinates])

  useEffect(() => {
    getDataSource()
  }, [params])

  const getDataSource = async () => {
    try {
      setIsLoading(true)
      const res = await getListStore(params)
      setListStore(res.data)
      setActive(res?.data[0]?.id)
      setDataMarker(res.data[0])
      setdefaultops({
        ...defaultProps,
        center: {
          lat: res.data[0].location_lat,
          lng: res.data[0].location_long,
        },
      })

      if (res.data.length < params.limit) {
        setLoadingMore(false)
      } else {
        setLoadingMore(true)
      }
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  const getMoreData = async () => {
    try {
      setIsLoading(true)
      const res = await getListStore({ ...params, page: params.page + 1 })
      setListStore(listStore.concat(res.data))

      setdefaultops({
        ...defaultProps,
        center: {
          lat: res.data[0].location_lat,
          lng: res.data[0].location_long,
        },
      })
      setDataMarker(res.data[0])

      if (res.data.length < params.limit) {
        setLoadingMore(false)
      } else {
        setLoadingMore(true)
      }
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  const getLocation = () => {
    if (!navigator.geolocation) {
      notificationError('Geolocation is not supported by this browser.')
      setGeoLocation(false)
    } else {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          setGeoLocation(true)
          dispatch(
            setDataCoordinates({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
          )
        },
        (error: any) => {
          notificationError(R.strings().warning_geolocation)
        }
      )
    }
  }

  const onChooseLocation = (data: any) => {
    setdefaultops({
      ...defaultProps,
      center: {
        lat: data.location_lat,
        lng: data.location_long,
      },
    })
    setDataMarker(data)
  }

  return (
    <Row
      style={{
        backgroundColor: 'white',
        height: '80vh',
        margin: '20px 0',
        padding: '20px',
        borderRadius: '12px',
      }}
    >
      <Col md={7} xs={24}>
        <StoreList
          active={active}
          setActive={setActive}
          onChooseLocation={onChooseLocation}
          listStore={listStore}
          params={params}
          setParams={setParams}
          isLoading={isLoading}
          loadingMore={loadingMore}
          getMoreData={getMoreData}
          geoLocation={geoLocation}
        />
      </Col>
      <Col md={17} xs={0}>
        <MapStore defaultProps={defaultProps} dataMarker={dataMarker} />
        <ButtonGoogleMap
          onClick={() => {
            window.open(
              `https://www.google.com/maps/place/?q=${dataMarker.name}/@${dataMarker?.location_lat},${dataMarker?.location_long}`
            )
          }}
        >
          Go to GoogleMap
        </ButtonGoogleMap>
      </Col>
    </Row>
  )
}
export default StoreScreen
