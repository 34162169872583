import { Col, Divider, Drawer, Modal, Row } from 'antd'
import styled from 'styled-components'

const StyledContainer = styled('div')`
  position: relative;
  width: 100%;
  /* max-width: 1200px; */
  background-color: #f5f5f5;

  display: flex;
  justify-content: center;

  @media only screen and (max-width: 1024px) {
    padding: 0 20px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 15px;
  }

  @media only screen and (max-width: 480px) {
    padding: 0 10px;
  }
`

const ContainerHeader = styled('div')`
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 99;
`

const ContainerFooter = styled('div')`
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
`
const FooterBlock = styled.footer`
  background-color: white;
  width: 100%;
  max-width: 1600px;
  /* padding: 15px; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;

  @media only screen and (max-width: 768px) {
    /* display: unset; */
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
`

const ColFooter = styled(Col)`
  flex-direction: column;
  display: flex;
  margin-top: 8px;
  padding: 6px;
  cursor: pointer;
  span {
    margin-top: 6px;
  }
  span:hover {
    font-weight: 500;
    text-decoration: underline;
  }

  @media only screen and (max-width: 768px) {
    /* display: unset; */
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
  }
`

const TitleStyles = styled.div`
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 5px;
`
const TextStyles = styled.div`
  font-size: 16px;
  padding-bottom: 10px;
`

const ImageDownload = styled.div`
  margin-top: 10px;
`

const HeaderBlock = styled.div`
  width: 100%;
  /* max-width: 1200px; */
  min-height: 60px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 80px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  z-index: 12;

  @media screen and (max-width: 1024px) {
    padding: 10px 20px;
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin: 0;
  }

  ul li {
    margin: 0px 5px;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ul li .category-item {
    color: #595959;
    text-decoration: none;
    padding: 0px 7px;
  }

  ul li .category-item:hover,
  ul li .active {
    color: #f26522;
    cursor: pointer;
  }

  ul li .active {
    color: #f26522;
    font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    ul {
      position: fixed;
      top: 10vh;
      left: -100%;
      background-color: #111;
      opacity: 0.9;
      height: 100vh;
      width: 100%;
      display: block;
      text-align: center;
      transition: all 0.3s ease;
      z-index: 1;
    }
    ul li {
      margin: 10px 0;
    }
  }
`

const LogoHeader = styled.div`
  display: flex;
  cursor: pointer;
`

const SearchArea = styled.div`
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  margin-right: 10px;
  width: 300px;
  padding: 3px 14px;
  @media only screen and (max-width: 768px) {
    width: auto;
    &&#big-width {
      display: none;
    }
  }
`

const HeaderItem = styled.li`
  cursor: pointer;
  :hover {
    color: #f26522;
  }
  @media only screen and (min-width: 768px) {
    display: none;
  }
`

const WrapAction = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const LoginButton = styled.button`
  height: 50%;
  width: max-content;
  background: #f47458;
  background-color: #f26522;
  border-radius: 12px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  padding: 4px 20px;
  font-weight: 600;
`

const MenuButton = styled.div`
  display: flex;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`

const StyledDrawer = styled(Drawer)`
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin: 0;
  }

  ul li {
    margin: 0px 5px;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ul li .category-item {
    color: #595959;
    text-decoration: none;
    padding: 0px 7px;
  }

  ul li .category-item:hover,
  ul li .active {
    color: #f26522;
    cursor: pointer;
  }

  ul li .active {
    color: #f26522;
    font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    ul {
      display: block;
      padding: 0 10px;
    }
    ul li {
      justify-content: center;
    }
  }
`
const ContainerNotify = styled.div`
  background-color: white;
  border: 0.5px solid lightgray;
  border-radius: 12px;
  width: 270px;
`
const RecordNotify = styled.div`
  display: flex;
  flex-flow: nowrap;
  padding: 0 7px 0 7px;
  cursor: pointer;
  :hover {
    background-color: #f4cfa8;
  }
`
const ModalNotify = styled(Modal)`
  .ant-modal-header {
    background-color: #f26522;
    padding: 12px 45px 16px 10px;
  }
  .ant-modal-title {
    color: white;
  }
  .ant-modal-close {
    color: white;
  }
  .ant-modal-body {
    padding: 10px;
  }
`
export {
  ModalNotify,
  RecordNotify,
  ContainerNotify,
  StyledContainer,
  ContainerHeader,
  ContainerFooter,
  FooterBlock,
  TitleStyles,
  TextStyles,
  ImageDownload,
  LogoHeader,
  SearchArea,
  HeaderItem,
  LoginButton,
  MenuButton,
  WrapAction,
  StyledDrawer,
  HeaderBlock,
  ColFooter,
}
