import AuthReducer from './auth/AuthSlice'
import CartReducer from './cart/CartSlice'
import StoreReducer from './stores/StoreSlice'
const rootReducer = {
  authReducer: AuthReducer,
  cartReducer: CartReducer,
  storeReducer: StoreReducer,
}

export default rootReducer
