import R from 'assets'
import { ROUTER_PATH } from 'common/config'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { CalenderWhiteIcon } from '../Icons'
import {
  ImageBigNews,
  OverviewBigNews,
  StyledImage,
  TimeBigNews,
} from './styled'

type Props = {
  data: any
}
const BigNews: React.FC<Props> = ({ data }) => {
  return (
    <ImageBigNews>
      <Link
        to={`${ROUTER_PATH.DETAIL_NEWS}/${data.id}`}
        style={{ color: 'black' }}
      >
        <StyledImage src={data.image_url || R.images.img_news2} alt=".." />
        <OverviewBigNews>
          {data.title_banner}
          <TimeBigNews>
            <CalenderWhiteIcon style={{ marginRight: '10px' }} />
            {moment(data.create_at).format('HH:mm DD/MM/YYYY')}
          </TimeBigNews>
        </OverviewBigNews>
      </Link>
    </ImageBigNews>
  )
}

export default BigNews
