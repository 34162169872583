import { render } from '@testing-library/react'
import {
  Breadcrumb,
  Button,
  Checkbox,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Switch,
} from 'antd'
import Slider from 'react-slick'
import styled from 'styled-components'

const BreadcrumbStyle = styled(Breadcrumb)`
  /* margin: 20px 0px 20px 0px; */
  padding: 20px 10px 15px 10px;
  font-weight: 500;
`

const AboveBlock = styled(Row)`
  background-color: white;
  width: 100%;
  padding: 13px 24px 7px 24px;
  margin: 0 0 15px 0;
  height: fit-content;
  border-radius: 12px;
  @media only screen and (max-width: 768px) {
    padding: 10px 0px 10px 0px;
  }
`
const FilterBlock = styled(Row)`
  /* height: 44px; */
  width: 80%;
  @media only screen and (max-width: 768px) {
    width: 100%;
    /* padding: 0 15px 0 15px; */
    background-color: white;
  }
`
const ButtonFilter = styled.button`
  @media only screen and (max-width: 480px) {
    /* margin: 0px 5px 0px 5px; */
    font-size: 14px;
    /* width: 50%; */
  }
`
const ButtonSale = styled.button`
  background-color: white;
  height: 44px;
  width: 114px;
  border-style: solid;
  border-color: #f26522;
  color: #f26522;
  border-radius: 12px;
  margin: 0px 16px 0px 0px;
  font-size: 16px;
  border-width: 0.5px;
  cursor: pointer;
  @media only screen and (max-width: 480px) {
    margin: 0px;
    width: 85px;
    font-size: 14px;
  }
`
const SelectFilter = styled(Select)`
  .ant-select-selector {
    height: 44px !important;
    border-radius: 12px !important;
    padding-top: 6px !important;
    border-color: #f26522 !important;
    box-shadow: none !important;
  }
  height: 44px;
  width: 145px;
  font-size: 16px;
  color: #f26522;
  /* margin: 0 0 0 15px; */
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    margin: 0px 15px 0px 0px;
  }
  @media only screen and (max-width: 390px) {
    margin: 15px 15px 0px 0px;
  }
`
const TextFilter = styled.span`
  padding: 13px 0px 13px 0px;
  font-size: 16px;
  font-weight: 500;
  @media only screen and (max-width: 768px) {
    margin: 0px 24px 0px 24px;
  }
`
const TextFilterOil = styled.span`
  padding: 13px 0px 13px 0px;
  font-size: 16px;
  font-weight: 500;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const TextSort = styled.span`
  padding: 13px 0px 13px 0px;
  font-size: 16px;
  font-weight: 500;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const TitleBlock = styled.div`
  padding: 0px 0px 30px 0px;
  font-size: 22px;
  font-weight: 600;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`
const InforDetailBlock = styled.div`
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  margin-top: 15px;
  @media only screen and (max-width: 768px) {
    display: unset;
  }
`
const ImageBlock = styled.div`
  background-color: white;
  display: flex;
  flex-flow: nowrap;
  /* justify-content: space-between; */
  border-radius: 12px;
  padding: 14px;
  margin-top: 15px;
  @media only screen and (max-width: 768px) {
    display: unset;
    width: 100%;
    background-color: unset;
  }
`
const ImageProduct = styled.div`
  width: 40%;
  margin: 0 25px 0 0;
  aspect-ratio: 1/0.6;
  background-color: white;
  @media only screen and (max-width: 768px) {
    border-radius: 12px 12px 0 0;
    width: 100%;
    margin: 0px;
    padding-top: 10px;
  }
`

const ImageInsurance = styled.div`
  width: 60%;
  margin: 0 25px 0 0;
  aspect-ratio: 1/0.6;
  background-color: white;
  @media only screen and (max-width: 768px) {
    border-radius: 12px 12px 0 0;
    width: 100%;
    margin: 0px;
    padding-top: 10px;
  }
`
const InforImage = styled.div`
  width: 60%;
  /* height: 100%; */
  display: flex;
  flex-flow: column;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 0 0 12px 12px;
  }
`
const DesctiptionBlock = styled.div`
  background-color: white;
  height: 550px;
  border-radius: 12px;
  padding: 14px;
  width: 60%;
  overflow-y: hidden;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
const StoreBlock = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 14px;
  width: 39%;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 20px 0 0px 0;
  }
`
const NameStore = styled.div`
  width: fit-content;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  :hover {
    color: #f26522;
  }
`
const NameProduct = styled.div`
  margin: 0 0 10px 0;
  font-size: 24px;
  font-weight: 500;
`
const ButtonCart = styled.button`
  width: 35%;
  height: 40px;
  border-radius: 12px;
  border-style: solid;
  font-size: 16px;
  font-weight: 600;
  color: #f26522;
  border-color: #f26522;
  background-color: #f8f9fa;
  :active {
    background-color: lightgray;
  }
  @media only screen and (max-width: 864px) {
    width: 45%;
    font-size: 2vw;
  }
  @media only screen and (max-width: 864px) {
    font-size: 12px;
  }
`
const ButtonSell = styled.button`
  width: 35%;
  height: 40px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 3%;
  border-style: none;
  background-color: #f26522;
  color: white;
  :active {
    background-color: #ad2102;
  }
  @media only screen and (max-width: 864px) {
    width: 45%;
    font-size: 2vw;
  }
  @media only screen and (max-width: 864px) {
    font-size: 12px;
  }
`
const ButtonSellInsurance = styled.button`
  width: 80%;
  height: 40px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 3%;
  border-style: none;
  background-color: #f26522;
  color: white;
  :active {
    background-color: #ad2102;
  }
  @media only screen and (max-width: 864px) {
    font-size: 2vw;
  }
  @media only screen and (max-width: 864px) {
    font-size: 12px;
  }
`
const ButtonMore = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 12px;
  border-style: solid;
  font-size: 16px;
  font-weight: 600;
  color: #f26522;
  border-color: #f26522;
  background-color: white;
  :active {
    background-color: #f8f9fa;
  }
`
const ModalStoreStyte = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-header {
    border-radius: 12px;
  }
  .ant-modal-body {
    padding: 10px 0px 0 0px;
  }
`
const SelectStore = styled(Select)`
  .ant-select-selector {
    height: 35px !important;
    border-radius: 12px !important;
    /* padding-top: 6px !important; */
  }
  height: 35px;
  width: 47%;
  font-size: 16px;
`
const InputStyte = styled(Input)`
  height: 35px;
  width: 47%;
  font-size: 16px;
  border-radius: 12px;
  padding: 0 0 0 10%;
  font-weight: 500;
  .ant-input[disabled] {
    text-align: center !important;
  }
  .ant-input-disabled {
    text-align: center !important;
  }
`
const CheckboxStyle = styled(Checkbox)`
  padding: 0 10px 0 0;
  /* width: 100%; */
  display: flex;
  align-items: center;
  /* border-radius: 1rem;
   */
  .ant-checkbox-inner {
    border-radius: 1rem;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    color: #f26522;
    background-color: #f26522;
    border-color: #f26522;
  }
`
const ButtonStore = styled.button`
  width: 90%;
  height: 40px;
  border-radius: 10px;
  font-size: 16px;

  border-style: none;
  background-color: #f26522;
  color: white;
  :active {
    background-color: #ad2102;
  }
`
const SliderProduct = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
`
const SliderBlock = styled.div`
  width: 85%;
  height: 100%;
  padding-top: 10px;
  margin: auto;
`
const SliderMain = styled.img`
  object-fit: cover;
  aspect-ratio: 1/1;
`
const SliderThumb = styled.img`
  object-fit: contain;
  width: 60px !important;
  aspect-ratio: 1/1;
  &&.active {
    border: 3px solid #f26522;
  }
`
const SliderThumbInsurance = styled.img`
  object-fit: contain;
  width: 120px !important;
  aspect-ratio: 2/1;
  &&.active {
    border: 3px solid #f26522;
  }
`
const SliderMainInsurance = styled.img`
  object-fit: cover;
  aspect-ratio: 2/1;
`
const SliderStyle = styled(Slider)`
  margin: 0 30px 0 30px;
  .slick-prev:before {
    content: '❮';
    color: black;
    font-size: 30px;
    z-index: 10;
  }
  .slick-next:before {
    content: '❯';
    color: black;
    font-size: 30px;
  }
`
const SliderThumbStyle = styled(Slider)`
  .slick-track {
    /* text-align: center; */
    display: flex;
    justify-content: center;
  }
  .slick-slide {
    width: unset !important;
  }
`
const ArrowPrev = styled.button`
  padding: 0px;
  margin: 0px;
  font-size: 32px;
  color: gray;
`
const RecordStore = styled.div`
  width: 100%;
  padding: 7px 10px 0px 5px;
  margin: 0 0 0 7px;
  display: flex;
  border-radius: 10px;
  flex-flow: column;
  :hover {
    background-color: #fff6ea;
    cursor: pointer;
  }
`
const RecordGas = styled(Row)`
  width: 100%;
  padding: 15px 10px 15px 10px;
  border-radius: 10px;
  background-color: #f8f9fa;
  margin: 0 0 10px 0;
  :hover {
    background-color: #fff6ea;
    cursor: pointer;
  }
  :visited {
    background-color: #fff6ea;
  }
`
const GasLeft = styled.div`
  width: 60%;
  background-color: white;
  border-radius: 12px;
  padding: 15px 5px 15px 15px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0 0 20px 0;
  }
`
const GasRight = styled.div`
  width: 39%;
  background-color: white;
  border-radius: 12px;
  padding: 15px 5px 15px 5px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
const SearchStore = styled(Input)`
  border-radius: 12px;
  width: 90%;
`
const GasContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  margin: 0 0 70px 0;
  border-radius: 12px;
  @media only screen and (max-width: 768px) {
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 20px 0;
  }
`
const MapStore = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  background-color: lightgreen;
  border-radius: 12px;
  @media only screen and (max-width: 768px) {
    aspect-ratio: 1/0.5;
  }
`
const MapBlock = styled.div`
  width: 45%;
  margin: 3%;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`
const ValueBuy = styled.div`
  width: 55%;
  margin: 3% 3% 3% 0;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`
const RadioItem = styled(Radio.Button)`
  width: 23%;
  height: 70px;
  border: solid 1px #adb5bd;
  border-right-color: #adb5bd;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding: 3% 0 0 0;
  .ant-radio-button-wrapper-checked {
    border-right-color: #f26522 !important;
  }
  :checked {
    border-right-color: #f26522 !important;
  }
  :hover {
    border-right-color: #f26522;
  }
`
const InputLit = styled(Input)`
  border: 0px;
  border-radius: 0px;
  border-bottom: 1px solid #adb5bd;
  margin-bottom: 15px;
  :focus {
    border-color: #f26522;
    box-shadow: none;
  }
`
const ButtonBuyGas = styled(Button)`
  background: #f26522;
  width: 100%;
  max-width: 470px;
  border-radius: 12px;
  border: none;
  :focus {
    background: #f26522;
  }
  :active {
    background: #f26522;
  }
  :hover {
    background: #f99566;
  }
`
const SwitchStyled = styled(Switch)`
  background-color: lightgray;
`
const RadioStyled = styled(Radio.Group)`
  width: 100%;
  padding: 0 0 15px 0;
  .ant-radio-button-wrapper-checked {
    border-right-color: #f26522 !important;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0px;
  }
  label.ant-radio-button-wrapper:hover {
    /* width: 0px; */
    border-color: #f26522;
    color: #f26522;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: #f26522;
    color: #f26522;
  }
  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper {
    border-right-color: unset !important;
  }
`
const OkButton = styled.button`
  border: 1px solid;
  border-radius: 7px;
  background-color: unset;
  cursor: pointer;
  font-weight: 600;
  margin: 10px 0 0 0;
  padding: 0px 10px 0 10px;
  :hover {
    color: #f26522;
  }
`
const Cancellation = styled.div`
  display: flex;
  flex-flow: nowrap;
  @media only screen and (max-width: 435px) {
    display: none;
  }
`
const CancellationMobile = styled.div`
  display: none;
  @media only screen and (max-width: 435px) {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    margin: 5px 15px 0px 15px;
  }
`
const RowAddress = styled.div`
  color: #595959;
  width: fit-content;
  cursor: pointer;
  :hover {
    color: #f26522;
  }
`
const RadioChange = styled(Radio)`
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #f26522;
    :hover {
      border-color: #f26522 !important;
    }
  }
  .ant-radio-inner {
    border-color: #f26522;
    :after {
      background-color: #f26522;
    }
    :hover {
      border-color: #f26522 !important;
    }
    :focus {
      border-color: #f26522 !important;
    }
  }
  .ant-radio-input {
    :hover {
      border-color: #f26522 !important;
    }
    :focus {
      border-color: #f26522 !important;
    }
  }
  .ant-radio {
    border-color: #f26522 !important;
    :hover {
      border-color: #f26522 !important;
    }
    :focus {
      border-color: #f26522 !important;
    }
  }
`
const ModalImg = styled(Modal)``

export const ImageStyled = styled(Image)`
  object-fit: cover;
  height: 100px;
  width: 100px;
  border-radius: 8px;
  border: 1px dashed #ccc;
  padding: 5px;
`

export const ColMoreImg = styled.div`
  object-fit: cover;
  height: 100px;
  width: 100px;
  border-radius: 8px;
  border: 1px dashed #ccc;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);

    cursor: pointer;
  }
`

export {
  RowAddress,
  RadioChange,
  ModalImg,
  SliderThumbInsurance,
  CancellationMobile,
  Cancellation,
  OkButton,
  ButtonBuyGas,
  RadioStyled,
  SwitchStyled,
  InputLit,
  MapStore,
  RadioItem,
  ValueBuy,
  BreadcrumbStyle,
  AboveBlock,
  TextFilter,
  TextSort,
  ButtonFilter,
  ButtonSale,
  SelectFilter,
  TitleBlock,
  DesctiptionBlock,
  StoreBlock,
  InforDetailBlock,
  ImageBlock,
  ImageProduct,
  InforImage,
  NameProduct,
  NameStore,
  ButtonCart,
  ButtonSell,
  ButtonMore,
  ModalStoreStyte,
  SelectStore,
  CheckboxStyle,
  ButtonStore,
  SliderProduct,
  SliderBlock,
  SliderMain,
  SliderThumb,
  SliderMainInsurance,
  SliderStyle,
  SliderThumbStyle,
  ArrowPrev,
  TextFilterOil,
  ButtonSellInsurance,
  RecordStore,
  GasRight,
  GasLeft,
  RecordGas,
  SearchStore,
  GasContainer,
  MapBlock,
  InputStyte,
  FilterBlock,
  ImageInsurance,
}
