import { Row } from 'antd'
import React from 'react'
import { ContentItem, SmallItem } from '../styled'

const PaymentPolicy = () => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '20px',
        textAlign: 'justify',
        listStyleType: ' upper-roman',
        margin: '20px 0',
      }}
    >
      <Row justify="center" style={{ marginBottom: '10px', width: '100%' }}>
        <b style={{ fontSize: '20px' }}>CHÍNH SÁCH THANH TOÁN</b>
      </Row>
      <SmallItem>
        <ContentItem>
          <p>- Tất cả các khoản thanh toán phải bằng Việt Nam Đồng (Đồng)</p>
          <p>
            - Thanh toán bằng tiền mặt: tại các cửa hàng bán lẻ xăng dầu của
            công ty
          </p>
          <p>
            - Thanh toán bằng hình thức chuyển khoản: 111603603999 Vietinbank CN
            Nam Thăng Long – Công ty Cổ phần Hệ sinh thái Công nghệ YoYo
          </p>
          <p>Nội dung chuyển khoản: copy sẵn cú pháp trong đơn hàng</p>
          <p>
            - Thanh toán qua VNPay hoặc Vietinpay: Thực hiện theo cú pháp và quy
            trình trong hệ thống. Sau khi thanh toán thành công, hệ thống sẽ tự
            động cập nhật trạng thái đơn hàng đã thanh toán thành công và chuyển
            bước.
          </p>
          <p>
            - Sau khi nhận được chuyển khoản chúng tôi sẽ thực hiện xác nhận
            trên hệ thống.
          </p>
          <p>
            - Công ty khi nhận được thanh toán sẽ xuất hóa đơn hoặc chứng từ gửi
            qua mail cho khách hàng trong quá trình mua hàng.
          </p>
        </ContentItem>
      </SmallItem>
    </div>
  )
}

export default PaymentPolicy
