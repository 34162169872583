import Icon from '@ant-design/icons'

const YoYoCoinSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="#F26522"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5345 17.6027H9.65406V16.9103L10.9641 16.6659C11.0818 16.6478 11.1723 16.6048 11.2356 16.537C11.299 16.4691 11.3307 16.3763 11.3307 16.2587V13.5367L8.28291 8.2286C8.23313 8.14262 8.1743 8.07474 8.10643 8.02497C8.03854 7.97519 7.95483 7.93899 7.85527 7.91636L7 7.70594V7H11.1474V7.70594L9.89843 7.95709L12.3828 12.4439L14.6771 7.95031L13.4078 7.70594V7H16.9035V7.70594L16.0686 7.896C15.9691 7.91862 15.8831 7.95482 15.8107 8.0046C15.7383 8.04986 15.6794 8.11774 15.6342 8.20825L12.8579 13.5435V16.2587C12.8579 16.3763 12.8874 16.4691 12.9462 16.537C13.0095 16.6048 13.1023 16.6478 13.2245 16.6659L14.5345 16.9103V17.6027Z"
      fill="#F26522"
    />
  </svg>
)

const YoYoCoinIcon = (props: any) => <Icon component={YoYoCoinSvg} {...props} />
export default YoYoCoinIcon
