import R from 'assets'
import React from 'react'
import { ContentItem, SmallItem } from './styled'
const ShoppingGuide: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '20px',
        listStyleType: ' upper-roman',
        textAlign: 'justify',
      }}
    >
      <b> QUY TRÌNH GIAO DỊCH</b>
      <SmallItem>
        <b>1. Quy trình Đăng kí tài khoản</b>
        <ContentItem>
          <p>
            Để sử dụng ứng dụng PYOYO, Khách hàng cần trang bị điện thoại di
            động chạy trên hệ điều hành Android hoặc IOS và có kết nối internet
            (wifi, 3G, 4G).
          </p>
          <b>Khách hàng có hai cách để truy cập vào sàn TMĐT YOYO:</b>
          <p style={{ textDecorationLine: 'underline' }}>
            Cách 1: Sử dụng trên ứng dụng di động YOYO
          </p>
          <p>
            <b>Bước 1:</b> Tải và cài đặt Ứng dụng PYOYO cho Thiết bị di động sử
            dụng hệ điều hành Android hoặc IOS phù hợp với phiên bản Thiết bị di
            động đang dùng.
          </p>
          <p>
            <b>Bước 2:</b> Sau khi đã tải và cài đặt thành công, khởi động Ứng
            dụng PYOYO từ màn hình chính Thiết bị di động.
          </p>
          <p>
            <b>Bước 3:</b> Trong lần đầu truy cập, Ứng dụng PYOYO sẽ yêu cầu
            Người dùng cho phép truy cập vào hệ thống Thiết bị di động của Người
            dùng. Người dùng ấn chọn “Cho phép” và tiếp tục lần lượt nhập các
            thông tin cần thiết để đăng ký Tài Khoản PYOYO như hình dưới. Việc
            đăng ký tài khoản là bắt buộc vì ứng dụng PYOYO cần xác thực chính
            xác họ tên và số điện thoại của Người dùng.
          </p>
          <i>Giao diện Màn hình đăng ký</i>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <img
              src={R.images.signup1}
              alt=""
              style={{ width: '240px', height: '570px' }}
            />

            <img
              src={R.images.signup2}
              alt=""
              style={{ width: '240px', height: '570px' }}
            />

            <img
              src={R.images.signup3}
              alt=""
              style={{ width: '240px', height: '570px' }}
            />
          </div>
          <p>
            <b>Bước 4: </b>Mã OTP sẽ được gửi qua tin nhắn SMS đến số điện thoại
            của Khách hàng, Khách hàng kích hoạt và đăng nhập vào trong ứng dụng
            PYOYO
          </p>
          <p>
            <b>Trường hợp người dùng quên mật khẩu</b>, hệ thống cung cấp tính
            năng hỗ trợ lấy lại mật khẩu qua email khách hàng đã đăng ký trên hệ
            thống
          </p>
          <i>Quên mật khẩu</i>
          <div>
            <img
              src={R.images.forgetPassword}
              alt=""
              style={{ width: '240px', height: '570px' }}
            />
          </div>
          <p>
            <span style={{ textDecorationLine: 'underline' }}> Cách 2: </span>
            Khách hàng trực tiếp truy cập website: http://pyoyo.vn để thực hiện
            đăng ký tài khoản như trên đồng thời mua hàng trực tiếp qua website.
          </p>
        </ContentItem>
      </SmallItem>

      <SmallItem>
        <b>2. Quy trình dành cho khách hàng cá nhân</b>
        <ContentItem>
          <div>
            <i>
              Khách hàng cá nhân chỉ được tìm kiếm, kiểm tra danh mục CH bán lẻ
              xăng dầu, cùng giá của các Cửa hàng xăng dầu đó.
            </i>
          </div>
          <p>
            <b>Bước 1:</b> Sau khi đã đăng nhập vào hệ thống PYOYO, màn hình
            chính Home hiện ra. Tại màn hình Home, Khách hàng có thể tìm kiếm
            Cửa hàng gần nhất bằng cách: Nhấp chọn Sản phẩm mình mong muốn. Hệ
            thống sẽ gợi ý danh sách Cửa hàng gần nhất theo bán kính.
          </p>
          <i> Tìm kiếm cửa hàng theo sản phẩm</i>
          <p>
            Khách hàng cũng có thể nhấp chọn nhanh Cửa hàng mà Khách hàng đã
            từng thực hiện giao dịch trước đây (Gợi ý cửa hàng đã đổ)
          </p>
          <i>Tìm Kiếm Cửa Hàng đã giao dịch gần đây</i>
          <p>
            Khi Khách hàng nhập địa điểm muốn tới, PYOYO sẽ chỉ dẫn đường đi tới
            địa điểm và gợi ý các Cửa hàng xung quanh trên quãng đường đó. Khách
            hàng có thể chọn Cửa hàng trên danh sách để thực hiện giao dịch.
          </p>
          <p>
            <b>Bước 2:</b> Sau khi tìm kiếm Cửa hàng bằng những gợi ý trên, hệ
            thống sẽ hiển thị cửa hàng gần nhất và các cửa hàng xung quanh.
            Người dùng chọn sản phẩm, số lượng hoặc giá trị sản phẩm trong Danh
            mục sản phẩm của Cửa hàng và ấn chọn <b>“Thêm vào giỏ” </b>từ màn
            hình mua nhanh.
          </p>
          <i>Thêm sản phẩm vào giỏ hàng từ màn hình mua nhanh</i>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <img
              alt=""
              src={R.images.buy_now_1}
              style={{ width: '240px', height: '570px' }}
            />
            <img
              alt=""
              src={R.images.buy_now_2}
              style={{ width: '240px', height: '570px' }}
            />
          </div>
          <p>
            <b>Bước 3:</b> Khách hàng rà soát lại các thông tin đặt hàng trước
            khi thanh toán. Nhập mã nếu có. Khi Khách hàng lựa chọn vào ô “Thanh
            toán” để thanh toán Đơn Hàng của mình có nghĩa là Khách hàng xác
            nhận đã rà soát thông tin Đơn Hàng và đồng ý với các Điều khoản và
            điều kiện giao dịch của từng tính năng tương ứng khi mua Sản phẩm
            trên ứng dụng YOYO.
          </p>
          <i>Xem thông tin giỏ hàng</i>
          <div>
            <img
              alt=""
              src={R.images.cart}
              style={{ width: '240px', height: '570px' }}
            />
          </div>
          <p>
            <b>Bước 4:</b> Sau khi kiểm tra thông tin chi tiết giỏ hàng, Khách
            hàng nhấn nút <b> Thanh toán</b> để chuyển sang màn hình Thanh toán.
            Tại màn hình này, Người dùng bổ sung thêm các thông tin: Hình thức
            thanh toán, Thông tin phương tiện, Xác nhận lấy hóa đơn.
          </p>
          <i>Giao diện trang Thanh toán</i>
          <div>
            <img
              alt=""
              src={R.images.payment}
              style={{ width: '240px', height: '570px' }}
            />
          </div>
          <p>
            <b>Bước 5:</b> Khách hàng sẽ đến nhận sản phẩm ở tại nơi Cửa hàng
            đăng ký theo quy định tại các chính sách của tính năng tương ứng.
            <p>
              Khi nhận hàng là các mặt hàng như: xăng, dầu, dầu mỡ nhờn, bảo
              hiểm. Khi khách hàng thanh toán, khách hàng cung cấp mã QR code
              cho nhân viên CHXD: mã QR được nhân viên CHXD quét bằng máy quét
              tại khu vực thu ngân và xác nhận đơn hàng đã hoàn thành.
            </p>
            <p>
              Trường hợp mua xăng dầu sáng (là các loại nhiên liệu động cơ), bảo
              hiểm hoặc dầu mỡ nhờn với số lượng dưới 5 sản phẩm hoặc dưới 10
              triệu đồng thì khách hàng cá nhân phải tới các cửa hàng xăng dầu
              đã đặt để được nhân viên bơm hàng vào phương tiện.
            </p>
            <p>
              Trường hợp các cá nhân mua các loại dầu mỡ nhờn (đã được đóng
              chai/can/hộp/thùng theo quy định của nhà sản xuất) với số lượng
              lớn hơn 5 sản phẩm hoặc lớn hơn 10 triệu đồng thì khách hàng có
              thể lựa chọn tới trực tiếp cửa hàng đã đặt để nhận hàng và thanh
              toán; hoặc lựa chọn hình thức giao hàng tận nơi. Khi đó cửa hàng
              xăng dầu sẽ sắp xếp nhân sự, phương tiện là xe ô tô bán tải để vận
              chuyển tới nơi cho khách hàng.
            </p>
          </p>
        </ContentItem>
      </SmallItem>

      <SmallItem>
        <b>3. Quy trình đối với khách hàng là tổ chức doanh nghiệp:</b>
        <ContentItem>
          {' '}
          <p>
            Khách hàng doanh nghiệp được tìm kiếm, kiểm tra giá bán và chiết
            khấu của các CHXD và các tổng kho cung cấp xăng dầu phù hợp với khu
            vực tìm kiếm của khách hàng nhất.
          </p>
          <p>
            Khách hàng doanh nghiệp thực hiện mua hàng theo các bước 1, 2, 3, 4
            tương tự như khách hàng cá nhân.
          </p>
          <p>
            Tuy nhiên khách hàng doanh nghiệp mua hàng với số lượng lớn sẽ liên
            quan tới việc vận chuyển hàng hóa là chất lỏng nguy hiểm. Tại đây,
            khi khách hàng doanh nghiệp đặt hàng mua ở tổng kho có liên kết với
            PYOYO, khách hàng có hai lựa chọn cho việc nhận hàng.
          </p>
          <ol>
            <li>
              Khách hàng có xe sitec - loại xe đặc biệt chuyên chở chất lỏng
              nguy hiểm - thì khách hàng trực tiếp tới tổng kho nhận hàng theo
              đơn đặt hàng
            </li>
            <li>
              Khách hàng chưa có xe sitec, thì chuyên viên phía PYOYO sẽ liên hệ
              với khách hàng để tư vấn về giá cước vận tải - giao tận nơi bằng
              xe sitec của PYOYO. Sau khi thỏa thuận về giá vận tải thì PYOYO sẽ
              điều phối xe sitec để lấy hàng và giao cho khách hàng.
            </li>
          </ol>
        </ContentItem>
      </SmallItem>
    </div>
  )
}

export default ShoppingGuide
