import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, DatePicker, Form, Input, Row } from 'antd'
import 'moment/locale/vi'
import 'moment/locale/en-au'
import localeVN from 'antd/es/date-picker/locale/vi_VN'
import localeES from 'antd/es/date-picker/locale/en_US'
import R from 'assets'
import { TitleStyles } from 'common/components/ContainerPage/styles'
import { getUserInfoAction } from 'features/auth/AuthSlice'
import {
  RadioGroup,
  RadioStyled,
  SelectionBlock,
} from 'features/payment/styled'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GENDER } from 'utils/constants'
import { notificationError, notificationSuccess } from 'utils/notification'
import { EMAIL_REGEX } from 'utils/ruleForm'
import { uploadSingleImageToServer } from 'utils/uploadImageToServer'
import { updateUserInfor } from '../ApiAccount'
import { ButtonStyled, UploadStyled } from '../styled'

type imagesUpload = {
  loading: boolean
  imageUrl: string | null
}

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    notificationError(R.strings().warning_upload_image)
  }
  const isLt2M = file.size / 1024 / 1024 <= 3
  if (!isLt2M) {
    notificationError(R.strings().warning_capacity_image)
  }
  return isJpgOrPng && isLt2M
}

const UserInforTab: React.FC = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [upload, setUpload] = useState<imagesUpload>({
    loading: false,
    imageUrl: '',
  })
  const [gender, setGender] = useState<number>(1)
  const userState: any = useSelector((state: any) => state.authReducer)
    ?.userInfo
  const [language, setLanguage] = useState<string>('vi')

  useEffect(() => {
    form.setFieldsValue({
      phone: userState?.phone,
      name: userState?.name,
      email: userState?.email,
      birthday: userState?.date_of_birth
        ? moment(userState?.date_of_birth)
        : null,
      gender: userState?.gender,
    })
    setGender(userState?.gender)
    setUpload({
      imageUrl: userState?.avatar ? userState?.avatar : null,
      loading: false,
    })
    setLanguage(userState?.language)
  }, [userState])

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true)
      if (validateForm()) {
        let dataPush = {
          email: values.email.trim(),
          name: values.name.trim(),
          avatar: upload.imageUrl
            ? upload.imageUrl.slice(upload.imageUrl.indexOf('upload'))
            : undefined,
          date_of_birth: values.birthday
            ? values.birthday?.toDate()
            : undefined,
          gender: values.gender == null ? undefined : values.gender,
        }

        if (dataPush.email === userState?.email.trim()) {
          delete dataPush.email
        }

        await updateUserInfor(dataPush)

        notificationSuccess(R.strings().update_successful)
        dispatch(getUserInfoAction())
      }
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  const validateForm = () => {
    if (!EMAIL_REGEX.test(form.getFieldValue('email').trim())) {
      notificationError(R.strings().err_email)
      return false
    }
    return true
  }

  const handleChange = async (info: any) => {
    if (info.file.status === 'uploading') {
      setUpload({
        imageUrl: '',
        loading: true,
      })
      return
    }

    if (info.file.status === 'done' || info.file.status === 'error') {
      let resUploadImage: any = await uploadSingleImageToServer(info.file)

      setUpload({
        imageUrl: resUploadImage?.location,
        loading: false,
      })
    }
  }

  const uploadButton = (
    <div>
      {upload.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{R.strings().upload}</div>
    </div>
  )

  return (
    <Row>
      <Col xs={16}>
        <Form
          form={form}
          layout="vertical"
          labelAlign="left"
          scrollToFirstError
          onFinish={values => onFinish(values)}
          style={{ padding: '25px' }}
        >
          <TitleStyles>{R.strings().account_information}</TitleStyles>
          <Form.Item
            name="phone"
            label={R.strings().text_form_placeholder_phone}
          >
            <Input
              placeholder={R.strings().text_form_placeholder_phone}
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            name="name"
            label={R.strings().text_form_name}
            rules={[
              {
                required: true,
                message: R.strings().enter_name,
              },
              {
                whitespace: false,
                message: R.strings().between4and55characters,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    getFieldValue('name').trimEnd().length > 3 &&
                    getFieldValue('name').trimEnd().length < 56
                  ) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(R.strings().between4and55characters)
                  )
                },
              }),
            ]}
          >
            <Input placeholder={R.strings().text_form_name} />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: R.strings().enter_email,
              },
              {
                min: 6,
                max: 55,
                message: R.strings().between6and55characters,
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item name="birthday" label={R.strings().birthday}>
            <DatePicker
              style={{ width: '100%' }}
              format="DD/MM/YYYY"
              locale={language === 'en' ? localeES : localeVN}
              placeholder={R.strings().select_date}
            />
          </Form.Item>
          <Form.Item name="gender" label={R.strings().gender}>
            <RadioGroup value={gender}>
              <SelectionBlock
                className={gender === GENDER.FEMALE ? 'selected' : ''}
                onClick={() => {
                  form.setFieldsValue({ gender: GENDER.FEMALE })
                  setGender(GENDER.FEMALE)
                }}
              >
                <RadioStyled value={GENDER.FEMALE}>
                  {R.strings().female}
                </RadioStyled>
              </SelectionBlock>

              <SelectionBlock
                className={gender === GENDER.MALE ? 'selected' : ''}
                onClick={() => {
                  form.setFieldsValue({ gender: GENDER.MALE })
                  setGender(GENDER.MALE)
                }}
              >
                <RadioStyled value={GENDER.MALE}>
                  {R.strings().male}
                </RadioStyled>
              </SelectionBlock>
            </RadioGroup>
          </Form.Item>
          <Row justify="center">
            <ButtonStyled
              htmlType="submit"
              style={{ width: '50%' }}
              loading={isLoading}
            >
              {R.strings().title_update}
            </ButtonStyled>
          </Row>
        </Form>
      </Col>
      <Col
        xs={8}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          borderLeft: '1px solid #CED4DA',
        }}
      >
        <UploadStyled
          name="avatar"
          listType="picture-card"
          showUploadList={false}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {upload.imageUrl ? (
            <img src={upload.imageUrl} alt="avatar" style={{ width: '100%' }} />
          ) : (
            uploadButton
          )}
        </UploadStyled>
        ({R.strings().click_to_select_photo})
      </Col>
    </Row>
  )
}
export default UserInforTab
